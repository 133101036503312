import { ApiClient } from './client'
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'

import shortNameExtractor from '../../src/services/shortNameExtractor'

import env from '../../env'

const domain = env.playDomain
const headers = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': Linking.createURL('')
}

export default {
	async getScreens(shortName) {
		const url = '/api/app/screens'
		const short_name = shortName || shortNameExtractor(window?.location?.host)
		const platformOS = env.env === 'DEV' ? 'web' : Platform.OS

		return await new ApiClient(domain, headers).get(
			`${url}/${short_name}/${platformOS}`
		)
	},
	async getFeatures(shortName = null) {
		const url = '/api/app/features'
		const short_name = shortName || shortNameExtractor(window?.location?.host)
		const platformOS = env.env === 'DEV' ? 'web' : Platform.OS

		return await new ApiClient(domain, headers, global.PLATFORM_TOKEN_KEY).get(
			`${url}/${short_name}/${platformOS}`
		)
	}
	// async getNav() {
	// 	const url = '/api/app/navigation'
	// 	const short_name = shortNameExtractor(window?.location?.host)

	// 	return await new ApiClient(domain, headers).get(`${url}/${short_name}`)
	// },
	// async getScreen(parentKey, key) {
	// 	const url = '/api/app/screen'
	// 	const short_name = shortNameExtractor(window?.location?.host)
	// 	const data = {
	// 		params: {
	// 			short_name: short_name,
	// 			parentKey: parentKey,
	// 			key: key
	// 		}
	// 	}

	// 	return await new ApiClient(domain, headers).get(url, data)
	// }
}
