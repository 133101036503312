import React, { forwardRef } from 'react'
import { TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'

import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

const Button = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={
				props?.screenSize == 'lg'
					? {
							...styles.button,
							minWidth: props.containerWidth
								? '100%'
								: (props.screenWidth / 4) * 0.8
					  }
					: props?.screenSize == 'md'
					? {
							...styles.button,
							flex: 1
					  }
					: {
							...styles.button,
							flex: 1
					  }
			}
			{...props}
		>
			{!!props?.icon && (
				<Ionicons name={props.icon} size={24} style={styles.icon} />
			)}
			{!!props?.text && (
				<TextWithFont style={styles.text} numberOfLines={1}>
					{props.text}
				</TextWithFont>
			)}

			{!!props?.loader && (
				<ActivityIndicator
					size="small"
					color={
						props?.textColor
							? props?.type == 'transparent' || props?.type == 'border'
								? props.textColor
								: theme.colors.layoutBackground
							: props?.type == 'transparent' || props?.type == 'border'
							? theme.colors.accent
							: theme.colors.layoutBackground
					}
					style={styles.indicator}
				/>
			)}
		</TouchableOpacity>
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Button)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		button: {
			borderRadius: props?.corner === 'square' ? 0 : theme.radiuses.sm,
			backgroundColor:
				props?.type === 'none'
					? 'transparent'
					: props?.textColor
					? props?.type === 'transparent' || props?.type === 'border'
						? opacity(props.textColor, 0.2)
						: props.textColor
					: props?.type === 'transparent' || props?.type === 'border'
					? opacity(theme.colors.accent, 0.2)
					: theme.colors.accent,
			borderWidth: props?.type === 'border' ? 2 : 0,
			borderColor: props?.textColor ? props.textColor : theme.colors.accent,
			opacity: props?.type === 'opacity' || props?.loader ? 0.6 : 1,
			height: 40,
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			marginTop: props.marginTop ? props.marginTop : 0,
			marginRight: props.marginRight ? props.marginRight : 0,
			marginLeft: props.marginLeft ? props.marginLeft : 0,
			padding: theme.spacing.xs
		},
		text: {
			color:
				props?.type === 'none'
					? theme.colors.accent
					: props?.textColor
					? props?.type === 'transparent' || props?.type === 'border'
						? props.textColor
						: theme.colors.layoutBackground
					: props?.type === 'transparent' || props?.type === 'border'
					? theme.colors.accent
					: theme.colors.layoutBackground,
			fontSize: theme.fontSizes.md,
			fontWeight: '600'
		},
		icon: {
			color:
				props?.type === 'none'
					? theme.colors.accent
					: props?.textColor
					? props?.type === 'transparent' || props?.type === 'border'
						? props.textColor
						: theme.colors.layoutBackground
					: props?.type === 'transparent' || props?.type === 'border'
					? theme.colors.accent
					: theme.colors.layoutBackground,
			marginRight: theme.spacing.xs
		},
		indicator: {
			paddingLeft: 10
		}
	})
}
