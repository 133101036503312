class RouteService {
	constructor() {
		this._route = null
	}

	set route(val) {
		this._route = val
	}

	get route() {
		return this._route
	}
}

const routeService = new RouteService()

export default routeService
