import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'

import localeParseDate from '../common/methods/localeParseDate'

import TextWithFont from './TextWithFont'

const CalendarListItem = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.mainContainer}
			onPress={() => props?.onPressEvent(props?.eventData)}
		>
			<View style={styles.contentContainer}>
				<View style={styles.textContainer}>
					{!!props.eventData?.title && (
						<TextWithFont style={styles.title} numberOfLines={1}>
							{props.eventData.title}
						</TextWithFont>
					)}
					{!!props.eventData?.description && (
						<TextWithFont style={styles.contentText} numberOfLines={1}>
							{props.eventData.description}
						</TextWithFont>
					)}
				</View>
				<View style={styles.hourContainer}>
					{!!props.eventData?.start && !!props.eventData?.end && (
						<>
							<TextWithFont style={styles.contentText} numberOfLines={1}>
								{localeParseDate(props.eventData.start)?.toLocaleTimeString(
									props?.localeString,
									{
										hour: '2-digit',
										minute: '2-digit'
									}
								)}
							</TextWithFont>
							<TextWithFont style={styles.contentText} numberOfLines={1}>
								{localeParseDate(props.eventData.end)?.toLocaleTimeString(
									props?.localeString,
									{
										hour: '2-digit',
										minute: '2-digit'
									}
								)}
							</TextWithFont>
						</>
					)}

					{/* {!!props.eventData?.start && (
						<TextWithFont style={styles.contentText} numberOfLines={1}>
							{localeParseDate(props.eventData.start)?.toLocaleTimeString(
								props?.localeString,
								{
									hour: '2-digit',
									minute: '2-digit'
								}
							)}
						</TextWithFont>
					)}
					{!!props.eventData?.end && (
						<TextWithFont style={styles.contentText} numberOfLines={1}>
							{localeParseDate(props.eventData.end)?.toLocaleTimeString(
								props?.localeString,
								{
									hour: '2-digit',
									minute: '2-digit'
								}
							)}
						</TextWithFont>
					)} */}
				</View>
			</View>
		</TouchableOpacity>
	)
}

export default CalendarListItem

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			position: 'relative',
			flex: 1,
			justifyContent: 'flex-start',
			alignItems: 'center',
			flexDirection: 'row',
			flexWrap: 'wrap',
			minHeight: 30,
			width: '100%',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,
			paddingHorizontal: theme.spacing.sm,
			paddingVertical: 5,
			marginTop: 5
		},
		contentContainer: {
			flex: 1,
			justifyContent: 'space-between',
			alignItems: 'stretch',
			flexDirection: 'row'
		},
		textContainer: {
			flex: 1
		},
		hourContainer: {
			justifyContent: 'flex-start'
		},
		title: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		contentText: {
			fontSize: theme.fontSizes.md - 2,
			color: theme.colors.inputText
		}
	})
}
