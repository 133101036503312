import React, { useState, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import { default as ProgressBarLegacy } from 'react-native-progress/Bar'
import { opacity } from 'simpler-color'

import TextWithFont from './TextWithFont'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import localeParseFloat from '../common/methods/localeParseFloat'

import { useTheme } from '../theme/Theme'

const ProgressBar = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [progressBarWidth, setProgressBarWidth] = useState(
		props.screenWidth - 32
	)
	const [value, setValue] = useState(0)
	const [minValue, setMinValue] = useState(0)
	const [maxValue, setMaxValue] = useState(0)
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		let min = localeParseFloat(getMappingValByKey(props, 'minValue') ?? 0)
		if (isNaN(min)) min = 0

		let max = localeParseFloat(getMappingValByKey(props, 'maxValue') ?? 0)
		if (isNaN(max)) max = 0

		let val = localeParseFloat(getMappingValByKey(props, 'value') ?? 0)
		if (isNaN(val)) val = 0

		let prg = val / max
		if (isNaN(prg)) prg = 0

		setValue(val)
		setMinValue(min)
		setMaxValue(max)
		setProgress(prg)
	}, [props])

	return (
		<View style={styles.container}>
			<View style={styles.titleContainer}>
				<TextWithFont style={styles.title}>{props.block.title}</TextWithFont>
				<TextWithFont style={styles.summary}>
					{value}
					{' / '}
					{maxValue}
				</TextWithFont>
			</View>
			<View style={styles.barContainer}>
				<ProgressBarLegacy
					progress={progress}
					width={progressBarWidth}
					height={10}
					borderWidth={0}
					borderRadius={theme.radiuses.md}
					color={opacity(theme.colors.accent, 1)}
					unfilledColor={opacity(theme.colors.accent, 0.2)}
				/>
			</View>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null)(ProgressBar)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		container: {
			flex: 1,
			alignItems: 'center',
			paddingVertical: theme.spacing.sm
		},
		titleContainer: {
			width: '100%',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: 10
		},
		title: {
			color: theme.colors.text,
			fontSize: theme.fontSizes.md
		},
		summary: {
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm
		},
		barContainer: {}
	})
}
