import linkService from '../../../services/linkService'
import routeService from '../../../services/routeService'

import navigateHome from './navigateHome'
import removeLastScreenFromPath from './removeLastScreenFromPath'

const getCurrentRoute = () => {
	return routeService.route
}

export default getCurrentRoute
