import React, { useEffect, useState, useCallback } from 'react'
import { StyleSheet, Platform, TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { connect } from 'react-redux'
import { isSupported } from 'firebase/messaging'

import TextWithFont from './TextWithFont'

import { useTheme } from '../theme/Theme'
import '../global/variables'
import i18n from '../i18n/i18n'

import { serviceWorker } from '../../utils/serviceWorker'
import { firebaseMessaging } from '../../utils/firebase'

import env from '../../env'

const PushPermisson = ({ navigation, route, ...props }) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const isPreviewApp = env.isPreview

	const [showBadge, setShowBadge] = useState(false)
	const [isPushSupported, setIsPushSupported] = useState(false)

	const checkPushSupported = useCallback(async () => {
		const supported = await isSupported()

		setIsPushSupported(supported)
	}, [])

	const askForPermission = () => {
		if (props.config?.settings?.firebase) {
			serviceWorker.askForPermission().then((allowed) => {
				if (allowed) {
					registerForWebPushNotificationsAsync(
						props.config.settings.firebase
					).then((token) => {
						props.setPushToken && props.setPushToken(token)
					})
				}
			})
		}
	}

	useEffect(() => {
		checkPushSupported()
	}, [checkPushSupported])

	useEffect(() => {
		const webAppCheck = !isPreviewApp && Platform.OS === 'web'
		const firebaseCheck = props.config?.settings?.firebase
		const tokenCheck = !props.pushToken
		const permissionCheck =
			serviceWorker.getPermission() !== 'granted' &&
			serviceWorker.getPermission() !== 'denied'

		const show =
			webAppCheck &&
			firebaseCheck &&
			tokenCheck &&
			permissionCheck &&
			isPushSupported

		setShowBadge(show)
	}, [props.pushToken, isPushSupported])

	useEffect(() => {
		const webAppCheck = !isPreviewApp && Platform.OS === 'web'
		const firebaseCheck = props.config?.settings?.firebase
		const permissionCheck = serviceWorker.getPermission() !== 'denied'

		if (webAppCheck && firebaseCheck && permissionCheck && isPushSupported) {
			askForPermission()
		}
	}, [props.pushToken, props.auth, isPushSupported])

	return (
		showBadge && (
			<TouchableOpacity
				activeOpacity={0.8}
				style={styles.permission}
				onPress={askForPermission}
			>
				<Ionicons
					name={'notifications-outline'}
					size={20}
					style={styles.icon}
				/>
				<TextWithFont style={styles.text}>
					{i18n.t('notification.allow')}
				</TextWithFont>
			</TouchableOpacity>
		)
	)
}

async function registerForWebPushNotificationsAsync(firebaseConfig) {
	return serviceWorker
		.init()
		.then((sw) => {
			if (!sw) return

			return firebaseMessaging.getToken(sw, firebaseConfig)
		})
		.catch((err) => {
			console.log('An error occurred while installing service worker.', err)
		})
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.screenSize
})

export default connect(mapStateToProps, null)(PushPermisson)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		permission: {
			backgroundColor: theme.colors.inputBackground,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 1,
			paddingVertical: 10,
			paddingHorizontal: 20,
			borderRadius: 5,
			width: 210,
			position: 'fixed',
			top: 25,
			left: '50%',
			transform: 'translate(-50%, 0)'
		},
		icon: {
			marginRight: 7,
			color: theme.colors.text
		},
		text: {
			fontSize: 14,
			color: theme.colors.text
		}
	})
}
