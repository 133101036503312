import {
	INCREMENTFETCHING,
	DECREMENTFETCHING
} from '../action-types/fetchingActionTypes'

const initialState = {
	fetching: 0
}

const fetchingReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case INCREMENTFETCHING:
			return { ...state, fetching: state.fetching + 1 }
		case DECREMENTFETCHING:
			return { ...state, fetching: state.fetching - 1 }

		default:
			return state
	}
}

export default fetchingReducer
