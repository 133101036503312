import { ApiClient } from './client'
import * as Linking from 'expo-linking'

import env from '../../env'

const domain = env.dataDomain
export default {
	async getData(url, params = null) {
		let headers = {}
		let data = params ? { params: params } : null
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return await new ApiClient(`${domain}/api/data`, headers).get(url, data)
	},
	async postData(url, headers, data = null, conf = null) {
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return await new ApiClient(`${domain}/api/data`, headers).post(
			url,
			JSON.stringify(data),
			conf
		)
	},
	async patchData(url, headers, data = null) {
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return await new ApiClient(`${domain}/api/data`, headers).patch(
			url,
			JSON.stringify(data)
		)
	},
	async deleteData(url, headers) {
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return await new ApiClient(`${domain}/api/data`, headers).delete(url)
	}
}
