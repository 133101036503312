import React from 'react'
import { View, Platform, TextInput, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

const LoginTextEntry = (props) => {
	const theme = useTheme()
	const { error, titleText, requiredText, infoText, ...restProps } = props
	const styles = themedStyles(theme, !error)

	return (
		<View style={styles.mainContainer}>
			<View>
				<TextInput
					{...restProps}
					style={styles.input}
					placeholderTextColor={theme.colors.inputText}
				/>
				{error ? (
					<TextWithFont style={styles.errorText}>{error.message}</TextWithFont>
				) : (
					<TextWithFont style={styles.requiredText}>{infoText}</TextWithFont>
				)}
			</View>
		</View>
	)
}

export default LoginTextEntry

const themedStyles = (theme, isValid) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: 10,
			paddingHorizontal: theme.spacing.xl,
			justifyContent: 'space-between'
		},
		topTextContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: 3
		},
		input:
			Platform.OS === 'web'
				? {
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						paddingHorizontal: theme.spacing.sm,
						height: 40,
						borderWidth: isValid ? 0 : 1,
						outlineStyle: 'none',
						borderColor: isValid
							? theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  }
				: {
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						paddingHorizontal: theme.spacing.sm,
						height: 40,
						borderWidth: isValid ? 0 : 1,
						borderColor: isValid
							? theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  },
		requiredText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputText,
			marginTop: 5
		},
		errorText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputTextDanger,
			marginTop: 5
		}
	})
}
