import {
	SETUSERINFO,
	SETORGID,
	SETGHOSTUSER
} from '../action-types/userInfoActionTypes'

const initialState = {
	userInfo: null,
	ghostUser: null,
	orgId: null
}

const userInfoReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETUSERINFO:
			return { ...state, userInfo: action.payload }
		case SETGHOSTUSER:
			return { ...state, ghostUser: action.payload }
		case SETORGID:
			return { ...state, orgId: action.payload }

		default:
			return state
	}
}

export default userInfoReducer
