import React, {
	useState,
	useEffect,
	forwardRef,
	useCallback,
	useImperativeHandle
} from 'react'
import {
	View,
	StyleSheet,
	ActivityIndicator,
	TouchableOpacity
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { MaterialIcons } from '@expo/vector-icons'
import { opacity } from 'simpler-color'

import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/tr'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'
import SearchBox from './SearchBox'

import {
	GiftedChat,
	IMessage,
	Send,
	SendProps,
	InputToolbar,
	Bubble,
	BubbleProps,
	SystemMessage,
	LeftAction,
	ChatInput,
	SendButton
} from 'react-native-gifted-chat'

import dataClient from '../services/dataClient'
import getLanguageCode from '../common/methods/getLanguageCode'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import localeParseDate from '../common/methods/localeParseDate'
import showToast from '../common/methods/showToast'
import triggerActionList from '../common/methods/triggerActionList'
import calculateScreenHeight from '../common/methods/calculateScreenHeight'
import i18n from '../i18n/i18n'

const defaultPageSize = 25

const Chat = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(
		props,
		theme,
		calculateScreenHeight(props.block.screenStyle, props.block.showTabBar)
	)
	const localeString = getLanguageCode() === 'tr-TR' ? 'tr' : 'en'

	const [messages, setMessages] = useState(null)
	const [inputHeight, setInputHeight] = useState(40)

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const pageSize = props.block?.pageSize
		? props.block.pageSize
		: defaultPageSize

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)

	const [allShown, setAllShown] = useState(
		componentDetail?.config?.allShown ?? true
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)
	const [fetching, setFetching] = useState(false)
	const [noRecords, setNoRecords] = useState(
		componentDetail?.config?.noRecords ?? false
	)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data
				//null,
				//props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const getMoreData = () => {
		if (!allShown) {
			const newPage = page + 1
			setPage(newPage)
		}
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						const filteredOldList = (page === 1 ? [] : listData ?? []).filter(
							(x) => !x?.isEmpty
						)
						const managedListData = [
							...filteredOldList,
							...response.data.records
						]

						setListData(managedListData)

						if (managedListData.length === 0) {
							setNoRecords(true)
						} else {
							setNoRecords(false)

							if (managedListData.length === response.data.total) {
								setAllShown(true)
							} else {
								setAllShown(false)
							}
						}
					} else {
						setAllShown(true)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const onSend = useCallback((newMessages = []) => {
		setMessages((previousMessages) => {
			let request = {}
			request[props.block?.mapping?.message?.field] = newMessages?.[0]?.['text']

			const blockWithData = {
				...props.block,
				screenData: props.block.data,
				data: request
			}

			triggerActionList(blockWithData, props.block.actions, blockWithData.id)

			return GiftedChat.append(previousMessages, newMessages)
		})
		setInputHeight(40)
	}, [])

	const renderSend = useCallback((props) => {
		return (
			<Send
				{...props}
				containerStyle={{
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'transparent',
					marginLeft: theme.spacing.xs
				}}
			>
				<MaterialIcons size={20} color={theme.colors.accent} name={'send'} />
			</Send>
		)
	}, [])

	const renderBubble = useCallback((props) => {
		return (
			<Bubble
				{...props}
				textStyle={{
					left: {
						color: theme.colors.textLight,
						fontSize: theme.fontSizes.md
					},
					right: {
						color: theme.colors.text,
						fontSize: theme.fontSizes.md
					}
				}}
				timeTextStyle={{
					left: {
						color: theme.colors.textLight
					},
					right: {
						color: theme.colors.text
					}
				}}
				linkStyle={{
					left: {
						color: theme.colors.textLight
					},
					right: {
						color: theme.colors.text
					}
				}}
				wrapperStyle={{
					left: {
						backgroundColor: theme.colors.inputBackground,
						borderBottomRightRadius: theme.radiuses.md,
						borderBottomLeftRadius: theme.radiuses.md,
						borderTopRightRadius: theme.radiuses.md,
						borderTopLeftRadius: 0
					},
					right: {
						backgroundColor: opacity(theme.colors.accent, 0.5),
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: theme.radiuses.md,
						borderTopRightRadius: theme.radiuses.md,
						borderTopLeftRadius: theme.radiuses.md
					}
				}}
			/>
		)
	}, [])

	const renderLoadEarlier = (props) => {
		return (
			<View style={styles.moreContainer}>
				<TouchableOpacity
					activeOpacity={0.8}
					style={styles.moreButton}
					onPress={() => {
						getMoreData()
					}}
				>
					<TextWithFont
						style={{
							fontSize: theme.fontSizes.sm,
							color: theme.colors.textLight
						}}
					>
						{i18n.t('chat.more')}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		)
	}

	const customtInputToolbar = useCallback((props) => {
		return (
			<InputToolbar
				{...props}
				containerStyle={{
					flex: 1,
					justifyContent: 'flex-start',
					alignItems: 'stretch',
					backgroundColor: 'transparent',
					borderTopColor: theme.colors.line,
					borderTopWidth: 0,
					marginTop: 10,
					paddingTop: 5
				}}
			/>
		)
	}, [])

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			listData,
			{
				page: page,
				allShown: allShown,
				noRecords: noRecords,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [listData])

	useEffect(() => {
		const msgs = listData?.map((item) => {
			return {
				_id: props.block?.mapping?.id?.field
					? getMappingValByKey(props, 'id', item.fields)
					: null,
				text: props.block?.mapping?.message?.field
					? getMappingValByKey(props, 'message', item.fields)
					: null,
				user: {
					_id: props.block?.mapping?.userEmail?.field
						? getMappingValByKey(props, 'userEmail', item.fields)
						: null,
					name: props.block?.mapping?.userName?.field
						? getMappingValByKey(props, 'userName', item.fields)
						: null,
					avatar: props.block?.mapping?.userPhoto?.field
						? getMappingValByKey(props, 'userPhoto', item.fields)
						: null
				},
				createdAt: props.block?.mapping?.time?.field
					? localeParseDate(getMappingValByKey(props, 'time', item.fields))
					: null
			}
		})

		setMessages(msgs)
	}, [listData])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	return (
		messages?.length >= 0 && (
			<View key={props.block._uid} style={styles.container}>
				<View style={styles.innerContainer}>
					<SearchBox
						block={{
							title: props.block.title,
							route: props?.block?.route,
							spacing: 'xl',
							searcheable: props.block?.searcheable,
							search: search,
							searchTerm: searchTerm,
							searchPlaceholder: getLocalizeContent(
								props.block?.searchPlaceholder
							)
						}}
					/>
					<GiftedChat
						placeholder={i18n.t('chat.placeholder')}
						locale={localeString}
						alwaysShowSend
						// renderUsernameOnMessage
						quickReplyTextStyle={{
							backgroundColor: 'transparent'
						}}
						listViewProps={{
							showsVerticalScrollIndicator: false
						}}
						messages={messages}
						onSend={(messages) => {
							onSend(messages)
						}}
						user={{
							_id: props.userInfo?.email,
							name: props.userInfo?.name,
							avatar: props.userInfo?.photo
						}}
						loadEarlier={!allShown}
						renderLoadEarlier={renderLoadEarlier}
						renderSend={renderSend}
						renderBubble={renderBubble}
						renderInputToolbar={customtInputToolbar}
						textInputProps={{
							onContentSizeChange: (event) => {
								setInputHeight(event.nativeEvent.contentSize.height)
							},
							style: {
								flexGrow: 1,
								backgroundColor: theme.colors.inputBackground,
								outlineColor: 'transparent',
								borderColor: 'transparent',
								borderStyle: 'solid',
								borderWidth: 0,
								color: theme.colors.inputText,
								borderRadius: theme.radiuses.sm,
								fontSize: theme.fontSizes.md,
								marginLeft: 0,
								padding: 10,
								height: Math.min(120, Math.max(35, inputHeight))
							}
						}}
					/>
				</View>
			</View>
		)
	)
})

const themedStyles = (props, theme, height) => {
	return StyleSheet.create({
		container: {
			position: 'relative',
			width: '100%',
			height: height
		},
		innerContainer: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'center',
			alignItems: 'stretch',
			overflow: 'hidden',
			paddingTop: props.block?.searcheable ? 0 : theme.spacing.sm,
			paddingBottom: theme.spacing.sm
		},
		moreContainer: {
			flex: 1,
			alignItems: 'center',
			justifyContent: 'center'
		},
		moreButton: {
			flex: 1,
			backgroundColor: theme.colors.inputBackground,
			justifyContent: 'center',
			alignItems: 'center',
			paddingHorizontal: theme.spacing.sm,
			paddingVertical: theme.spacing.xs,
			borderRadius: theme.radiuses.sm
		}
	})
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Chat)
