import { combineReducers } from 'redux'

import themeReducer from './themeReducer'
import generatorDataReducer from './generatorDataReducer'
import authReducer from './authReducer'
import userInfoReducer from './userInfoReducer'
import fetchingReducer from './fetchingReducer'
import configReducer from './configReducer'
import tempDetailReducer from './tempDetailReducer'
import routeDataReducer from './routeDataReducer'
import screenSizeReducer from './screenSizeReducer'
import screenWidthReducer from './screenWidthReducer'
import windowWidthReducer from './windowWidthReducer'
import componentDataReducer from './componentDataReducer'
import builderOnMobileReducer from './builderOnMobileReducer'

const rootReducer = combineReducers({
	theme: themeReducer,
	generatorData: generatorDataReducer,
	auth: authReducer,
	userInfo: userInfoReducer,
	fetching: fetchingReducer,
	config: configReducer,
	tempDetail: tempDetailReducer,
	routes: routeDataReducer,
	screenSize: screenSizeReducer,
	screenWidth: screenWidthReducer,
	windowWidth: windowWidthReducer,
	components: componentDataReducer,
	builderOnMobile: builderOnMobileReducer
})

export default rootReducer
