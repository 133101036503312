import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useRef
} from 'react'
import {
	View,
	ActivityIndicator,
	TouchableOpacity,
	Platform,
	FlatList,
	StyleSheet
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import Review from './Review'
import TextWithFont from './TextWithFont'
import SearchBox from './SearchBox'

import dataClient from '../services/dataClient'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'

const defaultPageSize = 25

const Reviews = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [imageRadius, setImageRadius] = useState(
		componentDetail?.config?.imageRadius ?? theme.radiuses.none
	)

	const pageSize = props.block?.pageSize
		? props.block.pageSize
		: defaultPageSize

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)

	const [allShown, setAllShown] = useState(
		componentDetail?.config?.allShown ?? true
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)
	const [fetching, setFetching] = useState(false)
	const [noRecords, setNoRecords] = useState(
		componentDetail?.config?.noRecords ?? false
	)

	const noRecordsMessage = getLocalizeContent(props.block?.emptyState)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data
				//null,
				//props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getMoreData = () => {
		if (!allShown) {
			const newPage = page + 1
			setPage(newPage)
		}
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						const filteredOldList = (page === 1 ? [] : listData ?? []).filter(
							(x) => !x?.isEmpty
						)
						const managedListData = [
							...filteredOldList,
							...response.data.records
						]

						if (managedListData.length === 0) {
							setNoRecords(true)
						} else {
							setNoRecords(false)

							if (managedListData.length === response.data.total) {
								setAllShown(true)
							} else {
								setAllShown(false)
							}
						}

						setListData(managedListData)
					} else {
						setAllShown(true)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const footerIndicator = () => {
		return !allShown ? (
			<View style={styles.moreContainer}>
				<TouchableOpacity activeOpacity={0.8} onPress={getMoreData}>
					<TextWithFont style={styles.moreText}>
						{i18n.t('reviews.moreText')}
						{/* {props.block?.moreText
							? getLocalizeContent(props.block.moreText)
							: i18n.t('reviews.moreText')} */}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		) : null
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			listData,
			{
				imageRadius: imageRadius,
				page: page,
				allShown: allShown,
				noRecords: noRecords,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [listData])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	useEffect(() => {
		if (props.block.corner == 'circle') {
			setImageRadius(props.screenWidth / 2)
		} else if (props.block.corner == 'oval') {
			setImageRadius(theme.radiuses.md)
		} else {
			setImageRadius(theme.radiuses.none)
		}
	}, [props.screenWidth, props.block.corner])

	return listData ? (
		<View key={props.block._uid}>
			<SearchBox
				block={{
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>

			{noRecords ? (
				noRecordsMessage && (
					<TextWithFont style={styles.noRecords}>
						{noRecordsMessage}
					</TextWithFont>
				)
			) : (
				<FlatList
					keyExtractor={(item, index) => String(index)}
					// contentContainerStyle={{ flexGrow: 1 }}
					listKey={props.block._uid}
					key={`${props.block._uid}-${props.block.horizontal}`}
					ListFooterComponent={footerIndicator}
					data={listData || []}
					horizontal={props.block.horizontal}
					showsHorizontalScrollIndicator={false}
					numColumns={1}
					ItemSeparatorComponent={({ highlighted }) => (
						<View
							style={{
								width: theme.spacing[props.block.spacing],
								height: theme.spacing[props.block.spacing]
							}}
						/>
					)}
					style={{
						paddingVertical: theme.spacing[props.block.spacing]
					}}
					renderItem={({ item, index, separators }) => (
						<Review
							index={index}
							data={item.fields}
							id={getMappingValByKey(props, 'id', item.fields)}
							userEmail={getMappingValByKey(props, 'userEmail', item.fields)}
							userName={getMappingValByKey(props, 'userName', item.fields)}
							userPhoto={getMappingValByKey(props, 'userPhoto', item.fields)}
							rating={getMappingValByKey(props, 'rating', item.fields)}
							time={getMappingValByKey(props, 'time', item.fields)}
							maxRating={props.block.maxRating}
							messageLineLimit={props.block.messageLineLimit}
							corner={props.block.corner}
							imageRadius={imageRadius}
							horizontal={props.block.horizontal}
							spacing={theme.spacing[props.block.spacing]}
							isEmpty={item?.isEmpty}
							block={{
								navigation: props.block.navigation,
								data: item.fields,
								screenData: props.block?.data,
								id: getMappingValByKey(props, 'id', item.fields),
								mapping: props.block.mapping,
								actions: props.block.actions
							}}
						/>
					)}
				/>
			)}
		</View>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Reviews)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		moreContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: 40,
			minWidth: 130
		},
		moreText: {
			fontSize: theme.fontSizes.sm,
			fontWeight: 'bold',
			color: theme.colors.textLight
		},
		noRecords: {
			flex: 1,
			paddingTop: theme.spacing.xs,
			paddingBottom: theme.spacing.sm,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		}
	})
}
