import React from 'react'
import { View, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

const CummunicationButton = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<View style={styles.mainContainer}>
			<View style={{ flex: 1 }}>
				<TextWithFont style={styles.titleText} numberOfLines={1}>
					{props.title}
				</TextWithFont>
				<TextWithFont style={styles.contentText} numberOfLines={1}>
					{props.text}
				</TextWithFont>
			</View>
			<View style={styles.buttonContainer}>{props.children}</View>
		</View>
	)
}

export default CummunicationButton

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			height: 66,
			// backgroundColor: 'color-white',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexDirection: 'row'
		},
		titleText: {
			fontSize: theme.fontSizes[props.fontSize],
			color: theme.colors.inputText
		},
		contentText: {
			fontSize: theme.fontSizes[props.fontSize],
			color: theme.colors.text
		},
		buttonContainer: {
			flexDirection: 'row'
		}
	})
}
