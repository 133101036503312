import React from 'react'
import { Text, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'

const TextWithFont = (props) => {
	const theme = useTheme()
	const styles = themedStyles(theme)

	const { style, children, ...restProps } = props

	return (
		<Text style={[style, styles.text]} {...restProps}>
			{children}
		</Text>
	)
}

const themedStyles = (theme) => {
	return StyleSheet.create({
		text: {
			fontFamily: theme.fontFamily
		}
	})
}

export default TextWithFont
