import {
	ADDCOMPONENTDATA,
	REMOVECOMPONENTDATA
} from '../action-types/componentDataActionTypes'

const initialState = {
	components: []
}

const componentDataReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case ADDCOMPONENTDATA: {
			let components = [...state?.components] || []
			const route = components.find((x) => x.uuid == action.uuid)
			if (route) {
				route.uuid = action.uuid
				route.data = action.data
				route.config = action.config
				route.time = action.time
			} else {
				components.push({
					uuid: action.uuid,
					data: action.data,
					config: action.config,
					time: action.time
				})
			}

			return {
				components: components
			}
		}

		case REMOVECOMPONENTDATA: {
			let components = [...state?.components] || []

			const component = components.find((x) => x.uuid === action.uuid)
			if (component) {
				const componentIndex = components.indexOf(component)
				if (componentIndex > -1) components.splice(componentIndex, 1)
			}

			return {
				components: components
			}
		}
		default:
			return state
	}
}

export default componentDataReducer
