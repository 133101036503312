import {
	ADDCOMPONENTDATA,
	REMOVECOMPONENTDATA
} from '../action-types/componentDataActionTypes'

export const addComponentData = (uuid, data, config, time) => ({
	type: ADDCOMPONENTDATA,
	uuid: uuid,
	data: data,
	config: config,
	time: time
})
export const removeComponentData = (uuid) => ({
	type: REMOVECOMPONENTDATA,
	uuid: uuid
})
