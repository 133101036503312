import React, { forwardRef } from 'react'

import TextEntry from './TextEntry'

const InputPhone = forwardRef((props, ref) => {
	return (
		<TextEntry
			ref={ref}
			// mask={}
			// maxLength={}
			// minLength={}
			regExp={/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/}
			isRegExString={false}
			{...props.block}
		/>
	)
})

export default InputPhone
