import React, { useEffect, useState } from 'react'
import {
	Platform,
	SafeAreaView,
	ScrollView,
	StatusBar,
	StyleSheet
} from 'react-native'

import CustomModal from './CustomModal'
import CustomBottomSheet from './CustomBottomSheet'
import CustomPopup from './CustomPopup'

import { useTheme } from '../theme/Theme'
import nav from '../common/methods/nav'

const ScreenContainer = ({ route, ...props }) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [visible, setVisible] = useState(true)

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	const cancel = () => {
		setVisible(false)
		nav.navigateBack()
	}

	return props.screenConfig?.style === 'modal' ? (
		props.screenSize !== 'lg' ? (
			<CustomModal
				isVisible={visible}
				fullHeight
				preventParentEvent={preventParentEvent}
				cancel={cancel}
			>
				{props?.children}
			</CustomModal>
		) : (
			<CustomPopup
				isVisible={visible}
				preventParentEvent={preventParentEvent}
				cancel={cancel}
			>
				{props?.children}
			</CustomPopup>
		)
	) : props.screenConfig?.style === 'bottom' ? (
		props.screenSize !== 'lg' ? (
			<CustomBottomSheet
				// name={'screen-bottom-sheet'}
				isVisible={visible}
				preventParentEvent={preventParentEvent}
				cancel={cancel}
			>
				{props?.children}
			</CustomBottomSheet>
		) : (
			<CustomPopup
				isVisible={visible}
				preventParentEvent={preventParentEvent}
				cancel={cancel}
			>
				{props?.children}
			</CustomPopup>
		)
	) : props.screenConfig?.style === 'popup' ? (
		<CustomPopup
			isVisible={visible}
			preventParentEvent={preventParentEvent}
			cancel={cancel}
		>
			{props?.children}
		</CustomPopup>
	) : (
		<SafeAreaView style={styles.mainContainer}>
			{Platform.OS != 'web' && (
				<StatusBar
					backgroundColor={theme.colors.headerBackground}
					barStyle={theme.statusBar.style}
					showHideTransition="none"
					translucent={false}
					hidden={false}
				/>
			)}
			{props?.children}
		</SafeAreaView>
	)
}

export default ScreenContainer

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			position: 'relative',
			backgroundColor:
				props.screenConfig?.style === 'modal' ||
				props.screenConfig?.style === 'bottom' ||
				props.screenConfig?.style === 'popup'
					? 'transparent'
					: props?.bgColor
					? props?.bgColor
					: theme.colors.layoutBackground
		}
	})
}
