import { ApiClient } from './client'
import * as Linking from 'expo-linking'

import '../../src/global/variables'
import env from '../../env'

const domain = env.platformDomain
const headers = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': Linking.createURL('')
}

export default {
	async getFeatures(id) {
		const url = '/api/app/features'
		return await new ApiClient(domain, headers, global.PLATFORM_TOKEN_KEY).get(
			`${url}/${id}`
		)
	},
	async getScreens(id) {
		const url = '/api/app/screens'
		return await new ApiClient(domain, headers, global.PLATFORM_TOKEN_KEY).get(
			`${url}/${id}`
		)
	}
}
