import React from 'react'
import { BaseToast, ErrorToast } from 'react-native-toast-message'
import { useTheme } from '../theme/Theme'

const toastConfig = {
	success: (props) => {
		const theme = useTheme()
		return (
			<BaseToast
				{...props}
				style={{
					borderLeftColor: 'green',
					backgroundColor: theme.colors.headerBackground,
					maxWidth: 300
				}}
				contentContainerStyle={{
					paddingHorizontal: 15,
					borderColor: 'gray',
					borderWidth: 1,
					borderLeftWidth: 0,
					borderTopRightRadius: 6,
					borderBottomRightRadius: 6
				}}
				text1Style={{
					color: theme.colors.headerText
				}}
				text2Style={{
					color: theme.colors.headerText
				}}
			/>
		)
	},
	error: (props) => {
		const theme = useTheme()
		return (
			<ErrorToast
				{...props}
				style={{
					borderLeftColor: 'red',
					backgroundColor: theme.colors.headerBackground,
					maxWidth: 300
				}}
				contentContainerStyle={{
					paddingHorizontal: 15,
					borderColor: 'gray',
					borderWidth: 1,
					borderLeftWidth: 0,
					borderTopRightRadius: 6,
					borderBottomRightRadius: 6
				}}
				text1Style={{
					color: theme.colors.headerText
				}}
				text2Style={{
					color: theme.colors.headerText
				}}
			/>
		)
	}
}

export default toastConfig
