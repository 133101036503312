import { Platform } from 'react-native'
import { ApiClient } from './client'
import * as Linking from 'expo-linking'

import '../global/variables'
import env from '../../env'

const domain = env.authDomain

export default {
	async invite(data) {
		let headers = {}
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return await new ApiClient(domain, headers).post('/api/a/invite', data)
	}
}
