import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { WebView as ExpoWebView } from 'react-native-webview'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import calculateScreenHeight from '../common/methods/calculateScreenHeight'

const WebView = (props) => {
	const theme = useTheme()
	const styles = themedStyles(
		props,
		theme,
		calculateScreenHeight(props.block.screenStyle, props.block.showTabBar)
	)
	return Platform.OS === 'web' ? (
		<View style={styles.aspectRatioContainer}>
			<View style={styles.aspectRatioContent}>
				<iframe
					style={{
						width: '100%',
						height: '100%',
						border: 'none'
					}}
					scrolling={props.block.scrollable ? 'auto' : 'no'} //"auto | yes | no"
					src={getMappingValByKey(props, 'url')}
				></iframe>
			</View>
		</View>
	) : (
		<ExpoWebView
			originWhitelist={['*']}
			style={styles.webView}
			source={{ uri: getMappingValByKey(props, 'url') }}
			nestedScrollEnabled={props.block.scrollable}
			scrollEnabled={props.block.scrollable}
			showsVerticalScrollIndicator={false}
		/>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(WebView)

const themedStyles = (props, theme, height) => {
	return StyleSheet.create({
		mainContainer: {
			width: '100%',
			flexDirection: 'row',
			justifyContent: 'center',
			padding: 0
		},
		webView: {
			width: '100%',
			aspectRatio: props.block.appearance == 'square' ? 1 / 1 : 7 / 5 //rectangle,square
		},
		aspectRatioContainer:
			props.block.appearance == 'square'
				? {
						position: 'relative',
						paddingTop: props.screenSize === 'lg' ? '71.42%' : '100%',
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: props.block.appearance == 'rectangle'
				? {
						position: 'relative',
						paddingTop: '71.42%', // 7:5
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: {
						position: 'relative',
						width: 'auto',
						height: height,
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  },
		aspectRatioContent: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		}
	})
}
