import React from 'react'
import {
	StyleSheet,
	Platform,
	Linking,
	Image as NativeImage,
	TouchableOpacity
} from 'react-native'

import { SvgUri } from 'react-native-svg'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import '../global/variables'
import env from '../../env'

const visitKozmikLink = 'https://kozmik.io'
const visitKozmikIcon = 'https://files.kozmik.io/cdn/builtWithKozmik.svg'
const visitKozmikNavyIcon =
	'https://files.kozmik.io/cdn/builtWithKozmikNavy.svg'

const BuiltWithKozmik = ({ navigation, route, ...props }) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const isPreviewApp = env.isPreview

	const visitKozmik = () => {
		if (Platform.OS === 'web') {
			window.open(visitKozmikLink, '_blank')
		} else {
			Linking.openURL(visitKozmikLink)
		}
	}

	return props.screenStyle === 'screen' &&
		(!props.config?.settings?.plan ||
			props.config?.settings?.plan?.includes('free')) ? (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.visitKozmik}
			onPress={() => {
				visitKozmik()
			}}
		>
			{Platform.OS === 'web' ? (
				<NativeImage
					resizeMode={'cover'}
					width={135}
					height={27}
					style={styles.visitKozmikIcon}
					source={{
						uri:
							props.config?.appearence?.Theme == 'Dark'
								? visitKozmikIcon
								: visitKozmikNavyIcon
					}}
				/>
			) : (
				<SvgUri
					width={135}
					height={27}
					style={styles.visitKozmikIcon}
					uri={
						props.config?.appearence?.Theme == 'Dark'
							? visitKozmikIcon
							: visitKozmikNavyIcon
					}
				/>
			)}
		</TouchableOpacity>
	) : null
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.screenSize
})

export default connect(mapStateToProps, null)(BuiltWithKozmik)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		visitKozmik: {
			position: Platform.OS === 'web' ? 'fixed' : 'absolute',
			zIndex: 1,
			left: theme.spacing.xl,
			bottom: props.screenSize === 'lg' || !props.showTabBar ? 25 : 85
		},
		visitKozmikIcon: {
			width: 135,
			height: 27,
			backgroundColor: 'transparent'
		}
	})
}
