import React, { forwardRef } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'

import { Barcode as BarcodeLegacy } from 'expo-barcode-generator'

import triggerActionList from '../common/methods/triggerActionList'
import getMappingValByKey from '../common/methods/getMappingValByKey'

import { useTheme } from '../theme/Theme'

const Barcode = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const codeText = getMappingValByKey(props, 'text')

	return (
		<View key={props.block._uid} style={styles.mainContainer}>
			<TouchableOpacity
				activeOpacity={0.8}
				style={styles.action}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			>
				<BarcodeLegacy
					value={codeText}
					options={{
						format: props.block.type ?? 'CODE128',
						// format: 'CODE39',
						// format: 'CODE128',
						// format: 'CODE128A',
						// format: 'CODE128B',
						// format: 'CODE128C',
						// format: 'EAN13',
						// format: 'EAN8',
						// format: 'UPC',
						// format: 'ITF14',
						// format: 'codabar',
						background: '#FFFFFF',
						marginTop: 0,
						marginBottom: 0,
						marginLeft: 0,
						marginRight: 0
					}}
				/>
			</TouchableOpacity>
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Barcode)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			paddingVertical: theme.spacing.sm,
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		},
		action: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			backgroundColor: '#FFFFFF',
			paddingHorizontal: theme.spacing.sm,
			borderRadius: 6
		}
	})
}
