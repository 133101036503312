import React, { useState } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import tokenServices from '../services/tokenServices'

import '../global/variables'
import authorizationClient from '../services/authorizationClient'
import { setAuth } from '../redux/actions/authActions'
import LoginTextEntry from '../components/LoginTextEntry'
import LoginButton from '../components/LoginButton'
import TextWithFont from '../components/TextWithFont'
import AuthScreenComponent from '../components/AuthScreenComponent'

import i18n from '../i18n/i18n'
import BuiltWithKozmik from '../components/BuiltWithKozmik'

const Login = (props) => {
	const theme = useTheme()
	const { route, navigation } = props
	const styles = themedStyles(theme)

	const [errorMessage, setErrorMessage] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [pinSent, setPinSent] = useState(false)

	/*********** */
	const {
		register,
		setValue,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: '',
			pin: '',
			password: ''
		}
	})

	const onSendPin = (data) => {
		setSubmitting(true)
		setErrorMessage(null)

		if (props?.config?.settings?.login_method === 'Pin') {
			authorizationClient
				.sendPin(data)
				.then((response) => {
					if (response?.data?.success) {
						setPinSent(true)
					} else {
						setErrorMessage(
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
					setSubmitting(false)
				})
				.catch(() => {
					setSubmitting(true)
				})
		}
	}

	const onSubmit = (data) => {
		setSubmitting(true)
		setErrorMessage(null)

		if (props?.config?.settings?.login_method === 'EmailPassword') {
			authorizationClient
				.login(data)
				.then((response) => {
					if (response?.data?.success) {
						tokenServices.add(response.data.data)

						props.setAuth(true)
					} else {
						setErrorMessage(
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
					setSubmitting(false)
				})
				.catch(() => {
					setSubmitting(true)
				})
		} else if (props?.config?.settings?.login_method === 'Pin') {
			authorizationClient
				.loginByPin(data)
				.then((response) => {
					if (response?.data?.success) {
						tokenServices.add(response.data.data)

						props.setAuth(true)
					} else {
						setErrorMessage(
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
					setSubmitting(false)
				})
				.catch(() => {
					setSubmitting(true)
				})
		}
	}

	const handleUserKeyPress = (e) => {
		if (props?.config?.settings?.login_method === 'EmailPassword') {
			if (e.key === 'Enter' && !e.shiftKey) {
				handleSubmit(onSubmit)()
			}
		}
	}

	return (
		<AuthScreenComponent
			signInRequired={props.signInRequired}
			screens={props.screens}
			route={route}
			navigation={navigation}
		>
			<BuiltWithKozmik />

			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<View
					style={styles.mainContainer}
					// showsVerticalScrollIndicator={false}
				>
					<View>
						{/* <TextWithFont style={styles.pageTitle}>
							{i18n.t('login.pageTitle')}
						</TextWithFont> */}
						{!!props?.config?.manifest?.name && (
							<TextWithFont style={styles.title}>
								{props.config.manifest.name}
							</TextWithFont>
						)}

						{props?.config?.settings?.login_method === 'EmailPassword' && (
							<TextWithFont style={styles.subTitle}>
								{i18n.t('login.subTitle')}
							</TextWithFont>
						)}
						{props?.config?.settings?.login_method === 'Pin' && (
							<TextWithFont style={styles.subTitle}>
								{i18n.t('login.pinSubTitle')}
							</TextWithFont>
						)}
					</View>

					{props?.config?.settings?.login_method === 'EmailPassword' && (
						<>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<LoginTextEntry
										// titleText={i18n.t('login.mailInput.title')}
										requiredText={i18n.t('login.required')}
										placeholder={i18n.t('login.mailInput.title')}
										onBlur={onBlur}
										onChangeText={(value) => onChange(value)}
										value={value}
										error={errors?.email}
										onKeyPress={handleUserKeyPress}
									/>
								)}
								name="email"
								rules={{
									required: {
										value: true,
										message: i18n.t('login.mailInput.requredError')
									},
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: i18n.t('login.mailInput.patternError')
									}
									// validate: (value) =>
									// 	value === getValues('OTHER_FIELD_NAME') ||
									// 	'Password is not correct'
								}}
							/>
							<Controller
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<LoginTextEntry
										requiredText={i18n.t('login.required')}
										placeholder={i18n.t('login.passwordInput.title')}
										secureTextEntry={true}
										onBlur={onBlur}
										onChangeText={(value) => onChange(value)}
										value={value}
										error={errors?.password}
										onKeyPress={handleUserKeyPress}
									/>
								)}
								name="password"
								rules={{
									required: {
										value: true,
										message: i18n.t('login.passwordInput.requredError')
									}
								}}
							/>

							<View style={styles.alignRightBox}>
								<TouchableOpacity
									activeOpacity={0.8}
									onPress={() => navigation.navigate('forgotpassword')}
								>
									<TextWithFont style={styles.forgotText}>
										{i18n.t('login.forgotPassword')}
									</TextWithFont>
								</TouchableOpacity>
							</View>

							<View style={styles.buttonContainer}>
								<LoginButton
									text={i18n.t('login.loginButton')}
									onPress={submitting ? null : handleSubmit(onSubmit)}
									loader={submitting}
								/>
							</View>
							{!props.inviteOnly && (
								<TouchableOpacity
									activeOpacity={0.8}
									onPress={() => navigation.navigate('signup')}
								>
									<TextWithFont style={styles.bottomText}>
										{i18n.t('login.noAccount')}{' '}
										<TextWithFont style={{ color: theme.colors.accent }}>
											{i18n.t('login.register')}
										</TextWithFont>
									</TextWithFont>
								</TouchableOpacity>
							)}
						</>
					)}

					{props?.config?.settings?.login_method === 'Pin' && (
						<>
							{pinSent ? (
								<>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<LoginTextEntry
												requiredText={i18n.t('login.required')}
												placeholder={i18n.t('login.mailInput.title')}
												onBlur={onBlur}
												onChangeText={(value) => onChange(value)}
												value={value}
												error={errors?.email}
												onKeyPress={handleUserKeyPress}
												editable={false}
											/>
										)}
										name="email"
										rules={{
											required: {
												value: true,
												message: i18n.t('login.mailInput.requredError')
											},
											pattern: {
												value:
													/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												message: i18n.t('login.mailInput.patternError')
											}
											// validate: (value) =>
											// 	value === getValues('OTHER_FIELD_NAME') ||
											// 	'Password is not correct'
										}}
									/>

									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<LoginTextEntry
												requiredText={i18n.t('login.required')}
												placeholder={i18n.t('login.pinInput.title')}
												secureTextEntry={true}
												onBlur={onBlur}
												onChangeText={(value) => onChange(value)}
												value={value}
												error={errors?.pin}
												onKeyPress={handleUserKeyPress}
											/>
										)}
										name="pin"
										rules={{
											required: {
												value: true,
												message: i18n.t('login.pinInput.requredError')
											}
										}}
									/>
									<View style={styles.buttonContainer}>
										<LoginButton
											text={i18n.t('login.loginButton')}
											onPress={submitting ? null : handleSubmit(onSubmit)}
											loader={submitting}
										/>
									</View>
								</>
							) : (
								<>
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<LoginTextEntry
												requiredText={i18n.t('login.required')}
												placeholder={i18n.t('login.mailInput.title')}
												onBlur={onBlur}
												onChangeText={(value) => onChange(value)}
												value={value}
												error={errors?.email}
												onKeyPress={handleUserKeyPress}
											/>
										)}
										name="email"
										rules={{
											required: {
												value: true,
												message: i18n.t('login.mailInput.requredError')
											},
											pattern: {
												value:
													/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												message: i18n.t('login.mailInput.patternError')
											}
											// validate: (value) =>
											// 	value === getValues('OTHER_FIELD_NAME') ||
											// 	'Password is not correct'
										}}
									/>

									<View style={styles.buttonContainer}>
										<LoginButton
											text={i18n.t('login.sendPinButton')}
											onPress={submitting ? null : handleSubmit(onSendPin)}
											loader={submitting}
										/>
									</View>
								</>
							)}
						</>
					)}

					{errorMessage && (
						<View style={styles.errorMessageContainer}>
							<View style={styles.errorMessage}>
								<TextWithFont style={styles.errorMessageText}>
									{errorMessage}
								</TextWithFont>
							</View>
						</View>
					)}
				</View>
			</View>
		</AuthScreenComponent>
	)
}

const mapStateToProps = (state) => ({
	...state.config
})
const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (data) => dispatch(setAuth(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

const themedStyles = (theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			justifyContent: 'center',
			width: 340
		},
		buttonContainer: {
			height: 40,
			marginVertical: 10,
			paddingHorizontal: theme.spacing.xl
		},
		pageTitle: {
			marginTop: 20,
			marginBottom: 50,
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.md,
			fontWeight: '800'
		},
		title: {
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.lg,
			fontWeight: '800',
			marginBottom: 20
		},
		subTitle: {
			marginBottom: 30,
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm
		},
		alignRightBox: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingHorizontal: theme.spacing.xl,
			paddingVertical: 15
		},
		forgotText: {
			color: theme.colors.accent,
			fontSize: theme.fontSizes.sm,
			fontWeight: '600'
		},
		bottomText: {
			marginBottom: 40,
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm,
			fontWeight: '600',
			alignContent: 'center'
		},
		errorMessageContainer: {
			paddingHorizontal: theme.spacing.xl
		},
		errorMessage: {
			backgroundColor: theme.colors.inputBackground,
			paddingHorizontal: theme.spacing.xl,
			padding: theme.spacing.sm,
			borderRadius: theme.radiuses.sm
		},
		errorMessageText: {
			color: theme.colors.inputTextDanger
		}
	})
}
