import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle
} from 'react'
import { connect } from 'react-redux'
import {
	NavigationContainer,
	DefaultTheme,
	DarkTheme,
	useNavigationContainerRef,
	getStateFromPath,
	getPathFromState
} from '@react-navigation/native'

import { createDrawerNavigator } from '@react-navigation/drawer'
import { createStackNavigator } from '@react-navigation/stack'
import { Linking, Platform } from 'react-native'
import Cookies from 'universal-cookie'

import ScreenGenerator from '../generators/ScreenGenerator'
import NotFound from '../views/NotFound'
import Gallery from '../views/Gallery'
import LoginNotFound from '../views/LoginNotFound'
import Login from '../views/Login'
import Signup from '../views/Signup'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import ChangePassword from '../views/ChangePassword'
import InvitationRegister from '../views/InvitationRegister'
import { useTheme } from '../theme/Theme'
import DrawerContent from './DrawerContent'
import { setGeneratorData } from '../redux/actions/generatorDataActions'

import withAdditionalProps from '../common/hoc/withAdditionalProps'
import showToast from '../common/methods/showToast'
import nav from '../common/methods/nav'
import visibilityHelper from '../common/methods/visibilityHelper'
import i18n from '../i18n/i18n'

import playClient from '../services/playClient'
import platformClient from '../services/platformClient'
import cryptoServices from '../services/cryptoServices'

import { default as screensJson } from '../../config/screens.json'
import env from '../../env'

const Drawer = createDrawerNavigator()
const RootStack = createStackNavigator()

const loginLinking = {
	config: {
		screens: {
			login: 'login',
			signup: 'signup',
			forgotpassword: 'forgotpassword',
			resetpassword: 'resetpassword',
			invitation: 'invitation',
			notFound: '*'
		}
	}
}

const emptyScreens = {
	tabs: [],
	drawers: [],
	hiddens: []
}

const RootNavigator = (props) => {
	const theme = useTheme()

	const Nav = Drawer

	return (
		<Nav.Navigator
			defaultStatus="closed"
			backBehavior="history"
			useLegacyImplementation={true}
			screenOptions={{
				headerShown: Platform.OS === 'web',
				headerStyle: {
					//NOTE:Workaround fix for showsVerticalScrollIndicator
					backgroundColor: theme.colors.headerBackground,
					height: 1,
					borderBottomWidth: 0
				},
				headerTitleContainerStyle: {
					display: 'none'
				},
				headerLeftContainerStyle: {
					display: 'none'
				},
				headerRightContainerStyle: {
					display: 'none'
				},
				swipeEnabled: !!props?.data?.drawers?.length,
				cardStyle: { height: '100%', flex: 1, backgroundColor: 'transparent' },
				cardOverlayEnabled: false,
				drawerStyle: { maxWidth: 250 }
			}}
			drawerContent={(properties) => (
				<DrawerContent drawerData={props?.data?.drawers} {...properties} />
			)}
		>
			{(props.data?.tabs?.length > 0 ||
				props.data?.drawers?.length > 0 ||
				props.data?.hiddens?.length > 0) && (
				<Nav.Screen
					name="app"
					component={withAdditionalProps(HomeNavigator, { ...props })}
					options={{ headerShown: false }}
				/>
			)}

			{props.auth ? (
				<>
					<Nav.Screen
						key="notFound"
						name="notFound"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'notFound.notFound'
								  )}`
								: i18n.t('notFound.notFound'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={NotFound}
					/>

					<Nav.Screen
						key="gallery"
						name="gallery"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'gallery.gallery'
								  )}`
								: i18n.t('gallery.gallery'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={Gallery}
					/>

					<Nav.Screen
						key="changepassword"
						name="changepassword"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'changePassword.pageTitle'
								  )}`
								: i18n.t('changePassword.pageTitle'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={withAdditionalProps(ChangePassword, {
							signInRequired: props.signInRequired,
							screens: props.data
						})}
					/>
				</>
			) : (
				<>
					<Nav.Screen
						key="login"
						name="login"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'login.pageTitle'
								  )}`
								: i18n.t('login.pageTitle'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={withAdditionalProps(Login, {
							signInRequired: props.signInRequired,
							inviteOnly: props.inviteOnly,
							screens: props.data
						})}
					/>

					{!props.inviteOnly && (
						<Nav.Screen
							key="signup"
							name="signup"
							options={{
								title: props?.config?.manifest?.name
									? `${props?.config?.manifest?.name} | ${i18n.t(
											'signup.pageTitle'
									  )}`
									: i18n.t('signup.pageTitle'),
								headerShown: false,
								detachPreviousScreen: false
							}}
							component={withAdditionalProps(Signup, {
								signInRequired: props.signInRequired,
								screens: props.data
							})}
						/>
					)}
					<Nav.Screen
						key="forgotpassword"
						name="forgotpassword"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'forgotPassword.pageTitle'
								  )}`
								: i18n.t('forgotPassword.pageTitle'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={withAdditionalProps(ForgotPassword, {
							signInRequired: props.signInRequired,
							screens: props.data
						})}
					/>
					<Nav.Screen
						key="resetpassword"
						name="resetpassword"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'resetPassword.pageTitle'
								  )}`
								: i18n.t('resetPassword.pageTitle'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={withAdditionalProps(ResetPassword, {
							signInRequired: props.signInRequired,
							screens: props.data
						})}
					/>
					<Nav.Screen
						key="invitation"
						name="invitation"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'invitationRegister.pageTitle'
								  )}`
								: i18n.t('invitationRegister.pageTitle'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={withAdditionalProps(InvitationRegister, {
							signInRequired: props.signInRequired,
							screens: props.data
						})}
					/>
					<Nav.Screen
						key="notFound"
						name="notFound"
						options={{
							title: props?.config?.manifest?.name
								? `${props?.config?.manifest?.name} | ${i18n.t(
										'notFound.notFound'
								  )}`
								: i18n.t('notFound.notFound'),
							headerShown: false,
							detachPreviousScreen: false
						}}
						component={NotFound}
					/>
				</>
			)}
		</Nav.Navigator>
	)
}

const HomeNavigator = (props) => {
	const theme = useTheme()

	const RootNav = RootStack

	return (
		<RootNav.Navigator
			backBehavior="history"
			useLegacyImplementation={true}
			screenOptions={{
				headerShown: Platform.OS === 'web',
				headerStyle: {
					backgroundColor: theme.colors.headerBackground,
					height: 1,
					borderBottomWidth: 0
				},
				headerTitleContainerStyle: {
					display: 'none'
				},
				headerLeftContainerStyle: {
					display: 'none'
				},
				headerRightContainerStyle: {
					display: 'none'
				},
				cardStyle: { height: '100%', flex: 1, backgroundColor: 'transparent' },
				cardOverlayEnabled: false,
				swipeEnabled: !!props?.data?.drawers?.length
			}}
		>
			{!!props?.data?.tabs?.length &&
				props.data.tabs.map((data) => {
					return ScreenGenerator(
						props.data,
						data,
						RootNav,
						props.userInfo,
						props.ghostUser,
						'tabs',
						false,
						props.screenSize !== 'lg' &&
							(!data.style || data.style === 'screen')
							? true
							: false,
						props?.config
					)
				})}
			{!!props?.data?.hiddens?.length &&
				props.data.hiddens.map((data) => {
					return ScreenGenerator(
						props.data,
						data,
						RootNav,
						props.userInfo,
						props.ghostUser,
						'hiddens',
						props.screenSize !== 'lg' ? true : false,
						props.screenSize !== 'lg' &&
							(!data.style || data.style === 'screen')
							? true
							: false,
						props?.config
					)
				})}
			{!!props?.data?.drawers?.length &&
				props.data.drawers.map((data) => {
					return ScreenGenerator(
						props.data,
						data,
						RootNav,
						props.userInfo,
						props.ghostUser,
						'drawers',
						false,
						props.screenSize !== 'lg' &&
							(!data.style || data.style === 'screen')
							? true
							: false,
						props?.config
					)
				})}
		</RootNav.Navigator>
	)
}

const AppNavigator = forwardRef((props, ref) => {
	const [isReady, setIsReady] = useState(false)
	const [initialState, setInitialState] = useState(null)

	const cookies = new Cookies()

	const isPreviewApp = env.isPreview
	const isLocalConfig = env.useLocalConfig
	const navigationRef = useNavigationContainerRef()

	const allScreens = props.generatorData
		? props?.config?.settings?.sign_in_required && !props.auth
			? emptyScreens
			: props.generatorData.screens
		: emptyScreens

	const screens = [].concat(
		allScreens.tabs,
		allScreens.drawers,
		allScreens.hiddens
	)

	// const postActiveRoute = () => {
	// 	if (isPreviewApp) {
	// 		const currentRoute = navigationRef.getCurrentRoute()
	// 		window.parent.postMessage(
	// 			{ source: 'kozmik-preview', activeRoute: currentRoute },
	// 			'*'
	// 		)
	// 	}
	// }

	const fetchData = async () => {
		if (!isPreviewApp && !props?.ssrData) {
			if (isLocalConfig) {
				props.setGeneratorData(screensJson)
			} else {
				playClient.getScreens().then((response) => {
					if (response?.status === 200) {
						const decryptedData = cryptoServices.decrypt(response.data)
						const parsedData = JSON.parse(decryptedData)

						props.setGeneratorData(parsedData)
					} else {
						showToast(
							'error',
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
				})
			}
		}
	}

	const fetchPreviewData = async () => {
		if (isPreviewApp) {
			props.previewAppId &&
				platformClient.getScreens(props.previewAppId).then((response) => {
					if (response?.status === 200) {
						const decryptedData = cryptoServices.decrypt(response.data)
						const parsedData = JSON.parse(decryptedData)

						props.setGeneratorData(parsedData)

						window.parent.postMessage(
							{ source: 'kozmik-preview', configData: parsedData },
							'*'
						)
					} else {
						showToast(
							'error',
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
				})
		}
	}

	const generateRoute = (sarr, path) => {
		let out
		const firstDimension = /(?<first>[^/]+)$/.exec(path)
		const secondDimension = /(?<first>[^/]+)[/](?<second>[^/]+)$/.exec(path)

		if (firstDimension) {
			const route = sarr.find((i) => path.endsWith(`/${i[1]}`))

			if (route) {
				out = {
					routeNames: ['app'],
					routes: [
						{
							name: 'app',
							state: {
								routes: [
									{
										name: route[0],
										path
									}
								]
							}
						}
					]
				}
			}
		}

		if (secondDimension) {
			const routePath = secondDimension?.groups.first + '/:id'

			const route = sarr.find((i) => routePath.endsWith(i[1]))
			if (route)
				out = {
					routeNames: ['app'],
					routes: [
						{
							name: 'app',
							state: {
								routes: [
									{
										name: route[0],
										path,
										params: {
											id: secondDimension?.groups.second
										}
									}
								]
							}
						}
					]
				}
		}

		return out
	}

	useEffect(() => {
		fetchData()
	}, [props.shortName])

	useEffect(() => {
		fetchPreviewData()
	}, [props.previewAppId])

	useImperativeHandle(ref, () => ({
		navigate(parentScreen, childScreen) {
			if (isPreviewApp) {
				nav.navigateByKey(parentScreen)
				// navigationRef.navigate(parentScreen, {
				// 	screen: childScreen
				// })
			}
		}
		// reset(key) {
		// 	if (isPreviewApp) {
		// 		// navigationRef.dispatch(
		// 		// 	CommonActions.reset({
		// 		// 		index: 0,
		// 		// 		routes: [
		// 		// 			{
		// 		// 				name: key
		// 		// 			}
		// 		// 		]
		// 		// 	})
		// 		// )
		// 		// const popAction = StackActions.pop()
		// 		// navigationRef.dispatch(popAction)
		// 		// navigationRef.navigate(key)

		// 		const pushAction = StackActions.push(key)

		// 		navigationRef.dispatch(pushAction)
		// 	}
		// }
	}))

	const conditionalLinking = {
		enabled: !isPreviewApp,
		config: {
			screens:
				props?.config?.settings?.sign_in_required && !props.auth
					? {
							login: 'login',
							signup: 'signup',
							forgotpassword: 'forgotpassword',
							resetpassword: 'resetpassword',
							invitation: 'invitation',
							notFound: '*'
					  }
					: {
							changepassword: 'changepassword',
							...(props.generatorData && props.userInfo
								? Object.keys(props.generatorData?.linking?.config?.screens)
										.filter(
											(x) =>
												screens.find(
													(s) =>
														x == s.key &&
														visibilityHelper.isScreenVisible(
															s.visibility,
															props.userInfo,
															props.ghostUser
														)
												) != null
										)
										.reduce((obj, key) => {
											obj[key] =
												props.generatorData?.linking?.config?.screens[key]
											return obj
										}, {})
								: props.generatorData?.linking?.config?.screens),
							...loginLinking.config.screens
					  }
		},
		getStateFromPath: (path, config) => {
			let out
			const sarr = Object.entries(config.screens)

			var loginScreen = Object.entries(loginLinking.config.screens).find((i) =>
				path.startsWith(`/${i[1]}`)
			)

			if (
				!isPreviewApp &&
				props?.config?.settings?.sign_in_required &&
				!props.userInfo &&
				!props.auth
			) {
				if (loginScreen) {
					const state = getStateFromPath(path, config)
					return state
				} else {
					out = {
						routes: [
							{
								name: 'login',
								path: '/login'
							}
						]
					}
				}
			} else {
				if (!props.auth && loginScreen) {
					const state = getStateFromPath(path, config)
					return state
				} else if (path === '/') {
					let firstScreen
					if (props.userInfo) {
						firstScreen = screens.find((x) =>
							visibilityHelper.isScreenVisible(
								x.visibility,
								props.userInfo,
								props.ghostUser
							)
						)
					} else {
						firstScreen = screens[0]
					}

					const newPath = firstScreen?.path ?? '/'

					const route = sarr.find((i) => i[1] === newPath)
					if (route) {
						out = {
							routeNames: ['app'],
							routes: [
								{
									name: 'app',
									state: {
										routes: [
											{
												name: route[0],
												path: `/${newPath}`
											}
										]
									}
								}
							]
						}
					}
				} else {
					out = generateRoute(sarr, path)
				}
			}

			if (out) {
				return out
			}

			// For all other URLs fall back to the built-in
			const state = getStateFromPath(path, config)

			return state
		},
		getPathFromState: (state, config) => {
			const originalPath = getPathFromState(state, config)

			const sarr = Object.entries(config.screens)
			const route = sarr?.find((i) => originalPath.endsWith(i[1]))

			const routePath = route?.[1]
			const exactPath = state.routes
				?.find((x) => x.name === 'app')
				?.state?.routes?.find((x) => x.name == route?.[0])?.path

			return exactPath || routePath || originalPath
		}
	}

	useEffect(() => {
		if (props.userInfo && navigationRef.isReady()) {
			const cr = navigationRef.getCurrentRoute()

			let firstScreen = screens.find((x) => x.key === cr.name)
			if (
				!(
					firstScreen &&
					visibilityHelper.isScreenVisible(
						firstScreen.visibility,
						props.userInfo,
						props.ghostUser
					)
				)
			) {
				firstScreen = allScreens.tabs.find((x) =>
					visibilityHelper.isScreenVisible(
						x.visibility,
						props.userInfo,
						props.ghostUser
					)
				)

				if (!firstScreen) {
					firstScreen = allScreens.drawers.find((x) =>
						visibilityHelper.isScreenVisible(
							x.visibility,
							props.userInfo,
							props.ghostUser
						)
					)
				}

				if (!firstScreen) {
					firstScreen = allScreens.hiddens.find((x) =>
						visibilityHelper.isScreenVisible(
							x.visibility,
							props.userInfo,
							props.ghostUser
						)
					)
				}
			}

			if (firstScreen) {
				if (!cr.path) {
					const sarr = Object.entries(conditionalLinking?.config?.screens)
					const state = generateRoute(sarr, `/${firstScreen.path}`)

					navigationRef.reset(state)
				} else if (cr.name !== firstScreen.key) {
					var state = {
						index: 0,
						routeNames: ['app'],
						routes: [
							{
								name: 'app',
								state: {
									routes: [
										{
											name: firstScreen.key,
											path: `/${firstScreen.path}`
										}
									]
								}
							}
						]
					}

					navigationRef.reset(state)
				}
			}
		}
	}, [props.userInfo])

	useEffect(() => {
		const initState = async () => {
			// try {
			// } finally {
			// 	setIsReady(true)
			// }
			setIsReady(true)
		}

		if (!isReady && props.generatorData) {
			initState()
		}
	}, [isReady, props.generatorData, props.userInfo])

	if (!isReady) {
		return null
	}

	const KozmikDarkTheme = {
		...DarkTheme,
		colors: {
			...DarkTheme.colors,
			background: 'transparent'
		}
	}

	const KozmikLightTheme = {
		...DefaultTheme,
		colors: {
			...DefaultTheme.colors,
			background: 'transparent'
		}
	}

	return (
		props.generatorData && (
			<NavigationContainer
				theme={
					props?.config?.appearence?.Theme === 'Dark'
						? KozmikDarkTheme
						: KozmikLightTheme
				}
				ref={navigationRef}
				linking={conditionalLinking}
				initialState={initialState}
			>
				<RootNavigator
					config={props.config}
					auth={props.auth}
					userInfo={props.userInfo}
					screenSize={props.screenSize}
					signInRequired={props?.config?.settings?.sign_in_required}
					inviteOnly={props?.config?.settings?.invite_only}
					data={allScreens}
				/>
			</NavigationContainer>
		)
	)
})

const mapStateToProps = (state) => ({
	...state.generatorData,
	...state.auth,
	...state.userInfo,
	...state.config,
	...state.screenSize
})

const mapDispatchToProps = (dispatch) => {
	return {
		setGeneratorData: (x) => dispatch(setGeneratorData(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(AppNavigator)
