import { parse, parseISO } from 'date-fns'
import { enUS, tr } from 'date-fns/locale'

import getLanguageCode from './getLanguageCode'

const locales = {
	tr: tr,
	en: enUS
}

const languageCode = getLanguageCode()
const localeString = languageCode === 'tr-TR' ? 'tr' : 'en'

const localeParseDate = (date) => {
	try {
		if (date) {
			const datePattern = locales[localeString].formatLong.date({
				width: 'short'
			})
			const timePattern = locales[localeString].formatLong.time({
				width: 'short'
			})

			let parsedDate

			if (date instanceof Date) {
				parsedDate = date
			} else {
				if (date.includes(' ')) {
					parsedDate = parse(date, `${datePattern} ${timePattern}`, new Date())
				} else if (date.includes('T')) {
					parsedDate = parse(date, `${datePattern}T${timePattern}`, new Date())
				} else {
					parsedDate = parse(date, datePattern, new Date())
				}

				if (parsedDate == 'Invalid Date') parsedDate = new Date(date)
				if (parsedDate == 'Invalid Date') parsedDate = parseISO(date)
			}

			return parsedDate
		}
	} catch (e) {
		return null
	}

	return null
}

export default localeParseDate
