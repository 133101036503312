import axios from 'axios'
import Cookies from 'universal-cookie'
import { Platform } from 'react-native'

import { getStoreData } from '../common/methods/StoreData'
import '../../src/global/variables'
import store from '../redux/store/store'
import { setAuth } from '../redux/actions/authActions'
import { setUserInfo } from '../redux/actions/userInfoActions'
import {
	incrementFetching,
	decrementFetching
} from '../redux/actions/fetchingActions'
import { getClientId } from '../common/methods/ClientHelper'
import getLanguageCode from '../common/methods/getLanguageCode'
import showToastCatchError from '../common/methods/showToastCatchError'
import tokenServices from './tokenServices'

import env from '../../env'

const domain = env.authDomain

const cookies = new Cookies()

const increment = () => {
	store.dispatch(incrementFetching())
}

const decrement = () => {
	store.dispatch(decrementFetching())
}

let refreshTokenPromise

const getRefreshToken = (refreshToken, client_id) =>
	axios.post(
		domain + '/api/a/refresh',
		{
			refreshToken: refreshToken
		},
		{
			headers: {
				'Content-Type': 'application/json',
				AppId: client_id.replace('"', '').replace('"', '')
			}
		}
	)

const getClient = async (baseUrl = null, headers = null, tokenKey = null) => {
	const options = {
		baseURL: baseUrl,
		headers: headers,
		timeout: 60000
	}

	let client

	const initClient = (options, token, refreshToken, apiKey, client_id) => {
		options.headers['Accept-Language'] = getLanguageCode()
		options.headers['X-Timezone-Offset'] = new Date().getTimezoneOffset()

		const orgId = store.getState().userInfo.orgId
		if (orgId && orgId != 'undefined' && orgId != 'null') {
			options.headers['OrgId'] = orgId
		}

		client = axios.create(options)

		client.interceptors.request.use(
			(requestConfig) => {
				if (token && token !== null && token !== undefined) {
					requestConfig.headers.Authorization = token
				} else if (apiKey) {
					requestConfig.headers['X-API-Key'] = apiKey
						.replace('"', '')
						.replace('"', '')
				}
				if (client_id) {
					requestConfig.headers.AppId = client_id
				}

				return requestConfig
			},
			(requestError) => {
				console.log('requestError', requestError)
				return Promise.reject(requestError)
			}
		)

		client.interceptors.response.use(
			(response) => response,
			(error) => {
				const originalRequest = error.config

				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log('response: ', error.response)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log('request: ', error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message)
				}

				if (error.response) {
					if (error.response.status == 401 && !originalRequest._retry) {
						originalRequest._retry = true

						if (refreshToken && refreshToken !== 'undefined') {
							if (!refreshTokenPromise) {
								// check for an existing in-progress request
								// if nothing is in-progress, start a new refresh token request
								refreshTokenPromise = getRefreshToken(
									refreshToken,
									client_id
								).then((x) => {
									refreshTokenPromise = null // clear state
									return x // resolve with the new token
								})
							}

							return refreshTokenPromise
								.then((res) => {
									if (res.data.success) {
										originalRequest.headers['Authorization'] =
											'Bearer ' + res.data.data.access_token

										tokenServices.add(res.data.data)

										// console.log('REFRESH_STORE', store.getState())
										// console.log('REFRESH_ORIGINAL_REQUEST', originalRequest)
										// store.dispatch(setAuth(true))

										return axios(originalRequest)
									} else {
										tokenServices.remove()
										store.dispatch(setAuth(false))
										store.dispatch(setUserInfo(null))
									}
								})
								.catch(() => {
									tokenServices.remove()
									store.dispatch(setAuth(false))
									store.dispatch(setUserInfo(null))
								})
						} else {
							tokenServices.remove()
							store.dispatch(setAuth(false))
							store.dispatch(setUserInfo(null))
						}
					}
					if (error.response.status >= 500) {
						//TODO:ERROR
					}
				}

				return Promise.reject(error)
			}
		)
	}

	if (Platform.OS !== 'web') {
		client = await getStoreData(global.TOKEN_KEY).then(async (token) => {
			let tokenText, refreshToken, apiKey

			if (token && token !== undefined && token !== null) {
				const tokenJson = JSON.parse(token)
				tokenText = tokenJson.token_type + ' ' + tokenJson.access_token
				refreshToken = tokenJson.refresh_token
			} else {
				apiKey = await getStoreData(global.API_KEY)
			}

			const client_id = await getClientId()

			initClient(options, tokenText, refreshToken, apiKey, client_id)

			return Promise.resolve(client)
		})
	} else {
		let tokenText, refreshToken
		const token = cookies.get(tokenKey ? tokenKey : global.TOKEN_KEY) //NOTE:tokenKey parameter created for platform prewiew
		const apiKey = cookies.get(global.API_KEY)

		if (token && token !== undefined && token !== null) {
			tokenText = `Bearer ${token}`
			refreshToken = cookies.get(global.REFRESH_TOKEN_KEY)
		}

		const client_id = await getClientId()

		initClient(options, tokenText, refreshToken, apiKey, client_id)
	}

	return client
}

class ApiClient {
	constructor(baseUrl = null, headers = null, tokenKey = null) {
		this.baseUrl = baseUrl
		this.headers = headers
		this.tokenKey = tokenKey
	}

	async get(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.get(url, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						// showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async delete(url) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()

				return c
					.delete(url)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async head(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.head(url, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						// showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async options(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.options(url, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						// showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async post(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.post(url, data, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async put(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.put(url, data, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
	async patch(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers, this.tokenKey).then(
			(c) => {
				increment()
				return c
					.patch(url, data, conf)
					.then((response) => {
						return response
					})
					.catch((error) => {
						//TODO:LOG
						showToastCatchError(error)
						return Promise.reject(error)
					})
					.finally(() => {
						decrement()
					})
			}
		)
	}
}
export { ApiClient }
