import { default as appConfig } from '../../config/features.json'

export default (host) => {
	if (!host) return appConfig.manifest?.short_name

	if (
		host.includes('localhost') ||
		host.includes('127.0.0.1') ||
		host.includes('192.168.68.100')
	)
		return appConfig.manifest?.short_name

	// Extract short_name from domain
	const arr = host.split('.').slice(0, -2)
	if (arr.length > 0) {
		return arr[0]
	} else {
		return appConfig.manifest?.short_name
	}
}
