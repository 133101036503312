import React, { forwardRef } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'

import { default as QRCodeLegacy } from 'react-native-qrcode-svg'

import triggerActionList from '../common/methods/triggerActionList'
import getMappingValByKey from '../common/methods/getMappingValByKey'

import { useTheme } from '../theme/Theme'

const QRCode = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const codeText = getMappingValByKey(props, 'text')

	return (
		<View key={props.block._uid} style={styles.mainContainer}>
			<TouchableOpacity
				style={styles.action}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			>
				<QRCodeLegacy
					value={codeText}
					size={
						props.block?.containerWidth
							? (props.screenWidth * props.block.containerWidth) / 100 / 3
							: props.screenWidth / 2.5
					}
					backgroundColor="#FFFFFF"
					onError={(error) => console.log('QR Code Error: ', error)}
				/>
			</TouchableOpacity>
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(QRCode)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			paddingVertical: theme.spacing.sm,
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		},
		action: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			backgroundColor: '#FFFFFF',
			paddingHorizontal: theme.spacing.sm,
			paddingVertical: theme.spacing.xl,
			borderRadius: 6
		}
	})
}
