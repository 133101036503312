import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Switch } from 'react-native'
import * as Linking from 'expo-linking'
import { default as NativeCheckbox } from 'expo-checkbox'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

import getMappingValByKey from '../common/methods/getMappingValByKey'
import i18n from '../i18n/i18n'
import showToast from '../common/methods/showToast'

import dataClient from '../services/dataClient'

const Checkbox = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [switchValue, setSwitchValue] = useState(false)

	const toggleSwitch = () => {
		if (props.block?.data?.Id) {
			updateSubmit(!switchValue)
			setSwitchValue(!switchValue)
		}
	}

	const getSwitchVal = () => {
		props.block?.mapping?.field?.field &&
			setSwitchValue(props.block?.data?.[props.block.mapping.field.field])
	}

	const updateSubmit = (switchVal) => {
		if (props.block?.mapping?.field?.field) {
			let jsonData = {}
			jsonData['Id'] = props.block.data.Id
			jsonData[props.block.mapping.field.field] = switchVal

			const jsonHeaders = {
				'Access-Control-Allow-Origin': Linking.createURL('')
			}

			dataClient
				.patchData(`/${props.block.layoutSource}`, jsonHeaders, jsonData)
				.then((response) => {
					if (response.status == 200) {
						props?.refresh && props.refresh()
					} else {
						showToast(
							'error',
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
				})
		}
	}

	useEffect(() => {
		props.block?.data ? getSwitchVal() : setSwitchValue(false)
	}, [props.block.data, props.block.mapping.field.field])

	return (
		<View style={styles.mainContainer}>
			{props.block.type === 'checkbox' && (
				<View style={styles.buttonContainer}>
					<NativeCheckbox
						style={styles.checkbox}
						value={switchValue}
						onValueChange={toggleSwitch}
						color={switchValue ? theme.colors.accent : undefined}
					/>
				</View>
			)}
			<View style={{ flex: 1 }}>
				<TextWithFont style={styles.titleText} numberOfLines={1}>
					{getMappingValByKey(props, 'title')}
				</TextWithFont>
				<TextWithFont style={styles.contentText} numberOfLines={1}>
					{getMappingValByKey(props, 'label')}
				</TextWithFont>
			</View>
			{props.block.type === 'switch' && (
				<View style={styles.buttonContainer}>
					<Switch
						// trackColor={{ true: theme.colors.accent, false: '#767577' }}
						// thumbColor={switchValue ? theme.colors.accent : '#f4f3f4'}
						ios_backgroundColor="#3e3e3e"
						onValueChange={toggleSwitch}
						value={switchValue}
					/>
				</View>
			)}
		</View>
	)
}

export default Checkbox

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			height: 66,
			alignItems: 'center',
			justifyContent: 'space-between',
			flexDirection: 'row'
		},
		titleText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		contentText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		buttonContainer: {
			flexDirection: 'row'
		},
		checkbox: {
			marginRight: theme.spacing.xl
		}
	})
}
