import React from 'react'
import { Linking } from 'react-native'

import CummunicationButton from './CummunicationButton'
import IconButton from './IconButton'

import getMappingValByKey from '../common/methods/getMappingValByKey'

const PhoneButton = (props) => {
	const phoneClickHandler = () => {
		Linking.openURL(`tel:${getMappingValByKey(props, 'address')}`)
	}
	const chatClickHandler = () => {
		Linking.openURL(
			`sms:${getMappingValByKey(props, 'address')}?body=${
				getMappingValByKey(props, 'body') ?? ''
			}`
		)
	}

	return (
		<CummunicationButton
			title={getMappingValByKey(props, 'title') ?? ''}
			text={getMappingValByKey(props, 'address') ?? ''}
			spacing={props.block.spacing}
			fontSize={props.block.fontSize}
		>
			{props.block?.showCall && (
				<IconButton clickHandler={phoneClickHandler} icon={'phone'} />
			)}
			{props.block?.showChat && (
				<IconButton clickHandler={chatClickHandler} icon={'chat'} />
			)}
		</CummunicationButton>
	)
}

export default PhoneButton
