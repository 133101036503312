import React, { forwardRef } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import SmartTextView from './SmartTextView'

const TextArea = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<View key={props.block._uid} style={styles.mainContainer}>
			<SmartTextView
				alignCenter={false}
				style={styles.contentText}
				chipStyle={styles.chipsText}
				mapping={'text'}
				numberOfLines={props.block?.lineCount}
				{...props}
			/>
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(TextArea)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			paddingVertical: theme.spacing.sm,
			flexDirection: 'row'
		},
		contentText: {
			width: 0,
			flex: 1,
			textDecorationLine:
				props.block?.style && props.block.style === 'underline'
					? 'underline'
					: 'none',
			fontStyle:
				props.block?.style && props.block.style === 'italic'
					? 'italic'
					: 'normal',
			fontWeight:
				props.block?.style && props.block.style === 'bold' ? '600' : '400',
			fontSize: theme.fontSizes[props.block.size],
			color: props.block?.color ? props.block.color : theme.colors.text,
			textAlign: props.block?.textAlign ? props.block.textAlign : 'auto'
		},
		chipsText: {
			textDecorationLine:
				props.block?.style && props.block.style === 'underline'
					? 'underline'
					: 'none',
			fontStyle:
				props.block?.style && props.block.style === 'italic'
					? 'italic'
					: 'normal',
			fontWeight:
				props.block?.style && props.block.style === 'bold' ? '600' : '400',
			fontSize: theme.fontSizes[props.block.size],
			color: props.block?.color ? props.block.color : theme.colors.text,
			textAlign: props.block?.textAlign ? props.block.textAlign : 'auto',
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.sm,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		}
	})
}
