import React, {
	useState,
	useRef,
	createRef,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'

import ComponentGenerator from '../generators/ComponentGenerator'
import Button from './Button'
import ConfirmationModal from './ConfirmationModal'
import Constants from 'expo-constants'

import TextWithFont from './TextWithFont'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import triggerActionList from '../common/methods/triggerActionList'
import nav from '../common/methods/nav'
import formatFormDataByScreenData from '../common/methods/formatFormDataByScreenData'

const Form = forwardRef((props, ref) => {
	const isFocused = useIsFocused()
	const [processing, setProcessing] = useState(false)
	const [processingIndex, setProcessingIndex] = useState(null)
	const [data, setData] = useState(null)
	const [submitData, setSubmitData] = useState(null)
	const [formData, setFormData] = useState([])
	const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)

	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const children = useRef([])

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {}
	}))

	const goBack = () => {
		if (props.block.canGoBack) {
			nav.navigateBack()
		} else {
			nav.navigateHome()
		}
	}

	const updateFormField = (field) => {
		let modifiedFormData = [...formData]
		const formObject = modifiedFormData.find((x) => x.key == field.key)
		formObject.value = field.value
		formObject.isValid = field.isValid

		if (field.recurrence) {
			formObject.recurrence = field.recurrence
		}

		if (field.repeat) {
			formObject.repeat = field.repeat
		}

		setFormData(modifiedFormData)
	}

	const clearForm = (callback = null) => {
		children.current.map((item) => {
			item.current?.clearInput()
		})

		clearFormData()
		callback && callback()
	}

	const validateForm = () => {
		let modifiedFormData = [...formData]

		children.current?.map((item) => {
			const fieldVal = item.current?.validate()
			const formObject = modifiedFormData.find((x) => x.key == fieldVal?.key)

			formObject.value = fieldVal?.value
			formObject.isValid = fieldVal?.isValid

			if (fieldVal?.recurrence) {
				formObject.recurrence = fieldVal?.recurrence
			}

			if (fieldVal?.repeat) {
				formObject.repeat = fieldVal?.repeat
			}
		})

		return modifiedFormData
	}

	const submitForm = () => {
		if (props.block?.submit?.visible) {
			const validationResult = validateForm()
			const notValidItems = validationResult?.filter((x) => x.isValid == false)

			if (notValidItems.length === 0) {
				setProcessing(true)

				const actions = props.block.type
					? props.block.type === 'update'
						? [{ type: 'update' }, { type: 'back' }].concat(
								props.block.submit.actions
						  )
						: [{ type: 'create' }, { type: 'back' }].concat(
								props.block.submit.actions
						  )
					: []

				let request = {}

				formData.forEach((value, index) => {
					request[value.key] = value.value === '' ? null : value.value
				})

				props.block?.hiddenFields?.map((item) => {
					request[item.field] = formatFormDataByScreenData(
						item,
						null,
						request,
						props.block?.data,
						props.block?.route?.params?.id,
						props.userInfo,
						actions
					)
				})

				const blockWithData = {
					...props.block,
					screenData: props.block.data,
					data: request,
					id:
						props.block.layoutSource === props.block.source
							? props.block?.route?.params?.id
							: null
				}

				actions?.length > 0 &&
					triggerActionList(blockWithData, actions, blockWithData.id)
						.then(() => {
							setProcessing(false)
						})
						.catch((err) => {
							setProcessing(false)
						})
			}
		}
	}

	const deleteSubmit = () => {
		if (props.block?.delete?.visible && data?.Id) {
			const actions = props.block.type
				? [{ type: 'delete' }, { type: 'back' }].concat(
						props.block.delete.actions
				  )
				: props.block.delete.actions

			actions?.length > 0 &&
				triggerActionList(props.block, actions, props.block?.route?.params?.id)
					.then(() => {
						setProcessing(false)
					})
					.catch((err) => {
						setProcessing(false)
					})
		}
	}

	const triggerActions = (button, index) => {
		const validationResult = validateForm()
		const notValidItems = validationResult?.filter((x) => x.isValid == false)

		if (notValidItems.length === 0) {
			setProcessing(true)
			setProcessingIndex(index)

			let request = {}

			formData.forEach((value, index) => {
				request[value.key] = value.value === '' ? null : value.value
			})

			props.block?.hiddenFields?.map((item) => {
				request[item.field] = formatFormDataByScreenData(
					item,
					null,
					request,
					props.block?.data,
					props.block?.route?.params?.id,
					props.userInfo,
					button.actions
				)
			})

			const recurrence = validationResult?.find((x) => x.recurrence)?.recurrence
			const repeat = validationResult?.find((x) => x.repeat)?.repeat

			const blockWithData = {
				...props.block,
				screenData: props.block.data,
				data: request,
				id: props.block?.route?.params?.id,
				recurrence: recurrence,
				repeat: repeat
			}

			button.actions?.length > 0 &&
				triggerActionList(blockWithData, button.actions, blockWithData.id)
					.then(() => {
						setProcessing(false)
					})
					.catch((err) => {
						setProcessing(false)
					})
		}
	}

	const updateSubmitData = () => {
		let jsonData = { ...submitData }

		formData?.forEach((value, index) => {
			// jsonData[value.key] = value.value
			// NOTE:numeric için backend empty text kabul etmiyor
			jsonData[value.key] = value.value === '' ? null : value.value
		})

		setSubmitData(jsonData)
	}

	const cancelForm = () => {
		clearForm()
		goBack()
	}

	const clearFormData = () => {
		let modifiedFormData = [...formData]

		modifiedFormData?.forEach((item, index) => {
			item.value = null
			item.isValid = false
		})

		setFormData(modifiedFormData)
	}

	const fillFormData = (elements, data) => {
		let modifiedFormData = []
		elements?.map((item) => {
			modifiedFormData.push({
				key: item.mapping.field,
				isValid: true,
				value: data?.[item.mapping.field] || null
			})
		})

		props.block?.hiddenFields?.map((item) => {
			modifiedFormData.push({
				key: item.field,
				isValid: true,
				value:
					formatFormDataByScreenData(
						item,
						null,
						modifiedFormData,
						props.block?.data,
						props.block?.route?.params?.id,
						props.userInfo,
						null
					) || null
			})
		})

		setFormData(modifiedFormData)
	}

	useEffect(() => {
		updateSubmitData()
	}, [formData])

	useEffect(() => {
		if (props.block?.layoutSource === props?.block?.source) {
			fillFormData(props.block.body, data)
		} else {
			fillFormData(props.block.body, null)
		}
	}, [props.block.body, data])

	useEffect(() => {
		children.current = []
	}, [props.block.body])

	useEffect(() => {
		setData(props.block?.data)
	}, [props.block.data])

	const localizeTitle = props.block?.title
		? getLocalizeContent(props.block.title)
		: null
	const localizeDesc = props.block?.description
		? getLocalizeContent(props.block.description)
		: null

	return (
		<View style={styles.mainContainer}>
			{props.block?.header ? (
				<View style={styles.header}>
					<TouchableOpacity activeOpacity={0.8} onPress={() => cancelForm()}>
						<TextWithFont style={styles.actionButton}>
							{getLocalizeContent(props.block.cancel.text)}
						</TextWithFont>
					</TouchableOpacity>

					<TextWithFont style={styles.pageTitle}>{localizeTitle}</TextWithFont>

					<TouchableOpacity activeOpacity={0.8} onPress={submitForm}>
						<TextWithFont style={styles.actionButton}>
							{getLocalizeContent(props.block.submit.text)}
						</TextWithFont>
					</TouchableOpacity>
				</View>
			) : (
				!!(localizeTitle || localizeDesc) && (
					<View style={styles.defaultHeader}>
						{!!localizeTitle && (
							<TextWithFont style={styles.pageTitle}>
								{localizeTitle}
							</TextWithFont>
						)}
						{!!localizeDesc && (
							<TextWithFont style={styles.pageDesc}>
								{localizeDesc}
							</TextWithFont>
						)}
					</View>
				)
			)}

			{!!props.block.body?.length &&
				props.block.body.map((block, index) => {
					children.current[index] = createRef()
					block.updateFormField = updateFormField
					block.data = data
					block.route = props.block.route
					block.submitData = submitData
					return (
						<ComponentGenerator
							key={block._uid}
							block={block}
							child={children.current[index]}
							ref={children.current[index]}
						/>
					)
				})}

			{!props.block?.header && (
				<View
					style={{
						flexDirection: 'row',
						paddingVertical: theme.spacing.xl,
						gap: theme.spacing.xl
					}}
				>
					{!props.block?.buttons?.length && props.block?.delete?.visible && (
						<>
							<ConfirmationModal
								route={props.block.route}
								visible={isConfirmationVisible}
								primaryText={getLocalizeContent(
									props.block.delete.confirmation.primaryText
								)}
								secondaryText={getLocalizeContent(
									props.block.delete.confirmation.secondaryText
								)}
								confirmText={getLocalizeContent(
									props.block.delete.confirmation.confirmText
								)}
								cancelText={getLocalizeContent(
									props.block.delete.confirmation.cancelText
								)}
								confirm={() => deleteSubmit()}
								cancel={() => setIsConfirmationVisible(false)}
							/>
							<Button
								text={getLocalizeContent(props.block.delete?.text)}
								icon={props.block.delete?.icon}
								corner={props.block.delete?.corner}
								type={props.block.delete?.type} //"transparent"
								textColor={theme.colors.inputTextDanger}
								onPress={() => setIsConfirmationVisible(true)}
							/>
						</>
					)}

					{!props.block?.buttons?.length && props.block?.submit?.visible && (
						<Button
							text={getLocalizeContent(props.block.submit?.text)}
							icon={props.block.submit?.icon}
							corner={props.block.submit?.corner}
							type={props.block.submit?.type}
							onPress={processing ? null : submitForm}
							loader={processing}
						/>
					)}

					{!!props.block?.buttons?.length &&
						props.block?.buttons.map((item, index) => (
							<Button
								key={`form-buttons-${index}`}
								text={getLocalizeContent(item.mapping.text)}
								icon={item.icon}
								corner={item.corner}
								type={item.type}
								onPress={
									processing && processingIndex === index
										? null
										: () => triggerActions(item, index)
								}
								loader={processing && processingIndex === index}
							/>
						))}
				</View>
			)}
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.fetching,
	...state.userInfo
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Form)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			width: '100%',
			// height: '100%',
			// backgroundColor: theme.colors.layoutBackground,
			paddingTop: Platform.OS === 'android' ? 0 : Constants.statusBarHeight
		},
		defaultHeader: {
			backgroundColor: theme.colors.headerBackground,
			paddingTop: theme.spacing.xl
		},
		header: {
			backgroundColor: theme.colors.headerBackground,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing.xl,
			marginBottom: theme.spacing.xl
		},
		actionButton: {
			fontSize: theme.fontSizes.sm,
			fontWeight: 'bold',
			color: theme.colors.accent
		},
		pageTitle: {
			color: theme.colors.headerText,
			fontSize: theme.fontSizes.md,
			fontWeight: 'bold'
		},
		pageDesc: {
			marginTop: theme.spacing.sm,
			color: theme.colors.headerText,
			fontSize: theme.fontSizes.sm
		}
	})
}
