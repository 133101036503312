const en = {
	common: {
		true: 'Yes',
		false: 'No'
	},
	notification: {
		allow: 'Turn on notifications'
	},
	login: {
		pageTitle: 'Login',
		subTitle: 'Please enter your email and password to login.',
		pinSubTitle: 'Please enter your email to continue.',
		required: 'Required',
		mailInput: {
			title: 'E-mail',
			requredError: 'E-mail is required',
			patternError: 'The e-mail address you entered is not a valid format.'
		},
		passwordInput: {
			title: 'Password',
			requredError: 'Password is required'
		},
		pinInput: {
			title: 'Pin',
			requredError: 'Pin is required'
		},
		forgotPassword: 'Forgot Password',
		loginButton: 'Login',
		sendPinButton: 'Continue',
		// eslint-disable-next-line prettier/prettier
		noAccount: 'Haven’t got an account?',
		register: 'Sign Up'
	},
	forgotPassword: {
		pageTitle: 'Forgot Password',
		subTitle:
			'First, we need to find your account. Please write your e-mail address and continue.',
		required: 'Required',
		mailInput: {
			title: 'E-mail',
			requredError: 'E-mail is required',
			patternError: 'The e-mail address you entered is not a valid format.'
		},
		acceptButton: 'Continue',
		cancelButton: 'Cancel'
	},
	resetPassword: {
		pageTitle: 'New Password',
		subTitle: 'Set your new password to use in your account.',
		required: 'Required',
		passwordInput: {
			title: 'New Password',
			requredError: 'New Password is required',
			patternError: 'Password must be at least 8 characters.'
		},
		confirmPassInput: {
			title: 'New Password (again)',
			placeholder: 'Password (again)',
			requredError: 'New Password (again) is required',
			patternError: 'Password must be at least 8 characters.',
			validationError: 'Passwords do not match!'
		},
		acceptButton: 'Continue',
		cancelButton: 'Cancel'
	},
	changePassword: {
		pageTitle: 'Change Password',
		subTitle: 'Set your new password to use in your account.',
		required: 'Required',
		oldPassInput: {
			title: 'Old Password',
			placeholder: 'Old Password',
			requredError: 'Old Password is required'
		},
		passwordInput: {
			title: 'New Password',
			requredError: 'New Password is required',
			patternError: 'Password must be at least 8 characters.'
		},
		confirmPassInput: {
			title: 'New Password (again)',
			placeholder: 'Password (again)',
			requredError: 'New Password (again) is required',
			patternError: 'Password must be at least 8 characters.',
			validationError: 'Passwords do not match!'
		},
		acceptButton: 'Continue',
		cancelButton: 'Cancel'
	},
	invitationRegister: {
		pageTitle: 'Sign Up by Invitation',
		subTitle: 'To register, please fill in the information below.',
		required: 'Required',
		nameInput: {
			title: 'Full Name',
			placeholder: 'Full Name',
			requredError: 'Full name is required'
		},
		passwordInput: {
			title: 'Password',
			info: 'Password must be at least 8 characters.',
			requredError: 'Password is required',
			patternError: 'Password must be at least 8 characters.'
		},
		signupButton: 'Sign Up',
		haveAccount: 'I have an account.',
		login: 'Login'
	},
	signup: {
		pageTitle: 'Sign Up',
		subTitle: 'To register, please fill in the information below.',
		required: 'Required',
		nameInput: {
			title: 'Full Name',
			placeholder: 'Full Name',
			requredError: 'Full name is required'
		},
		mailInput: {
			title: 'E-mail',
			requredError: 'E-mail is required',
			patternError: 'The e-mail address you entered is not a valid format.'
		},
		passwordInput: {
			title: 'Password',
			info: 'Password must be at least 8 characters.',
			requredError: 'Password is required',
			patternError: 'Password must be at least 8 characters.'
		},
		signupButton: 'Sign Up',
		haveAccount: 'I have an account.',
		login: 'Login'
	},
	verification: {
		pageTitle: 'Activation Code',
		subTitle: 'Please enter your activation code sent to your e-mail address.',
		required: 'Required',
		codeInput: {
			title: 'Activation Code',
			placeholder: 'Enter the 6-digit code sent to your e-mail.',
			requredError: 'Activation Code is required'
		},
		acceptButton: 'Continue',
		cancelButton: 'Cancel'
	},
	api: {
		error: 'Something went wrong, try again soon.'
	},
	notFound: {
		text: 'You are not authorized to view this screen, or it does not exist.',
		back: 'Go back'
	},
	gallery: {
		gallery: 'Gallery',
		close: 'Close'
	},
	screenContainer: {
		by: 'by ',
		copyLink: 'Copy Link',
		scan: 'Scan to install',
		buildWith: 'Build your own app with'
	},
	calendarList: {
		today: 'Today'
	},
	barcodeScanner: {
		scan: 'Scan Code'
	},
	chat: {
		more: 'Load earlier messages',
		placeholder: 'Type a message...'
	},
	tileList: {
		moreText: 'See more'
	},
	mapList: {
		moreText: 'See more'
	},
	reviews: {
		moreText: 'See more',
		lessText: 'See less',
		agoText: 'ago'
	},
	list: {
		moreText: 'See more'
	},
	form: {
		endsAfter: 'Ends After',
		numberOfOccurrences: 'Number of occurrences',
		endsOn: 'Ends On',
		lastDate: 'Last date to repeat',
		cancel: 'Cancel',
		done: 'Done',
		none: 'Does not repeat',
		day: 'Every day',
		week: 'Every week',
		month: 'Every month',
		year: 'Every year'
	},
	aggOperators: {
		sum: 'Sum',
		avg: 'Avg',
		min: 'Min',
		max: 'Max',
		count: 'Count'
	},
	addToHomeScreen: {
		safari: {
			description:
				'For the best experience, we recommend installing the {0} app to your home screen!',
			click: 'Click the',
			icon: 'icon',
			scroll: 'Scroll down and then click',
			add: 'Add to Home Screen'
		},
		chrome: {
			description:
				'For the best experience, we recommend installing the {0} app to your home screen!',
			click: 'Click the',
			icon: 'icon',
			scroll: 'Scroll down and then click',
			add: 'Add to Home Screen'
		},
		samsung: {
			description:
				'For the best experience, we recommend installing the {0} app to your home screen!',
			click: 'Click the',
			icon: 'icon',
			add: 'Add page to',
			then: 'Then select',
			home: 'Home screen'
		}
	}
}

export default en
