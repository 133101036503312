import React, { useState, useEffect } from 'react'
import { StyleSheet, Image, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'
import { opacity } from 'simpler-color'

import TextWithFont from './TextWithFont'
import ActionButton from './ActionButton'
import SmartTextView from './SmartTextView'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

import env from '../../env'

const MapDetail = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const isPreviewApp = env.isPreview

	// const title = getMappingValByKey(props, 'title')
	// const text = getMappingValByKey(props, 'text')
	// const subText = getMappingValByKey(props, 'subText')
	// const markerText = getMappingValByKey(props, 'markerText')
	const imgUrl = getMappingValByKey(props, 'imgUrl')
	// const geoLocation = getMappingValByKey(props, 'geoLocation')

	return (
		<View style={styles.mainContainer}>
			<View style={styles.detailClose}>
				<ActionButton
					onPress={() =>
						props.setDetailVisible && props.setDetailVisible(false)
					}
					size={20}
					icon={'close'}
				/>
			</View>
			<TouchableOpacity
				style={styles.detailContent}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() => {
					triggerActionList(props.block, props.block.actions, props.block.id)
				}}
			>
				{imgUrl && (
					<View style={styles.leftContainer}>
						<Image
							resizeMode="cover"
							style={styles.leftImage}
							source={{
								uri: imgUrl
							}}
						/>
					</View>
				)}
				<View style={styles.rightContainer}>
					<SmartTextView
						alignCenter={false}
						style={styles.titleText}
						chipStyle={[styles.titleText, styles.chipsText]}
						mapping={'title'}
						numberOfLines={1}
						{...props}
					/>
					<SmartTextView
						alignCenter={false}
						style={styles.textText}
						chipStyle={[styles.textText, styles.chipsText]}
						mapping={'text'}
						numberOfLines={1}
						{...props}
					/>
					<SmartTextView
						alignCenter={false}
						style={styles.subTextText}
						chipStyle={[styles.subTextText, styles.chipsText]}
						mapping={'subText'}
						numberOfLines={1}
						{...props}
					/>
				</View>
			</TouchableOpacity>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize
})

export default connect(mapStateToProps)(MapDetail)

const themedStyles = (props, theme, height) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			flexDirection: 'column',
			position: 'absolute',
			left: '5%',
			bottom: '5%',
			width:
				props.screenSize === 'sm'
					? '90%'
					: props.screenSize === 'md'
					? '70%'
					: '40%',
			height: 110,
			borderRadius: theme.radiuses.lg,
			justifyContent: 'center',
			alignItems: 'flex-start',
			overflow: 'hidden',
			backgroundColor: theme.colors.layoutBackground
		},
		detailClose: {
			position: 'absolute',
			top: theme.spacing.xs,
			right: theme.spacing.xs,
			zIndex: 1
		},
		detailContent: {
			position: 'relative',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'flex-start',
			flexDirection: 'row',
			flexWrap: 'wrap',
			width: '100%'
		},
		leftContainer: {
			width: 110,
			maxWidth: 110,
			height: 110,
			flex: 1,
			alignItems: 'center',
			justifyContent: 'center',
			borderTopLeftRadius: theme.radiuses.sm,
			borderBottomLeftRadius: theme.radiuses.sm,
			backgroundColor: opacity(theme.colors.accent, 0.2)
		},
		leftImage: {
			width: '100%',
			height: '100%',
			borderTopLeftRadius: theme.radiuses.sm,
			borderBottomLeftRadius: theme.radiuses.sm
		},
		rightContainer: {
			width: '100%',
			height: 110,
			flex: 1,
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			flexDirection: 'column',
			padding: theme.spacing.xl
		},
		titleText: {
			flexGrow: 1,
			fontSize: theme.fontSizes.md,
			color: theme.colors.text,
			fontWeight: '600'
		},
		textText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.inputText
		},
		subTextText: {
			marginTop: theme.spacing.xs,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.text,
			fontWeight: '600'
		},
		chipsText: {
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.sm,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		}
	})
}
