import React from 'react'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'

import visibilityHelper from '../common/methods/visibilityHelper'
import nav from '../common/methods/nav'

const DrawerContent = (props) => {
	const theme = useTheme()
	const activeRoute = props.state.routes[props.state.index]

	return (
		<DrawerContentScrollView
			{...props}
			style={{
				backgroundColor: theme.colors.headerBackground
			}}
		>
			{props?.drawerData?.length > 0 &&
				props.drawerData.map((item, index) =>
					visibilityHelper.isMenuVisible(
						item.visibility,
						props.userInfo,
						props.ghostUser
					) ? (
						<DrawerItem
							key={index}
							style={{
								// marginTop: -12,
								marginBottom: -8,
								cursor: 'pointer',
								backgroundColor:
									item.key === activeRoute.name
										? theme.colors.accent
										: 'transparent'
							}}
							label={item.name}
							labelStyle={{
								color:
									item.key === activeRoute.name
										? theme.colors.headerBackground
										: theme.colors.accent
							}}
							onPress={() => {
								if (item?.screens) {
									nav.navigateTo(`/${item.path}`, false)
								} else {
									nav.navigateTo(`/${item.path}`, false)
								}
							}}
						/>
					) : null
				)}
		</DrawerContentScrollView>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.ghostUser
})

export default connect(mapStateToProps, null)(DrawerContent)
