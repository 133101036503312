import React, { useState, useEffect } from 'react'
import {
	Image,
	View,
	StyleSheet,
	Platform,
	TouchableOpacity
} from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { opacity } from 'simpler-color'

import { connect } from 'react-redux'
import { setAuth } from '../redux/actions/authActions'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

const ProfileCard = (props) => {
	const theme = useTheme()

	const [textAlign, setTextAlign] = useState('center') //left-right-center
	const [imageRadius, setImageRadius] = useState(theme.radiuses.none)
	const [imageResizeMode, setImageResizeMode] = useState('cover')
	const [cardWidth, setCardWidth] = useState('100%')

	useEffect(() => {
		if (props.block.imageResizeMode) {
			setImageResizeMode(props.block.imageResizeMode)
		}
		if (props.block.appearance != 'profile') {
			setImageRadius(theme.radiuses.none)
			setCardWidth('100%')
			if (props.block.textAlign) {
				setTextAlign(props.block.textAlign)
			}
		}

		if (props.block.appearance == 'profile') {
			setTextAlign('center')
			setCardWidth(props.block.width)
			setImageRadius(props.screenWidth / 2)
		} else if (props.block.appearance == 'card') {
			setImageRadius(theme.radiuses.lg)
		}
	}, [props.block.appearance])

	const styles = themedStyles(props, theme, textAlign, imageRadius)
	const imgUrl =
		props?.userInfo && getMappingValByKey(props, 'imgUrl', props.userInfo)
	const title =
		props?.userInfo && getMappingValByKey(props, 'title', props.userInfo)
	const text =
		props?.userInfo && getMappingValByKey(props, 'text', props.userInfo)

	return (
		<TouchableOpacity
			style={styles.mainContainer}
			activeOpacity={0.8}
			disabled={props.block.actions?.[0]?.type == null ? true : false}
			onPress={() =>
				triggerActionList(
					props.block,
					props.block.actions,
					props.block?.route?.params?.id
				)
			}
		>
			<View style={{ width: cardWidth }}>
				<View style={styles.aspectRatioContainer}>
					<View style={styles.aspectRatioContent}>
						{imgUrl ? (
							<Image
								resizeMode={imageResizeMode}
								style={{
									width: '100%',
									height: '100%'
								}}
								source={{
									uri: imgUrl
								}}
							/>
						) : (
							<TextWithFont style={styles.letter}>
								{!!(title || text) && (title || text).toString().charAt(0)}
							</TextWithFont>
						)}
					</View>
				</View>

				<LinearGradient
					style={
						props.block.appearance != 'profile'
							? styles.textContainer
							: { marginTop: 5, fontWeight: '600' }
					}
					colors={
						props.block.appearance != 'profile'
							? ['transparent', 'rgba(0,0,0,1)']
							: ['transparent', 'transparent']
					}
				>
					<TextWithFont style={styles.titleText} numberOfLines={1}>
						{title}
					</TextWithFont>
					<TextWithFont style={styles.contentText} numberOfLines={1}>
						{text}
					</TextWithFont>
				</LinearGradient>
			</View>
		</TouchableOpacity>
	)
}

const themedStyles = (props, theme, textAlign, imageRadius) => {
	return StyleSheet.create({
		mainContainer: {
			width: 'auto',
			flexDirection: 'row',
			justifyContent: 'center',
			padding:
				props.block.appearance == 'full'
					? 0
					: theme.spacing[props.block.spacing],
			marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
		},
		titleText: {
			fontWeight: 'bold',
			fontSize: theme.fontSizes[props.block.fontSize],
			textAlign: textAlign,
			color:
				props.block.appearance != 'profile'
					? theme.colors.coverText
					: theme.colors.text
		},
		contentText: {
			fontSize: theme.fontSizes[props.block.fontSize] - 2,
			textAlign: textAlign,
			color:
				props.block.appearance != 'profile'
					? theme.colors.coverText
					: theme.colors.textLight
		},
		letter: {
			fontSize: 100,
			fontWeight: '600',
			color: theme.colors.accent
		},
		textContainer: {
			flex: 1,
			justifyContent: 'center',
			position: 'absolute',
			left: -1,
			bottom: -1,
			right: -1,
			borderRadius: imageRadius,
			fontWeight: '600',
			padding: theme.spacing[props.block.spacing]
		},
		aspectRatioContainer:
			props.block.appearance == 'profile'
				? {
						position: 'relative',
						paddingTop: '100%',
						width: '100%'
				  }
				: {
						position: 'relative',
						height: props.block.appearance == 'card' ? 328 : 308,
						width: '100%'
				  },
		aspectRatioContent: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: opacity(theme.colors.accent, 0.2),
			borderRadius: imageRadius,
			overflow: 'hidden'
		}
	})
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.screenSize,
	...state.screenWidth
})

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (data) => dispatch(setAuth(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
