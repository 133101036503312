const tr = {
	common: {
		true: 'Evet',
		false: 'Hayır'
	},
	notification: {
		allow: 'Bildirimleri aç'
	},
	login: {
		pageTitle: 'Giriş Yap',
		subTitle: 'Giriş yapmak için lütfen e-posta adresinizi ve şifrenizi girin.',
		pinSubTitle: 'Devam etmek için lütfen e-postanızı girin.',
		required: 'Zorunlu',
		mailInput: {
			title: 'E-posta',
			requredError: 'E-posta adresinizi girin.',
			patternError: 'Girdiğiniz e-posta adresi geçerli bir format değil.'
		},
		passwordInput: {
			title: 'Şifre',
			requredError: 'Şifrenizi girin.'
		},
		pinInput: {
			title: 'Pin',
			requredError: 'Pin kodu girin.'
		},
		forgotPassword: 'Şifremi Unuttum',
		loginButton: 'Giriş Yap',
		sendPinButton: 'Devam Et',
		noAccount: 'Henüz üyeliğiniz yok mu?',
		register: 'Üye Ol'
	},
	forgotPassword: {
		pageTitle: 'Şifremi Unuttum',
		subTitle:
			'Öncelikle hesabınızı bulmamız gerekiyor. Lütfen e-posta adresinizi yazıp devam ediniz.',
		required: 'Zorunlu',
		mailInput: {
			title: 'E-posta',
			requredError: 'E-posta adresinizi girin.',
			patternError: 'Girdiğiniz e-posta adresi geçerli bir format değil.'
		},
		acceptButton: 'Devam Et',
		cancelButton: 'İptal'
	},
	resetPassword: {
		pageTitle: 'Şifre Yenile',
		subTitle: 'Hesabınızda kullanmak üzere yeni şifrenizi belirleyin.',
		required: 'Zorunlu',
		passwordInput: {
			title: 'Yeni Şifre',
			requredError: 'Yeni şifrenizi girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.'
		},
		confirmPassInput: {
			title: 'Yeni Şifre (tekrar)',
			placeholder: 'Yeni Şifre (tekrar)',
			requredError: 'Yeni şifrenizi tekrar girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.',
			validationError: 'Şifreler uyuşmuyor!'
		},
		acceptButton: 'Devam Et',
		cancelButton: 'İptal'
	},
	changePassword: {
		pageTitle: 'Şifre Yenile',
		subTitle: 'Hesabınızda kullanmak üzere yeni şifrenizi belirleyin.',
		required: 'Zorunlu',
		oldPassInput: {
			title: 'Eski Şifre',
			placeholder: 'Eski Şifre',
			requredError: 'Eski şifrenizi girin.'
		},
		passwordInput: {
			title: 'Yeni Şifre',
			requredError: 'Yeni şifrenizi girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.'
		},
		confirmPassInput: {
			title: 'Yeni Şifre (tekrar)',
			placeholder: 'Yeni Şifre (tekrar)',
			requredError: 'Yeni şifrenizi tekrar girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.',
			validationError: 'Şifreler uyuşmuyor!'
		},
		acceptButton: 'Devam Et',
		cancelButton: 'İptal'
	},
	invitationRegister: {
		pageTitle: 'Hesap Oluştur',
		subTitle: 'Hesap oluşturmak için lütfen aşağıdaki bilgileri doldurunuz.',
		required: 'Zorunlu',

		nameInput: {
			title: 'Ad Soyad',
			placeholder: 'Ad Soyad',
			requredError: 'Ad Soyad bilgisini girin.'
		},
		passwordInput: {
			title: 'Şifre',
			info: 'Şifre en az 8 karakterden oluşmalıdır.',
			requredError: 'Şifrenizi girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.'
		},
		signupButton: 'Hesap Oluştur',
		haveAccount: 'Hesabım var.',
		login: 'Giriş Yap'
	},
	signup: {
		pageTitle: 'Hesap Oluştur',
		subTitle: 'Hesap oluşturmak için lütfen aşağıdaki bilgileri doldurunuz.',
		required: 'Zorunlu',
		nameInput: {
			title: 'Ad Soyad',
			placeholder: 'Ad Soyad',
			requredError: 'Ad Soyad bilgisini girin.'
		},
		mailInput: {
			title: 'E-posta',
			requredError: 'E-posta adresinizi girin.',
			patternError: 'Girdiğiniz e-posta adresi geçerli bir format değil.'
		},
		passwordInput: {
			title: 'Şifre',
			info: 'Şifre en az 8 karakterden oluşmalıdır.',
			requredError: 'Şifrenizi girin.',
			patternError: 'Şifre en az 8 karakterden oluşmalıdır.'
		},
		signupButton: 'Hesap Oluştur',
		haveAccount: 'Hesabım var.',
		login: 'Giriş Yap'
	},
	verification: {
		pageTitle: 'Aktivasyon Kodu',
		subTitle:
			'Lütfen e-posta adresinize gönderilen aktivasyon kodunuzu giriniz.',
		required: 'Zorunlu',
		codeInput: {
			title: 'Aktivasyon Kodu',
			placeholder: 'E-postanıza gönderilen 6 haneli kodu giriniz.',
			requredError: 'Aktivasyon kodunu girin.'
		},
		acceptButton: 'Devam Et',
		cancelButton: 'İptal'
	},
	api: {
		error: 'Bir şeyler ters gitti, kısa bir süre sonra tekrar dene.'
	},
	notFound: {
		text: 'Bu ekranı görüntüleme yetkiniz yok veya ekran mevcut değil.',
		back: 'Geri dön'
	},
	gallery: {
		gallery: 'Galeri',
		close: 'Kapat'
	},
	screenContainer: {
		by: 'ile ',
		copyLink: 'Linki kopyala',
		scan: 'Yüklemek için tarayın',
		buildWith: 'ile kendi uygulamanızı oluşturun'
	},
	calendarList: {
		today: 'Bugün'
	},
	barcodeScanner: {
		scan: 'Kodu Tara'
	},
	chat: {
		more: 'Önceki mesajları yükle',
		placeholder: 'Bir mesaj yazın...'
	},
	tileList: {
		moreText: 'Daha fazla'
	},
	mapList: {
		moreText: 'Daha fazla'
	},
	reviews: {
		moreText: 'Daha fazla',
		lessText: 'Daha az',
		agoText: 'önce'
	},
	list: {
		moreText: 'Daha fazla'
	},
	form: {
		endsAfter: 'Şu kadar tekrardan sonra bitir',
		numberOfOccurrences: 'Tekrarlama sayısı',
		endsOn: 'Şu tarihte bitir',
		lastDate: 'Son gün',
		cancel: 'İptal',
		done: 'Tamam',
		none: 'Tekrarlanmaz',
		day: 'Her gün',
		week: 'Her hafta',
		month: 'Her ay',
		year: 'Her yıl'
	},
	aggOperators: {
		sum: 'Top',
		avg: 'Ort',
		min: 'Min',
		max: 'Mak',
		count: 'Say'
	},
	addToHomeScreen: {
		safari: {
			description:
				'En iyi deneyim için {0} uygulamasını ana ekranınıza yüklemenizi öneririz!',
			click: 'Bu',
			icon: 'butona tıklayın',
			scroll: 'Aşağı kaydırın ve tıklayın',
			add: 'Ana ekrana ekle'
		},
		chrome: {
			description:
				'En iyi deneyim için {0} uygulamasını ana ekranınıza yüklemenizi öneririz!',
			click: 'Bu',
			icon: 'butona tıklayın',
			scroll: 'Aşağı kaydırın ve tıklayın',
			add: 'Ana ekrana ekle'
		},
		samsung: {
			description:
				'En iyi deneyim için {0} uygulamasını ana ekranınıza yüklemenizi öneririz!',
			click: 'Bu',
			icon: 'butona tıklayın',
			add: 'Sayfa ekle',
			then: 'Sonra seçin',
			home: 'Ana ekran'
		}
	}
}

export default tr
