import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import ReactPlayer from 'react-player'

import getMappingValByKey from '../common/methods/getMappingValByKey'
import { useTheme } from '../theme/Theme'

/*
Example videos
http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4
https://www.youtube.com/embed/tgbNymZ7vqY
https://player.vimeo.com/video/707012696?h=4ecdb8a7c4
https://www.youtube.com/watch?v=FucPJyqG4TI
*/

const Video = (props) => {
	const theme = useTheme()
	const styles = themedStyles(theme)

	const [videoUrl, setVideUrl] = useState(null)

	useEffect(() => {
		const videoUrl = getMappingValByKey(props, 'videoUrl')
		setVideUrl(videoUrl)
	}, [])

	return (
		<View style={styles.videoContainer}>
			<ReactPlayer
				url={videoUrl}
				width="100%"
				height="100%"
				controls
				style={{
					background: '#000000'
				}}
				config={{
					file: {
						attributes: {
							controlsList: 'nodownload noplaybackrate',
							disablePictureInPicture: true
						}
					}
				}}
			/>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null)(Video)

const themedStyles = (theme) => {
	return StyleSheet.create({
		videoContainer: {
			width: '100%',
			aspectRatio: 16 / 9,
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden',
			marginTop: theme.spacing.sm,
			marginBottom: theme.spacing.sm
		}
	})
}
