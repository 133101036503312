import React from 'react'
import { Platform, StyleSheet, StatusBar, TouchableOpacity } from 'react-native'
import { default as MobileModal } from 'react-native-modal'
import { useTheme } from '../theme/Theme'

import env from '../../env'

const isPreviewApp = env.isPreview

const CustomModal = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const content = () => {
		return (
			<TouchableOpacity style={styles.modalContainer} activeOpacity={1}>
				{Platform.OS !== 'web' && (
					<StatusBar
						translucent
						backgroundColor={theme.colors.layoutBackground}
					/>
				)}

				<TouchableOpacity
					style={styles.modalContent}
					activeOpacity={1}
					onPress={props.preventParentEvent}
				>
					{props.children}
				</TouchableOpacity>
			</TouchableOpacity>
		)
	}

	return (
		<MobileModal
			statusBarTranslucent
			coverScreen={props.coverScreen ?? false}
			focusTrap={false}
			style={styles.view}
			animationIn={'slideInUp'}
			animationOut={'slideOutDown'}
			animationInTiming={50}
			animationOutTiming={50}
			hasBackdrop={false}
			{...props}
		>
			{content()}
		</MobileModal>
	)
}

export default CustomModal

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		view: {
			marginLeft: 0,
			marginRight: 0,
			marginBottom: 0,
			marginTop: 0
		},
		modalContainer: {
			width: '100%',
			height: '100%',
			backgroundColor: 'transparent'
		},
		modalContent: {
			backgroundColor: theme.colors.layoutBackground,
			width: '100%',
			height: '100%'
		}
	})
}
