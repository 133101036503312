import React, { useState, useEffect } from 'react'
import { TouchableOpacity, Image, View, StyleSheet } from 'react-native'
import { opacity } from 'simpler-color'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { connect } from 'react-redux'

import i18n from '../i18n/i18n'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'
import SmartTextView from './SmartTextView'

import triggerActionList from '../common/methods/triggerActionList'
import localeDateDistance from '../common/methods/localeDateDistance'

const Review = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [numberOfLines, setNumberOfLines] = useState(props.messageLineLimit)

	const userPhoto = props?.userPhoto ? (
		<Image
			resizeMode={props.imageResizeMode}
			style={styles.userPhoto}
			source={{
				uri: props.userPhoto
			}}
		/>
	) : (
		<View style={styles.userPhotoTextContainer}>
			<TextWithFont style={styles.userPhotoText}>
				{props?.userName?.[0]}
			</TextWithFont>
		</View>
	)

	return (
		<View
			key={`review-${props.index}-${props.id}`}
			style={styles.reviewContainer}
		>
			{!props.isEmpty && (
				<View>
					<View style={styles.userContainer}>
						<View style={styles.userPhotoContainer}>{userPhoto}</View>
						<View style={styles.userNameContainer}>
							<SmartTextView
								style={styles.userNameText}
								mapping={'userName'}
								numberOfLines={1}
								{...props}
							/>
							<TextWithFont style={styles.ratingContainer}>
								{props.rating &&
									Array.from({ length: props.maxRating ?? 5 }).map((e, idx) => (
										<MaterialCommunityIcons
											key={`review-${props.index}-${props.id}-rating-${idx}`}
											name={'star-box'}
											size={20}
											style={
												props.rating >= idx + 1
													? styles.ratingIcon
													: styles.ratingIconGray
											}
										/>
									))}
							</TextWithFont>
							<TextWithFont style={styles.time}>{`${localeDateDistance(
								props.time
							)} ${i18n.t('reviews.agoText')}`}</TextWithFont>
						</View>
					</View>

					<View style={styles.messageContainer}>
						<SmartTextView
							style={styles.messageText}
							mapping={'message'}
							numberOfLines={numberOfLines}
							{...props}
						/>
					</View>
					<TouchableOpacity
						style={styles.seeMoreButton}
						onPress={() => {
							numberOfLines
								? setNumberOfLines(null)
								: setNumberOfLines(props.messageLineLimit)
						}}
					>
						<TextWithFont style={styles.seeMoreText}>
							{numberOfLines
								? i18n.t('reviews.moreText')
								: i18n.t('reviews.lessText')}
						</TextWithFont>
					</TouchableOpacity>
				</View>
			)}
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Review)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		reviewContainer: props.horizontal
			? {
					width:
						props.screenSize === 'sm'
							? props.screenWidth * 0.75
							: props.screenSize === 'md'
							? props.screenWidth * 0.7
							: props.screenSize === 'lg'
							? props.screenWidth * 0.5
							: props.screenWidth
			  }
			: { flex: 1, marginTop: props.index === 0 ? 0 : props.spacing },
		userContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		userNameContainer: {
			flex: 1,
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center'
		},
		userNameText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text,
			fontWeight: '600'
		},
		messageContainer: {
			marginTop: theme.spacing.xl,
			fontWeight: '600'
		},
		messageText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		userPhotoContainer: {
			marginRight: theme.spacing.xl
		},
		userPhoto: {
			width: 60,
			height: 60,
			borderRadius: props.imageRadius
		},
		userPhotoTextContainer: {
			backgroundColor: opacity(theme.colors.accent, 0.2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			borderRadius: props.imageRadius,
			overflow: 'hidden'
		},
		userPhotoText: {
			width: 60,
			height: 60,
			fontSize: theme.fontSizes.sm,
			fontWeight: '600',
			color: theme.colors.accent,
			textTransform: 'uppercase'
		},
		ratingContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		ratingIcon: {
			color: theme.colors.accent
		},
		ratingIconGray: {
			color: opacity(theme.colors.inputText, 0.3)
		},
		time: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		},
		seeMoreButton: {
			marginTop: theme.spacing.xs
		},
		seeMoreText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		}
	})
}
