import store from '../../../redux/store/store'

const removeLastScreenFromPath = (path) => {
	let newPath = '/'
	const generatorData = store.getState()?.generatorData?.generatorData
	const linkingScreens = generatorData?.linking?.config?.screens
	const currentScreenPaths = Object.values(linkingScreens || {})

	const firstDimension = /(?<first>[^/]+)$/.exec(path)
	const secondDimension = /(?<first>[^/]+)[/](?<second>[^/]+)$/.exec(path)

	if (currentScreenPaths.includes(firstDimension?.[0])) {
		newPath = path.substring(0, path.length - firstDimension[0].length - 1)
	} else if (secondDimension && secondDimension?.[0]) {
		newPath = path.substring(0, path.length - secondDimension[0].length - 1)
	}

	return newPath
}

export default removeLastScreenFromPath
