import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, StyleSheet, TextInput } from 'react-native'
import { connect } from 'react-redux'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as Linking from 'expo-linking'

import { useTheme } from '../theme/Theme'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import dataClient from '../services/dataClient'

import TextWithFont from './TextWithFont'

const NumberEntry = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const maxVal = parseInt(getMappingValByKey(props, 'max'))
	const minVal = parseInt(getMappingValByKey(props, 'min'))

	const [timer, setTimer] = useState(0)
	const [number, setNumber] = useState(0)

	const onChange = (val) => {
		const intVal = parseInt(val)

		if (!isNaN(val) && !isNaN(intVal) && intVal <= maxVal && intVal >= minVal) {
			setNumber(intVal)
			timer && clearTimeout(timer)
			setTimer(setTimeout(() => submit(intVal), 1000))
		} else if (
			(!isNaN(val) && !isNaN(intVal) && (intVal > maxVal || intVal < minVal)) ||
			val === ''
		) {
			let submitVal = minVal
			if (intVal > maxVal) {
				submitVal = maxVal
			}
			setNumber(submitVal)
			timer && clearTimeout(timer)
			setTimer(setTimeout(() => submit(submitVal), 1000))
		}
	}

	const increment = () => {
		number < maxVal && onChange(number + 1)
	}
	const decrement = () => {
		number > minVal && onChange(number - 1)
	}

	const submit = (val) => {
		let jsonData = {}
		jsonData['Id'] = props.block.data.Id
		jsonData[props.block.mapping.number.field] = val

		const jsonHeaders = {
			'Access-Control-Allow-Origin': Linking.createURL('')
		}
		dataClient
			.patchData(`/${props.block.layoutSource}`, jsonHeaders, jsonData)
			.then((response) => {
				if (response.status == 200) {
					props?.refresh && props.refresh()
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
			})
	}

	useEffect(() => {
		props.block?.data
			? setNumber(
					parseInt(props.block.data?.[props.block?.mapping?.number?.field]) || 0
			  )
			: setNumber(0)
	}, [props.block.data, props.block.mapping.number.field])

	return (
		<View style={styles.mainContainer}>
			<TextWithFont style={styles.title} numberOfLines={1}>
				{getMappingValByKey(props, 'title')}
			</TextWithFont>
			<View style={styles.componentBox}>
				<TouchableOpacity
					style={styles.button}
					activeOpacity={0.8}
					onPress={decrement}
				>
					<MaterialCommunityIcons
						name="minus"
						size={24}
						color={number <= minVal ? '#b9b9b9' : 'black'}
						style={styles.icon}
					/>
				</TouchableOpacity>
				<TextInput
					style={styles.input}
					onChangeText={onChange}
					value={number?.toString()}
					keyboardType="numeric"
					textAlign="center"
				/>
				<TouchableOpacity
					style={styles.button}
					activeOpacity={0.8}
					onPress={increment}
				>
					<MaterialCommunityIcons
						name="plus"
						size={24}
						color={number >= maxVal ? '#b9b9b9' : 'black'}
						style={styles.icon}
					/>
				</TouchableOpacity>
			</View>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.fetching
})

export default connect(mapStateToProps)(NumberEntry)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: 10,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		title: {
			fontSize: theme.fontSizes.sm,
			lineHeight: 17,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		componentBox: {
			backgroundColor: '#FAFAFC',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: 6,
			padding: 2
		},
		input: {
			height: 30,
			width: 48,
			borderWidth: 0,
			borderRadius: 4,
			padding: 10,
			textAlign: 'center',
			backgroundColor: '#ffffff'
		},
		icon: {
			margin: 5
		}
	})
}
