import * as Font from 'expo-font'

import OpenSans from '../assets/fonts/OpenSans-Regular.ttf'
import Roboto from '../assets/fonts/Roboto-Regular.ttf'

const useFonts = async () =>
	await Font.loadAsync({
		'Open Sans': {
			uri: OpenSans,
			fontDisplay: Font.FontDisplay.SWAP
		},

		Roboto: {
			uri: Roboto,
			fontDisplay: Font.FontDisplay.SWAP
		}
	})

export default useFonts
