import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { StyleSheet, Image as NativeImage } from 'react-native'
import { View } from 'react-native-web'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'

import Carousel from 'react-native-reanimated-carousel'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'

import TextWithFont from './TextWithFont'

import dataClient from '../services/dataClient'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'

import { useTheme } from '../theme/Theme'

const Gallery = forwardRef((props, ref) => {
	const theme = useTheme()

	const [currentIndex, setCurrentIndex] = useState(0)
	const [touchEnabled, setTouchEnabled] = useState(true)
	const [data, setData] = useState([])
	const [fetching, setFetching] = useState(false)

	const width = props.screenWidth - theme.spacing.xl * 2
	const baseOptions = {
		vertical: false,
		width: width,
		height: width * (props.screenSize === 'lg' ? 0.5 : 0.7)
	}
	const images =
		data
			?.map((image, index) => getMappingValByKey(props, 'imgUrl', image.fields))
			?.filter(
				(image) => image !== undefined && image !== null && image !== ''
			) ?? []

	const styles = themedStyles(props, images, theme)

	useFocusEffect(
		useCallback(() => {
			refresh()
		}, [])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: (term = null) => {}
	}))

	const refresh = () => {
		getData()
	}

	const getData = () => {
		setFetching(true)

		let jsonData = {
			rows: props.block?.limit ? props.block.limit : 100,
			page: 1,
			sortField: props.block?.sorting?.field,
			sortDirection: props.block?.sorting?.sort,
			filters: {
				groupOp: 'AND',
				groups: [
					props.block?.filters?.rules?.length
						? formatFilterDataByScreenData(
								props.block.filters,
								props.block?.data
						  )
						: {}
				]
			}
		}

		dataClient
			.postData(`/${props.block?.source}/search`, {}, jsonData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						setData(response.data.records)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	return images?.length > 0 ? (
		<PhotoProvider loop={false}>
			{props.screenSize === 'lg' && (
				<View style={styles.largeContainer}>
					<View style={styles.largeConverImageContainer}>
						<PhotoView key={0} src={images[0]}>
							<img
								src={images[0]}
								alt=""
								style={
									images.length === 1
										? {
												...{
													borderRadius: theme.radiuses.md
												},
												...styles.largeConverImage
										  }
										: styles.largeConverImage
								}
							/>
						</PhotoView>
					</View>
					{images.length > 1 && (
						<View style={styles.largeSmallImageContainer}>
							{images.slice(1, images.length).map((image, index) => {
								if (index <= 3) {
									return (
										<PhotoView key={index} src={image}>
											<img
												src={image}
												alt=""
												style={
													images.length >= 5 && index === 3
														? {
																...{
																	borderBottomRightRadius: theme.radiuses.md
																},
																...styles.largeSmallImage
														  }
														: images.length >= 5 && index === 1
														? {
																...{
																	borderTopRightRadius: theme.radiuses.md
																},
																...styles.largeSmallImage
														  }
														: images.length >= 4 && index === 1
														? {
																...{
																	borderTopRightRadius: theme.radiuses.md
																},
																...styles.largeSmallImage
														  }
														: images.length >= 3 && index === 1
														? {
																...{
																	borderBottomRightRadius: theme.radiuses.md,
																	borderTopRightRadius: theme.radiuses.md
																},
																...styles.largeSmallImage
														  }
														: images.length == 2 && index === 0
														? {
																...{
																	borderBottomRightRadius: theme.radiuses.md,
																	borderTopRightRadius: theme.radiuses.md
																},
																...styles.largeSmallImage
														  }
														: styles.largeSmallImage
												}
											/>
										</PhotoView>
									)
								} else {
									return <PhotoView key={index} src={image} />
								}
							})}
						</View>
					)}
				</View>
			)}
			{props.screenSize !== 'lg' && (
				<View style={styles.container}>
					<Carousel
						{...baseOptions}
						data={images}
						loop={false}
						onSnapToItem={(index) => setCurrentIndex(index)}
						onScrollBegin={() => {
							setTouchEnabled(false)
						}}
						onScrollEnd={(index) => setTouchEnabled(true)}
						scrollAnimationDuration={300}
						renderItem={({ index }) =>
							touchEnabled ? (
								<PhotoView key={index} src={images[index]}>
									<NativeImage
										resizeMode={props?.block?.imageResizeMode ?? 'cover'}
										style={styles.image}
										source={{
											uri: images[index]
										}}
									/>
								</PhotoView>
							) : (
								<NativeImage
									resizeMode={props?.block?.imageResizeMode ?? 'cover'}
									style={styles.image}
									source={{
										uri: images[index]
									}}
								/>
							)
						}
					/>
					<View style={styles.badge}>
						<TextWithFont style={styles.badgeText}>
							{currentIndex + 1} / {images.length}
						</TextWithFont>
					</View>
				</View>
			)}
		</PhotoProvider>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Gallery)

const themedStyles = (props, images, theme) => {
	return StyleSheet.create({
		largeContainer: {
			flexDirection: 'row',
			alignItems: 'stretch',
			justifyContent: 'center',
			paddingVertical: theme.spacing.sm
		},
		largeConverImageContainer: {
			flex: 1,
			justifyContent: 'space-between',
			alignItems: 'stretch'
		},
		largeConverImage: {
			height: '100%',
			backgroundColor: 'transparent',
			marginTop: '1%',
			objectFit: 'cover',
			borderTopLeftRadius: theme.radiuses.md,
			borderBottomLeftRadius: theme.radiuses.md
		},
		largeSmallImageContainer: {
			flex: 1,
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
			alignItems: 'stretch'
		},
		largeSmallImage: {
			width: images?.length <= 2 ? '100%' : '49%',
			backgroundColor: 'transparent',
			marginTop: '1%',
			marginLeft: '1%',
			objectFit: 'cover',
			cursor: 'pointer'
		},
		container: {
			alignItems: 'center',
			paddingVertical: theme.spacing.sm,
			backgroundColor: 'transparent'
		},
		badge: {
			position: 'absolute',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			paddingHorizontal: theme.spacing.sm,
			paddingVertical: 2,
			bottom: theme.spacing.sm + theme.spacing.md,
			right: theme.spacing.xl + theme.spacing.md,
			borderRadius: theme.radiuses.xs
		},
		badgeText: {
			color: '#FFFFFF',
			fontSize: theme.fontSizes.sm
		},
		image: {
			width: '100%',
			height: '100%',
			backgroundColor: 'transparent',
			borderRadius: props?.block?.corner === 'oval' ? theme.radiuses.md : 0,
			cursor: 'pointer'
		},
		button: {
			flex: 1,
			justifyContent: 'center'
		}
	})
}
