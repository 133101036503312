import React, { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import Cookies from 'universal-cookie'

import AddToMobileChrome from './AddToMobileChrome'
import AddToMobileChromeIos from './AddToMobileChromeIos'
import AddToIosSafari from './AddToIosSafari'
import AddToSamsung from './AddToSamsung'

import useUserAgent from '../../hooks/useUserAgent'
import CustomPopup from '../CustomPopup'

import env from '../../../env'

const COOKIE_NAME = 'addToHomeScreenPrompt'

const AddToHomeScreen = (props) => {
	const [displayPrompt, setDisplayPrompt] = useState('')
	const { userAgent, isMobile, isStandalone, isIOS } = useUserAgent()
	const [visible, setVisible] = useState(true)

	const isPreviewApp = env.isPreview
	const webAppCheck = !isPreviewApp && Platform.OS === 'web'

	const cookies = new Cookies()

	const doNotShowAgain = () => {
		cookies.set(COOKIE_NAME, 'dontShow')

		setDisplayPrompt('')
		setVisible(false)
	}

	useEffect(() => {
		const addToHomeScreenCookie = cookies.get(COOKIE_NAME)
		if (addToHomeScreenCookie !== 'dontShow') {
			// Only show prompt if user is on mobile and app is not installed
			if (isMobile && !isStandalone) {
				if (userAgent === 'Safari') {
					setDisplayPrompt('safari')
				} else if (userAgent === 'Chrome') {
					setDisplayPrompt('chrome')
				}
				//  else if (userAgent === 'Firefox') {
				// 	setDisplayPrompt('firefox')
				// } else if (userAgent === 'FirefoxiOS') {
				// 	setDisplayPrompt('firefoxIos')
				// }
				else if (userAgent === 'ChromeiOS') {
					setDisplayPrompt('chromeIos')
				} else if (userAgent === 'SamsungBrowser') {
					setDisplayPrompt('samsung')
				} else {
					setDisplayPrompt('')
				}
			}
		}
	}, [userAgent, isMobile, isStandalone, isIOS])

	const Prompt = () => (
		<>
			{
				{
					chrome: <AddToMobileChrome doNotShowAgain={doNotShowAgain} />,
					chromeIos: <AddToMobileChromeIos doNotShowAgain={doNotShowAgain} />,
					safari: <AddToIosSafari doNotShowAgain={doNotShowAgain} />,
					samsung: <AddToSamsung doNotShowAgain={doNotShowAgain} />,
					'': <></>
				}[displayPrompt]
			}
		</>
	)

	return (
		webAppCheck &&
		displayPrompt && (
			<CustomPopup isVisible={visible} cancel={doNotShowAgain}>
				<Prompt />
			</CustomPopup>
		)
	)
}

export default AddToHomeScreen
