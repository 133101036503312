import React, { forwardRef, useState, createElement } from 'react'
import { Text, View, Platform } from 'react-native'

import Btn from '../components/Btn'
import Txt from '../components/Txt'
import TileList from '../components/TileList'
import Carousel from '../components/Carousel'
import Container from '../components/Container'
import Gallery from '../components/Gallery'
import List from '../components/List'
import Table from '../components/Table'
import Columns from '../components/Columns'
import TitleCard from '../components/TitleCard'
import ProfileCard from '../components/ProfileCard'
import ImagePicker from '../components/ImagePicker'
import NoteBox from '../components/NoteBox'
import Seperator from '../components/Seperator'
import SearchBox from '../components/SearchBox'
import SettingsButton from '../components/SettingsButton'
import LogoutButton from '../components/LogoutButton'
import UserInfo from '../components/UserInfo'
import Form from '../components/Form'
import EmailButton from '../components/EmailButton'
import PhoneButton from '../components/PhoneButton'
import LinkButton from '../components/LinkButton'
import InputPhone from '../components/InputPhone'
import InputMail from '../components/InputMail'
import InputText from '../components/InputText'
import InputNumeric from '../components/InputNumeric'
import InputMoney from '../components/InputMoney'
import TextArea from '../components/TextArea'
import Image from '../components/Image'
import ButtonElement from '../components/ButtonElement'
import ButtonBar from '../components/ButtonBar'
import Checkbox from '../components/Checkbox'
import InputCheckbox from '../components/InputCheckbox'
import Video from '../components/Video'
import FilePicker from '../components/FilePicker'
import WebView from '../components/WebView'
import Map from '../components/Map'
import DatePicker from '../components/DatePicker'
import Choice from '../components/Choice'
import InputChoice from '../components/InputChoice'
import CalendarList from '../components/CalendarList'
import NumberEntry from '../components/NumberEntry'
import ChartLine from '../components/ChartLine'
import ChartBar from '../components/ChartBar'
import ChartProgress from '../components/ChartProgress'
import ChartPie from '../components/ChartPie'
import ProgressBar from '../components/ProgressBar'
import Barcode from '../components/Barcode'
import QRCode from '../components/QRCode'
import Numbers from '../components/Numbers'
import Rating from '../components/Rating'
import Chat from '../components/Chat'
import Reviews from '../components/Reviews'
import MapList from '../components/MapList'

const Components = {
	numbers: Numbers,
	rating: Rating,
	chartLine: ChartLine,
	chartBar: ChartBar,
	chartProgress: ChartProgress,
	chartPie: ChartPie,
	progressBar: ProgressBar,
	btn: Btn,
	txt: Txt,
	titleCard: TitleCard,
	profileCard: ProfileCard,
	tileList: TileList,
	reviews: Reviews,
	mapList: MapList,
	chat: Chat,
	carousel: Carousel,
	container: Container,
	gallery: Gallery,
	list: List,
	table: Table,
	columns: Columns,
	emailButton: EmailButton,
	phoneButton: PhoneButton,
	linkButton: LinkButton,
	imagePicker: ImagePicker,
	noteBox: NoteBox,
	seperator: Seperator,
	inputText: InputText,
	inputPhone: InputPhone,
	inputMail: InputMail,
	inputNumeric: InputNumeric,
	inputMoney: InputMoney,
	searchBox: SearchBox,
	settingsButton: SettingsButton,
	logoutButton: LogoutButton,
	userInfo: UserInfo,
	form: Form,
	textArea: TextArea,
	image: Image,
	button: ButtonElement,
	buttonBar: ButtonBar,
	checkbox: Checkbox,
	inputCheckbox: InputCheckbox,
	video: Video,
	filePicker: FilePicker,
	webView: WebView,
	map: Map,
	datePicker: DatePicker,
	choice: Choice,
	inputChoice: InputChoice,
	calendarList: CalendarList,
	numberEntry: NumberEntry,
	barcode: Barcode,
	qrCode: QRCode
}

const referables = [
	//NOTE:components using refs should be added to the array
	'imagePicker',
	'inputText',
	'inputPhone',
	'inputMail',
	'inputNumeric',
	'inputMoney',
	'list',
	'table',
	'tileList',
	'reviews',
	'mapList',
	'chat',
	'carousel',
	'container',
	'gallery',
	'choice',
	'inputCheckbox',
	'filePicker',
	'datePicker',
	'inputChoice',
	'calendarList',
	'chartLine',
	'chartBar',
	'chartProgress',
	'chartPie',
	'numbers',
	'rating',
	'form'
]

const ComponentGenerator = forwardRef((props, ref) => {
	if (typeof Components[props.block.component] !== 'undefined') {
		const [groupsLayout, setGroupsLayout] = useState(null)

		const scrollTo = (coordinate) => {
			if (coordinate && groupsLayout) {
				if (Platform.OS === 'web') {
					props.block.scrollTo(0, coordinate - 55)
				} else {
					props.block.scrollTo(
						0,
						(groupsLayout.top ?? groupsLayout.y) + coordinate + 50
					)
				}
			}
		}

		const propObject =
			ref && referables.includes(props.block.component)
				? {
						key: props.block._uid,
						scrollTo: scrollTo,
						block: props.block,
						ref: ref
				  }
				: {
						key: props.block._uid,
						scrollTo: scrollTo,
						block: props.block
				  }

		return props?.withNativeID ? (
			<View
				nativeID={props.block._uid}
				onLayout={(event) => {
					const layout = event.nativeEvent.layout

					setGroupsLayout(layout)
				}}
			>
				{createElement(Components[props.block.component], propObject)}
			</View>
		) : (
			createElement(Components[props.block.component], propObject)
		)
	}
	return createElement(
		() => (
			<Text>
				The component
				{props.block.component} has not been created yet.
			</Text>
		),
		{ key: props.block._uid }
	)
})

export default ComponentGenerator
