const localeParseFloat = (s, locale) => {
	if (typeof s === 'number') return s

	// Get the thousands and decimal separator characters used in the locale.
	let [, thousandsSeparator, , , , decimalSeparator] = (1111.1).toLocaleString(
		locale
	)
	// Remove thousand separators, and put a point where the decimal separator occurs
	s = Array.from(s, (c) =>
		c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c
	).join('')
	// Now it can be parsed
	return parseFloat(s)
}

export default localeParseFloat
