import getLanguageCode from './getLanguageCode'
import isValidDate from './isValidDate'
import i18n from '../../i18n/i18n'

const languageCode = getLanguageCode()
const localeString = languageCode === 'tr-TR' ? 'tr' : 'en'

const getFormattedValByDatatype = (dataType, value, userInfo = null) => {
	let formattedValue = value

	switch (dataType) {
		case 'Date':
			{
				const processVal = value ? new Date(value) : null
				const secondsOfTheDate =
					processVal?.getHours() * 3600 +
						processVal?.getMinutes() * 60 +
						processVal?.getSeconds() ?? 0

				if (secondsOfTheDate > 60) {
					formattedValue = isValidDate(processVal)
						? `${processVal.toLocaleString(localeString)}`
						: value
				} else {
					formattedValue = isValidDate(processVal)
						? processVal.toLocaleDateString(localeString)
						: value
				}
			}
			break
		case 'Checkbox':
			{
				if (value === null) {
					formattedValue = null
				} else if (value) {
					formattedValue = i18n.t('common.true')
				} else {
					formattedValue = i18n.t('common.false')
				}
			}
			break

		case 'Relation':
			{
				if (Array.isArray(value)) {
					if (value.length > 0) {
						if (typeof value[0] === 'object') {
							formattedValue = value.map((item) => item?.Name ?? '')
						} else {
							formattedValue = value
						}
					}
				} else if (typeof value === 'object') {
					formattedValue = value?.Name
				}
			}
			break
		case 'Variables':
			switch (value) {
				case '{{profile.id}}':
					formattedValue = userInfo?.id
					break
				case '{{profile.name}}':
					formattedValue = userInfo?.name
					break
				case '{{profile.email}}':
					formattedValue = userInfo?.email
					break
				case '{{profile.photo}}':
					formattedValue = userInfo?.photo
					break
				case '{{datetime.year}}':
					formattedValue = new Date().getFullYear()
					break
				case '{{datetime.month}}':
					formattedValue = new Date().getMonth() + 1
					break
				case '{{datetime.date}}':
					formattedValue = new Date().toLocaleDateString()
					break
				case '{{datetime.time}}':
					formattedValue = new Date().toLocaleString() + ''
					break
			}
			break
		default:
			formattedValue = value
			break
	}

	return formattedValue
}

export default getFormattedValByDatatype
