import React, { useState, useEffect } from 'react'
import {
	View,
	TouchableOpacity,
	StyleSheet,
	TextInput,
	Platform
} from 'react-native'
import { connect } from 'react-redux'
import * as Linking from 'expo-linking'

import { useTheme } from '../theme/Theme'

import Button from './Button'
import Loader from './Loader'
import TextWithFont from './TextWithFont'
import CustomPopup from './CustomPopup'

import dataClient from '../services/dataClient'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'

const NoteBox = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [currentRoute, setCurrentRoute] = useState(null)
	const [note, setNote] = useState('')
	const [modalNote, setModalNote] = useState('')
	const [visible, setVisible] = useState(false)
	const [processing, setProcessing] = useState(false)
	//const [data, setData] = useState(null)

	useEffect(() => {
		setModalNote(note)
	}, [visible])

	useEffect(() => {
		setCurrentRoute(props?.block?.route)
	}, [props?.block?.route])

	const getNote = () => {
		setNote(
			props.block?.data?.[props.block?.mapping?.note?.field]
				? getMappingValByKey(props, 'note')
				: getMappingValByKey(props, 'placeholder')
		)

		setModalNote(
			props.block?.data?.[props.block?.mapping?.note?.field]
				? getMappingValByKey(props, 'note')
				: getMappingValByKey(props, 'placeholder')
		)
	}

	const saveNote = () => {
		if (props.block?.data?.Id) {
			updateSubmit(modalNote)
			setNote(modalNote)
		}
	}

	let jsonHeaders = {
		'Access-Control-Allow-Origin': Linking.createURL('')
	}

	const updateSubmit = (note) => {
		let jsonData = {}
		jsonData['Id'] = props.block.data.Id
		jsonData[props.block.mapping.note.field] = note

		setProcessing(true)

		dataClient
			.patchData(`/${props.block.layoutSource}`, jsonHeaders, jsonData)
			.then((response) => {
				if (response.status == 200) {
					props?.block?.refresh && props.block.refresh()
					setVisible(false)
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
				setProcessing(false)
			})
			.catch((err) => {
				setProcessing(false)
			})
	}

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	useEffect(() => {
		props.block?.data ? getNote() : setNote('')
	}, [props.block.data, props.block.mapping.note.field])

	return (
		<>
			{currentRoute && (
				<CustomPopup
					coverScreen={true}
					fullHeight
					isVisible={visible}
					preventParentEvent={preventParentEvent}
					cancel={() => setVisible(false)}
				>
					<View style={styles.notePopupContainer}>
						<TextInput
							style={
								Platform.OS == 'android'
									? { ...styles.modalInput, textAlignVertical: 'top' }
									: styles.modalInput
							}
							onChangeText={(txt) => setModalNote(txt)}
							value={modalNote}
							multiline={true}
						/>
						<View style={{ flexDirection: 'row' }}>
							<Button
								text={getLocalizeContent(props.block.cancel?.text)}
								type="transparent"
								onPress={() => setVisible(false)}
							/>
							<Button
								text={getLocalizeContent(props.block.submit?.text)}
								marginLeft={theme.spacing.xl}
								onPress={processing ? null : saveNote}
								loader={processing}
							/>
						</View>
						{/* {props.fetching > 0 && <Loader />} */}
					</View>
				</CustomPopup>
			)}

			<View style={styles.mainContainer}>
				<TextWithFont style={styles.title}>
					{getMappingValByKey(props, 'title')}
				</TextWithFont>
				<TouchableOpacity
					activeOpacity={0.8}
					onPress={() => setVisible(true)}
					style={styles.noteContainer}
				>
					<TextWithFont style={styles.text}>{note}</TextWithFont>
					{/* <TextWithFont style={styles.editText}>
						{getLocalizeContent(props.block.editText)}
					</TextWithFont> */}
				</TouchableOpacity>
			</View>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.fetching
})

export default connect(mapStateToProps)(NoteBox)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: theme.spacing.sm,
			justifyContent: 'space-between'
		},
		noteContainer: {
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,
			padding: theme.spacing.xl
		},
		notePopupContainer: {
			padding: theme.spacing.xl,
			height: '100%'
		},
		title: {
			fontSize: theme.fontSizes.sm,
			lineHeight: 17,
			color: theme.colors.inputText,
			fontWeight: '600',
			marginBottom: 10
		},
		text: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.text
		},
		editText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			marginTop: 5
		},
		modalInput:
			Platform.OS === 'web'
				? {
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						padding: 10,
						outlineStyle: 'none',
						width: '100%',
						flex: 1,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						marginBottom: theme.spacing.xl,
						color: theme.colors.text
				  }
				: {
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						padding: 10,
						width: '100%',
						flex: 1,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						marginBottom: theme.spacing.xl,
						color: theme.colors.text
				  }
	})
}
