import React, { useEffect } from 'react'
import { useIsFocused } from '@react-navigation/native'

import env from '../../env'

const Redirector = (props) => {
	const isFocused = useIsFocused()
	const isPreviewApp = env.isPreview

	useEffect(() => {
		if (!isPreviewApp && isFocused) {
			console.log('REDIRECTOR', props.target)
			props.navigation.navigate(props.target)
		}
	}, [isFocused])

	return null
}

export default Redirector
