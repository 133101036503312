import env from '../../../env'
import dayjs from 'dayjs'

const isPreviewApp = env.isPreview

const convertToArray = (data) => {
	if (!data) return []

	const ids = Array.isArray(data)
		? data.map((item) => (typeof item === 'object' ? item['Id'] : item))
		: typeof data === 'object'
		? [data['Id']]
		: [data]

	return ids
}
const getLeftValue = (obj, field) => {
	return obj?.[field]
}
const getRightValue = (obj, type, data) => {
	switch (type) {
		case 'Data':
			return data
		case 'Field':
			return obj?.[data]
		case 'Screen':
			return obj?.[data?.replace('Screen.', '')]
		case 'Variables':
			switch (data) {
				case '{{profile.id}}':
					return obj?.['Id']
				case '{{profile.name}}':
					return obj?.['Name']
				case '{{profile.email}}':
					return obj?.['Email']
				case '{{profile.photo}}':
					return obj?.['Photo']
				case '{{datetime.year}}':
					return new Date().getFullYear()
				case '{{datetime.month}}':
					return new Date().getMonth() + 1
				case '{{datetime.date}}':
					return new Date().toLocaleDateString()
				case '{{datetime.time}}':
					return new Date().toLocaleString() + ''
			}
			break
		default:
			return data
	}
}

const methods = {
	nu: (left, right = null) => {
		return left === null || left === undefined || left === ''
	},
	nn: (left, right = null) => {
		return left !== null && left !== undefined && left !== ''
	},
	eq: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return left.toString().toLowerCase() === right.toString().toLowerCase()
	},
	ne: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return left.toString().toLowerCase() !== right.toString().toLowerCase()
	},
	cn: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return left
			.toString()
			.toLowerCase()
			.includes(right?.toString().toLowerCase())
	},
	nc: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return !left
			.toString()
			.toLowerCase()
			.includes(right?.toString().toLowerCase())
	},
	lt: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return Number(left) < Number(right)
	},
	le: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return Number(left) <= Number(right)
	},
	gt: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return Number(left) > Number(right)
	},
	ge: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return Number(left) >= Number(right)
	},
	deq: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return dayjs(left).isSame(dayjs(right))
	},
	dne: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return !dayjs(left).isSame(dayjs(right))
	},
	dgt: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return dayjs(left).isAfter(dayjs(right))
	},
	dge: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return dayjs(left).isSameOrAfter(dayjs(right))
	},
	dlt: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return dayjs(left).isBefore(dayjs(right))
	},
	dle: (left, right = null) => {
		if (methods.nu(left) || methods.nu(right)) return false

		return dayjs(left).isSameOrBefore(dayjs(right))
	},
	rnu: (left, right = null) => {
		return (
			(Array.isArray(left) && left?.length === 0) ||
			(!Array.isArray(left) &&
				(typeof left === 'object'
					? left === null || left?.['Id'] === null || left?.['Id'] === ''
					: left === null || left === ''))
		)
	},
	rnn: (left, right = null) => {
		return (
			(Array.isArray(left) && left?.length > 0) ||
			(!Array.isArray(left) &&
				(typeof left === 'object'
					? left !== null && left?.['Id'] !== null && left?.['Id'] !== ''
					: left !== null && left !== ''))
		)
	},
	req: (left, right = null) => {
		if (methods.rnu(left) || methods.rnu(right)) return false

		const leftData = convertToArray(left)
		const rightData = convertToArray(right)

		return (
			leftData.length == rightData.length &&
			leftData.every((id, index) => {
				return rightData?.includes(id) ?? true
			})
		)
	},
	rne: (left, right = null) => {
		return !methods.req(left, right)
	},
	rcn: (left, right = null) => {
		if (methods.rnu(left) || methods.rnu(right)) return false

		const leftData = convertToArray(left)
		const rightData = convertToArray(right)

		return leftData.some((id, index) => {
			return rightData?.includes(id) ?? true
		})
	},
	rnc: (left, right = null) => {
		return !methods.rcn(left, right)
	}
}

const filterOperators = [
	{
		type: 'string',
		dataTypes: [
			'RowId',
			'Text',
			'LongText',
			'Image',
			'File',
			'Email',
			'Phone',
			'URL',
			'GeoLocation',
			'Lookup',
			'Rollup',
			'Formula'
		],
		operators: {
			nu: (left, right = null) => {
				return methods.nu(left, right)
			},
			nn: (left, right = null) => {
				return methods.nn(left, right)
			},
			eq: (left, right = null) => {
				return methods.eq(left, right)
			},
			ne: (left, right = null) => {
				return methods.ne(left, right)
			},
			cn: (left, right = null) => {
				return methods.cn(left, right)
			},
			nc: (left, right = null) => {
				return methods.nc(left, right)
			}
		}
	},
	{
		type: 'numeric',
		dataTypes: ['Number', 'Formula', 'Rollup'],
		operators: {
			nu: (left, right = null) => {
				return methods.nu(left, right)
			},
			nn: (left, right = null) => {
				return methods.nn(left, right)
			},
			eq: (left, right = null) => {
				return methods.eq(left, right)
			},
			ne: (left, right = null) => {
				return methods.ne(left, right)
			},
			lt: (left, right = null) => {
				return methods.lt(left, right)
			},
			le: (left, right = null) => {
				return methods.le(left, right)
			},
			gt: (left, right = null) => {
				return methods.gt(left, right)
			},
			ge: (left, right = null) => {
				return methods.ge(left, right)
			}
		}
	},
	{
		type: 'datetime',
		dataTypes: ['Date'],
		operators: {
			nu: (left, right = null) => {
				return methods.nu(left, right)
			},
			nn: (left, right = null) => {
				return methods.nn(left, right)
			},
			deq: (left, right = null) => {
				return methods.deq(left, right)
			},
			dne: (left, right = null) => {
				return methods.dne(left, right)
			},
			dgt: (left, right = null) => {
				return methods.dgt(left, right)
			},
			dge: (left, right = null) => {
				return methods.dge(left, right)
			},
			dlt: (left, right = null) => {
				return methods.dlt(left, right)
			},
			dle: (left, right = null) => {
				return methods.dle(left, right)
			}
		}
	},
	{
		type: 'boolean',
		dataTypes: ['Checkbox'],
		operators: {
			nu: (left, right = null) => {
				return methods.nu(left, right)
			},
			nn: (left, right = null) => {
				return methods.nn(left, right)
			},
			eq: (left, right = null) => {
				return methods.eq(left, right)
			},
			ne: (left, right = null) => {
				return methods.ne(left, right)
			}
		}
	},
	{
		type: 'relation',
		dataTypes: ['Relation'],
		operators: {
			rnu: (left, right = null) => {
				return methods.rnu(left, right)
			},
			rnn: (left, right = null) => {
				return methods.rnn(left, right)
			},
			req: (left, right = null) => {
				return methods.req(left, right)
			},
			rne: (left, right = null) => {
				return methods.rne(left, right)
			},
			rcn: (left, right = null) => {
				return methods.rcn(left, right)
			},
			rnc: (left, right = null) => {
				return methods.rnc(left, right)
			}
		}
	}
]

const checkVisibilityRules = (group, data) => {
	let compareResults = []

	if (group?.rules && group.rules.length > 0) {
		group.rules.forEach((item) => {
			const func = filterOperators.find((x) =>
				x.dataTypes.includes(item.dataType)
			)?.operators?.[item.op]

			if (!func) return

			const left = getLeftValue(data, item.field)
			const right = getRightValue(data, item.type, item.data)

			const compareResult = func(left, right)

			compareResults.push(compareResult)
		})
	}

	return compareResults.length > 0
		? group.groupOp === 'AND'
			? compareResults.every(function (cr, index) {
					return cr === true
			  })
			: compareResults.includes(true)
		: true
}

const checkVisibilityGroups = (visibility, userData, screenData = null) => {
	let compareResults = []

	if (
		visibility?.conditions?.groups &&
		visibility?.conditions?.groups.length > 0
	) {
		if (userData?.fields) {
			const profileResult = checkVisibilityRules(
				visibility?.conditions?.groups[0],
				userData?.fields
			)

			compareResults.push(profileResult)
		} else {
			const profileResult = isPreviewApp
				? true
				: (visibility?.conditions?.groups[0]?.rules?.length ?? 0) > 0
				? false
				: true

			compareResults.push(profileResult)
		}

		if (screenData) {
			const screenResult = checkVisibilityRules(
				visibility?.conditions?.groups[1],
				screenData
			)

			compareResults.push(screenResult)
		} else {
			const screenResult =
				(visibility?.conditions?.groups[1]?.rules?.length ?? 0) > 0
					? false
					: true

			compareResults.push(screenResult)
		}
	}

	return compareResults.length > 0
		? visibility?.conditions?.groupOp === 'AND'
			? compareResults.every(function (cr, index) {
					return cr === true
			  })
			: compareResults.includes(true)
		: true
}

const convertToNewSettings = (visibility) => {
	if (visibility.visible) return visibility

	return {
		visible: visibility.screen,
		conditions: {
			groupOp: 'AND',
			groups: [
				{
					groupOp: visibility?.filters?.groupOp || 'AND',
					rules: visibility?.filters?.rules || []
				},
				{
					groupOp: 'AND',
					rules: []
				}
			]
		}
	}
}

const isScreenVisible = (visibility, userData, ghostUserData = null) => {
	if (!visibility) return true

	let result = false

	try {
		const v = convertToNewSettings(visibility)

		if (isPreviewApp && !ghostUserData) {
			return true
		} else {
			switch (v.visible) {
				case 'loggedInUser':
					{
						result = !!userData?.id && checkVisibilityGroups(v, userData)
					}
					break
				case 'everyone':
					result = true
					break
			}
		}
	} catch (error) {
		console.error('visibility', error)

		result = false
	}

	return result
}

const isMenuVisible = (visibility, userData, ghostUserData = null) => {
	if (!visibility) return true

	let result = true

	try {
		const v = convertToNewSettings(visibility)

		if (isPreviewApp && !ghostUserData) {
			return true
		} else {
			switch (v.visible) {
				case 'loggedInUser':
					result = checkVisibilityGroups(v, userData)
					break
				case 'everyone':
					result = true
					break
			}
		}
	} catch (error) {
		console.error('visibility', error)

		result = false
	}

	return result
}

const isElementVisible = (
	visibility,
	userData,
	ghostUserData = null,
	screenData = null
) => {
	if (!visibility) return true

	let result = true

	try {
		const v = convertToNewSettings(visibility)

		switch (v.visible) {
			case 'loggedInUser':
				result = checkVisibilityGroups(
					v,
					isPreviewApp && !ghostUserData ? null : userData,
					screenData
				)
				break
			case 'everyone':
				result = checkVisibilityGroups(
					v,
					isPreviewApp && !ghostUserData ? null : userData,
					screenData
				)
				break
		}
	} catch (error) {
		console.error('visibility', error)

		result = false
	}

	return result
}

export default { isScreenVisible, isMenuVisible, isElementVisible }
