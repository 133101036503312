import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { connect } from 'react-redux'

const Txt = (props) => {
	return <Text style={styles.text}>{props.block.children}</Text>
}

const styles = StyleSheet.create({
	text: {
		backgroundColor: 'aqua'
	}
})

const mapStateToProps = (state) => ({
	// status: state.status
})

const mapDispatchToProps = (dispatch) => ({
	change: (data) => dispatch({ type: 'SET_STATUS', payload: data })
})

export default connect(mapStateToProps, mapDispatchToProps)(Txt)
