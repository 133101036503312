import React from 'react'

import { FlatList, ScrollView, Platform } from 'react-native'

const ScrollableContainer = (props) => {
	const { style, children, ...restProps } = props

	return Platform.OS === 'ios' || Platform.OS === 'android' ? (
		<FlatList
			ref={props.containerRef}
			removeClippedSubviews={false}
			scrollEnabled={true}
			data={[]}
			listKey="testKey"
			ListEmptyComponent={<>{children}</>}
			contentContainerStyle={style}
			{...restProps}
		/>
	) : (
		<ScrollView
			ref={props.containerRef}
			nestedScrollEnabled={false}
			scrollEnabled
			contentContainerStyle={style}
			{...restProps}
		>
			{children}
		</ScrollView>
	)
}

export default ScrollableContainer
