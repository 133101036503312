import { createTheming } from '@callstack/react-theme-provider'

//import store from '../redux/store/store'

// function getTheme() {
// 	var newState = store.getState()
// 	return newState.theme.theme
// }

//store.subscribe(getTheme)

export var { ThemeProvider, withTheme, useTheme } = createTheming()
