import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import showToast from '../common/methods/showToast'

import '../global/variables'
import authorizationClient from '../services/authorizationClient'
import { setAuth } from '../redux/actions/authActions'
import ScreenContainer from '../components/ScreenContainer'
import LoginTextEntry from '../components/LoginTextEntry'
import LoginButton from '../components/LoginButton'
import TextWithFont from '../components/TextWithFont'

import nav from '../common/methods/nav'

import i18n from '../i18n/i18n'
import BuiltWithKozmik from '../components/BuiltWithKozmik'

const ForgotPassword = (props) => {
	const theme = useTheme()
	const { route, navigation } = props
	const styles = themedStyles(theme)

	const [submitting, setSubmitting] = useState(false)
	/*********** */
	const {
		register,
		setValue,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.forgotPassword(data)
			.then((response) => {
				if (response?.data?.success) {
					nav.navigateHome()
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	return (
		<ScreenContainer>
			<BuiltWithKozmik />

			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<View
					style={styles.mainContainer}
					// showsVerticalScrollIndicator={false}
				>
					<View>
						<View>
							{/**TODO: APP NAME logindeki gibi */}
							{/* <TextWithFont style={styles.pageTitle}>
							{i18n.t('forgotPassword.pageTitle')}
						</TextWithFont> */}
							{!!props?.config?.manifest?.name && (
								<TextWithFont style={styles.title}>
									{props.config.manifest.name}
								</TextWithFont>
							)}
							<TextWithFont style={styles.subTitle}>
								{i18n.t('forgotPassword.subTitle')}
							</TextWithFont>
						</View>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, value } }) => (
								<LoginTextEntry
									// titleText={i18n.t('forgotPassword.mailInput.title')}
									requiredText={i18n.t('forgotPassword.required')}
									placeholder={i18n.t('forgotPassword.mailInput.title')}
									onBlur={onBlur}
									onChangeText={(value) => onChange(value)}
									value={value}
									error={errors?.email}
									onKeyPress={handleUserKeyPress}
								/>
							)}
							name="email"
							rules={{
								required: {
									value: true,
									message: i18n.t('forgotPassword.mailInput.requredError')
								},
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: i18n.t('forgotPassword.mailInput.patternError')
								}
							}}
						/>

						<View style={styles.buttonContainer}>
							<LoginButton
								text={i18n.t('forgotPassword.acceptButton')}
								onPress={submitting ? null : handleSubmit(onSubmit)}
								loader={submitting}
							/>
						</View>
						<View style={styles.buttonContainer}>
							<LoginButton
								transparent
								text={i18n.t('forgotPassword.cancelButton')}
								onPress={() => navigation.navigate('login')}
							/>
						</View>
					</View>
				</View>
			</View>
		</ScreenContainer>
	)
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.generatorData
})
const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (data) => dispatch(setAuth(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

const themedStyles = (theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			justifyContent: 'center',
			width: 340
		},
		buttonContainer: {
			height: 40,
			marginVertical: 10,
			paddingHorizontal: theme.spacing.xl
		},
		pageTitle: {
			marginTop: 20,
			marginBottom: 50,
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.md,
			fontWeight: '800'
		},
		title: {
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.lg,
			fontWeight: '800',
			marginBottom: 20
		},
		subTitle: {
			marginBottom: 30,
			textAlign: 'center',
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm
		}
	})
}
