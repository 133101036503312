import React, { useEffect } from 'react'
import { TouchableOpacity, Image, View, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'
import SmartTextView from './SmartTextView'

// import nav from '../common/methods/nav'
import triggerActionList from '../common/methods/triggerActionList'

const ListItem = (props) => {
	const theme = useTheme()

	useEffect(() => {}, [])

	const styles = themedStyles(props, theme)

	const sign = props.block?.imgVisible ? (
		props.block?.imgUrl ? (
			<Image
				resizeMode="cover"
				style={styles.image}
				source={{
					uri: props.block.imgUrl
				}}
			/>
		) : (
			<View style={styles.sign}>
				<TextWithFont style={styles.signText}>
					{props.block.texts?.title?.[0]?.toString()?.toUpperCase()}
				</TextWithFont>
			</View>
		)
	) : null

	return (
		<View style={styles.spacingContainer}>
			<TouchableOpacity
				style={styles.mainContainer}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() => {
					triggerActionList(props.block, props.block.actions, props.block.id)
				}}
			>
				{props.block?.imgPosition === 'left' && sign}
				<View style={styles.contentContainer}>
					<View style={styles.textContainer}>
						{!!props.block.texts?.title && (
							<SmartTextView
								alignCenter={false}
								style={styles.title}
								chipStyle={[styles.title, styles.chipsText]}
								mapping={'title'}
								numberOfLines={1}
								{...props}
							/>
						)}
						{props.block.height > 53 && !!props.block.texts?.contentText && (
							<SmartTextView
								alignCenter={false}
								style={styles.contentText}
								chipStyle={[styles.contentText, styles.chipsText]}
								mapping={'text'}
								numberOfLines={1}
								{...props}
							/>
						)}
						{props.block.height > 53 && !!props.block.texts?.subText && (
							<SmartTextView
								alignCenter={false}
								style={styles.subText}
								chipStyle={[styles.subText, styles.chipsText]}
								mapping={'subText'}
								numberOfLines={1}
								{...props}
							/>
						)}
					</View>
					{props.block?.imgPosition === 'left' && (
						<View style={styles.detailContainer}>
							{!!props.block.texts?.detailText && (
								<TextWithFont style={styles.detailText}>
									{props.block.texts.detailText}
								</TextWithFont>
							)}

							{props.block.actions?.[0]?.type != null && (
								<MaterialCommunityIcons
									name="chevron-right"
									size={24}
									style={styles.detailArrow}
								/>
							)}
						</View>
					)}
				</View>
				{props.block?.imgPosition === 'right' && sign}
			</TouchableOpacity>
		</View>
	)
}

export default ListItem

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		spacingContainer: {},
		mainContainer: {
			position: 'relative',
			flex: 1,
			justifyContent: 'flex-start',
			alignItems: 'center',
			flexDirection: 'row',
			flexWrap: 'wrap',
			height: props.block.height,
			minHeight: props.block.height,
			width: '100%',
			paddingVertical: 8,
			borderBottomWidth: props.block.total === 1 ? 0 : 1,
			borderBottomColor: theme.colors.line
		},
		image: {
			width: props.block.height - 16,
			height: props.block.height - 16,
			borderRadius:
				props.block.corner == 'oval'
					? theme.radiuses.sm
					: props.block.corner == 'circle'
					? (props.block.height - 16) / 2
					: theme.radiuses.none
		},
		contentContainer: {
			flex: 1,
			justifyContent: 'space-between',
			alignItems: 'center',
			flexDirection: 'row'
		},
		detailContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		},
		detailText: {
			fontSize: theme.fontSizes.sm,
			lineHeight: theme.fontSizes.sm + 2,
			color: theme.colors.inputText
		},
		detailArrow: {
			color: theme.colors.inputText
		},
		sign: {
			width: props.block.height - 16,
			height: props.block.height - 16,
			borderRadius:
				props.block.corner == 'oval'
					? theme.radiuses.sm
					: props.block.corner == 'circle'
					? (props.block.height - 16) / 2
					: theme.radiuses.none,
			backgroundColor: opacity(theme.colors.accent, 0.2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row'
		},
		signText: {
			fontSize: props.block.height / 2.5, //< 50 ? 21 : 28, //TODO
			fontWeight: '600',
			color: theme.colors.accent
		},
		textContainer: {
			marginLeft:
				props.block?.imgPosition === 'right' || !props.block?.imgVisible
					? 0
					: 12,
			flex: 1
		},
		title: {
			fontSize: theme.fontSizes[props.block.fontSize],
			color: theme.colors.text
		},
		contentText: {
			fontSize: theme.fontSizes[props.block.fontSize] - 2,
			color: theme.colors.inputText
		},
		subText: {
			fontSize: theme.fontSizes[props.block.fontSize] - 4,
			color: theme.colors.inputText
		},
		chipsText: {
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.sm,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		}
	})
}
