import { BUILDERONMOBILE } from '../action-types/builderOnMobileActionTypes'

const initialState = {
	builderOnMobile: false
}

const builderOnMobileReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case BUILDERONMOBILE:
			return { ...state, builderOnMobile: action.payload }

		default:
			return state
	}
}

export default builderOnMobileReducer
