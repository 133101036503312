import React from 'react'
import { TouchableOpacity, Text, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import { setTheme } from '../redux/actions/themeActions'
import { useTheme } from '../theme/Theme'
import themes from '../config/themes/themes'

const Btn = (props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.main}
			onPress={() => props.changeTheme(themes.dark)}
		>
			<Text>{props.block.children}</Text>
		</TouchableOpacity>
	)
}

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		main: {
			backgroundColor: 'purple' //theme.colors.purple //theme.colors.purple
		}
	})
}

const mapStateToProps = (state) => ({
	...state.theme
})

const mapDispatchToProps = (dispatch) => {
	return {
		changeTheme: (data) => dispatch(setTheme(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Btn)
