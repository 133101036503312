import React from 'react'
import { Platform, StyleSheet, TouchableOpacity, StatusBar } from 'react-native'
import { default as MobileModal } from 'react-native-modal'
import { useTheme } from '../theme/Theme'
import { connect } from 'react-redux'

const CustomPopup = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const content = () => {
		return (
			<TouchableOpacity style={styles.modalContainer} activeOpacity={1}>
				{Platform.OS !== 'web' && (
					<StatusBar
						barStyle={theme.statusBar.style}
						backgroundColor={theme.colors.modalOverlay}
					/>
				)}

				<TouchableOpacity
					activeOpacity={1}
					style={styles.modalOverlay}
					onPress={props.cancel}
				>
					<TouchableOpacity
						style={styles.modalContent}
						activeOpacity={1}
						onPress={props.preventParentEvent}
					>
						{props.children}
					</TouchableOpacity>
				</TouchableOpacity>
			</TouchableOpacity>
		)
	}

	return (
		<MobileModal
			statusBarTranslucent
			coverScreen={props.coverScreen ?? false}
			focusTrap={false}
			style={styles.view}
			animationIn={'fadeIn'}
			animationOut={'fadeOut'}
			animationInTiming={50}
			animationOutTiming={50}
			hasBackdrop={false}
			{...props}
		>
			{content()}
		</MobileModal>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize
})

export default connect(mapStateToProps, null)(CustomPopup)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		view: {
			justifyContent: 'flex-end',
			marginLeft: 0,
			marginRight: 0,
			marginBottom: 0,
			marginTop: 0
		},
		modalContainer: {
			height: '100%',
			width: '100%',
			backgroundColor: 'transparent'
		},
		modalOverlay: {
			backgroundColor: theme.colors.modalOverlay,
			width: '100%',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center'
		},
		modalContent: {
			backgroundColor: theme.colors.layoutBackground,
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden',
			width: props.screenSize === 'lg' ? '45%' : '90%',
			height: props.fullHeight ? '90%' : 'auto',
			maxHeight: '90%'
		}
	})
}
