import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'

const ActionButton = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { icon, size, ...restProps } = props

	return (
		<TouchableOpacity activeOpacity={0.8} {...restProps}>
			<TextWithFont style={styles.actionButton}>
				<Ionicons name={icon} size={size ?? 24} style={styles.actionColor} />
			</TextWithFont>
		</TouchableOpacity>
	)
}

export default ActionButton

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		actionButton: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: theme.fontSizes.sm,
			fontWeight: 'bold',
			minWidth: 30
		},
		actionColor: {
			color: theme.colors.accent
		}
	})
}
