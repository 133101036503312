import {
	INCREMENTFETCHING,
	DECREMENTFETCHING
} from '../action-types/fetchingActionTypes'

export const incrementFetching = () => ({
	type: INCREMENTFETCHING
})

export const decrementFetching = () => ({
	type: DECREMENTFETCHING
})
