import React, { useState, useEffect } from 'react'
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Image as NativeImage
} from 'react-native'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import TextWithFont from '../components/TextWithFont'
import nav from '../common/methods/nav'
import i18n from '../i18n/i18n'

import AwesomeGallery from 'react-native-awesome-gallery'

const Gallery = (props) => {
	const theme = useTheme()
	const { route, navigation } = props

	const { images, index } = route.params

	const styles = themedStyles(props, theme)
	const [currentIndex, setCurrentIndex] = useState(index)

	const renderItem = ({ item, setImageDimensions }) => {
		return (
			<NativeImage
				source={{
					uri: item
				}}
				style={{
					width: '100%',
					height: '100%'
				}}
				resizeMode="contain"
				onLoad={(e) => {
					if (!e.source) return

					const { width, height } = e.source
					setImageDimensions({ width, height })
				}}
			/>
		)
	}

	return (
		<View style={styles.container}>
			<View style={styles.topContainer}>
				<TouchableOpacity
					activeOpacity={0.8}
					onPress={() => {
						nav.navigateBack()
					}}
				>
					<TextWithFont
						style={{
							color: theme.colors.text,
							fontSize: theme.fontSizes.md
						}}
					>
						{i18n.t('gallery.close')}
					</TextWithFont>
				</TouchableOpacity>

				<TextWithFont
					style={{
						color: theme.colors.text,
						fontSize: theme.fontSizes.md
					}}
				>
					{currentIndex + 1} / {images.length}
				</TextWithFont>
			</View>
			<AwesomeGallery
				data={images}
				initialIndex={index}
				renderItem={renderItem}
				onIndexChange={(newIndex) => {
					setCurrentIndex(newIndex)
				}}
			/>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null)(Gallery)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		container: {
			backgroundColor: '#000000'
		},
		topContainer: {
			zIndex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			height: 54,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: theme.spacing.xl
		}
	})
}
