import { SETTEMPDETAIL } from '../action-types/tempDetailActionTypes'

const initialState = {
	tempDetail: null
}

const tempDetailReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETTEMPDETAIL:
			return { ...state, tempDetail: action.payload }

		default:
			return state
	}
}

export default tempDetailReducer
