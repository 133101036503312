import React, { forwardRef } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { complement, opacity } from 'simpler-color'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'

import getColumnValByKey from '../common/methods/getColumnValByKey'

const SmartCellView = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { column, data, alignCenter, style, chipStyle, ...restProps } = props

	const content = getColumnValByKey(props.column, props.data)

	const isManyType = (column, data = null) => {
		if (column?.type == 'Relation' || column?.type == 'Lookup') {
			const extractedData = data?.[column?.field]
			if (typeof extractedData === 'string') return false

			return true
		}

		return false
	}

	const decideColor = (index) => {
		return opacity(
			complement(
				theme.colors.accent == '#000000'
					? theme.colors.kozmik
					: theme.colors.accent,
				index % 11
			),
			0.7
		)
	}

	return isManyType(column, data) ? (
		Array.isArray(content) ? (
			<View
				style={
					alignCenter
						? {
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center'
						  }
						: {
								flexDirection: 'row'
						  }
				}
			>
				{content.map((item, index) =>
					item ? (
						<Text
							key={index}
							style={[
								{ backgroundColor: decideColor(index + 1) },
								chipStyle,
								styles.text
							]}
							{...restProps}
						>
							{item}
						</Text>
					) : null
				)}
			</View>
		) : (
			<View
				style={
					alignCenter
						? {
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center'
						  }
						: {
								flexDirection: 'row'
						  }
				}
			>
				{content ? (
					<Text
						style={[
							{ backgroundColor: decideColor(1) },
							chipStyle,
							styles.text
						]}
						{...restProps}
					>
						{content}
					</Text>
				) : null}
			</View>
		)
	) : (
		<Text
			style={[
				alignCenter && {
					textAlign: 'center'
				},
				styles.text,
				style
			]}
			{...restProps}
		>
			{content}
		</Text>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(SmartCellView)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		text: {
			fontFamily: theme.fontFamily,
			color: theme.colors.text
		}
	})
}
