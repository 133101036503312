import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import {
	View,
	Platform,
	TextInput,
	StyleSheet,
	TouchableOpacity
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { MaskedTextInput } from 'react-native-mask-text'
import { MaterialIcons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

import getLocalizeContent from '../common/methods/getLocalizeContent'
import getLanguageCode from '../common/methods/getLanguageCode'
import BarcodeScanner from './BarcodeScanner'

const TextEntry = forwardRef((props, ref) => {
	const [isFocused, setIsFocused] = useState(false)
	const [inputText, setInputText] = useState(null)
	const [isValid, setIsValid] = useState(true)
	const theme = useTheme()
	const styles = themedStyles(props, isFocused, theme, isValid)
	const [isScannerVisible, setIsScannerVisible] = useState(false)

	//const [data, setData] = useState(null)
	const decimalSep = getLanguageCode() === 'tr-TR' ? ',' : '.' //decimalSeperator
	const thousandSep = getLanguageCode() === 'tr-TR' ? '.' : ',' //thousandSeperator

	const stringToRegExp = (string, isRegExString = true) => {
		if (isRegExString) {
			let localizeRegex = string

			if (props?.isNumeric) {
				localizeRegex = localizeRegex
					.replace(/:thousand/g, thousandSep)
					.replace(/:decimal/g, decimalSep)
			}
			const match = localizeRegex.match(new RegExp('^/(.*?)/([gimy]*)$'))
			return new RegExp(match[1], match[2])
		} else {
			return string
		}
	}

	const checkNumericMaxMin = (text) => {
		if (props?.isNumeric && text) {
			const currentVal = Number(
				getLanguageCode() === 'tr-TR'
					? text.replace(/\./g, '').replace(/,/g, '.')
					: text.replace(/,/g, '')
			)

			return (
				(props?.maxVal ? currentVal <= props.maxVal : true) &&
				(props?.minVal ? currentVal >= props.minVal : true)
			)
		} else {
			return true
		}
	}

	const checkLength = (text) => {
		if (props.required.status) {
			return (
				!!text &&
				text?.length > 0 &&
				(props.maxLength ? text.length <= props.maxLength : true) &&
				(props.minLength ? text.length >= props.minLength : true)
			)
		} else {
			return (
				(props.maxLength ? text.length <= props.maxLength : true) &&
				(props.minLength ? text.length >= props.minLength : true)
			)
		}
	}

	const validateText = (text, regExp) => {
		if (props.required.status) {
			return regExp.test(text) && checkLength(text) && checkNumericMaxMin(text)
		} else {
			if (text?.length) {
				return regExp.test(text) && checkNumericMaxMin(text)
			} else {
				return true && checkNumericMaxMin(text)
			}
		}
	}

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {},
		validate: validate,
		clearInput: () => {
			clearInput()
		}
	}))

	const getCurrent = () => {
		return props.operator
			? props.submitData?.[`${props.mapping.field}_${props.operator}`] ??
					props.submitData?.[props.mapping.field]
			: props.submitData?.[props.mapping.field]
	}

	const current = getCurrent()

	const updateFormFieldByRequired = (val, valid = true) => {
		props?.updateFormField &&
			props.updateFormField({
				isValid: valid,
				key: props.mapping.field,
				value: val,
				operator: props.operator
			})
	}

	const validate = () => {
		let valid = true

		if (props.required?.status) {
			valid = checkLength(inputText)
		}

		if (valid && props.regExp) {
			valid = validateText(
				inputText,
				stringToRegExp(props.regExp, props.isRegExString)
			)
		}

		setIsValid(valid)

		return {
			key: props.mapping.field,
			dataType: props.mapping.type,
			isValid: valid,
			value: inputText,
			operator: props.operator
		}
	}

	const validateInput = (text) => {
		setInputText(text)

		if (current != text) {
			const result = props.regExp
				? validateText(text, stringToRegExp(props.regExp, props.isRegExString))
				: checkLength(text)

			setIsValid(result)
			updateFormFieldByRequired(text, result)
		}
	}

	const clearInput = () => {}

	const onBarCodeScanned = ({ type, data }) => {
		setInputText(data)
		setIsScannerVisible(false)
	}

	useEffect(() => {
		if (props?.submitData?.[props.mapping.field]) {
			setInputText(getCurrent())
		} else {
			setInputText(null)
		}
	}, [props.submitData])

	return (
		<View style={styles.mainContainer}>
			<View style={styles.titleContainer}>
				<TextWithFont style={styles.title}>{props.title}</TextWithFont>

				{props.required.status ? (
					<TextWithFont style={styles.requiredText}>
						{getLocalizeContent(props.required.text)}
					</TextWithFont>
				) : null}
			</View>
			<View>
				{props?.mask ? (
					<MaskedTextInput
						mask={props.mask}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						value={inputText || ''}
						placeholder={getLocalizeContent(props?.placeholder)}
						onChangeText={(text, rawText) => {
							validateInput(text)
							//props.block.regExp && validateText(text, stringToRegExp(props.block.regExp))
						}}
						style={styles.inputBasic}
						placeholderTextColor={theme.colors.inputText}
					/>
				) : (
					<View style={styles.inputMainContainer}>
						<View style={styles.inputContainer}>
							<TextInput
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
								value={inputText || ''}
								placeholder={getLocalizeContent(props?.placeholder)}
								onChangeText={(text) => {
									validateInput(text)
									//props.block.regExp && validateText(text, stringToRegExp(props.block.regExp))
								}}
								multiline={props.multiline ?? false}
								numberOfLines={props.multiline ? 4 : 1}
								style={styles.inputBasic}
								placeholderTextColor={theme.colors.inputText}
							/>
						</View>

						{props.scanner && (
							<>
								<View style={styles.scannerContainer}>
									<TouchableOpacity
										activeOpacity={0.8}
										style={styles.scannerButton}
										onPress={() => {
											setIsScannerVisible(true)
										}}
									>
										<MaterialIcons
											name="qr-code-scanner"
											size={20}
											color={theme.colors.inputText}
										/>
									</TouchableOpacity>
								</View>

								<BarcodeScanner
									route={props.route}
									isVisible={isScannerVisible}
									cancel={() => setIsScannerVisible(false)}
									onBarCodeScanned={onBarCodeScanned}
								/>
							</>
						)}
					</View>
				)}
				{!isValid && (
					<TextWithFont style={styles.errorText}>
						{getLocalizeContent(props.errorText)}
					</TextWithFont>
				)}
			</View>
		</View>
	)
})

export default TextEntry

const themedStyles = (props, isFocused, theme, isValid) => {
	return StyleSheet.create({
		mainContainer: {
			//marginTop: 16,
			// height: props.required.status ? 96 : 77,
			marginVertical: 10,
			justifyContent: 'space-between'
		},
		titleContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 10
		},
		title: {
			fontSize: theme.fontSizes.sm,
			color: isFocused ? theme.colors.accent : theme.colors.inputText,
			fontWeight: '600'
		},
		inputMainContainer: {
			flexDirection: 'row',
			justifyContent: 'flex-end'
		},
		inputContainer: {
			flexDirection: 'row',
			justifyContent: 'center',
			flexGrow: 1
		},
		input:
			Platform.OS === 'web'
				? {
						paddingHorizontal: theme.spacing.sm,
						minHeight: 40,
						borderBottomWidth: 1,
						outlineStyle: 'none', //TODO:webde input outline style'ı global css olarak eklenmeli
						borderColor: isValid
							? isFocused
								? theme.colors.accent
								: theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  }
				: {
						paddingHorizontal: theme.spacing.sm,
						minHeight: 40,
						borderBottomWidth: 1,
						borderColor: isValid
							? isFocused
								? theme.colors.accent
								: theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  },
		inputBasic:
			Platform.OS === 'web'
				? {
						flex: 1,
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						paddingHorizontal: theme.spacing.sm,
						paddingVertical: props.multiline ? theme.spacing.sm : 0,
						minHeight: 40,
						textAlignVertical: props.multiline ? 'top' : 'center',
						borderWidth: isValid ? 0 : 1,
						outlineStyle: 'none', //TODO:webde input outline style'ı global css olarak eklenmeli
						borderColor: isValid
							? isFocused
								? theme.colors.accent
								: theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  }
				: {
						flex: 1,
						backgroundColor: theme.colors.inputBackground,
						borderRadius: theme.radiuses.sm,
						paddingHorizontal: theme.spacing.sm,
						paddingVertical: props.multiline ? theme.spacing.sm : 0,
						minHeight: 40,
						textAlignVertical: props.multiline ? 'top' : 'center',
						borderWidth: isValid ? 0 : 1,
						borderColor: isValid
							? isFocused
								? theme.colors.accent
								: theme.colors.inputText
							: theme.colors.inputTextDanger,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.md,
						color: theme.colors.text
				  },
		requiredText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputText
		},
		errorText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputTextDanger,
			marginTop: 5
		},
		scannerContainer: {
			flexDirection: 'row',
			justifyContent: 'center',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,
			marginLeft: 10,
			paddingHorizontal: theme.spacing.sm
		},
		scannerButton: {
			justifyContent: 'center'
		}
	})
}
