import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'

import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { enUS, tr } from 'date-fns/locale'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

import getLocalizeContent from '../common/methods/getLocalizeContent'
import getLanguageCode from '../common/methods/getLanguageCode'
import convertDatetimeToDate from '../common/methods/convertDatetimeToDate'
import localeParseDate from '../common/methods/localeParseDate'

const BaseDatePicker = forwardRef((props, ref) => {
	const theme = useTheme()

	const [date, setDate] = useState(null)
	const [isValid, setIsValid] = useState(true)
	const localeString = getLanguageCode() === 'tr-TR' ? 'tr' : 'en'
	const locales = {
		tr: tr,
		en: enUS
	}
	registerLocale(localeString, locales[localeString])

	const styles = themedStyles(props, theme, isValid)

	useEffect(() => {
		getDate()
	}, [props?.submitData])

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {},
		validate: validate,
		clearInput: () => {
			clearInput()
		}
	}))

	const getCurrent = () => {
		return props.operator
			? props.submitData?.[`${props.mapping.field}_${props.operator}`] ??
					props.submitData?.[props.mapping.field]
			: props.submitData?.[props.mapping.field]
	}

	const current = getCurrent()

	const updateFormFieldByRequired = (val, valid = true) => {
		props.updateFormField &&
			props.updateFormField({
				isValid: valid,
				key: props.mapping.field,
				value: val,
				recurrence: props.recurrence,
				operator: props.operator
			})
	}

	const getDate = () => {
		if (props?.submitData != null) {
			setDate(localeParseDate(getCurrent()))
		} else {
			setDate(null)
		}
	}

	const clearInput = () => {
		if (props?.submitData != null) {
			setDate(localeParseDate(getCurrent()))
		} else {
			setDate(null)
		}
		setIsValid(true)
	}

	const validate = () => {
		let valid = true

		if (props.required?.status) {
			valid = !!date
		}
		setIsValid(valid)

		return {
			key: props.mapping.field,
			dataType: props.mapping.type,
			isValid: valid,
			value: date,
			recurrence: props.recurrence,
			operator: props.operator
		}
	}

	const webDateChange = (selectedDate) => {
		if (current != selectedDate) {
			setIsValid(true)

			if (props.mode === 'date') {
				const convertedDate = convertDatetimeToDate(selectedDate)
				setDate(localeParseDate(convertedDate))
				updateFormFieldByRequired(convertedDate)
			} else {
				setDate(localeParseDate(selectedDate))
				updateFormFieldByRequired(selectedDate)
			}
		}
	}

	const getDateText = () => {
		if (date) {
			const newDate = new Date(date)
			return props.mode === 'date'
				? newDate.toLocaleDateString(localeString)
				: `${newDate.toLocaleDateString(
						localeString
				  )} ${newDate.toLocaleTimeString(localeString)}`
		} else {
			return getLocalizeContent(props.placeholder)
		}
	}

	const PickerContainer = ({ className, children }) => {
		return (
			<div>
				<CalendarContainer className={className}>
					<div style={{ position: 'relative', display: 'flex' }}>
						{children}
					</div>
				</CalendarContainer>
			</div>
		)
	}

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<View style={styles.mainContainer}>
			<View style={styles.titleContainer}>
				<TextWithFont style={styles.title}>{props?.title}</TextWithFont>

				{props.required.status ? (
					<TextWithFont style={styles.requiredText}>
						{getLocalizeContent(props.required.text)}
					</TextWithFont>
				) : null}
			</View>
			<View style={styles.pickerContainer}>
				<TouchableOpacity
					activeOpacity={0.8}
					ref={ref}
					onPress={onClick}
					style={{
						width: '90%',
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center'
					}}
				>
					<MaterialCommunityIcons
						name={props.icon}
						size={20}
						style={styles.icon}
					/>
					<TextWithFont style={styles.text} numberOfLines={1}>
						{getDateText()}
					</TextWithFont>
				</TouchableOpacity>
				<TouchableOpacity
					activeOpacity={0.8}
					onPress={() => {
						setDate(null)
						props.updateWhenClearInput && updateFormFieldByRequired(null)
					}}
					style={{
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center'
					}}
				>
					<MaterialCommunityIcons
						name={isValid ? 'close-circle' : 'alert'}
						size={20}
						style={isValid ? styles.commonColor : styles.errorColor}
					/>
				</TouchableOpacity>
			</View>
			{!isValid && (
				<TextWithFont style={styles.errorText}>
					{getLocalizeContent(props.errorText)}
				</TextWithFont>
			)}
		</View>
	))

	return (
		<DatePicker
			portalId={'root-portal'}
			locale={localeString}
			selected={date ? new Date(date) : new Date()}
			onChange={webDateChange}
			calendarContainer={PickerContainer}
			customInput={<CustomInput />}
			showTimeSelect={props.mode === 'datetime'}
			timeIntervals={15}
		/>
	)
})

export default BaseDatePicker

const themedStyles = (props, theme, isValid) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: 10,
			justifyContent: 'space-between'
		},
		titleContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 10
		},
		title: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		icon: {
			marginRight: 7,
			color: theme.colors.inputText
		},
		text: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.inputText
		},
		pickerContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			backgroundColor: theme.colors.inputBackground,
			height: 40,
			borderRadius: theme.radiuses.sm,
			borderColor: isValid ? 'transparent' : theme.colors.inputTextDanger,
			borderWidth: 1,
			paddingHorizontal: theme.spacing.sm
		},
		commonColor: {
			color: theme.colors.inputText
		},
		errorColor: {
			color: theme.colors.inputTextDanger
		},
		requiredText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputText
		},
		errorText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputTextDanger,
			marginTop: 5
		}
	})
}
