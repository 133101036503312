import { add } from 'date-fns'

const recure = (startDate, endDate, every, times) => {
	let date = startDate ? new Date(startDate) : new Date()
	let date2 = endDate ? new Date(endDate) : null

	let recurrent = []

	let index = 0
	let lastDate = date

	while ((!times || times > index) && (!date2 || lastDate < date2)) {
		switch (every) {
			case 'day':
				lastDate = add(lastDate, {
					days: 1
				})
				break
			case 'week':
				lastDate = add(lastDate, {
					weeks: 1
				})
				break
			case 'month':
				lastDate = add(lastDate, {
					months: 1
				})
				break
			case 'year':
				lastDate = add(lastDate, {
					years: 1
				})
				break

			default:
				break
		}

		if (!date2 || lastDate < date2) {
			recurrent.push(lastDate)
		}

		index++
	}

	return recurrent
}

export default recure
