import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { ProgressChart } from 'react-native-chart-kit'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import hexToRgba from 'hex-to-rgba'
import { complement } from 'simpler-color'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'
import ProgressBar from './ProgressBar'

import dataClient from '../services/dataClient'
import triggerActionList from '../common/methods/triggerActionList'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import localeParseFloat from '../common/methods/localeParseFloat'
import getLocalizeContent from '../common/methods/getLocalizeContent'

import SearchBox from './SearchBox'

const ChartProgress = forwardRef((props, ref) => {
	const theme = useTheme()

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)

	const chartHeight = 220
	const [chartWidth, setChartWidth] = useState(0)

	const styles = themedStyles(props, theme)

	const [chartData, setChartData] = useState(
		componentDetail?.config?.forceLoad ? null : componentDetail?.data ?? null
	)
	const [fetching, setFetching] = useState(false)

	const progressConfig = {
		useBackgroundCanvas:
			props.block?.background && props.block?.background !== 'none',
		backgroundColor: theme.colors.inputBackground,
		backgroundGradientFrom: theme.colors.inputBackground,
		backgroundGradientFromOpacity: 1,
		backgroundGradientTo: theme.colors.inputBackground,
		backgroundGradientToOpacity: 1,
		fillShadowGradientFrom: theme.colors.accent,
		fillShadowGradientFromOpacity: 0.8,
		fillShadowGradientTo: theme.colors.accent,
		fillShadowGradientToOpacity: 0,
		color: (opacity = 1) => hexToRgba(theme.colors.text, opacity),
		labelColor: (opacity = 1) => hexToRgba(theme.colors.text, opacity),
		strokeWidth: 2,
		style: {
			fontFamily: theme.fontFamily,
			fontSize: theme.fontSizes.sm,
			borderRadius: props.block?.background === 'oval' ? 6 : 0
		},
		propsForLabels: {
			fontSize: theme.fontSizes.xs
		}
	}
	const graphStyle = {
		marginVertical: theme.spacing.sm,
		...progressConfig.style
	}

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ?? 10,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	// const progressChartData = {
	// 	labels: ['Swim'],
	// 	data: [0.8]
	// }

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
		getData()
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const maxValue = props.block?.maxValue?.field
		? localeParseFloat(
				props.block?.data?.[
					props.block.maxValue.field.replace('Screen.', '')
				] ?? 0
		  )
		: localeParseFloat(props.block?.maxValue?.default ?? 0)

	const getData = () => {
		setFetching(true)

		const query = props.block?.mapping?.aggregation
			? `${props.block?.mapping?.label?.field},${
					props.block?.mapping?.aggregation
			  } ${
					props.block?.mapping?.value?.field
						? props.block?.mapping?.value?.field
						: '1'
			  }`
			: `${props.block?.mapping?.label?.field},${props.block?.mapping?.value?.field}`

		dataClient
			.postData(`/${props.block?.source}/aggregate`, {}, requestData, {
				params: {
					aggregation: query
				}
			})
			.then((response) => {
				if (response?.status === 200) {
					let progressData = {
						labels: response.data?.data?.map(
							(item) =>
								(typeof item[props.block?.mapping?.label?.field] === 'string'
									? item[props.block?.mapping?.label?.field]
									: item[props.block?.mapping?.label?.field]?.Name) ?? ''
						),
						data: response.data?.data?.map((item) => {
							if (maxValue == 0) return 0

							let val =
								item[
									`${props.block?.mapping?.aggregation ?? ''}${
										props.block?.mapping?.value?.field ?? ''
									}`
								]
							let numValue = localeParseFloat(val ?? 0)

							return numValue / maxValue
						})
					}

					if (progressData?.data?.length === 0) {
						progressData.data.push(0)

						setChartData(progressData)
					} else {
						setChartData(progressData)
					}
				} else {
					setChartData(null)

					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	useEffect(() => {
		setChartWidth(
			props.block?.containerWidth
				? (props.screenWidth * props.block.containerWidth) / 100 -
						(props.screenSize === 'sm'
							? theme.spacing.xl * 2
							: props.screenSize === 'md'
							? theme.spacing.xl * 1.5
							: props.screenSize === 'lg'
							? theme.spacing.xl
							: 0)
				: props.screenSize === 'sm'
				? props.screenWidth - theme.spacing.xl * 2
				: props.screenSize === 'md'
				? props.screenWidth - theme.spacing.xl * 2
				: props.screenSize === 'lg'
				? props.screenWidth - theme.spacing.xl
				: 0
		)
	}, [props.screenWidth])

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			chartData,
			{
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [chartData])

	useEffect(() => {
		if (reload) {
			getData()
		}
	}, [reload])

	return chartData ? (
		<View key={props.block._uid} style={styles.mainContainer}>
			<SearchBox
				block={{
					compact: true,
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>
			<TouchableOpacity
				key={props.block._uid}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			>
				<View key={props.block._uid} style={{ alignItems: 'flex-start' }}>
					{props.block.style && props.block.style === 'line' ? (
						chartData?.data?.map((item, index) => {
							return (
								<ProgressBar
									key={`progressBar-${props.block._uid}-${index}`}
									block={{
										title: chartData?.labels?.[index],
										mapping: {
											value: {
												default: item * maxValue
											},
											minValue: {
												default: 0
											},
											maxValue: {
												default: maxValue
											}
										}
									}}
								/>
							)
						})
					) : (
						<>
							<View style={styles.topTextContainer}>
								{!!props.block?.title && (
									<TextWithFont style={styles.title}>
										{props.block.title}
									</TextWithFont>
								)}
							</View>
							<ProgressChart
								data={chartData}
								width={chartWidth}
								height={chartHeight}
								strokeWidth={16}
								radius={32}
								chartConfig={progressConfig}
								style={graphStyle}
								hideLegend={false}
								strokeColor={(o = 1) => {
									return complement(
										theme.colors.accent == '#000000'
											? theme.colors.kozmik
											: theme.colors.accent,
										Math.round((o > 1 ? 11 : o) * 10)
									)
								}}
							/>
						</>
					)}
				</View>
			</TouchableOpacity>
		</View>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(ChartProgress)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: theme.spacing.sm,
			borderRadius:
				props.block?.background && props.block?.background == 'oval'
					? theme.radiuses.sm
					: !props.block?.background || props.block?.background === 'none'
					? theme.radiuses.sm
					: 0,
			backgroundColor:
				props.block?.background && props.block?.background !== 'none'
					? theme.colors.inputBackground
					: 'transparent'
		},
		title: {
			flex: 1,
			paddingTop: 8,
			fontSize: theme.fontSizes.md,
			fontWeight: 'bold',
			color: theme.colors.text
		},
		topTextContainer: {
			flexDirection: 'row',
			alignItems: 'center'
		}
	})
}
