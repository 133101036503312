import React, { useEffect } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import '@expo/match-media'
import { connect } from 'react-redux'
import { opacity } from 'simpler-color'

import { useTheme } from './theme/Theme'

import statusBarIcons from '../assets/images/statusBarIcons.png'
import statusBarIconsWhite from '../assets/images/statusBarIconsWhite.png'
import KozmikLogoLight from '../assets/images/KozmikLogoLight.png'
import Clock from './components/Clock'
import env from '../env'
import i18n from './i18n/i18n'

const PreviewNotch = (props) => {
	const theme = useTheme()
	const hasBackgroundImage =
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.default ||
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.field

	const styles = themedStyles(props, Platform, theme, hasBackgroundImage)

	return (
		env.isPreview &&
		!props.builderOnMobile &&
		Platform.OS === 'web' &&
		props.screenSize !== 'lg' && (
			<View style={styles.previewContainer}>
				<View style={styles.previewNotch}></View>
				<View style={styles.previewStatusIcons}></View>
				<Clock style={styles.previewTime} />
			</View>
		)
	)
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.screenSize,
	...state.builderOnMobile
})

export default connect(mapStateToProps)(PreviewNotch)

const themedStyles = (props, Platform, theme, hasBackgroundImage) => {
	return StyleSheet.create({
		previewContainer: {
			position: 'relative',
			overflow: 'hidden',
			width: '100%',
			height: 28,
			backgroundColor: props?.bgColor
				? props.bgColor
				: hasBackgroundImage
				? opacity(theme.colors.headerBackground, 0.2)
				: opacity(theme.colors.headerBackground, 0.97)
		},
		previewNotch: {
			position: 'absolute',
			backgroundColor: '#000000',
			width: 150,
			height: 28,
			top: -2,
			left: 0,
			right: 0,
			marginLeft: 'auto',
			marginRight: 'auto',
			borderBottomLeftRadius: 20,
			borderBottomRightRadius: 20,
			zIndex: 1
		},
		previewStatusIcons: {
			position: 'absolute',
			right: 19,
			top: 13,
			width: 54,
			height: 20,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundImage:
				props.config.appearence.Theme == 'Dark'
					? `url(${statusBarIconsWhite})`
					: `url(${statusBarIcons})`
		},
		previewTime: {
			position: 'absolute',
			left: 25,
			top: 12,
			fontSize: 12,
			fontWeight: 'bold',
			color: theme.colors.text
		}
	})
}
