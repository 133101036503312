import React, {
	useState,
	useImperativeHandle,
	useCallback,
	forwardRef,
	useEffect,
	useRef,
	createRef
} from 'react'
import {
	View,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Platform
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'

import FilterModal from './FilterModal'
import { MaterialIcons } from '@expo/vector-icons'
import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

const SearchBox = forwardRef((props, ref) => {
	const [timer, setTimer] = useState(0)
	const [isFocused, setIsFocused] = useState(false)
	const [inputText, setInputText] = useState('')
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)

	const theme = useTheme()
	const styles = themedStyles(props, isFocused, props.screenSize, theme)

	const clearInput = () => {
		setInputText('')
	}

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {}
	}))

	useEffect(() => {
		setInputText(props.block?.searchTerm ?? '')
	}, [props.block?.searchTerm])

	const cancelFilterModal = () => {
		setIsFilterModalVisible(false)
	}

	const search = (searchTerm) => {
		timer && clearTimeout(timer)
		setTimer(
			setTimeout(() => {
				props.block?.search && props.block?.search(searchTerm)
			}, 1000)
		)
	}

	return props.block?.searcheable ||
		(props.block?.filterModal &&
			props.block?.filterModal?.body &&
			props.block?.filterModal?.body?.length > 0) ? (
		<View style={styles.mainContainer}>
			{props.screenSize === 'lg' && !!props.block?.title && (
				<TextWithFont style={styles.title}>{props.block.title}</TextWithFont>
			)}

			{props.block?.searcheable && (
				<View style={styles.searchContainer}>
					<TouchableOpacity
						activeOpacity={0.8}
						style={styles.searchButton}
						onPress={() => search(inputText)}
					>
						<MaterialIcons
							name="search"
							size={20}
							color={theme.colors.inputText}
						/>
					</TouchableOpacity>

					<TextInput
						placeholder={props.block?.searchPlaceholder}
						placeholderTextColor={theme.colors.inputText}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						value={inputText}
						onChangeText={(text) => {
							setInputText(text)
							search(text)
						}}
						style={styles.searchInput}
						// onSubmitEditing={() => search(inputText)}
					/>
				</View>
			)}

			{props.block?.filterModal && props.block?.filterModal?.body?.length > 0 && (
				<>
					<View style={styles.filterContainer}>
						<TouchableOpacity
							activeOpacity={0.8}
							style={styles.filterButton}
							onPress={() => setIsFilterModalVisible(true)}
						>
							<MaterialIcons
								name="filter-list"
								size={20}
								color={
									props.block?.filterData && props.block.filterData.length > 0
										? theme.colors.text
										: theme.colors.textLight
								}
							/>
							<TextWithFont style={{ color: '#fff' }}></TextWithFont>
						</TouchableOpacity>
					</View>

					<FilterModal
						screenSize={props.screenSize}
						route={props.block?.route}
						filterData={props.block?.filterData}
						filter={props.block?.filter}
						block={props.block?.filterModal}
						isVisible={isFilterModalVisible}
						cancel={cancelFilterModal}
					/>
				</>
			)}
		</View>
	) : (
		props.screenSize === 'lg' && !!props.block?.title && (
			<View style={styles.mainContainer}>
				<TextWithFont style={styles.title}>{props.block.title}</TextWithFont>
			</View>
		)
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(SearchBox)

const themedStyles = (props, isFocused, screenSize, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			height:
				(props.block?.searcheable ||
					props.block?.filterModal?.body?.length > 0) &&
				props.block?.compact
					? 30
					: 40,
			marginVertical: props.block?.compact ? 0 : 10
		},
		searchContainer: {
			flexDirection: 'row',
			justifyContent: 'center',
			flexGrow: 1,
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,
			paddingHorizontal: theme.spacing.sm,
			maxWidth: screenSize === 'lg' ? 300 : '100%'
		},
		searchInput:
			Platform.OS === 'web'
				? {
						outlineStyle: 'none',
						flex: 1,
						color: theme.colors.text,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.sm,
						marginLeft: theme.spacing.sm,
						width: 1
				  }
				: {
						flex: 1,
						color: theme.colors.text,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSizes.sm,
						marginLeft: theme.spacing.sm
				  },
		searchButton: {
			justifyContent: 'center'
		},
		filterContainer: {
			width:
				(props.block?.searcheable ||
					props.block?.filterModal?.body?.length > 0) &&
				props.block?.compact
					? 30
					: 40,
			flexDirection: 'row',
			justifyContent: 'center',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,
			marginLeft: 10
		},
		filterButton: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center'
		},
		title: {
			flexGrow: 1,
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: theme.fontSizes.lg,
			// fontWeight: 'bold',
			color: theme.colors.text,
			paddingTop: 7
		}
	})
}
