import { SETCONFIG } from '../action-types/configActionTypes'

const initialState = {
	config: null
}

const configReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETCONFIG:
			return { ...state, config: action.payload }

		default:
			return state
	}
}

export default configReducer
