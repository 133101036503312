import React, { useState, useEffect } from 'react'
import { View, Platform, StyleSheet, TouchableOpacity } from 'react-native'
import { Camera, CameraType } from 'expo-camera'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { MaterialIcons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'
import i18n from '../i18n/i18n'

import CustomModal from './CustomModal'
import SimpleHeader from './SimpleHeader'

const BarcodeScanner = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [currentRoute, setCurrentRoute] = useState(null)

	const [cameraPermission, setCameraPermission] = useState(null)
	const [camera, setCamera] = useState(null)

	const checkPermision = async () => {
		let cameraPermission = await Camera.getCameraPermissionsAsync()

		if (cameraPermission.status !== 'granted') {
			cameraPermission = await BarCodeScanner.requestPermissionsAsync()
			if (cameraPermission.status !== 'granted')
				alert('Permission for media access needed.')
		}

		setCameraPermission(cameraPermission.status === 'granted')
	}

	useEffect(() => {
		if (props.isVisible) {
			checkPermision()
		}
	}, [props.isVisible])

	useEffect(() => {
		setCurrentRoute(props.route)
	}, [props.route])

	return (
		cameraPermission && (
			<CustomModal
				coverScreen={true}
				fullHeight
				isVisible={props.isVisible}
				cancel={() => props.cancel()}
			>
				<View style={{ flex: 1 }}>
					<View style={{ paddingHorizontal: theme.spacing.xl }}>
						<SimpleHeader
							title={i18n.t('barcodeScanner.scan')}
							cancel={props.cancel}
						/>
					</View>
					{Platform.OS === 'web' ? (
						<Camera
							type={CameraType.back}
							ref={(ref) => setCamera(ref)}
							ratio={'16:9'}
							onBarCodeScanned={(result) => {
								props.onBarCodeScanned(result)
							}}
							barCodeScannerSettings={{
								barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr]
							}}
							style={{ flex: 1 }}
						></Camera>
					) : (
						<BarCodeScanner
							type={CameraType.back}
							ratio={'16:9'}
							ref={(ref) => setCamera(ref)}
							onBarCodeScanned={(result) => {
								props.onBarCodeScanned(result)
							}}
							style={{ flex: 1 }}
						/>
					)}
				</View>
			</CustomModal>
		)
	)
}

export default BarcodeScanner

const themedStyles = (props, theme) => {
	return StyleSheet.create({})
}
