import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import {
	View,
	Image as NativeImage,
	StyleSheet,
	ScrollView
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'

import { DataTable } from 'react-native-paper'
import { opacity } from 'simpler-color'

import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import SearchBox from './SearchBox'
import SmartCellView from './SmartCellView'

import dataClient from '../services/dataClient'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import triggerActionList from '../common/methods/triggerActionList'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'

const defaultPageSize = 25
const imageSize = 30

const Table = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const pageSize = props.block.limit
		? props.block.limit
		: props.block.pageSize
		? props.block.pageSize
		: defaultPageSize

	const [totalRecords, setTotalRecords] = useState(0)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)

	const [fetching, setFetching] = useState(false)

	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)

	const from = (page - 1) * pageSize
	const to = Math.min(page * pageSize, listData?.length)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						if (page === 1) {
							setListData(response.data.records)
						} else {
							const managedListData = [...listData] ?? []

							response.data.records?.forEach((row) => {
								var exists = listData.find((x) => x.id === row.id)
								if (!exists) {
									managedListData.push(row)
								}
							})

							setListData(managedListData)
						}

						setTotalRecords(response.data.total)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const getWidth = (type) => {
		switch (type) {
			case 'Image':
				return 80
			case 'Checkbox':
				return 80
			case 'Number':
				return 100
			case 'Date':
			case 'Email':
			case 'Phone':
				return 150

			default:
				return 200
		}
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			listData,
			{
				page: page,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [listData])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	return listData ? (
		<View key={props.block._uid}>
			<SearchBox
				block={{
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>

			<View
				style={{
					paddingVertical: theme.spacing.sm
				}}
			>
				<DataTable
					style={{
						borderWidth: 2,
						borderColor: theme.colors.line,
						borderRadius: theme.radiuses.sm,
						backgroundColor: theme.colors.layoutBackground
					}}
				>
					<ScrollView
						horizontal
						contentContainerStyle={{
							flexDirection: 'column'
						}}
					>
						<DataTable.Header style={{ borderBottomColor: theme.colors.line }}>
							{!!props.block.columns?.length &&
								props.block.columns.map((column, columnIndex) => (
									<DataTable.Title
										key={`table-header-${props.block._uid}-${columnIndex}`}
										style={{
											paddingRight: theme.spacing.sm,
											width: getWidth(column.type),
											maxWidth: getWidth(column.type)
										}}
										textStyle={{
											fontSize: theme.fontSizes[props.block.fontSize],
											color: theme.colors.text
										}}
										numeric={column.type === 'Number' ? true : false}
									>
										{getLocalizeContent(column.title)}
									</DataTable.Title>
								))}
						</DataTable.Header>

						{listData.slice(from, to).map((item, rowIndex) => (
							<DataTable.Row
								key={`table-row-${props.block._uid}-${rowIndex}`}
								onPress={(e) => {
									const blockWithData = {
										...props.block,
										screenData: props.block.data,
										data: item.fields,
										id: item.fields.Id
									}
									triggerActionList(
										blockWithData,
										blockWithData.actions,
										blockWithData.id
									)
								}}
								style={{
									height: 50,
									borderBottomColor: theme.colors.line,
									alignItems: 'center'
								}}
							>
								{!!props.block.columns?.length &&
									props.block.columns.map((column, columnIndex) => (
										<DataTable.Cell
											key={`table-cell-${props.block._uid}-${columnIndex}`}
											style={{
												paddingRight: theme.spacing.sm,
												width: getWidth(column.type),
												maxWidth: getWidth(column.type)
											}}
											textStyle={{}}
											numeric={column.type === 'Number' ? true : false}
										>
											{column.type === 'Image' ? (
												<NativeImage
													resizeMode="cover"
													style={styles.image}
													source={{
														uri: item?.fields?.[column.field]
													}}
												/>
											) : (
												<SmartCellView
													alignCenter={false}
													style={styles.title}
													chipStyle={[styles.title, styles.chipsText]}
													column={column}
													data={item.fields}
													numberOfLines={1}
												/>
											)}
										</DataTable.Cell>
									))}
							</DataTable.Row>
						))}
					</ScrollView>

					<DataTable.Pagination
						theme={{
							colors: {
								text: theme.colors.text,
								onSurface: theme.colors.text,
								onSurfaceDisabled: opacity(theme.colors.text, 0.2)
							}
						}}
						page={page - 1}
						numberOfPages={Math.ceil(totalRecords / pageSize)}
						onPageChange={(page) => {
							setPage(page + 1)
						}}
						label={`${to > 0 ? from + 1 : 0}-${to} of ${totalRecords}`}
					/>
				</DataTable>
			</View>
		</View>
	) : null
	// <View style={[styles.indicatorContainer, styles.indicatorHorizontal]}>
	// 	<ActivityIndicator
	// 		size="small"
	// 		color={theme.colors.accent}
	// 		style={styles.indicator}
	// 	/>
	// </View>
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Table)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		topContainer: {
			flexDirection: 'row',
			alignItems: 'center'
		},
		image: {
			width: imageSize,
			height: imageSize,
			borderRadius:
				props.block.corner == 'oval'
					? theme.radiuses.sm
					: props.block.corner == 'circle'
					? imageSize / 2
					: theme.radiuses.none
		},
		title: {
			fontSize: theme.fontSizes[props.block.fontSize],
			color: theme.colors.text
		},
		chipsText: {
			marginRight: 5,
			marginVertical: 4,
			paddingHorizontal: theme.spacing.xs,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		}
	})
}
