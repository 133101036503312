import React, { forwardRef } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { complement, opacity } from 'simpler-color'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'

import getMappingValByKey from '../common/methods/getMappingValByKey'

const SmartTextView = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { alignCenter, style, chipStyle, ...restProps } = props

	const content = getMappingValByKey(
		props,
		props?.mapping,
		null,
		props?.userInfo
	)

	const isManyType = (elementProps, key, data = null) => {
		const currentData = data ? data : elementProps.block.data

		if (
			elementProps.block.mapping?.[key]?.type == 'Relation' ||
			elementProps.block.mapping?.[key]?.type == 'Lookup'
		) {
			const extractedData =
				currentData?.[elementProps.block.mapping[key]?.field]
			if (typeof extractedData === 'string') return false

			return true
		}

		return false
	}

	const decideColor = (index) => {
		return opacity(
			complement(
				theme.colors.accent == '#000000'
					? theme.colors.kozmik
					: theme.colors.accent,
				index % 11
			),
			0.7
		)
	}

	return isManyType(props, props?.mapping, null) ? (
		Array.isArray(content) ? (
			<View
				style={
					alignCenter
						? {
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center'
						  }
						: {
								flexDirection: 'row'
						  }
				}
			>
				{content.map((item, index) =>
					item ? (
						<Text
							key={index}
							style={[
								{ backgroundColor: decideColor(index + 1) },
								chipStyle,
								styles.text
							]}
							{...restProps}
						>
							{item}
						</Text>
					) : null
				)}
			</View>
		) : (
			<View
				style={
					alignCenter
						? {
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center'
						  }
						: {
								flexDirection: 'row'
						  }
				}
			>
				{content ? (
					<Text
						style={[
							{ backgroundColor: decideColor(1) },
							chipStyle,
							styles.text
						]}
						{...restProps}
					>
						{content}
					</Text>
				) : null}
			</View>
		)
	) : (
		<Text
			style={[
				alignCenter && {
					textAlign: 'center'
				},
				styles.text,
				style
			]}
			{...restProps}
		>
			{content}
		</Text>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(SmartTextView)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		text: {
			fontFamily: theme.fontFamily,
			color: theme.colors.text
		}
	})
}
