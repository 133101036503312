const convertDatetimeToDate = (datetime) => {
	var dateObj = new Date(datetime)

	var year = dateObj.getFullYear()
	var month = dateObj.getMonth()
	var day = dateObj.getDate()

	var date = new Date(year, month, day, 0, 0, 0).toISOString()

	return date
}

export default convertDatetimeToDate
