import React from 'react'
import { Linking, Platform } from 'react-native'

import CummunicationButton from './CummunicationButton'
import IconButton from './IconButton'

import getMappingValByKey from '../common/methods/getMappingValByKey'

const LinkButton = (props) => {
	const clickHandler = () => {
		const url = `${getMappingValByKey(props, 'address')}`

		if (Platform.OS == 'web') {
			window.open(url, '_blank')
		} else {
			Linking.canOpenURL(url).then((supported) => {
				//NOTE: https://reactnative.dev/docs/linking#canopenurl
				if (supported) {
					Linking.openURL(url)
				} else {
					console.log('Cannot open link', url)
				}
			})
		}
	}

	return (
		<CummunicationButton
			title={getMappingValByKey(props, 'title')}
			// text={getMappingValByKey(props, 'label')}
			text={getMappingValByKey(props, 'address')}
			spacing={props.block.spacing}
			fontSize={props.block.fontSize}
		>
			<IconButton clickHandler={clickHandler} icon={'link-variant'} />
		</CummunicationButton>
	)
}

export default LinkButton
