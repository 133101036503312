import React, {
	useState,
	useRef,
	createRef,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, ImageBackground, StyleSheet, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'

import ComponentGenerator from '../generators/ComponentGenerator'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import visibilityHelper from '../common/methods/visibilityHelper'
import { useTheme } from '../theme/Theme'

const Container = forwardRef((props, ref) => {
	const theme = useTheme()

	const usableScreenWidth =
		props.screenWidth -
		(props.screenSize !== 'lg' ? theme.spacing.xl * 2 : 0) -
		(props.block?.background && props.block?.background !== 'none'
			? theme.spacing.xl * 2
			: 0)

	const spacing = theme.spacing[props.block?.spacing] ?? theme.spacing.sm

	let itemSize =
		props.block?.size === 'xs'
			? 4
			: props.block?.size === 'sm' &&
			  (props.screenSize == 'sm' || props.screenSize === 'md')
			? 3
			: props.block?.size === 'sm' && props.screenSize == 'lg'
			? 3
			: props.block?.size === 'md' &&
			  (props.screenSize == 'sm' || props.screenSize === 'md')
			? 2
			: props.block?.size === 'md' && props.screenSize == 'lg'
			? 3
			: props.block?.size === 'lg' && props.screenSize == 'lg'
			? 2
			: props.block?.size === 'lg' &&
			  (props.screenSize == 'sm' || props.screenSize === 'md')
			? 1
			: 1

	itemSize =
		!!props.block?.body?.length &&
		props.block?.body?.length &&
		itemSize > props.block?.body?.length
			? props.block?.body?.length
			: itemSize

	const itemWidth =
		itemSize === 4
			? usableScreenWidth / 4 - spacing * 0.8
			: itemSize === 3
			? usableScreenWidth / 3 - spacing * 0.7
			: itemSize === 2
			? usableScreenWidth / 2 - spacing * 0.6
			: usableScreenWidth

	const styles = themedStyles(props, itemWidth, spacing, theme)
	const children = useRef([])

	const backgroundImageUrl = getMappingValByKey(
		props,
		'backgroundImageUrl',
		null,
		props.userInfo
	)

	useFocusEffect(
		useCallback(() => {
			refresh()
		}, [])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: (term = null) => {}
	}))

	const refresh = () => {
		// children.current?.map((item) => {
		// 	item.current?.refresh()
		// })
	}

	return (
		<View key={props.block._uid} style={styles.mainContainer}>
			<ImageBackground
				source={
					backgroundImageUrl && {
						uri: backgroundImageUrl
					}
				}
				style={styles.container}
			>
				{!!props.block?.body?.length &&
					props.block.body.map((block, index) => {
						if (
							!visibilityHelper.isElementVisible(
								block.visibility,
								props.userInfo,
								props.ghostUser,
								props.block?.data
							)
						)
							return

						block.route = props.block?.route
						block.navigation = props.block?.navigation
						block.data = props.block?.data
						block.refresh = props.block?.refresh
						block.search = props.block?.search
						block.setFloatingChildren = props.block?.setFloatingChildren
						block.canGoBack = props.block?.canGoBack
						block.layoutSource = props.block?.layoutSource
						block.screenElementCount = props.block?.screenElementCount

						block.containerWidth =
							props.block?.size === 'xs'
								? 25
								: props.block?.size === 'sm' &&
								  (props.screenSize == 'sm' || props.screenSize === 'md')
								? 33.333333
								: props.block?.size === 'md' &&
								  (props.screenSize == 'sm' || props.screenSize === 'md')
								? 50
								: props.block?.size === 'md' && props.screenSize == 'lg'
								? 33.333333
								: props.block?.size === 'lg' &&
								  (props.screenSize == 'sm' || props.screenSize === 'md')
								? 100
								: props.block?.size === 'lg' && props.screenSize == 'lg'
								? 50
								: 25

						children.current[index] = createRef()

						return (
							<View
								key={`container-item-${block._uid}`}
								style={styles.containerItem}
							>
								<ComponentGenerator
									key={block._uid}
									block={block}
									ref={children.current[index]}
									withNativeID
								/>
							</View>
						)
					})}
			</ImageBackground>
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.userInfo
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Container)

const themedStyles = (props, itemWidth, spacing, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: theme.spacing.xs
		},
		container: {
			borderRadius:
				props.block?.background && props.block?.background == 'oval'
					? theme.radiuses.sm
					: 0,
			flex: 1,
			flexDirection:
				props.block?.size === 'lg' && props.screenSize === 'sm'
					? 'column'
					: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			columnGap: spacing,
			overflow: 'hidden',
			backgroundColor:
				props.block?.background && props.block?.background !== 'none'
					? props.block?.backgroundColor ?? theme.colors.layoutBackground
					: 'transparent',
			resizeMode: 'cover',
			paddingVertical:
				props.block?.background && props.block?.background !== 'none'
					? theme.spacing.sm
					: 0,
			paddingHorizontal:
				props.block?.background && props.block?.background !== 'none'
					? theme.spacing.xl
					: 0
		},
		containerItem: {
			flexGrow: 1,
			flexDirection: 'column',
			alignItems: 'stretch',
			overflow: 'hidden',
			width: itemWidth,
			maxWidth: itemWidth
		}
	})
}
