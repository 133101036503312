import React from 'react'
import { View, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'

const CheckboxMarker = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return <View style={styles.marker}></View>
}

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		marker: {
			width: 16,
			height: 16,
			borderRadius: 2,
			borderWidth: props.active ? 4 : 1,
			borderColor: props.activeColor
				? props.active
					? props.activeColor
					: theme.colors.inputText
				: props.active
				? theme.colors.accent
				: theme.colors.inputText
		}
	})
}

export default CheckboxMarker
