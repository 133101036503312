import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import { View, ScrollView, TouchableOpacity, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'
import { BlurView } from 'expo-blur'
import * as Linking from 'expo-linking'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import CustomModal from './CustomModal'
import Button from './Button'
import Loader from './Loader'
import TextWithFont from './TextWithFont'
import RadioMarker from './RadioMarker'

import dataClient from '../services/dataClient'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import env from '../../env'

import CustomPopup from './CustomPopup'
import CheckboxMarker from './CheckboxMarker'

const Choice = forwardRef((props, ref) => {
	const theme = useTheme()
	const isPreviewApp = env.isPreview
	const [currentRoute, setCurrentRoute] = useState(null)

	const [comparisonData, setComparisonData] = useState(null)
	const [options, setOptions] = useState(null)
	const [value, setValue] = useState(null)
	const [modalValue, setModalValue] = useState(null)
	const [visible, setVisible] = useState(false)

	const styles = themedStyles(props, theme)

	const relationType = props.block.relation.type
	const placeholder = getLocalizeContent(props.block.placeholder)

	let jsonHeaders = {
		'Access-Control-Allow-Origin': Linking.createURL('')
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				refresh()
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh
	}))

	const refresh = () => {
		getChoiceData()
	}

	const initRelation = (data) => {
		let val = null

		if (relationType === 'One') {
			val = data?.[props.block?.mapping?.value?.field]?.Id
				? data[props.block.mapping.value.field].Id
				: null
		} else if (relationType === 'Many') {
			const idArray = data?.[props.block?.mapping?.value?.field]
				? data[props.block.mapping.value.field]?.map((x) => x.Id)
				: []

			val = idArray
		} else {
			console.log('UNRECOGNIZED RELATION TYPE')
		}

		setValue(val)
		setModalValue(val)
	}

	const saveRelation = (val) => {
		if (props.block?.data?.Id) {
			updateSubmit(val)
		}
	}

	const updateSubmit = (val) => {
		let jsonData = {
			Id: props.block.data.Id
		}

		var vl =
			val != null && relationType === 'One'
				? { Id: val, Name: options?.find((x) => x.id === val)?.label }
				: val?.map((x) => ({
						Id: x,
						Name: options?.find((o) => o.id === x)?.label
				  })) ?? []

		jsonData[props.block.mapping.value.field] = vl

		dataClient
			.patchData(`/${props.block.layoutSource}`, jsonHeaders, jsonData)
			.then((response) => {
				if (response.status == 200) {
					props?.block?.refresh && props.block.refresh()
					setValue(val)
					setVisible(false)
				} else {
					setModalValue(value)
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
			})
			.catch((error) => {
				setModalValue(value)
			})
	}

	const getLabel = (data) => {
		if (!data) return ''

		const text = Array.isArray(data)
			? data.map((item) => (typeof item === 'object' ? item['Name'] : item))
			: typeof data === 'object'
			? [data['Name']]
			: [data]

		return text?.join(', ') ?? ''
	}

	const getChoiceData = () => {
		let jsonData = {
			rows: props.block?.limit ? props.block.limit : 25,
			page: 1,
			sortField: props.block?.sorting?.field,
			sortDirection: props.block?.sorting?.sort,
			fields: `Id,${props.block.relation.field}`,
			filters: {
				groups: props.block?.filters?.rules?.length
					? [
							formatFilterDataByScreenData(
								props.block.filters,
								props.block?.data
							)
					  ]
					: []
			}
		}

		const canMakeRequest =
			JSON.stringify(comparisonData) !== JSON.stringify(jsonData)
		if (!canMakeRequest) return

		setComparisonData(jsonData)

		dataClient
			.postData(`/${props.block.relation.source}/search`, {}, jsonData)
			.then((response) => {
				if (response?.status === 200 && response?.data) {
					// if (response.data?.records?.length) {
					const createdOptions =
						response.data.records?.map((x) => ({
							id: x.id,
							label: getLabel(x.fields[props.block.relation.field])
						})) ?? []

					// if (createdOptions && createdOptions.length > 0) {
					setOptions(createdOptions)
					// }
					// }
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
			})
	}

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	const selectedOptionsClick = (option, directSubmit = false) => {
		if (relationType === 'One') {
			setModalValue(option.id)
			directSubmit && updateSubmit(option.id)
		} else if (relationType === 'Many') {
			const idArray = [...(modalValue ?? [])]

			const idIndex = idArray.indexOf(option.id)
			if (idIndex >= 0) {
				idArray.splice(idIndex, 1)
			} else {
				idArray.push(option.id)
			}
			setModalValue(idArray)
			directSubmit && updateSubmit(idArray)
		} else {
			console.log('UNRECOGNIZED RELATION TYPE')
		}
	}

	const getInputText = (val) => {
		if (val) {
			if (relationType === 'One') {
				const inputText = options?.find((x) => x.id === val)?.label
				return (
					<TextWithFont style={styles.text}>
						{inputText ? inputText : placeholder}
					</TextWithFont>
				)
			} else if (relationType === 'Many') {
				return val?.length ? (
					<View style={styles.tagContainer}>
						{options?.map((item, index) => {
							if (val.includes(item.id)) {
								return (
									<TextWithFont key={index} style={styles.inputTag}>
										{item.label || getLocalizeContent(props.block.emptyText)}
									</TextWithFont>
								)
							}
						})}
					</View>
				) : (
					<TextWithFont style={styles.text}>{placeholder}</TextWithFont>
				)
			} else {
				console.log('UNRECOGNIZED RELATION TYPE')
			}
		} else {
			return <TextWithFont style={styles.text}>{placeholder}</TextWithFont>
		}
	}

	const getModalOptions = (opts, modalVal) => {
		return opts?.map((item, index) => (
			<TouchableOpacity
				key={index}
				activeOpacity={0.8}
				style={styles.listItem}
				onPress={() => selectedOptionsClick(item)}
			>
				<TextWithFont style={styles.itemText} numberOfLines={1}>
					{item.label || getLocalizeContent(props.block.emptyText)}
				</TextWithFont>
				{((relationType === 'One' && modalVal === item.id) ||
					(relationType === 'Many' && modalVal?.includes(item.id))) && (
					<MaterialCommunityIcons
						name="check"
						size={20}
						style={styles.commonColor}
					/>
				)}
			</TouchableOpacity>
		))
	}

	const getOptions = (opts, modalVal) => {
		const itemIsActive = (item) =>
			(relationType === 'One' && modalVal === item.id) ||
			(relationType === 'Many' && modalVal?.includes(item.id))
		if (props.block.type === 'chips') {
			return (
				<View style={styles.tagContainer}>
					{opts?.map((item, index) => (
						<TouchableOpacity
							key={index}
							activeOpacity={0.8}
							style={
								itemIsActive(item) ? styles.activeItem : styles.passiveItem
							}
							onPress={() => selectedOptionsClick(item, true)}
						>
							<TextWithFont
								style={
									itemIsActive(item)
										? styles.activeItemText
										: styles.passiveItemText
								}
								numberOfLines={1}
							>
								{item.label || getLocalizeContent(props.block.emptyText)}
							</TextWithFont>
						</TouchableOpacity>
					))}
				</View>
			)
		} else if (props.block.type === 'radio') {
			return (
				<View>
					{opts?.map((item, index) => (
						<View key={index} style={styles.radioItemBox}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={styles.radioItem}
								onPress={() => selectedOptionsClick(item, true)}
							>
								<View style={styles.radioMarker}>
									<RadioMarker active={itemIsActive(item)} />
								</View>
								<TextWithFont style={styles.passiveItemText} numberOfLines={1}>
									{item.label || getLocalizeContent(props.block.emptyText)}
								</TextWithFont>
							</TouchableOpacity>
						</View>
					))}
				</View>
			)
		} else if (props.block.type === 'checkbox') {
			return (
				<View>
					{opts?.map((item, index) => (
						<View key={index} style={styles.radioItemBox}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={styles.radioItem}
								onPress={() => selectedOptionsClick(item, true)}
							>
								<View style={styles.radioMarker}>
									<CheckboxMarker active={itemIsActive(item)} />
								</View>
								<TextWithFont style={styles.passiveItemText} numberOfLines={1}>
									{item.label || getLocalizeContent(props.block.emptyText)}
								</TextWithFont>
							</TouchableOpacity>
						</View>
					))}
				</View>
			)
		} else {
			return null
		}
	}

	useEffect(() => {
		setModalValue(value)
	}, [visible])

	useEffect(() => {
		setCurrentRoute(props?.block?.route)
	}, [props?.block?.route])

	useEffect(() => {
		props.block?.data ? initRelation(props.block.data) : setValue(null)
	}, [props.block.data, props.block.mapping.value.field])

	return (
		<>
			{props.block.type === 'dropdown' && (
				<>
					{currentRoute && (
						<CustomPopup
							isVisible={visible}
							preventParentEvent={preventParentEvent}
							cancel={() => setVisible(false)}
						>
							<View style={{ padding: theme.spacing.xl, height: '100%' }}>
								<ScrollView
									showsVerticalScrollIndicator={false}
									scrollEnabled
									style={styles.listBox}
								>
									{getModalOptions(options, modalValue)}
								</ScrollView>
								<View style={{ flexDirection: 'row' }}>
									<Button
										text={getLocalizeContent(props.block.cancel?.text)}
										type="transparent"
										marginRight={7}
										onPress={() => setVisible(false)}
									/>
									<Button
										text={getLocalizeContent(props.block.submit?.text)}
										marginLeft={7}
										onPress={() => saveRelation(modalValue)}
									/>
								</View>
								{props.fetching > 0 && <Loader />}
							</View>
						</CustomPopup>
					)}

					<View style={styles.mainContainer}>
						<TextWithFont style={styles.title}>
							{getMappingValByKey(props, 'title')}
						</TextWithFont>
						<TouchableOpacity
							activeOpacity={0.8}
							onPress={() => setVisible(true)}
							style={styles.pickerContainer}
						>
							{getInputText(value)}
							<MaterialCommunityIcons
								name="menu-down"
								size={20}
								style={styles.iconColor}
							/>
						</TouchableOpacity>
					</View>
				</>
			)}
			{(props.block.type === 'chips' ||
				props.block.type === 'radio' ||
				props.block.type === 'checkbox') && (
				<View style={styles.mainContainer}>
					<TextWithFont style={styles.title}>
						{getMappingValByKey(props, 'title')}
					</TextWithFont>
					{getOptions(options, modalValue)}
				</View>
			)}
		</>
	)
})

const mapStateToProps = (state) => ({
	...state.fetching
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Choice)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: 10,
			justifyContent: 'space-between'
		},
		title: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600',
			marginBottom: 10
		},
		text: {
			flex: 1,
			display: 'flex',
			fontSize: theme.fontSizes.md,
			marginLeft: 2,
			color: theme.colors.inputText
		},
		pickerContainer: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: theme.colors.inputBackground,
			minHeight: 40,
			borderRadius: theme.radiuses.sm,
			borderColor: 'transparent',
			borderWidth: 1,
			paddingHorizontal: theme.spacing.sm
		},
		activeItem: {
			backgroundColor: theme.colors.accent,
			borderColor: 'transparent',
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 4,
			fontSize: 13,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.xs,
			borderRadius: theme.radiuses.sm
		},
		activeItemText: {
			flex: 1,
			fontSize: theme.fontSizes.md,
			color: theme.colors.layoutBackground
		},
		radioItemBox: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingVertical: 8,
			borderBottomColor: theme.colors.line,
			borderBottomWidth: 1
		},
		radioItem: {
			flexDirection: 'row',
			alignItems: 'center'
		},
		radioMarker: {
			marginRight: 8
		},
		passiveItem: {
			borderColor: theme.colors.inputText,
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 4,
			fontSize: 13,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.xs,
			borderRadius: theme.radiuses.sm
		},
		passiveItemText: {
			flex: 1,
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		listBox: {
			width: '100%',
			flex: 1,
			marginBottom: theme.spacing.xl
		},
		listItem: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: 40,
			marginBottom: 2,
			borderColor: theme.colors.line,
			borderBottomWidth: 1
		},
		itemText: {
			flex: 1,
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		commonColor: {
			color: theme.colors.text
		},
		iconColor: {
			color: theme.colors.inputText
		},
		tagContainer: {
			flex: 1,
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		inputTag: {
			color: theme.colors.inputText,
			borderColor: theme.colors.inputText,
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 2,
			fontSize: 13,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.xs,
			borderRadius: theme.radiuses.sm
		}
	})
}
