import { SETTHEME } from '../action-types/themeActionTypes'

import themes from '../../config/themes/themes'

const initialState = {
	theme: themes['Light']
}

const themeReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETTHEME:
			return { ...state, theme: action.payload }

		default:
			return state
	}
}

export default themeReducer
