import Cookies from 'universal-cookie'

import store from '../redux/store/store'
import { setAuth } from '../redux/actions/authActions'
import { setUserInfo } from '../redux/actions/userInfoActions'

import { ApiClient } from './client'
import tokenServices from './tokenServices'

import env from '../../env'

const domain = env.authDomain

export default {
	async register(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/register', data)
	},
	async invitation(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/invitation', data)
	},
	async forgotPassword(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post(
			'/api/a/forgotpassword',
			data
		)
	},
	async resetpassword(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post(
			'/api/a/resetpassword',
			data
		)
	},
	async changepassword(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post(
			'/api/a/changepassword',
			data
		)
	},
	async getUserInfo() {
		let headers = {}
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(domain, headers).get('/api/a/me')
	},
	async ghost(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/ghost', data)
	},
	async login(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/login', data)
	},
	async sendPin(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/sendPin', data)
	},
	async loginByPin(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(domain, headers).post('/api/a/loginByPin', data)
	},
	async logout(/*callback = null*/) {
		let headers = { 'content-type': 'application/x-www-form-urlencoded' }

		return await new ApiClient(domain, headers)
			.post('/api/b/logout')
			.then(async (response) => {
				if (response?.data?.success) {
					tokenServices.remove()
				} else {
					console.log('ERROR', response)
				}
			})
			.then(() => {
				store.dispatch(setAuth(false))
				store.dispatch(setUserInfo(null))
			})
			.catch((error) => {})
	}
}
