import React, { useEffect, useState } from 'react'
import {
	SafeAreaView,
	Platform,
	StatusBar,
	StyleSheet,
	View,
	TouchableOpacity
} from 'react-native'

import { useTheme } from '../theme/Theme'
import TextWithFont from '../components/TextWithFont'

import { useLinkTo } from '@react-navigation/native'
import linkService from '../services/linkService'
import routeService from '../services/routeService'

import nav from '../common/methods/nav'

import i18n from '../i18n/i18n'
import env from '../../env'
import CustomPopup from '../components/CustomPopup'

const NotFound = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { route, navigation } = props
	const isPreviewApp = env.isPreview

	const [visible, setVisible] = useState(true)

	/*********NAV_SERVICES_INIT*********/
	const linkTo = useLinkTo()
	// const route = useRoute()
	useEffect(() => {
		linkService.linkTo = linkTo
	}, [linkTo])
	useEffect(() => {
		routeService.route = route
	}, [route])
	/********************/

	const redirectByRoute = (routeObject) => {
		if (
			routeObject?.path === '/login' ||
			routeObject?.path === '/signup' ||
			routeObject?.path === '/forgotpassword' ||
			routeObject?.path === '/resetpassword' ||
			routeObject?.path === '/changepassword' ||
			routeObject?.path?.includes('/login?') ||
			routeObject?.path?.includes('/signup?') ||
			routeObject?.path?.includes('/forgotpassword?') ||
			routeObject?.path?.includes('/resetpassword?') ||
			routeObject?.path?.includes('/changepassword?')
		) {
			nav.navigateHome()
		}
	}

	useEffect(() => {
		redirectByRoute(route)
	})

	useEffect(() => {
		setVisible(Math.random())
	}, [props])

	return isPreviewApp ? (
		<CustomPopup
			isVisible={!!visible}
			cancel={() => {
				setVisible(null)
				nav.navigateBack()
			}}
		>
			<View style={styles.popupContainer}>
				<TextWithFont style={styles.notFound}>
					{i18n.t('notFound.text')}
				</TextWithFont>
				<TouchableOpacity
					activeOpacity={0.8}
					onPress={() => {
						setVisible(null)
						nav.navigateBack()
					}}
				>
					<TextWithFont style={styles.goBack}>
						{i18n.t('notFound.back')}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		</CustomPopup>
	) : (
		<SafeAreaView
			style={{
				flex: 1
			}}
		>
			{Platform.OS != 'web' && (
				<StatusBar
					animated={true}
					backgroundColor={theme.colors.headerBackground}
					barStyle={theme.statusBar.style}
					showHideTransition="none"
					hidden={false}
				/>
			)}
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: theme.colors.layoutBackground
				}}
			>
				<TextWithFont style={styles.notFound}>
					{i18n.t('notFound.text')}
				</TextWithFont>
			</View>
		</SafeAreaView>
	)
}

export default NotFound

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		popupContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'transparent'
		},
		notFound: {
			color: theme.colors.text,
			padding: theme.spacing.xl
		},
		goBack: {
			color: theme.colors.accent,
			padding: theme.spacing.xl,
			fontWeight: '600'
		}
	})
}
