import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, Image as NativeImage, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'
import SmartCellView from './SmartCellView'

import getLocalizeContent from '../common/methods/getLocalizeContent'
import i18n from '../i18n/i18n'

const imageSize = 30

const Columns = forwardRef((props, ref) => {
	const theme = useTheme()

	const styles = themedStyles(props, theme)

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {},
		search: (term = null) => {}
	}))

	const Row = (props) => (
		<View style={styles.rowContainer}>
			<TextWithFont style={styles.rowLeftText}>
				{getLocalizeContent(props?.column?.title)}
			</TextWithFont>

			{props?.column?.type === 'Image' ? (
				<NativeImage
					style={styles.image}
					source={{
						uri: props?.item?.[props?.column?.field]
					}}
				/>
			) : (
				<SmartCellView
					alignCenter={false}
					style={styles.title}
					chipStyle={[styles.title, styles.chipsText]}
					column={props?.column}
					data={props?.item}
					numberOfLines={1}
				/>
			)}
		</View>
	)

	return (
		<View key={props.block._uid} style={styles.columnsContainer}>
			{!!props.block.columns?.length &&
				props.block.columns.map((column, columnIndex) => (
					<Row
						key={`columns-${props.block._uid}-${columnIndex}`}
						column={column}
						item={props.block?.data}
					/>
				))}
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Columns)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		columnsContainer: {
			paddingVertical: theme.spacing.sm
		},
		rowContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingVertical: 8
		},
		rowLeftText: {
			fontSize: theme.fontSizes[props.block.fontSize],
			paddingRight: 20,
			minWidth: 150,
			color: theme.colors.textLight
		},
		image: {
			width: imageSize,
			height: imageSize,
			borderRadius:
				props.block.corner == 'oval'
					? theme.radiuses.sm
					: props.block.corner == 'circle'
					? imageSize / 2
					: theme.radiuses.none
		},
		title: {
			fontSize: theme.fontSizes[props.block.fontSize],
			color: theme.colors.text
		},
		chipsText: {
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.sm,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		}
	})
}
