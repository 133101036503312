import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'

import nav from '../common/methods/nav'

const HeaderWebLink = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { item, isActive, ...restProps } = props

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.mainContainer}
			onPress={() => {
				if (item?.screens) {
					nav.navigateTo(`/${item.path}`, false)
				} else {
					nav.navigateTo(`/${item.path}`, false)
				}
			}}
			{...restProps}
		>
			<Ionicons
				name={item.icon}
				size={16}
				color={isActive ? theme.colors.accent : 'gray'}
			/>
			<TextWithFont style={styles.text} numberOfLines={1}>
				{item.name}
			</TextWithFont>
		</TouchableOpacity>
	)
}

export default HeaderWebLink

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			flexDirection: 'row',
			flexGrow: 1,
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: 130,
			marginRight: 10,
			// backgroundColor: props.isActive && theme.colors.inputBackground,
			paddingVertical: 7,
			borderRadius: theme.radiuses.sm
		},
		text: {
			color: props.isActive ? theme.colors.accent : 'gray',
			fontSize: 14,
			marginLeft: 7
		}
	})
}
