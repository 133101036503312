import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { BlurView } from 'expo-blur'

import { useTheme } from '../theme/Theme'

const Loader = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<View style={styles.overlayContainer}>
			<BlurView intensity={50} style={styles.overlay}>
				<View style={styles.content}>
					<ActivityIndicator style={styles.indicator} />
				</View>
			</BlurView>
		</View>
	)
}

export default Loader

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		overlayContainer: {
			position: 'absolute',
			zIndex: 1,
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			backgroundColor: theme.colors.modalOverlay,
			height: '100%',
			width: '100%'
		},
		overlay: {
			padding: theme.spacing.xl,
			height: '100%',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center'
		},
		content: {
			backgroundColor: theme.colors.layoutBackground,
			borderRadius: theme.radiuses.lg,
			padding: theme.spacing.xl
		},
		indicator: {
			color: theme.colors.layoutBackground
		}
	})
}
