import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import AddToHomeScreen from './components/addToHomeScreen/AddToHomeScreen'

import '@expo/match-media'
import { connect } from 'react-redux'

import { useTheme } from './theme/Theme'

import PushPermisson from './components/PushPermisson'

const AppContainer = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, Platform, theme)

	return (
		<View style={styles.mainContainer}>
			<PushPermisson
				auth={props?.auth}
				pushToken={props?.pushToken}
				setPushToken={props?.setPushToken}
			/>
			<AddToHomeScreen />
			<View style={styles.container}>{props?.children}</View>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.config
})

export default connect(mapStateToProps)(AppContainer)

const themedStyles = (props, Platform, theme) => {
	return StyleSheet.create({
		mainContainer:
			Platform.OS == 'web'
				? {
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'center',
						backgroundColor: props?.bgColor
							? props.bgColor
							: `${theme.colors.layoutBackground}`
				  }
				: {
						flex: 1,
						backgroundColor: props?.bgColor
							? props.bgColor
							: theme.colors.layoutBackground
				  },

		container:
			Platform.OS == 'web'
				? {
						flex: 1,
						position: 'relative',
						overflow: 'hidden',
						backgroundColor: props?.bgColor
							? props.bgColor
							: theme.colors.layoutBackground
				  }
				: {
						flex: 1,
						position: 'relative',
						backgroundColor: props?.bgColor
							? props?.bgColor
							: theme.colors.layoutBackground
				  }
	})
}
