import React, { useEffect } from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'
import visibilityHelper from '../common/methods/visibilityHelper'

import TabBarItem from './TabBarItem'

import env from '../../env'

const TabBar = (props) => {
	const theme = useTheme()
	const isMobile = useMediaQuery({ maxWidth: 767 })
	const isMobileBrowser = Platform.OS == 'web' && isMobile && !env.isPreview
	const hasBackgroundImage =
		!!props.screenConfig?.mapping?.backgroundImageUrl?.default ||
		!!props.screenConfig?.mapping?.backgroundImageUrl?.field

	const styles = themedStyles(props, theme, isMobileBrowser, hasBackgroundImage)

	return props?.screens?.tabs?.length > 0 ? (
		<View style={styles.mainContainer}>
			{props.screens.tabs.map((item, index) =>
				visibilityHelper.isMenuVisible(
					item.visibility,
					props.userInfo,
					props.ghostUser
				) ? (
					<TabBarItem
						key={index}
						item={item}
						navigation={props.navigation}
						isActive={item.key === props.route.name}
					/>
				) : null
			)}
			{/* <TabBarItem
				item={{ key: 'test', name: 'test' }}
				navigation={props.navigation}
			/> */}
		</View>
	) : null
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.ghostUser
})

export default connect(mapStateToProps, null)(TabBar)

const themedStyles = (props, theme, isMobileBrowser, hasBackgroundImage) => {
	return StyleSheet.create({
		mainContainer: {
			backgroundColor: hasBackgroundImage
				? opacity(theme.colors.layoutBackground, 0.2)
				: opacity(theme.colors.layoutBackground, 0.97),
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			alignItems: 'center',
			padding: 10,
			paddingBottom: 20,
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			borderTopColor: hasBackgroundImage
				? opacity(theme.colors.line, 0.2)
				: theme.colors.line,
			borderTopWidth: 1
		}
	})
}
