import React, { forwardRef } from 'react'

import TextEntry from './TextEntry'

const InputNumeric = forwardRef((props, ref) => {
	const { regExp, precision, ...restBlockProps } = props.block
	return (
		<TextEntry
			ref={ref}
			// mask={}
			// maxLength={}
			// minLength={}
			regExp={'/(^-?[0-9]{1,3}(?:\\:thousand[0-9]{3})+$)|(^[0-9]+$)/'}
			isRegExString={true}
			isNumeric
			{...restBlockProps}
		/>
	)
})

export default InputNumeric
