import React, { useEffect, useState, useRef, createRef } from 'react'
import { View, TouchableOpacity, StyleSheet, ScrollView } from 'react-native'

import { useTheme } from '../theme/Theme'

import ComponentGenerator from '../generators/ComponentGenerator'
import CustomBottomSheet from './CustomBottomSheet'
import CustomPopup from './CustomPopup'
import Button from './Button'
import SimpleHeader from './SimpleHeader'
import getLocalizeContent from '../common/methods/getLocalizeContent'

const FilterModal = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [data, setData] = useState(null)
	const [parentKey, setParentKey] = useState(null)
	const [currentRoute, setCurrentRoute] = useState(null)

	const children = useRef([])

	const isDirty = data && Object.keys(data).length > 0

	const filter = () => {
		const validationResult = validateFilterData()

		const notValidItems = validationResult?.filter((x) => x.isValid == false)
		if (notValidItems.length === 0) {
			let filterData = []

			validationResult
				?.filter(
					(x) =>
						x.isValid == true &&
						x.value !== '' &&
						x.value !== null &&
						x.value !== undefined &&
						((Array.isArray(x.value) && x.value?.length > 0) ||
							(!Array.isArray(x.value) && x.value !== null))
				)
				.map((item) => {
					filterData.push({
						operator: item.operator,
						key: item.key,
						value: item.value,
						dataType: item.dataType
					})
				})

			props.filter(filterData)

			cancel()
		}
	}
	const cancel = () => {
		props.cancel()
	}

	const clear = () => {
		children.current?.map((item) => {
			item.current?.clearInput()
		})

		props.filter(null)
	}

	const validateFilterData = () => {
		let modifiedFilterData = []

		children.current?.map((item, index) => {
			const field = item.current?.validate()

			modifiedFilterData.push(field)
		})

		return modifiedFilterData
	}

	const fillFilterData = (filterData) => {
		let convertedData = {}

		filterData?.map((item) => {
			convertedData[item.key] = item.value
			convertedData[`${item.key}_${item.operator}`] = item.value
		})

		setData(convertedData)
	}

	const renderFilterContent = () => {
		return (
			<View style={styles.filterContainer}>
				<SimpleHeader
					title={getLocalizeContent(props.block?.title)}
					cancel={props.cancel}
				/>

				<ScrollView
					showsVerticalScrollIndicator={false}
					scrollEnabled
					style={{ paddingVertical: theme.spacing.xl }}
				>
					{!!props.block?.body?.length &&
						props.block?.body.map((block, index) => {
							children.current[index] = createRef()

							block.data = data
							block.submitData = data
							block.route = props.route

							return (
								<ComponentGenerator
									key={block._uid}
									block={block}
									child={children.current[index]}
									ref={children.current[index]}
								/>
							)
						})}
				</ScrollView>

				<View style={styles.footerMainContainer}>
					<View style={styles.footer}>
						{isDirty && (
							<Button
								text={getLocalizeContent(props.block?.buttons?.clear?.text)}
								icon={props.block?.buttons?.clear?.icon}
								corner={props.block?.buttons?.clear?.corner}
								type={props.block?.buttons?.clear?.type}
								marginRight={7}
								onPress={() => clear()}
							/>
						)}

						<Button
							text={getLocalizeContent(props.block?.buttons?.filter?.text)}
							icon={props.block?.buttons?.filter?.icon}
							corner={props.block?.buttons?.filter?.corner}
							type={props.block?.buttons?.filter?.type}
							onPress={() => filter()}
						/>
					</View>
				</View>

				<View style={{ flexDirection: 'row' }}></View>
			</View>
		)
	}

	useEffect(() => {
		if (props.isVisible) {
			fillFilterData(props.filterData)

			children.current?.map((item) => {
				item.current?.refresh()
			})
		}
	}, [props.isVisible, props.filterData])

	useEffect(() => {
		setCurrentRoute(props.route)
	}, [props.route])

	return (
		currentRoute &&
		(props.screenSize !== 'lg' ? (
			<CustomBottomSheet
				// name={'filter-bottom-sheet'}
				coverScreen={true}
				isVisible={props.isVisible}
				cancel={props.cancel}
			>
				{renderFilterContent()}
			</CustomBottomSheet>
		) : (
			<CustomPopup
				coverScreen={true}
				isVisible={props.isVisible}
				cancel={props.cancel}
			>
				{renderFilterContent()}
			</CustomPopup>
		))
	)
}

export default FilterModal

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		filterContainer: {
			paddingHorizontal: theme.spacing.xl
		},
		footerMainContainer: {
			flexDirection: 'column',
			borderTopColor: theme.colors.line,
			borderTopWidth: 1,
			marginBottom: theme.spacing.md
		},
		footer: {
			marginTop: theme.spacing.sm,
			marginBottom: theme.spacing.md,
			flexDirection: 'row'
		}
	})
}
