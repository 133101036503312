import { Platform } from 'react-native'
import { ApiClient } from './client'
import * as Linking from 'expo-linking'

import '../global/variables'
import env from '../../env'

const domain = env.emailDomain

export default {
	async send(data, func = null) {
		let headers = {}
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = Linking.createURL('')

		return new ApiClient(domain, headers)
			.post('/api/email/send', data)
			.then(() => {
				if (func != null) {
					func()
				}
			})
	}
}
