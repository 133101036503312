import React, {
	forwardRef,
	useEffect,
	useState,
	useImperativeHandle
} from 'react'
import {
	View,
	TouchableOpacity,
	StyleSheet,
	TextInput,
	Platform
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import Button from './Button'
import BaseDatePicker from './BaseDatePicker'
import CustomPopup from './CustomPopup'
import TextWithFont from './TextWithFont'
import RadioMarker from './RadioMarker'

import { useTheme } from '../theme/Theme'
import InputNumeric from './InputNumeric'

import i18n from '../i18n/i18n'

const DatePicker = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const everyOptions = [
		{
			name: 'none',
			label: i18n.t('form.none')
		},
		{
			name: 'day',
			label: i18n.t('form.day')
		},
		{
			name: 'week',
			label: i18n.t('form.week')
		},
		{
			name: 'month',
			label: i18n.t('form.month')
		},
		{
			name: 'year',
			label: i18n.t('form.year')
		}
	]

	const [visible, setVisible] = useState(false)

	const [recurrence, setRecurrence] = useState({
		field: props.block?.mapping?.field,
		every: 'none',
		ends: null,
		times: null
	})

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	const updateEvery = (value) => {
		if (value) {
			const copiedRecurrence =
				value === 'none'
					? { ...recurrence, every: value, ends: null, times: null }
					: { ...recurrence, every: value }
			setRecurrence(copiedRecurrence)
		}
	}

	const updateEnds = (field) => {
		if (field && field.isValid) {
			const copiedRecurrence = { ...recurrence, ends: field.value }
			setRecurrence(copiedRecurrence)
		}
	}

	const updateTimes = (field) => {
		if (field && field.isValid) {
			const copiedRecurrence = { ...recurrence, times: field.value }
			setRecurrence(copiedRecurrence)
		}
	}

	return props.block.enableRecurrence ? (
		<>
			<CustomPopup
				coverScreen={true}
				isVisible={visible}
				preventParentEvent={preventParentEvent}
				cancel={() => setVisible(false)}
			>
				<View style={styles.repeatPopupContainer}>
					<View style={styles.repeatPopupContent}>
						<View
							style={{
								flexDirection: 'column',
								marginBottom: theme.spacing.xl
							}}
						>
							{everyOptions?.map((option, index) => (
								<View key={index} style={styles.radioItemBox}>
									<TouchableOpacity
										activeOpacity={0.8}
										style={styles.radioItem}
										onPress={() => updateEvery(option.name)}
									>
										<View style={styles.radioMarker}>
											<RadioMarker active={option.name === recurrence?.every} />
										</View>
										<TextWithFont
											numberOfLines={1}
											style={{ color: theme.colors.text }}
										>
											{option.label}
										</TextWithFont>
									</TouchableOpacity>
								</View>
							))}
						</View>
						<BaseDatePicker
							icon={'calendar'}
							title={i18n.t('form.endsOn')}
							// placeholder={{
							// 	default: i18n.t('form.lastDate')
							// }}
							updateFormField={updateEnds}
							updateWhenClearInput={true}
							submitData={{
								Ends: recurrence?.ends
							}}
							required={{ status: false }}
							mapping={{ field: 'Ends' }}
						/>
						<InputNumeric
							block={{
								updateFormField: updateTimes,
								title: i18n.t('form.endsAfter'),
								// placeholder: {
								// 	default: i18n.t('form.numberOfOccurrences')
								// },
								submitData: {
									Times: recurrence?.times
								},
								required: { status: false },
								mapping: {
									field: 'Times'
								},
								maxVal: 100,
								minVal: 0
							}}
						/>
					</View>
					<View style={styles.repeatPopupButtons}>
						<Button
							text={i18n.t('form.done')}
							onPress={() => setVisible(false)}
						/>
					</View>
				</View>
			</CustomPopup>

			<View style={styles.mainContainer}>
				<BaseDatePicker
					ref={ref}
					icon={'calendar'}
					recurrence={recurrence}
					{...props.block}
				/>
				<TouchableOpacity
					activeOpacity={0.8}
					onPress={() => {
						setVisible(true)
					}}
					style={styles.repeatTextContainer}
				>
					<TextWithFont style={styles.repeatText} numberOfLines={1}>
						{everyOptions.find((x) => x.name === recurrence?.every)?.label ||
							i18n.t('form.none')}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		</>
	) : (
		<BaseDatePicker ref={ref} icon={'calendar'} {...props.block} />
	)
})

export default DatePicker

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flexDirection: 'column'
		},
		repeatPopupContainer: {
			paddingHorizontal: theme.spacing.xl,
			paddingVertical: theme.spacing.xxl
		},
		repeatPopupContent: {
			width: '100%',
			flexDirection: 'column'
		},
		repeatPopupButtons: {
			width: '100%',
			flexDirection: 'row',
			marginTop: theme.spacing.xl
		},
		repeatTextContainer: {
			width: '100%',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end'
		},
		repeatText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.accent,
			fontWeight: '600',
			textDecorationLine: 'underline'
		},
		radioItemBox: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingVertical: 8,
			borderBottomColor: theme.colors.line,
			borderBottomWidth: 1
		},
		radioItem: {
			flexDirection: 'row',
			alignItems: 'center'
		},
		radioMarker: {
			marginRight: 8
		}
	})
}
