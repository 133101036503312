const serviceWorker = {
	init: function () {
		if ('serviceWorker' in navigator) {
			return navigator.serviceWorker.getRegistration().then((sw) => {
				if (sw) return sw

				return navigator.serviceWorker.register('sw.js')
			})
		} else {
			return Promise.resolve(null)
		}
	},
	getPermission: function () {
		if (!('Notification' in window)) {
			return null
		}

		return Notification.permission
	},
	askForPermission: async function () {
		if (!('Notification' in window)) {
			return Promise.resolve(false)
		}

		let permission = await Notification.requestPermission()
		if (permission === 'granted') {
			return Promise.resolve(true)
		} else {
			return Promise.resolve(false)
		}
	}
}

export { serviceWorker }
