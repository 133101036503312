import React, { useEffect } from 'react'
import {
	View,
	StyleSheet,
	Dimensions,
	Image,
	TouchableOpacity
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Ionicons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import nav from '../common/methods/nav'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

// import SearchBox from './SearchBox'
import TextWithFont from './TextWithFont'
import ActionButton from './ActionButton'

const PageHeaderWeb = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<View style={styles.headerMainContainer}>
			<View style={styles.header}>
				<View style={styles.sectionLeft}></View>

				<View style={styles.sectionMid}>
					<TextWithFont style={styles.pageTitle} numberOfLines={1}>
						{getMappingValByKey(props, 'title')}
					</TextWithFont>
				</View>

				<View style={styles.sectionRight}>
					{!!props.block?.buttons?.right?.length &&
						props.block.buttons.right.map((item, index) => {
							const text = getMappingValByKey(
								{
									block: {
										...item
									}
								},
								'text'
							)

							return (
								<TouchableOpacity
									activeOpacity={0.8}
									key={`right_${index}`}
									onPress={() =>
										triggerActionList(
											props.block,
											item.actions,
											props.block?.route?.params?.id
										)
									}
									style={
										text && {
											flex: 1,
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'flex-end'
										}
									}
								>
									<Ionicons
										name={item.icon}
										size={24}
										style={styles.buttonIcon}
									/>

									{text && (
										<TextWithFont
											style={{
												color: theme.colors.headerText,
												fontSize: theme.fontSizes.md,
												marginLeft: 5
											}}
											numberOfLines={1}
										>
											{text}
										</TextWithFont>
									)}
								</TouchableOpacity>
							)
						})}
				</View>
			</View>
		</View>
	)
}

export default PageHeaderWeb

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		headerMainContainer: {
			backgroundColor: 'transparent',
			flexDirection: 'column',
			marginTop: theme.spacing.xl
		},
		header: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingVertical: 8
		},
		pageTitle: {
			color: theme.colors.headerText,
			fontSize: theme.fontSizes.lg,
			fontWeight: 'bold'
		},
		sectionLeft: {
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'row'
		},
		sectionMid: {
			flex: 1,
			justifyContent: 'flex-start',
			flexDirection: 'row'
		},
		sectionRight: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'row',
			minWidth: 200,
			gap: theme.spacing.xl
		},
		buttonIcon: {
			color: theme.colors.accent
		}
	})
}
