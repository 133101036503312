import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useTheme } from '../theme/Theme'
import Button from './Button'

import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

const ButtonElement = (props) => {
	const theme = useTheme()
	const styles = themedStyles(theme)

	return (
		<View style={styles.mainContainer}>
			<Button
				text={getMappingValByKey(props, 'text')}
				icon={props.block?.icon}
				corner={props.block?.corner}
				spacing={theme.spacing[props.block?.spacing] ?? theme.spacing.sm}
				type={props.block?.type}
				containerWidth={props.block?.containerWidth}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			/>
		</View>
	)
}

const themedStyles = (theme) => {
	return StyleSheet.create({
		mainContainer: {
			flexDirection: 'row',
			justifyContent: 'flex-start',
			paddingVertical: theme.spacing.sm
		}
	})
}

export default ButtonElement
