import { initializeApp, getApps, getApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

const firebaseMessaging = {
	getToken: async function (sw, config) {
		try {
			const isSupportedBrowser = await isSupported()

			if (!isSupportedBrowser) return Promise.resolve(null)
			if (!sw || !config) return Promise.resolve(null)

			const app = !getApps().length ? initializeApp(config) : getApp()
			if (!app) return Promise.resolve(null)

			const messaging = getMessaging(app)

			return getToken(messaging, {
				serviceWorkerRegistration: sw
			})
		} catch (error) {
			console.error(error)

			return Promise.resolve(null)
		}
	}
}

export { firebaseMessaging }
