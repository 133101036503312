import React from 'react'

import ScreenComponent from '../components/ScreenComponent'
import Redirector from '../components/Redirector'
import { TransitionPresets } from '@react-navigation/stack'

import visibilityHelper from '../common/methods/visibilityHelper'

const ScreenGenerator = (
	screens,
	screenConfig,
	Navigator,
	userInfo,
	ghostUser,
	screenGroup = null,
	slideFromRight = false,
	animationEnabled = false,
	config = null
) => {
	return (
		<Navigator.Screen
			key={screenConfig.key}
			name={screenConfig.key}
			options={{
				title: config?.manifest?.name
					? `${config?.manifest?.name} | ${screenConfig.name}`
					: screenConfig.name,
				headerShown: false,
				// animationEnabled: animationEnabled,
				animationEnabled: false,
				detachPreviousScreen: false,
				presentation: 'card'
				// presentation:
				// 	!screenConfig.style || screenConfig.style === 'screen'
				// 		? 'card'
				// 		: 'transparentModal'
				// ...(slideFromRight &&
				// 	(!screenConfig.style || screenConfig.style === 'screen') &&
				// 	TransitionPresets.SlideFromRightIOS)
			}}
		>
			{(props) =>
				visibilityHelper.isScreenVisible(
					screenConfig.visibility,
					userInfo,
					ghostUser
				) ? (
					<ScreenComponent
						{...props}
						screenGroup={screenGroup}
						screens={screens}
						screenConfig={screenConfig}
					/>
				) : (
					<Redirector navigation={props.navigation} target="login" />
				)
			}
		</Navigator.Screen>
	)
}

export default ScreenGenerator
