import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import { View, StyleSheet, Switch } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import Checkbox from 'expo-checkbox'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

import getLocalizeContent from '../common/methods/getLocalizeContent'

const InputCheckbox = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [switchValue, setSwitchValue] = useState(null)

	const toggleSwitchVal = () => {
		const toggledVal = !switchValue
		setSwitchValue(toggledVal)
		updateFormFieldByRequired(toggledVal)
	}

	const getCurrent = () => {
		return (
			(props.block.operator
				? props.block.submitData?.[
						`${props?.block?.mapping?.field}_${props.block.operator}`
				  ] ?? props.block.submitData?.[props?.block?.mapping?.field]
				: props.block.submitData?.[props?.block?.mapping?.field]) ?? null
		)
	}

	const updateFormFieldByRequired = (val, valid = true) => {
		props.block?.updateFormField &&
			props.block.updateFormField({
				isValid: valid,
				key: props.block.mapping.field,
				value: val,
				operator: props.block.operator
			})
	}

	const validate = () => {
		const valid = true

		return {
			key: props.block.mapping.field,
			dataType: props.block.mapping.type,
			isValid: valid,
			value: switchValue,
			operator: props.block.operator
		}
	}

	const clearInput = () => {
		setSwitchValue(getCurrent())
	}

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {},
		validate: validate,
		clearInput: clearInput
	}))

	useEffect(() => {
		setSwitchValue(getCurrent())
	}, [props.block?.submitData])

	return (
		<View style={styles.mainContainer}>
			{props.block.type === 'checkbox' && (
				<View style={styles.buttonContainer}>
					<Checkbox
						style={styles.checkbox}
						value={switchValue}
						onValueChange={toggleSwitchVal}
						color={switchValue ? theme.colors.accent : undefined}
					/>
				</View>
			)}
			<View style={{ flex: 1 }}>
				<TextWithFont style={styles.titleText} numberOfLines={1}>
					{props.block?.title}
				</TextWithFont>
				<TextWithFont style={styles.contentText} numberOfLines={1}>
					{getLocalizeContent(props.block?.label)}
				</TextWithFont>
			</View>
			{props.block.type === 'switch' && (
				<View style={styles.buttonContainer}>
					<Switch
						// trackColor={{ true: theme.colors.accent, false: '#767577' }}
						// thumbColor={switchValue ? theme.colors.accent : '#f4f3f4'}
						ios_backgroundColor="#3e3e3e"
						onValueChange={toggleSwitchVal}
						value={switchValue}
					/>
				</View>
			)}
		</View>
	)
})

export default InputCheckbox

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			height: 66,
			alignItems: 'center',
			justifyContent: 'space-between',
			flexDirection: 'row'
		},
		titleText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		contentText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		buttonContainer: {
			flexDirection: 'row'
		},
		checkbox: {
			marginRight: theme.spacing.xl
		}
	})
}
