import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import { useTheme } from '../theme/Theme'

import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'

import { opacity } from 'simpler-color'

import { connect } from 'react-redux'
import { setAuth } from '../redux/actions/authActions'

import TextWithFont from './TextWithFont'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

const SettingsButton = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const title = getMappingValByKey(props, 'title', null, props.userInfo)

	return (
		<TouchableOpacity
			style={styles.mainContainer}
			activeOpacity={0.8}
			disabled={props.block.actions?.[0]?.type == null ? true : false}
			onPress={() =>
				triggerActionList(
					props.block,
					props.block.actions,
					props.block?.route?.params?.id
				)
			}
		>
			{props.block.icon && (
				<View style={styles.sign}>
					<Ionicons
						name={props.block.icon}
						size={props.block.height - 25}
						style={styles.icon}
					/>
				</View>
			)}
			<View style={styles.contentContainer}>
				<View style={styles.textContainer}>
					<TextWithFont style={styles.title}>{title}</TextWithFont>
				</View>
				{!(props.block.actions?.[0]?.type == null) && (
					<View style={styles.detailContainer}>
						<MaterialCommunityIcons
							name="chevron-right"
							size={24}
							style={styles.detailArrow}
						/>
					</View>
				)}
			</View>
		</TouchableOpacity>
	)
}

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			position: 'relative',
			flex: 1,
			justifyContent: 'flex-start',
			alignItems: 'center',
			flexDirection: 'row',
			flexWrap: 'wrap',
			minHeight: props.block.height,
			width: '100%',
			paddingVertical: 8,
			borderBottomWidth: 1,
			borderBottomColor: theme.colors.line
		},
		contentContainer: {
			flex: 1,
			justifyContent: 'space-between',
			alignItems: 'center',
			flexDirection: 'row'
		},
		detailContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		},
		detailArrow: {
			color: theme.colors.inputText
		},
		sign: {
			width: props.block.height - 16,
			height: props.block.height - 16,
			// borderRadius:
			// 	props.block.corner == 'oval'
			// 		? theme.radiuses.sm
			// 		: props.block.corner == 'circle'
			// 		? (props.block.height - 16) / 2
			// 		: theme.radiuses.none,
			// backgroundColor: opacity(theme.colors.accent, 0.2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row'
		},
		icon: {
			color: theme.colors.accent
		},
		textContainer: {
			marginLeft: props.block.icon ? theme.spacing.md : 0
		},
		title: {
			fontSize: theme.fontSizes[props.block.fontSize],
			lineHeight: theme.fontSizes[props.block.fontSize] + 2,
			fontWeight: '600',
			color: theme.colors.text
		}
	})
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (data) => dispatch(setAuth(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsButton)
