import { Platform } from 'react-native'
import { getStoreData, setStoreData, deleteStoreData } from './StoreData'
import '../../global/variables'

const asyncLocalStorage = {
	setItem: (key, value) => {
		return Promise.resolve().then(() => {
			localStorage.setItem(key, value)
		})
	},
	getItem: (key) => {
		return Promise.resolve().then(() => {
			return localStorage.getItem(key)
		})
	},
	removeItem: (key) => {
		return Promise.resolve().then(() => {
			return localStorage.removeItem(key)
		})
	}
}

export const setClientId = async (data) => {
	if (Platform.OS === 'web') {
		await asyncLocalStorage.setItem(global.CLIENT_ID, data)
	} else {
		await setStoreData(global.CLIENT_ID, data)
	}
}

export const removeClientId = async () => {
	if (Platform.OS === 'web') {
		await asyncLocalStorage.removeItem(global.CLIENT_ID)
	} else {
		await deleteStoreData(global.CLIENT_ID)
	}
}

export const getClientId = async () => {
	if (Platform.OS === 'web') {
		return await asyncLocalStorage.getItem(global.CLIENT_ID)
	} else {
		const client_id = await getStoreData(global.CLIENT_ID)
		return client_id?.replace('"', '')?.replace('"', '')
	}
}
