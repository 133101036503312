import {
	ADDROUTEDATA,
	REMOVEROUTEDATA
} from '../action-types/routeDataActionTypes'

export const addRouteData = (name, data) => ({
	type: ADDROUTEDATA,
	name: name,
	data: data
})
export const removeRouteData = (name) => ({
	type: REMOVEROUTEDATA,
	name: name
})
