class LinkService {
	constructor() {
		this._linkTo = null
	}

	set linkTo(val) {
		this._linkTo = val
	}

	get linkTo() {
		return this._linkTo
	}
}

const linkService = new LinkService()

export default linkService
