const env = {
	env: process.env.ENV,
	authEndpoint: process.env.AUTH_ENDPOINT,
	manifestEndpoint: process.env.MANIFEST_ENDPOINT,
	authDomain: process.env.AUTH_DOMAIN,
	pushDomain: process.env.PUSH_DOMAIN,
	emailDomain: process.env.EMAIL_DOMAIN,
	dataDomain: process.env.DATA_DOMAIN,
	playDomain: process.env.PLAY_DOMAIN,
	platformDomain: process.env.PLATFORM_DOMAIN,
	isPreview: process.env.IS_PREVIEW === 'true',
	useLocalConfig: process.env.USE_LOCAL_CONFIG === 'true',
	aesKey: process.env.AES_KEY,
	aesIV: process.env.AES_IV
}

export default env
