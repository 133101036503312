import extractActionResultFromActions from './extractActionResultFromActions'

const fixDataCorruption = (item, data) => {
	if (item.type === 'Relation') {
		if (item.relationType === 'Many') {
			return Array.isArray(data)
				? data
				: data?.Id
				? [
						{
							Id: data?.Id,
							Name: data?.Name
						}
				  ]
				: data
				? [
						{
							Id: data
						}
				  ]
				: null
		} else {
			return data?.Id
				? {
						Id: data?.Id,
						Name: data?.Name
				  }
				: Array.isArray(data)
				? {
						Id: data[0]?.Id
				  }
				: data
				? {
						Id: data
				  }
				: null
		}
	} else if (item.type === 'Text') {
		if (!data) return null

		return Array.isArray(data)
			? data?.map((item) => item?.Name ?? item)?.join(', ')
			: data?.Name
			? data?.Name
			: data
	} else {
		return data
	}
}

const formatFormDataByScreenData = (
	item,
	itemData = null,
	formData = null,
	screenData = null,
	id = null,
	userInfo = null,
	actions = null
) => {
	if (item.valueType == 'Screen') {
		if (item.value === 'Screen' || item.value === 'Screen.Id') {
			return fixDataCorruption(item, id || screenData?.Id)
		} else if (item.value.startsWith('Screen.')) {
			if (!screenData) return null

			const extractedFieldName = item.value.replace('Screen.', '')
			const data = screenData?.[extractedFieldName]
			if (!data) return null

			return fixDataCorruption(item, data)
		} else if (item.value.startsWith('Form.')) {
			if (!formData) return null

			const extractedFieldName = item.value.replace('Form.', '')
			const data = formData?.[extractedFieldName]
			if (!data) return null

			return fixDataCorruption(item, data)
		}
	} else if (item.valueType == 'Form') {
		if (!formData) return null

		const extractedFieldName = item.value.replace('Form.', '')
		const data = formData?.[extractedFieldName]
		if (!data) return null

		return fixDataCorruption(item, data)
	} else if (item.valueType == 'Field') {
		if (!itemData) return null

		const data = itemData[item.value]
		if (!data) return null

		return fixDataCorruption(item, data)
	} else if (item.valueType == 'Variables') {
		switch (item.value) {
			case '{{profile.id}}':
				return fixDataCorruption(item, userInfo?.id)
			case '{{profile.name}}':
				return userInfo?.name
			case '{{profile.email}}':
				return userInfo?.email
			case '{{profile.photo}}':
				return userInfo?.photo
			case '{{datetime.year}}':
				return new Date().getFullYear()
			case '{{datetime.month}}':
				return new Date().getMonth() + 1
			case '{{datetime.date}}':
				return new Date().toLocaleDateString()
			case '{{datetime.time}}':
				return new Date().toLocaleString() + ''
		}
	} else if (item.valueType == 'Action') {
		if (!actions?.length) return null

		const actionResult = extractActionResultFromActions(actions, item?.value)

		return fixDataCorruption(item, actionResult)
	} else {
		return item.value
	}
}

export default formatFormDataByScreenData
