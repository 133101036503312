import getLanguageCode from './getLanguageCode'

const getLocalizeContent = (content) => {
	if (!content) return ''

	const languageCode = getLanguageCode()

	return content.default
		? content.default
		: (languageCode === 'tr-TR' ? content.tr : content.en) ?? ''
}

export default getLocalizeContent
