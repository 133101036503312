import { SETGENERATORDATA } from '../action-types/generatorDataActionTypes'

const initialState = {
	generatorData: null
}

const generatorDataReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETGENERATORDATA:
			return { ...state, generatorData: action.payload }

		default:
			return state
	}
}

export default generatorDataReducer
