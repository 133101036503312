import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	createRef
} from 'react'
import { ImageBackground, StyleSheet, View, Platform } from 'react-native'
import {
	useIsFocused,
	useFocusEffect,
	useLinkTo
} from '@react-navigation/native'

import ScrollableContainer from './ScrollableContainer'
import ScreenContainer from './ScreenContainer'
import BuiltWithKozmik from './BuiltWithKozmik'
import TabBar from './TabBar'
import Header from './Header'
import HeaderWeb from './HeaderWeb'
import PageHeaderWeb from './PageHeaderWeb'
import ComponentGenerator from '../generators/ComponentGenerator'

// redux
import { connect } from 'react-redux'
import {
	addRouteData,
	removeRouteData
} from '../redux/actions/routeDataActions'

import visibilityHelper from '../common/methods/visibilityHelper'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import showToast from '../common/methods/showToast'
import linkService from '../services/linkService'
import routeService from '../services/routeService'
import i18n from '../i18n/i18n'
import removeLastScreenFromPath from '../common/methods/nav/removeLastScreenFromPath'

import { useTheme } from '../theme/Theme'

import '../global/variables'
import env from '../../env'

import dataClient from '../services/dataClient'
import PreviewNotch from '../PreviewNotch'

const ScreenComponent = ({ navigation, route, ...props }) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const isFocused = useIsFocused()
	const isPreviewApp = env.isPreview

	const [floatingChildren, setFloatingChildren] = useState(null)

	const [containerRef, setContainerRef] = useState(null)
	const [canGoBack, setCanGoBack] = useState(false)

	const showTabBar =
		props.screenGroup !== 'hiddens' &&
		(!props.screenConfig?.style || props.screenConfig?.style === 'screen') &&
		props.screenSize !== 'lg'

	const initCanGoBack = (route) => {
		if (route?.path) {
			let target = '/'

			target = removeLastScreenFromPath(route.path)

			if (target && target != '/') {
				setCanGoBack(true)
			} else {
				setCanGoBack(false)
			}
		} else {
			setCanGoBack(false)
		}
	}

	/*********NAV_SERVICES_INIT*********/
	const linkTo = useLinkTo()
	useEffect(() => {
		linkService.linkTo = linkTo
	}, [linkTo])

	const initRouteService = (route) => {
		routeService.route = route
		initCanGoBack(route)
	}

	/********************/

	// const [isPreviewMobile, setIsPreviewMobile] = useState(false)

	const source = props?.screenConfig?.source
	const isDetailScreen = !!route?.params?.id && !!source

	const [data, setData] = useState(null)

	const backgroundImageUrl = getMappingValByKey(
		{
			block: { ...props?.screenConfig, data: data }
		},
		'backgroundImageUrl',
		null,
		props.userInfo
	)

	const children = useRef([])

	const fixDetailData = (id, currentRouteData) => {
		if (
			(id === 'null' || id === null || id === '' || id === ':id') &&
			props.config?.settings?.table === props.screenConfig?.source &&
			props.userInfo?.isAppUser &&
			props.userInfo
		) {
			id = props.userInfo.id
		}

		if (id === 'null' || id === null) return

		return dataClient.getData(`/${source}/${id}`).then((response) => {
			if (response?.status === 200) {
				if (response.data) {
					if (
						JSON.stringify(currentRouteData) !== JSON.stringify(response.data)
					) {
						props.addRouteData(route?.name, response.data)
						setData(response.data)
					}
				} else {
					props.addRouteData(route?.name, null)
					setData(null)
				}
			} else {
				showToast(
					'error',
					response?.data?.message ||
						response?.data?.errorDetails ||
						i18n.t('api.error')
				)
			}
		})
	}

	const refresh = async () => {
		children.current?.map((item) => {
			item.current?.refresh()
		})
	}

	const search = (term = null) => {
		children.current?.map((item) => {
			item.current?.search(term)
		})
	}

	useFocusEffect(
		useCallback(() => {
			initRouteService(route)

			const currentRoute = props.routes?.find((x) => x.name === route?.name)

			if (isDetailScreen) {
				const currentRouteData = currentRoute?.data || null

				setData(currentRouteData)
				fixDetailData(route.params.id, currentRouteData)
			}
		}, [route, props.screenConfig])
	)

	const postActiveRoute = () => {
		const routeAsJson = JSON.parse(
			JSON.stringify({
				route: route
			})
		)

		window.parent.postMessage(
			{ source: 'kozmik-preview', activeRoute: routeAsJson },
			'*'
		)
	}

	const scrollTo = (x, y) => {
		if (Platform.OS === 'ios' || Platform.OS === 'android') {
			containerRef &&
				containerRef.scrollToOffset({
					offset: y,
					animated: true
				})
		} else {
			containerRef &&
				containerRef.scrollTo({
					x: x,
					y: y,
					animated: true
				})
		}
	}

	useEffect(() => {
		//NOTE:update activeRoute for data changes
		isPreviewApp && isFocused && postActiveRoute()
	}, [isFocused, props.screenConfig])

	return (
		props.screenConfig && (
			<ScreenContainer route={route} {...props}>
				<ImageBackground
					source={
						backgroundImageUrl && {
							uri: backgroundImageUrl
						}
					}
					style={{
						flex: 1,
						resizeMode: 'cover',
						justifyContent: 'center',
						backgroundColor: props.screenConfig.backgroundColor
					}}
				>
					{isPreviewApp &&
						props.screenSize !== 'lg' &&
						props.screenConfig.style === 'screen' && (
							<PreviewNotch
								block={{
									screenConfig: props.screenConfig
								}}
							/>
						)}

					{props.screenSize === 'lg' && props.screenConfig.style === 'screen' && (
						<HeaderWeb
							block={{
								screenConfig: props.screenConfig,
								route: route,
								screens: props.screens,
								navigation: navigation,
								userInfo: props.userInfo,
								...props.screenConfig?.header
							}}
						/>
					)}

					{(props.screenSize !== 'lg' ||
						props.screenConfig.style !== 'screen') &&
						props.screenConfig?.header?.visible && (
							<Header
								block={{
									screenConfig: props.screenConfig,
									route: route,
									data: data,
									refresh: refresh,
									search: search,
									navigation: navigation,
									canGoBack: canGoBack,
									...props.screenConfig?.header
								}}
							/>
						)}

					<View
						style={{
							...styles.scrollable,
							...{ paddingBottom: 0, position: 'relative', zIndex: 1 }
						}}
					>
						{floatingChildren}
					</View>

					<BuiltWithKozmik
						showTabBar={showTabBar}
						screenStyle={props.screenConfig?.style}
					/>

					<ScrollableContainer
						containerRef={(ref) => {
							setContainerRef(ref)
						}}
						style={styles.scrollable}
						showsVerticalScrollIndicator={false}
					>
						{props.screenSize === 'lg' &&
							props.screenConfig.style === 'screen' && (
								<PageHeaderWeb
									block={{
										screenConfig: props.screenConfig,
										route: route,
										data: data,
										refresh: refresh,
										search: search,
										navigation: navigation,
										canGoBack: canGoBack,
										...props.screenConfig?.header
									}}
								/>
							)}

						{!!props.screenConfig?.body?.length &&
							props.screenConfig.body.map((block, index) => {
								if (
									!visibilityHelper.isElementVisible(
										block.visibility,
										props.userInfo,
										props.ghostUser,
										data
									)
								)
									return

								block.showTabBar = showTabBar
								block.screenStyle = props.screenConfig?.style
								block.route = route
								block.navigation = navigation
								block.data = data
								block.refresh = refresh
								block.search = search
								block.scrollTo = scrollTo
								block.setFloatingChildren = setFloatingChildren
								block.canGoBack = true //canGoBack
								block.layoutSource = props?.screenConfig?.source
								block.screenElementCount = props.screenConfig.body.length

								children.current[index] = createRef()

								return (
									<ComponentGenerator
										key={block._uid}
										block={block}
										ref={children.current[index]}
										withNativeID
									/>
								)
							})}
					</ScrollableContainer>

					{showTabBar && (
						<TabBar
							screens={props.screens}
							screenConfig={props.screenConfig}
							route={route}
							navigation={navigation}
							userInfo={props.userInfo}
						/>
					)}
				</ImageBackground>
			</ScreenContainer>
		)
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.config,
	...state.routes,
	...state.screenSize,
	...state.screenWidth,
	...state.windowWidth
})

const mapDispatchToProps = (dispatch) => {
	return {
		addRouteData: (name, data) => dispatch(addRouteData(name, data)),
		removeRouteData: (name) => dispatch(removeRouteData(name))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenComponent)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		scrollable: {
			paddingHorizontal:
				props.screenSize === 'lg' &&
				props.screenConfig?.style &&
				props.screenConfig?.style === 'screen'
					? props.windowWidth > 820 &&
					  props.windowWidth < 1186 + theme.spacing.xl * 2
						? theme.spacing.xl
						: (props.windowWidth - 1186) / 2
					: theme.spacing.xl,
			paddingBottom:
				props.screenConfig?.style && props.screenConfig?.style !== 'screen'
					? props.screenConfig?.style === 'popup'
						? 10
						: 20
					: 70
		}
	})
}
