import navigateBack from './navigateBack'
import navigateByKey from './navigateByKey'
import navigateHome from './navigateHome'
import navigateTo from './navigateTo'
import getCurrentRoute from './getCurrentRoute'

export default {
	navigateBack,
	navigateByKey,
	navigateHome,
	navigateTo,
	getCurrentRoute
}
