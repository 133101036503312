const formatFilterDataByScreenData = (
	filters,
	screenData,
	formData = null,
	id = null
) => {
	const formatted = {
		groupOp: filters.groupOp,
		rules: filters.rules.map((x) => {
			if (x.type == 'Screen') {
				if (x.data.startsWith('Screen')) {
					if (x.data === 'Screen' || x.data === 'Screen.Id') {
						return {
							...x,
							data: [
								{
									Id: id || screenData?.Id
								}
							]
						}
					} else {
						const extractedFieldName = x.data.replace('Screen.', '')
						return {
							...x,
							data: screenData?.[extractedFieldName]
						}
					}
				} else {
					const extractedFieldName = x.data.replace('Form.', '')
					return {
						...x,
						data: formData?.[extractedFieldName]
					}
				}
			} else if (x.type == 'Form') {
				if (x.data.startsWith('Form')) {
					const extractedFieldName = x.data.replace('Form.', '')
					return {
						...x,
						data: formData?.[extractedFieldName]
					}
				}
			} else {
				return x
			}
		})
	}
	return formatted
}

export default formatFilterDataByScreenData
