import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useTheme } from '../theme/Theme'
import Button from './Button'

import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

const ButtonBar = (props) => {
	const theme = useTheme()
	const styles = themedStyles(theme)

	return (
		<View style={styles.mainContainer}>
			<Button
				text={getMappingValByKey(props, 'leftText')}
				icon={props.block.left?.icon}
				corner={props.block.left?.corner}
				spacing={theme.spacing[props.block.spacing] ?? theme.spacing.sm}
				type={props.block.left?.type}
				containerWidth={props.block?.containerWidth}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.left.actions,
						props.block?.route?.params?.id
					)
				}
			/>
			<Button
				text={getMappingValByKey(props, 'rightText')}
				icon={props.block.right?.icon}
				corner={props.block.right?.corner}
				spacing={theme.spacing[props.block.spacing] ?? theme.spacing.sm}
				type={props.block.right?.type}
				containerWidth={props.block?.containerWidth}
				marginLeft={theme.spacing.sm}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.right.actions,
						props.block?.route?.params?.id
					)
				}
			/>
		</View>
	)
}

const themedStyles = (theme) => {
	return StyleSheet.create({
		mainContainer: {
			flexDirection: 'row',
			justifyContent: 'flex-start',
			paddingVertical: theme.spacing.sm
		}
	})
}

export default ButtonBar
