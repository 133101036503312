import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { Text as TextSVG } from 'react-native-svg'

import { LineChart } from 'react-native-chart-kit'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import hexToRgba from 'hex-to-rgba'

import { useTheme } from '../theme/Theme'

import dataClient from '../services/dataClient'
import TextWithFont from './TextWithFont'
import triggerActionList from '../common/methods/triggerActionList'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import isDate from '../common/methods/isDate'
import i18n from '../i18n/i18n'
import localeParseFloat from '../common/methods/localeParseFloat'
import getLocalizeContent from '../common/methods/getLocalizeContent'

import SearchBox from './SearchBox'

const ChartLine = forwardRef((props, ref) => {
	const theme = useTheme()

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)

	const chartHeight = 220
	const [chartWidth, setChartWidth] = useState(0)

	const styles = themedStyles(props, theme)

	const [chartData, setChartData] = useState(
		componentDetail?.config?.forceLoad ? null : componentDetail?.data ?? null
	)
	const [showBarTops, setShowBarTops] = useState(
		props.block?.limit && props.block?.limit > 10 ? false : true
	)
	const [fetching, setFetching] = useState(false)

	const lineConfig = {
		decimalPlaces: 0,
		useBackgroundCanvas:
			props.block?.background && props.block?.background !== 'none',
		backgroundColor: theme.colors.inputBackground,
		backgroundGradientFrom: theme.colors.inputBackground,
		backgroundGradientFromOpacity: 1,
		backgroundGradientTo: theme.colors.inputBackground,
		backgroundGradientToOpacity: 1,
		fillShadowGradientFrom: theme.colors.accent,
		fillShadowGradientFromOpacity: 0.8,
		fillShadowGradientTo: theme.colors.accent,
		fillShadowGradientToOpacity: 0,
		color: (opacity = 1) => hexToRgba(theme.colors.text, opacity),
		labelColor: (opacity = 1) => hexToRgba(theme.colors.text, opacity),
		strokeWidth: 2,
		style: {
			fontFamily: theme.fontFamily,
			fontSize: theme.fontSizes.sm,
			borderRadius: props.block?.background === 'oval' ? 6 : 0
		},
		propsForLabels: {
			fontSize: theme.fontSizes.xs
		},
		propsForDots: {
			r: '3'
		}
	}
	const graphStyle = {
		marginVertical: theme.spacing.sm,
		...lineConfig.style
	}

	// // sample data for line charts
	// const lineChartData = {
	// 	labels: ['Janua', 'Febru', 'March', 'April', 'May', 'June', 'July'],
	// 	datasets: [
	// 		{
	// 			data: [20, 45, 28, 80, 99, 43, 250],
	// 			color: (o = 1) => opacity(complement(theme.colors.accent, 1), o)
	// 		}
	// 		// {
	// 		// 	data: [10, 22, 58, 50, 99, 4, 122],
	// 		// 	color: (o = 1) => opacity(complement(theme.colors.accent, 2), o)
	// 		// },
	// 		// {
	// 		// 	data: [55, 34, 89, 5, 33, 77, 300],
	// 		// 	color: (o = 1) => opacity(complement(theme.colors.accent, 3), o)
	// 		// },
	// 		// {
	// 		// 	data: [99, 100, 200],
	// 		// 	color: (o = 1) => opacity(complement(theme.colors.accent, 4), o)
	// 		// }
	// 	],
	// 	legend: ['Rainy Days']
	// }

	const yAxisInterval =
		chartData?.labels?.length <= 5
			? 1
			: chartData?.labels?.length <= 10
			? 2
			: chartData?.labels?.length <= 20
			? 4
			: chartData?.labels?.length <= 30
			? 5
			: chartData?.labels?.length <= 40
			? 7
			: chartData?.labels?.length <= 50
			? 9
			: chartData?.labels?.length <= 60
			? 10
			: chartData?.labels?.length <= 70
			? 12
			: chartData?.labels?.length <= 80
			? 14
			: chartData?.labels?.length <= 100
			? 17
			: 25

	const yAxisLabelInterval = props.block?.containerWidth
		? props.screenSize === 'md'
			? yAxisInterval
			: props.screenSize === 'lg'
			? yAxisInterval
			: yAxisInterval
		: props.screenSize === 'md'
		? Math.round(yAxisInterval / 1.5)
		: props.screenSize === 'lg'
		? Math.round(yAxisInterval / 3)
		: yAxisInterval

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ?? 10,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getData = () => {
		setFetching(true)

		let query = `${props.block?.mapping?.label?.field}`
		if (props.block?.mapping?.aggregation)
			query = `${query},${props.block?.mapping?.aggregation} ${
				props.block?.mapping?.value?.field
					? props.block?.mapping?.value?.field
					: '1'
			}`
		else query = `${query},${props.block?.mapping?.value?.field}`

		dataClient
			.postData(`/${props.block?.source}/aggregate`, {}, requestData, {
				params: {
					aggregation: query
				}
			})
			.then((response) => {
				if (response?.status === 200) {
					const lineData = {
						labels: response.data?.data?.map((item) => {
							let val = item[props.block?.mapping?.label?.field]
							let dateVal = isDate(val)

							if (dateVal && isNaN(val)) {
								return dateVal.getDate() === 1
									? dateVal.toString().replace(/\w+ (\w+).*/, '$1')
									: dateVal
											.toString()
											.replace(/\w+ (\w+) (\d+) (\d+).*/, '$1 $2')
							} else {
								let charLength = 1
								if (response.data?.data.length <= 3) charLength = 10
								else if (response.data?.data.length <= 5) charLength = 8
								else if (response.data?.data.length <= 10) charLength = 4

								return (
									(typeof val === 'string'
										? val?.substring(0, charLength)
										: val?.Name?.substring(0, charLength)) ?? ''
								)
							}
						}),
						datasets: [
							{
								data: response.data?.data?.map((item) =>
									localeParseFloat(
										item[
											`${props.block?.mapping?.aggregation ?? ''}${
												props.block?.mapping?.value?.field ?? ''
											}`
										] ?? 0
									)
								),
								color: (o = 1) => hexToRgba(theme.colors.accent, o)
							}
						]
						// legend: [
						// 	props.block?.mapping?.aggregation
						// 		? `${
						// 				props.block?.mapping?.aggregation &&
						// 				i18n.t(`aggOperators.${props.block?.mapping?.aggregation}`)
						// 		  } (${props.block?.mapping?.value?.field ?? ''})`
						// 		: `${props.block?.mapping?.value?.field ?? ''}`
						// ]
					}

					setChartData(lineData)
					setShowBarTops(response.data?.data?.length > 10 ? false : true)
				} else {
					setChartData(null)
					setShowBarTops(false)

					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	useEffect(() => {
		setChartWidth(
			props.block?.containerWidth
				? (props.screenWidth * props.block.containerWidth) / 100 -
						(props.screenSize === 'sm'
							? theme.spacing.xl * 2
							: props.screenSize === 'md'
							? theme.spacing.xl * 1.5
							: props.screenSize === 'lg'
							? theme.spacing.xl
							: 0)
				: props.screenSize === 'sm'
				? props.screenWidth - theme.spacing.xl * 2
				: props.screenSize === 'md'
				? props.screenWidth - theme.spacing.xl * 2
				: props.screenSize === 'lg'
				? props.screenWidth - theme.spacing.xl
				: 0
		)
	}, [props.screenWidth])

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			chartData,
			{
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [chartData])

	useEffect(() => {
		if (reload) {
			getData()
		}
	}, [reload])

	return chartData ? (
		<View key={props.block._uid} style={styles.mainContainer}>
			<SearchBox
				block={{
					compact: true,
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>
			<TouchableOpacity
				key={props.block._uid}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			>
				<View key={props.block._uid} style={{ alignItems: 'flex-start' }}>
					<View style={styles.topTextContainer}>
						{!!props.block?.title && (
							<TextWithFont style={styles.title}>
								{props.block.title}
							</TextWithFont>
						)}
					</View>

					<LineChart
						data={chartData}
						width={chartWidth}
						height={chartHeight}
						chartConfig={lineConfig}
						fromZero={true}
						withHorizontalLabels={!showBarTops}
						style={graphStyle}
						yAxisInterval={5}
						yAxisLabelInterval={yAxisLabelInterval}
						renderDotContent={({ x, y, index, indexData }) =>
							showBarTops && (
								<TextSVG
									key={`chartLine_dot_${x + y}`}
									x={x - 3}
									y={y - 7}
									fill={hexToRgba(theme.colors.text, 0.8)}
									fontSize={theme.fontSizes.xs}
								>
									{indexData}
								</TextSVG>
							)
						}
						bezier
					/>
				</View>
			</TouchableOpacity>
		</View>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(ChartLine)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: theme.spacing.sm,
			borderRadius:
				props.block?.background && props.block?.background == 'oval'
					? theme.radiuses.sm
					: !props.block?.background || props.block?.background === 'none'
					? theme.radiuses.sm
					: 0,
			backgroundColor:
				props.block?.background && props.block?.background !== 'none'
					? theme.colors.inputBackground
					: 'transparent'
		},
		title: {
			flex: 1,
			paddingTop: 8,
			fontSize: theme.fontSizes.md,
			fontWeight: 'bold',
			color: theme.colors.text
		},
		topTextContainer: {
			flexDirection: 'row',
			alignItems: 'center'
		}
	})
}
