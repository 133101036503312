import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import {
	View,
	StyleSheet,
	Platform,
	TouchableOpacity,
	Image as NativeImage
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { default as CarouselLegacy } from 'react-native-reanimated-carousel'

import dataClient from '../services/dataClient'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import triggerActionList from '../common/methods/triggerActionList'

import { useTheme } from '../theme/Theme'

const Carousel = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [touchEnabled, setTouchEnabled] = useState(true)
	const [data, setData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [fetching, setFetching] = useState(false)

	const baseOptions = {
		vertical: false,
		width: props.screenWidth,
		height: props.screenWidth * (props.screenSize === 'lg' ? 0.5 : 0.55)
	}
	const images = data?.map((image, index) =>
		getMappingValByKey(props, 'imgUrl', image.fields)
	)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(props.block.filters, props.block?.data)
		: null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : 100,
		page: 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: (term = null) => {}
	}))

	const refresh = () => {
		getData()
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						setData(response.data.records)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			data,
			{
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [data])

	useEffect(() => {
		if (reload) {
			getData()
		}
	}, [reload])

	return images?.length > 0 ? (
		<View style={styles.container}>
			<CarouselLegacy
				{...baseOptions}
				mode="parallax"
				modeConfig={{
					parallaxScrollingScale: 0.9,
					parallaxScrollingOffset:
						props.screenWidth <= 340
							? 42
							: props.screenWidth <= 390
							? 46
							: props.screenWidth <= 440
							? 50
							: props.screenWidth <= 500
							? 55
							: props.screenWidth <= 600
							? 65
							: props.screenWidth <= 700
							? 75
							: props.screenWidth <= 800
							? 85
							: props.screenWidth <= 900
							? 95
							: props.screenWidth <= 1000
							? 115
							: props.screenWidth <= 1100
							? 125
							: props.screenWidth <= 1280
							? 135
							: 150
				}}
				autoPlay={true}
				loop
				autoPlayInterval={3500}
				scrollAnimationDuration={500}
				data={images}
				onScrollBegin={() => {
					Platform.OS === 'web' && setTouchEnabled(false)
				}}
				onScrollEnd={(index) => Platform.OS === 'web' && setTouchEnabled(true)}
				renderItem={({ index }) => (
					<TouchableOpacity
						activeOpacity={0.8}
						style={styles.button}
						disabled={props.block.actions?.[0]?.type == null ? true : false}
						onPress={() => {
							if (
								(Platform.OS === 'web' && touchEnabled) ||
								Platform.OS !== 'web'
							) {
								triggerActionList(
									props.block,
									props.block.actions,
									data[index].id
								)
							}
						}}
					>
						<NativeImage
							resizeMode={props?.block?.imageResizeMode ?? 'cover'}
							style={{
								width: '100%',
								height: '100%',
								backgroundColor: 'transparent',
								borderRadius:
									props?.block?.corner === 'oval' ? theme.radiuses.md : 0
							}}
							source={{
								uri: images[index]
							}}
						/>
					</TouchableOpacity>
				)}
			/>
		</View>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Carousel)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		container: {
			alignItems: 'center'
		},
		button: {
			flex: 1,
			justifyContent: 'center'
		}
	})
}
