import * as SecureStore from 'expo-secure-store'

export async function getStoreData(key) {
	let result = await SecureStore.getItemAsync(key)
	return result
}

export async function setStoreData(key, value) {
	const valueAsString = JSON.stringify(value)

	await SecureStore.setItemAsync(key, valueAsString)
}

export async function deleteStoreData(key) {
	await SecureStore.deleteItemAsync(key)
}
