import { SETWINDOWWIDTH } from '../action-types/windowWidthActionTypes'
import { Dimensions } from 'react-native'

const initialState = {
	windowWidth: Dimensions.get('window').width
}

const windowWidthReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETWINDOWWIDTH:
			return { ...state, windowWidth: action.payload }

		default:
			return state
	}
}

export default windowWidthReducer
