import React from 'react'
import { View, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'

const Seperator = (props) => {
	const theme = useTheme()
	const styles = themedStyles(
		theme,
		props?.block?.size,
		props?.block?.linePosition
	)

	return (
		<View style={styles.mainContainer}>
			{!!props?.block?.lineVisible && <View style={styles.seperator} />}
		</View>
	)
}

export default Seperator

const themedStyles = (theme, size, linePosition) => {
	return StyleSheet.create({
		mainContainer: {
			height: size ? size : 10,
			justifyContent: linePosition ? linePosition : 'center'
		},
		seperator: {
			backgroundColor: theme.colors.line,
			height: 1
		}
	})
}
