import React, { useEffect, useState } from 'react'

import TextWithFont from './TextWithFont'

const Clock = (props) => {
	const [time, setTime] = useState(null)

	const refreshTime = () => {
		const date = new Date()
		setTime({
			hour: date.getHours(),
			minute: date.getMinutes()
		})
	}

	useEffect(() => {
		refreshTime()

		let timeInterval = setInterval(refreshTime, 3000)

		return () => {
			clearInterval(timeInterval)
		}
	}, [])

	return (
		<TextWithFont {...props}>
			{time != null &&
				`${time.hour}:${time.minute < 10 ? '0' : ''}${time.minute}`}
		</TextWithFont>
	)
}

export default Clock
