import React, { forwardRef } from 'react'

import BasePicker from './BasePicker'

const ImagePicker = forwardRef((props, ref) => {
	return (
		<BasePicker
			ref={ref}
			icon={'image-outline'}
			mimeTypes={['image/*']}
			{...props.block}
		/>
	)
})

export default ImagePicker
