import React, { useEffect } from 'react'
import { View, StyleSheet, Dimensions, Image } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { connect } from 'react-redux'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import visibilityHelper from '../common/methods/visibilityHelper'
import nav from '../common/methods/nav'
import triggerActionList from '../common/methods/triggerActionList'

// import SearchBox from './SearchBox'
import TextWithFont from './TextWithFont'
import ActionButton from './ActionButton'
import HeaderWebLink from './HeaderWebLink'
import { TouchableOpacity } from 'react-native-gesture-handler'

const HeaderWeb = (props) => {
	const theme = useTheme()
	const hasBackgroundImage =
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.default ||
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.field

	const styles = themedStyles(props, theme, hasBackgroundImage)

	return props?.block?.screens?.tabs?.length > 0 ? (
		<View style={styles.mainContainer}>
			<TouchableOpacity
				activeOpacity={0.8}
				style={styles.titleContainer}
				onPress={() => {
					nav.navigateHome()
				}}
			>
				{props.config?.appearence?.Logo ? (
					<View style={styles.logoContainer}>
						<Image
							resizeMode="contain"
							resizeMethod="auto"
							style={{
								width: '100%',
								height: '100%'
							}}
							source={{
								uri: props.config?.appearence?.Logo
							}}
						/>
					</View>
				) : (
					<TextWithFont style={styles.title}>
						{props.config?.manifest?.name}
					</TextWithFont>
				)}
			</TouchableOpacity>

			<View
				style={{
					display: 'flex',
					flex: '1 0 auto',
					flexDirection: 'row',
					flexGrow: 1,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{props.block.screens.tabs.map((item, index) =>
					visibilityHelper.isMenuVisible(
						item.visibility,
						props.userInfo,
						props.ghostUser
					) ? (
						<HeaderWebLink
							key={index}
							item={item}
							navigation={props.block.navigation}
							isActive={item.key === props.block.route.name}
						/>
					) : null
				)}
				{props.block.screens.drawers.map((item, index) =>
					visibilityHelper.isMenuVisible(
						item.visibility,
						props.userInfo,
						props.ghostUser
					) ? (
						<HeaderWebLink
							key={index}
							item={item}
							navigation={props.block.navigation}
							isActive={item.key === props.block.route.name}
						/>
					) : null
				)}
			</View>
		</View>
	) : null
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.generatorData,
	...state.userInfo,
	...state.ghostUser,
	...state.windowWidth
})

export default connect(mapStateToProps, null)(HeaderWeb)

const themedStyles = (props, theme, hasBackgroundImage) => {
	return StyleSheet.create({
		mainContainer: {
			backgroundColor: hasBackgroundImage
				? opacity(theme.colors.headerBackground, 0.2)
				: opacity(theme.colors.headerBackground, 0.97),
			borderBottomColor: hasBackgroundImage
				? opacity(theme.colors.line, 0.2)
				: theme.colors.line,
			borderBottomWidth: 1,
			display: 'flex',
			gap: theme.spacing.xl,
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			alignItems: 'center',
			paddingVertical: theme.spacing.xl,
			paddingHorizontal:
				props.windowWidth > 820 &&
				props.windowWidth < 1186 + theme.spacing.xl * 2
					? theme.spacing.xl
					: (props.windowWidth - 1186) / 2
		},
		titleContainer: {
			justifyContent: 'flex-start',
			cursor: 'pointer',
			marginRight: theme.spacing.xl
		},
		title: {
			justifyContent: 'flex-start',
			fontSize: 16,
			color: theme.colors.headerText,
			fontWeight: '600'
		},
		logoContainer: {
			minWidth: 150,
			height: 40
		}
	})
}
