import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import {
	View,
	FlatList,
	ActivityIndicator,
	TouchableOpacity,
	StyleSheet
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import ListItem from './ListItem'
import TextWithFont from './TextWithFont'
import SearchBox from './SearchBox'

import dataClient from '../services/dataClient'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'

const defaultPageSize = 25

const List = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [coordinates, setCoordinates] = useState([])
	const [selectedGroup, setSelectedGroup] = useState(null)
	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [groupedData, setGroupedData] = useState([])
	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)
	const [allShown, setAllShown] = useState(
		componentDetail?.config?.allShown ?? true
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)
	const [fetching, setFetching] = useState(false)
	const [noRecords, setNoRecords] = useState(
		componentDetail?.config?.forceLoad
			? false
			: componentDetail?.config?.noRecords ?? false
	)

	const noRecordsMessage = getLocalizeContent(props.block?.emptyState)
	const pageSize = props.block?.pageSize
		? props.block.pageSize
		: defaultPageSize

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getMoreData = () => {
		if (!allShown) {
			const newPage = page + 1
			setPage(newPage)
		}
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						const filteredOldList = (page === 1 ? [] : listData ?? []).filter(
							(x) => !x?.isEmpty
						)
						const managedListData = [
							...filteredOldList,
							...response.data.records
						]

						setListData(managedListData)

						if (managedListData.length === 0) {
							setNoRecords(true)
						} else {
							setNoRecords(false)

							if (managedListData.length === response.data.total) {
								setAllShown(true)
							} else {
								setAllShown(false)
							}
						}
					} else {
						setAllShown(true)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const groupListData = (listData) => {
		if (listData && listData?.length) {
			const groups = listData.reduce((groups, object) => {
				const group = getMappingValByKey(props, 'groupBy', object.fields)
				if (!groups[group]) {
					groups[group] = []
				}
				groups[group].push(object)
				return groups
			}, {})

			const groupedListData = Object.keys(groups)
				.filter((item) => item !== 'undefined')
				.map((item) => {
					return {
						group: item,
						items: groups[item]
					}
				})

			setGroupedData(groupedListData)
		} else {
			setGroupedData([])
		}
	}

	const footerIndicator = () => {
		return !allShown ? (
			<View style={styles.moreContainer}>
				<TouchableOpacity activeOpacity={0.8} onPress={getMoreData}>
					<TextWithFont style={styles.moreText}>
						{props.block?.moreText
							? getLocalizeContent(props.block.moreText)
							: i18n.t('list.moreText')}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		) : null
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			listData,
			{
				page: page,
				allShown: allShown,
				noRecords: noRecords,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)

		props.block.mapping.groupBy?.field && groupListData(listData)
	}, [listData])

	useEffect(() => {
		selectedGroup && props.scrollTo(coordinates[selectedGroup] ?? 0)
	}, [selectedGroup])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	return listData ? (
		<View key={props.block._uid}>
			<SearchBox
				block={{
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>

			{groupedData?.length > 0 ? (
				<View style={styles.mainContainer}>
					{props.block.groupBy?.showGroupsOnTop && (
						<FlatList
							keyExtractor={(item, index) => String(index)}
							listKey={`group-${props.block._uid}`}
							key={`group-${props.block._uid}`}
							data={groupedData || []}
							horizontal={true}
							showsHorizontalScrollIndicator={false}
							numColumns={1}
							style={{
								paddingTop:
									props.block?.searcheable ||
									props.block?.filterModal?.body?.length > 0
										? 0
										: theme.spacing[props.block.spacing],
								paddingBottom:
									props.block?.searcheable ||
									props.block?.filterModal?.body?.length > 0
										? theme.spacing.sm
										: theme.spacing[props.block.spacing]
							}}
							renderItem={({ item, index, separators }) => (
								<TouchableOpacity
									activeOpacity={0.8}
									key={`group-${props.block._uid}-${item.group}`}
									style={
										selectedGroup === `${props.block._uid}-${item.group}`
											? styles.groupActiveItem
											: styles.groupPassiveItem
									}
									onPress={() => {
										setSelectedGroup(`${props.block._uid}-${item.group}`)
									}}
								>
									<TextWithFont
										style={
											selectedGroup === `${props.block._uid}-${item.group}`
												? styles.groupActiveItemText
												: styles.groupPassiveItemText
										}
										numberOfLines={1}
									>
										{item.group}
									</TextWithFont>
								</TouchableOpacity>
							)}
						/>
					)}

					{groupedData.map((group, groupIndex) => (
						<View
							key={`group-${props.block._uid}-item-${groupIndex}`}
							style={styles.groupContainer}
							onLayout={(event) => {
								const layout = event.nativeEvent.layout

								coordinates[`${props.block._uid}-${group.group}`] =
									layout.top ?? layout.y

								setCoordinates(coordinates)
							}}
						>
							<TextWithFont style={styles.groupContainerTitle}>
								{group.group}
							</TextWithFont>
							<View>
								{group.items.map((item, index) => (
									<ListItem
										key={props.block._uid + groupIndex + index}
										data={item.fields}
										block={{
											total: listData.length,
											navigation: props.block.navigation,
											route: props?.block?.route,
											data: item.fields,
											screenData: props.block?.data,
											id: item.id, //item[props.block.mapping.id.field],
											imgUrl: item.fields[props.block.mapping.imgUrl.field],
											imgVisible: props.block.mapping.imgUrl.field,
											imgPosition: props.block.imgPosition,
											height: props.block.height,
											fontSize: props.block.fontSize,
											spacing: props.block.spacing,
											corner: props.block.corner,
											actions: props.block.actions,
											mapping: props.block.mapping,
											texts: {
												title: getMappingValByKey(props, 'title', item.fields),
												contentText: getMappingValByKey(
													props,
													'text',
													item.fields
												),
												subText: getMappingValByKey(
													props,
													'subText',
													item.fields
												),
												detailText: props.block.detailText
											}
										}}
									/>
								))}
							</View>
						</View>
					))}
				</View>
			) : listData?.length > 0 ? (
				<View style={styles.mainContainer}>
					{listData.map((item, index) => (
						<ListItem
							key={props.block._uid + index}
							data={item.fields}
							block={{
								total: listData.length,
								navigation: props.block.navigation,
								route: props?.block?.route,
								data: item.fields,
								screenData: props.block?.data,
								id: item.id,
								imgUrl: item.fields[props.block.mapping.imgUrl.field],
								imgVisible: props.block.mapping.imgUrl.field,
								imgPosition: props.block.imgPosition,
								height: props.block.height,
								fontSize: props.block.fontSize,
								spacing: props.block.spacing,
								corner: props.block.corner,
								actions: props.block.actions,
								mapping: props.block.mapping,
								texts: {
									title: getMappingValByKey(props, 'title', item.fields),
									contentText: getMappingValByKey(props, 'text', item.fields),
									subText: getMappingValByKey(props, 'subText', item.fields),
									detailText: props.block.detailText
								}
							}}
						/>
					))}
				</View>
			) : null}

			{noRecords && noRecordsMessage && (
				<TextWithFont style={styles.noRecords}>{noRecordsMessage}</TextWithFont>
			)}

			{footerIndicator()}
		</View>
	) : null
	// <View style={[styles.indicatorContainer, styles.indicatorHorizontal]}>
	// 	<ActivityIndicator
	// 		size="small"
	// 		color={theme.colors.accent}
	// 		style={styles.indicator}
	// 	/>
	// </View>
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(List)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			paddingBottom: theme.spacing.sm
		},
		indicatorContainer: {
			flex: 1,
			justifyContent: 'center'
		},
		indicatorHorizontal: {
			flexDirection: 'row',
			justifyContent: 'space-around',
			padding: 10
		},
		indicator: {
			paddingTop: 10,
			paddingBottom: 10
		},
		moreContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: 40,
			minWidth: 130
		},
		moreText: {
			fontSize: theme.fontSizes.sm,
			fontWeight: 'bold',
			color: theme.colors.textLight
		},
		noRecords: {
			flex: 1,
			paddingTop: theme.spacing.xs,
			paddingBottom: theme.spacing.sm,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		},
		groupContainer: {
			marginBottom: theme.spacing[props.block.spacing]
		},
		groupContainerTitle: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.accent,
			fontWeight: '600'
		},
		groupActiveItem: {
			backgroundColor: theme.colors.accent,
			borderColor: 'transparent',
			borderWidth: 1,
			marginRight: theme.spacing.sm,
			marginVertical: 4,
			paddingVertical: 6,
			paddingHorizontal: 6,
			borderRadius: theme.radiuses.sm
		},
		groupActiveItemText: {
			flex: 1,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.layoutBackground
		},
		groupPassiveItem: {
			borderColor: theme.colors.inputText,
			borderWidth: 1,
			marginRight: theme.spacing.sm,
			marginVertical: 4,
			paddingVertical: 6,
			paddingHorizontal: 6,
			borderRadius: theme.radiuses.sm
		},
		groupPassiveItemText: {
			flex: 1,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.text
		}
	})
}
