import linkService from '../../../services/linkService'
// import store from '../../../redux/store/store'

const navigateHome = () => {
	// const generatorData = store.getState()?.generatorData?.generatorData
	// const screens = generatorData?.screens

	// let homePath
	// if (screens.tabs.length) {
	// 	homePath = screens.tabs[0].path
	// } else if (screens.drawers.length) {
	// 	homePath = screens.drawers[0].path
	// } else if (screens.hiddens.length) {
	// 	homePath = screens.hiddens[0].path
	// }

	// linkService.linkTo(`/${homePath}`)
	linkService.linkTo('/')
}

export default navigateHome
