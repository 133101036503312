import Cookies from 'universal-cookie'
import * as SecureStore from 'expo-secure-store'
import { Platform } from 'react-native'

import { setStoreData, deleteStoreData } from '../common/methods/StoreData'

import '../global/variables'
import env from '../../env'

const add = (tokenData) => {
	if (Platform.OS !== 'web') {
		setStoreData(global.TOKEN_KEY, tokenData)
	} else {
		const cookies = new Cookies()

		cookies.set(global.TOKEN_KEY, tokenData.access_token, {
			path: '/',
			maxAge: tokenData.expires_in,
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
			// httpOnly: true
		})
		cookies.set(global.REFRESH_TOKEN_KEY, tokenData.refresh_token, {
			path: '/',
			maxAge: tokenData.expires_in,
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
			// httpOnly: true
		})
	}
}

const remove = () => {
	if (Platform.OS !== 'web') {
		deleteStoreData(global.TOKEN_KEY)
	} else {
		const cookies = new Cookies()

		cookies.remove(global.TOKEN_KEY, {
			path: '/',
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
		})
		cookies.remove(global.REFRESH_TOKEN_KEY, {
			path: '/',
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
		})
	}
}

export default { add, remove }
