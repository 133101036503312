const formatFilterDataByFilter = (filterData = null) => {
	if (!filterData || filterData?.length === 0) return {}

	const formatted = {
		groupOp: 'AND',
		rules: filterData.map((item) => {
			const opExist = !!item.operator

			return {
				type: 'Data',
				field: item.key,
				op:
					(opExist
						? item.operator
						: item.dataType === 'Relation'
						? 'rcn'
						: item.dataType === 'Number' || item.dataType === 'Checkbox'
						? 'eq'
						: item.dataType === 'Date'
						? 'deq'
						: 'cn') ?? 'cn',
				data: item.value
			}
		})
	}

	return formatted
}

export default formatFilterDataByFilter
