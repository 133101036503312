import React, { useState, useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'
import GoogleMapReact from 'google-map-react'

import TextWithFont from './TextWithFont'
import MapDetail from './MapDetail'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'
import calculateScreenHeight from '../common/methods/calculateScreenHeight'

import env from '../../env'

const zoom = {
	'1x': 12,
	'2x': 15,
	'3x': 18
}

const Map = (props) => {
	const theme = useTheme()
	const styles = themedStyles(
		props,
		theme,
		calculateScreenHeight(props.block.screenStyle, props.block.showTabBar)
	)
	const isPreviewApp = env.isPreview

	const markerText = getMappingValByKey(props, 'markerText')
	const geoLocation = getMappingValByKey(props, 'geoLocation')

	const [detailVisible, setDetailVisible] = useState(false)

	const createMapOptions = (maps) => {
		return {
			controlSize:
				props.screenSize === 'lg' ? 32 : props.screenSize === 'md' ? 25 : 24,
			gestureHandling: 'cooperative',
			mapTypeId: props.block.mapType ?? 'roadmap',
			scrollwheel: props.block.scrollwheel ?? true,
			clickableIcons: false,
			zoomControl: props.block.zoomControl ?? true,
			zoomControlOptions: {},
			fullscreenControl: false,
			fullscreenControlOptions: {},
			mapTypeControl: false,
			mapTypeControlOptions: {},
			rotateControl: false,
			rotateControlOptions: {},
			scaleControl: false,
			scaleControlOptions: {},
			keyboardShortcuts: false,
			styles: [
				{
					featureType: 'administrative.land_parcel',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'poi',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'poi.business',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.local',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.local',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'transit',
					stylers: [
						{
							visibility: 'off'
						}
					]
				}
			]
		}
	}
	const [lat, setLat] = useState(null)
	const [lng, setLng] = useState(null)

	const initGeoLocation = () => {
		const geoLocationArray = geoLocation.split(',')
		if (geoLocationArray.length === 2) {
			setLat(Number(geoLocationArray[0]))
			setLng(Number(geoLocationArray[1]))
			setDetailVisible(true)
		}
	}

	useEffect(() => {
		initGeoLocation()
	}, [])

	return (
		<View style={styles.mainContainer}>
			<View style={styles.mainContent}>
				{!!lat && !!lng && (
					<GoogleMapReact
						bootstrapURLKeys={{
							key: props.config.settings.google_api_key || ''
						}}
						defaultCenter={{ lat: lat, lng: lng }}
						defaultZoom={zoom[props.block?.zoom || '2x']}
						options={createMapOptions}
						onClick={() => {
							setDetailVisible(false)
						}}
					>
						<TouchableOpacity
							lat={lat}
							lng={lng}
							activeOpacity={0.8}
							style={
								markerText
									? styles.markerTextContainer
									: styles.markerIconContainer
							}
							onPress={() => {
								setDetailVisible(true)
							}}
						>
							{markerText ? (
								<TextWithFont style={styles.markerText}>
									{markerText}
								</TextWithFont>
							) : (
								<Ionicons
									name={props.block?.markerIcon ?? 'location'}
									size={24}
									style={{
										color: theme.colors.accent
									}}
									lat={lat}
									lng={lng}
								/>
							)}
						</TouchableOpacity>
					</GoogleMapReact>
				)}
			</View>
			{detailVisible && (
				<MapDetail setDetailVisible={setDetailVisible} {...props} />
			)}
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.config
})

export default connect(mapStateToProps)(Map)

const themedStyles = (props, theme, height) => {
	return StyleSheet.create({
		mainContainer:
			props.block.appearance == 'square'
				? {
						position: 'relative',
						paddingTop: props.screenSize === 'lg' ? '71.42%' : '100%',
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: props.block.appearance == 'rectangle'
				? {
						position: 'relative',
						paddingTop: '71.42%', // 7:5
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: {
						position: 'relative',
						width: 'auto',
						height: height,
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  },
		mainContent: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		},
		markerText: {
			width: '100%',
			textTransform: 'none',
			whiteSpace: 'nowrap',
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm,
			textAlign: 'center',
			fontWeight: '600',
			paddingVertical: theme.spacing.xs,
			paddingHorizontal: theme.spacing.sm
		},
		markerTextContainer: {
			position: 'absolute',
			width: 70,
			height: 28,
			left: -70 / 2,
			top: -28 / 2,

			cursor: 'pointer',
			backgroundColor: theme.colors.inputBackground,

			borderRadius: theme.radiuses.xl,
			borderColor: theme.colors.line,
			borderStyle: 'solid',
			borderWidth: 1,

			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		},
		markerIconContainer: {
			position: 'absolute',
			width: 32,
			height: 32,
			left: -32 / 2,
			top: -32 / 2,

			cursor: 'pointer',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,

			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
}
