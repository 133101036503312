import { Linking, Platform } from 'react-native'
import * as LinkingExpo from 'expo-linking'
import * as Sharing from 'expo-sharing'
import { add } from 'date-fns'

import pushNotificationClient from '../../services/pushNotificationClient'
import userClient from '../../services/userClient'
import emailClient from '../../services/emailClient'
import getFormattedValByDatatype from './getFormattedValByDatatype'
import dataClient from '../../services/dataClient'
import authorizationClient from '../../services/authorizationClient'

import formatFormDataByScreenData from './formatFormDataByScreenData'
import extractActionResultFromActions from './extractActionResultFromActions'
import recure from './recure'
import showToast from './showToast'
import nav from './nav'
import store from '../../redux/store/store'
import i18n from '../../i18n/i18n'
import env from '../../../env'

import {
	ADDROUTEDATA,
	REMOVEROUTEDATA
} from '../../redux/action-types/routeDataActionTypes'

const getFieldData = (actions, blockProps, field) => {
	if (!field) return null

	const state = store.getState()
	const userInfo = state.userInfo?.userInfo

	const isForm = field?.startsWith('Form.') ?? false
	const isScreen = field?.startsWith('Screen.') ?? false
	const isAction = field?.startsWith('Action.') ?? false
	const isVariable = field?.startsWith('{{') ?? false

	let data

	if (isForm) {
		if (blockProps.data) {
			data = blockProps.data[field?.replace('Form.', '')]
		}

		if (!data && blockProps.body?.length > 0 && blockProps.body[0].submitData) {
			data = blockProps.body[0].submitData[field?.replace('Form.', '')]
		}
	} else if (isScreen) {
		if (blockProps.data) {
			data = blockProps.data[field?.replace('Screen.', '')]
		}

		if (!data && blockProps.body?.length > 0 && blockProps.body[0].submitData) {
			data = blockProps.body[0].submitData[field?.replace('Screen.', '')]
		}

		if (!data && blockProps.screenData) {
			data = blockProps.screenData[field?.replace('Screen.', '')]
		}
	} else if (isAction) {
		data = extractActionResultFromActions(actions, field)
	} else if (isVariable) {
		if (field === '{{profile.id}}') {
			data = { Id: userInfo?.id }
		} else {
			data = field
		}
	} else {
		data = blockProps?.data?.[field]
	}

	return data
}

const getWhen = (actions, actionObj, blockProps) => {
	if (actionObj?.when?.type === 'field' && actionObj?.when?.field) {
		let fieldData = getFieldData(actions, blockProps, actionObj.when.field)

		if (
			fieldData &&
			actionObj?.when?.before?.unit &&
			actionObj?.when?.before?.value
		) {
			const date = new Date(fieldData) || new Date().toUTCString()
			const interval = parseInt(actionObj.when.before.value) || 0

			let newDate = date

			switch (actionObj.when.before.unit) {
				case 'minute':
					newDate = add(newDate, {
						minutes: -1 * interval
					})
					break
				case 'hour':
					newDate = add(newDate, {
						hours: -1 * interval
					})
					break
				case 'day':
					newDate = add(newDate, {
						days: -1 * interval
					})
					break

				default:
					break
			}

			return newDate
		}
	}

	return new Date().toUTCString()
}

const getActionMappingValByKey = (actionObj, blockProps, key) => {
	return blockProps.data?.[actionObj?.mapping?.[key]?.field]
		? blockProps.data[actionObj.mapping[key].field]
		: actionObj?.mapping?.[key]?.default
}

const parseTemplate = (actions, actionObj, blockProps, key) => {
	const state = store.getState()
	const userInfo = state.userInfo?.userInfo

	let template = actionObj?.mapping?.[key]

	template?.replace(/\[(.*?)\]/g, (tagText) => {
		const tagArr = JSON.parse(tagText)
		if (Array.isArray(tagArr) && tagArr.length > 0) {
			const tag = tagArr[0]
			if (tag) {
				const field = tag.name ?? tag.value
				const data = getFieldData(actions, blockProps, field)

				template = template.replace(
					tagText,
					getFormattedValByDatatype(tag.dataType ?? 'Text', data, userInfo) ??
						''
				)
			}
		}
	})

	return template
}

const notify = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	showToast(
		'success',
		parseTemplate(actions, actionObj, blockProps, 'title'),
		parseTemplate(actions, actionObj, blockProps, 'message')
	)

	return await Promise.resolve()
}

const sendInvite = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	if (!blockProps.data.Email) return

	const message = parseTemplate(actions, actionObj, blockProps, 'message')

	return await userClient.invite({
		Data: blockProps.data,
		Message: message
	})
}

const signOut = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	return env.isPreview
		? await Promise.resolve()
		: await authorizationClient.logout()
}

const sendPush = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	if (blockProps?.payload) {
		if (Array.isArray(blockProps.payload)) {
			blockProps.payload.map(async (item, index) => {
				const copiedBlockProps = {
					...blockProps,
					data: item,
					payload: item
				}

				await sendPushItem(actions, actionObj, copiedBlockProps, index)
			})

			return await Promise.resolve()
		} else {
			return await sendPushItem(actions, actionObj, blockProps)
		}
	} else {
		return await sendPushItem(actions, actionObj, blockProps)
	}
}

const sendPushItem = async (actions, actionObj, blockProps, index = null) => {
	const when = getWhen(actions, actionObj, blockProps)
	const title = parseTemplate(actions, actionObj, blockProps, 'title')
	const body = parseTemplate(actions, actionObj, blockProps, 'message')
	const correlationIdData = getFieldData(
		actions,
		blockProps,
		actionObj.correlation?.correlationId
	)

	const correlation = {
		correlationId: Array.isArray(correlationIdData)
			? correlationIdData[index ?? 0]?.Id
			: correlationIdData?.constructor.name === 'Object'
			? correlationIdData.Id
			: correlationIdData ?? null,
		category: actionObj.correlation?.category,
		clean: actionObj.correlation?.clean
	}

	switch (actionObj.audiences.sendTo) {
		case 'everyone': {
			const notification = {
				message: {
					title: title,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await pushNotificationClient.send(notification)
		}
		case 'specific': {
			const audiences = actionObj.audiences.specific?.map((x) => ({
				userId: x.Id
			}))

			if (!audiences) return

			const notification = {
				audiences: audiences,
				message: {
					title: title,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await pushNotificationClient.send(notification)
		}
		case 'field': {
			const data = getFieldData(actions, blockProps, actionObj.audiences.field)

			const audiences = Array.isArray(data)
				? data.map((val) => ({
						userId: val.Id
				  }))
				: [{ userId: data.Id }]

			if (audiences.length === 0) return

			const notification = {
				audiences: audiences,
				message: {
					title: title,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await pushNotificationClient.send(notification)
		}
		default:
			console.log('NO_AUDIENCES_FOR_PUSH_NOTIFICATON')
			break
	}
}

const sendEmail = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	if (blockProps?.payload) {
		if (Array.isArray(blockProps.payload)) {
			blockProps.payload.map(async (item, index) => {
				const copiedBlockProps = {
					...blockProps,
					data: item,
					payload: item
				}

				await sendEmailItem(actions, actionObj, copiedBlockProps, index)
			})

			return await Promise.resolve()
		} else {
			return await sendEmailItem(actions, actionObj, blockProps)
		}
	} else {
		return await sendEmailItem(actions, actionObj, blockProps)
	}
}

const sendEmailItem = async (actions, actionObj, blockProps, index = null) => {
	const when = getWhen(actions, actionObj, blockProps)
	const subject = parseTemplate(actions, actionObj, blockProps, 'subject')
	const body = parseTemplate(
		actions,
		actionObj,
		blockProps,
		'body'
	)?.replaceAll('\n', '<br>')
	const correlationIdData = getFieldData(
		actions,
		blockProps,
		actionObj.correlation?.correlationId
	)

	const correlation = {
		correlationId: Array.isArray(correlationIdData)
			? correlationIdData[index ?? 0]?.Id
			: correlationIdData?.constructor.name === 'Object'
			? correlationIdData.Id
			: correlationIdData ?? null,
		category: actionObj.correlation?.category,
		clean: actionObj.correlation?.clean
	}

	switch (actionObj.audiences.sendTo) {
		case 'everyone': {
			const notification = {
				message: {
					subject: subject,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await emailClient.send(notification)
		}
		case 'specific': {
			const audiences = actionObj.audiences.specific?.map((x) => ({
				userId: x.Id
			}))

			if (!audiences) return

			const notification = {
				audiences: audiences,
				message: {
					subject: subject,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await emailClient.send(notification)
		}
		case 'field': {
			const data = getFieldData(actions, blockProps, actionObj.audiences.field)

			const audiences = Array.isArray(data)
				? data.map((val) => ({
						userId: val.Id
				  }))
				: [{ userId: data.Id }]

			if (audiences.length === 0) return

			const notification = {
				audiences: audiences,
				message: {
					subject: subject,
					body: body
				},
				when: when,
				correlation: correlation
			}

			return await emailClient.send(notification)
		}
		default:
			console.log('NO_AUDIENCES_FOR_PUSH_NOTIFICATON')
			break
	}
}

const createRow = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	const state = store.getState()
	const userInfo = state?.userInfo?.userInfo
	const source = actionObj?.source || blockProps.source

	const itemData =
		blockProps?.component !== 'form' && blockProps?.screenData !== undefined
			? blockProps?.data
			: null
	const formData = blockProps?.component === 'form' ? blockProps?.data : null
	const screenData =
		blockProps?.screenData === undefined
			? blockProps?.data
			: blockProps?.screenData

	let request = {}

	if (actionObj?.fields) {
		actionObj?.fields
			?.filter((item) => item.value)
			?.filter(
				(item) =>
					item.value.startsWith('Form.')
						? formData?.[item.value.replace('Form.', '')] !== undefined
						: true
				// item.value.startsWith('Form.')
				// 	? formData?.[item.field] !== undefined
				// 	: true
			)
			?.map((item) => {
				request[item.field] = formatFormDataByScreenData(
					item,
					itemData,
					formData,
					screenData,
					null,
					userInfo,
					actions
				)
			})
	} else if (blockProps?.component === 'form') {
		request = formData
	}

	if (
		blockProps.recurrence &&
		blockProps.recurrence.field &&
		blockProps.recurrence.every !== 'none'
	) {
		const date = request?.[blockProps.recurrence.field]
		if (date) {
			const recurring = recure(
				date,
				blockProps.recurrence.ends,
				blockProps.recurrence.every,
				blockProps.recurrence.times
					? parseInt(blockProps.recurrence.times)
					: null
			)

			let bulkData = [request]

			recurring?.map((date) => {
				const newItem = { ...request, [`${blockProps.recurrence.field}`]: date }
				bulkData.push(newItem)
			})

			blockProps.payload = bulkData

			return await dataClient
				.postData(`/${source}/bulk`, {}, bulkData)
				.then((response) => {
					if (response?.data?.some((x) => !x.success)) {
						showToast(
							'error',
							response?.data?.[0]?.message ||
								response?.data?.[0]?.errorDetails ||
								i18n.t('api.error')
						)
					}

					return response?.data
				})
		}
	}

	if (blockProps.repeat && blockProps.repeat.field) {
		const items = request?.[blockProps.repeat.field] ?? []
		if (items?.length > 0) {
			let bulkData = items.map((item) => ({
				...request,
				[`${blockProps.repeat.field}`]: [item]
			}))

			bulkData.map((item) => {
				actionObj?.fields?.map((x) => {
					item[x.field] = formatFormDataByScreenData(
						x,
						itemData,
						item,
						screenData,
						null,
						userInfo,
						actions
					)
				})

				return item
			})

			blockProps.payload = bulkData

			return await dataClient
				.postData(`/${source}/bulk`, {}, bulkData)
				.then((response) => {
					if (response?.data?.some((x) => !x.success)) {
						showToast(
							'error',
							response?.data?.[0]?.message ||
								response?.data?.[0]?.errorDetails ||
								i18n.t('api.error')
						)
					}

					return response?.data
				})
		}
	}

	blockProps.payload = request

	if (
		blockProps.source === source &&
		blockProps.body?.length > 0 &&
		blockProps.body[0].submitData
	) {
		blockProps.body = blockProps.body.map((item) => {
			return { ...item, submitData: request }
		})
	}

	return await dataClient
		.postData(`/${source}`, {}, request)
		.then((response) => {
			if (!response?.data?.success) {
				showToast(
					'error',
					response?.data?.message ||
						response?.data?.errorDetails ||
						i18n.t('api.error')
				)
			}

			return response?.data
		})
}

const updateRow = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	const state = store.getState()
	const userInfo = state?.userInfo?.userInfo
	const source = actionObj?.source || blockProps.source
	const id = blockProps.id || targetRouteParamsId

	const itemData =
		blockProps?.component !== 'form' && blockProps?.screenData !== undefined
			? blockProps?.data
			: null
	const formData = blockProps?.component === 'form' ? blockProps?.data : null
	const screenData =
		blockProps?.screenData === undefined
			? blockProps?.data
			: blockProps?.screenData

	let request = {}

	if (actionObj?.fields) {
		actionObj?.fields
			?.filter((item) => item.value)
			?.filter(
				(item) =>
					item.value.startsWith('Form.')
						? formData?.[item.value.replace('Form.', '')] !== undefined
						: true
				// item.value.startsWith('Form.')
				// 	? formData?.[item.field] !== undefined
				// 	: true
			)
			?.map((item) => {
				request[item.field] = formatFormDataByScreenData(
					item,
					itemData,
					formData,
					screenData,
					null,
					userInfo,
					actions
				)
			})
	} else if (blockProps?.component === 'form') {
		request = formData
	}

	request.Id = id

	blockProps.payload = request

	if (
		blockProps.source === source &&
		blockProps.body?.length > 0 &&
		blockProps.body[0].submitData
	) {
		blockProps.body = blockProps.body.map((item) => {
			return { ...item, submitData: request }
		})
	}

	return await dataClient
		.patchData(`/${source}`, {}, request)
		.then((response) => {
			if (!response?.data?.success) {
				showToast(
					'error',
					response?.data?.message ||
						response?.data?.errorDetails ||
						i18n.t('api.error')
				)
			}

			return response?.data
		})
}

const deleteRow = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	const source = actionObj?.source || blockProps.source
	const id = blockProps.id || targetRouteParamsId

	return await dataClient
		.deleteData(`/${source}/${id}`, {})
		.then((response) => {
			if (!response?.data?.success) {
				showToast(
					'error',
					response?.data?.message ||
						response?.data?.errorDetails ||
						i18n.t('api.error')
				)
			}

			return response?.data
		})
}

const navigate = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	if (actionObj.screenKey) {
		const state = store.getState()
		const generatorData = state?.generatorData?.generatorData
		const isPath = actionObj.screenKey.includes('/')

		let target

		if (isPath) {
			target = actionObj.screenKey
		} else {
			target = `/${
				generatorData?.linking?.config?.screens?.[actionObj.screenKey]
			}`
		}

		const screenPaths = Object.values(
			generatorData?.linking?.config?.screens || {}
		)
		const screenExist = screenPaths.includes(target.substring(1))

		if (screenExist) {
			const targetHasId = target?.indexOf('/:id') >= 0
			const newTarget = targetHasId
				? target.replace(':id', targetRouteParamsId)
				: target

			if (
				targetHasId &&
				targetRouteParamsId &&
				targetRouteParamsId === blockProps.data?.Id
			) {
				store.dispatch({
					type: ADDROUTEDATA,
					name: actionObj.screenKey,
					data: blockProps.data
				})
			}

			const targetOnTab =
				generatorData?.screens?.tabs?.some((s) => {
					return s.path === target?.substring(1) && s.style === 'screen'
				}) ?? false

			if (
				actions?.some((x) => {
					return x.type === 'back'
				})
			) {
				setTimeout(() => {
					nav.navigateTo(newTarget, !targetOnTab)
				}, 100)
			} else {
				nav.navigateTo(newTarget, !targetOnTab)
			}
		} else {
			showToast(
				'error',
				'Screen Not Found' //TODO:Localization
			)
		}
	} else {
		console.log('CANT_NAVIGATE')
	}

	return await Promise.resolve()
}

const navigateWithParam = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	if (!actionObj.screenParam) {
		return await navigate(actions, actionObj, blockProps, targetRouteParamsId)
	}

	const data = getFieldData(actions, blockProps, actionObj.screenParam)

	if (data) {
		const targetId = Array.isArray(data)
			? data[0]?.Id
			: data?.constructor.name === 'Object'
			? data.Id
			: data

		return await navigate(actions, actionObj, blockProps, targetId)
	} else {
		if (!actionObj.emptyScreenKey) return await Promise.resolve()

		const emptyData =
			actionObj.emptyScreenParam &&
			getFieldData(actions, blockProps, actionObj.emptyScreenParam)

		const copyOfActionObj = {
			...actionObj,
			screenKey: actionObj.emptyScreenKey
		}

		if (emptyData) {
			const targetId = Array.isArray(emptyData)
				? emptyData[0]?.Id
				: emptyData?.constructor.name === 'Object'
				? emptyData.Id
				: emptyData

			return await navigate(actions, copyOfActionObj, blockProps, targetId)
		} else {
			return await navigate(actions, copyOfActionObj, blockProps, null)
		}
	}
}

const openLink = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	const url = getActionMappingValByKey(actionObj, blockProps, 'url')

	if (Platform.OS == 'web') {
		window.open(url, '_blank')
	} else {
		Linking.canOpenURL(url).then((supported) => {
			//NOTE: https://reactnative.dev/docs/linking#canopenurl
			if (supported) {
				Linking.openURL(url)
			} else {
				console.log('CANT_OPEN_LINK', url)
			}
		})
	}

	return await Promise.resolve()
}

const shareLink = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	Sharing.isAvailableAsync().then((available) => {
		if (available) {
			LinkingExpo.getInitialURL().then((url) => {
				Sharing.shareAsync(url).catch((err) => {
					console.log('An error occurred during sharing: ', JSON.stringify(err))
				})
			})
		} else {
			console.log('Sharing is NOT available')
		}
	})

	return await Promise.resolve()
}

const goBack = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	nav.navigateBack()

	return await Promise.resolve()
}

const openMenu = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	blockProps.navigation.openDrawer()

	return await Promise.resolve()
}

const actionByActionObj = async (
	actions,
	actionObj,
	blockProps,
	targetRouteParamsId = null
) => {
	switch (actionObj?.type) {
		case 'notify':
			// "action": {
			// "type": "notify",
			// "mapping": {
			// 	"title": "",
			// 	"message": "",
			// 	}
			// }
			return await notify(actions, actionObj, blockProps, targetRouteParamsId)
		case 'invite':
			// "action": {
			// "type": "invite",
			// "mapping": {
			// 	"message": "",
			// 	}
			// }
			return await sendInvite(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		case 'signOut':
			// "action": {
			// "type": "signOut",
			// }
			return await signOut(actions, actionObj, blockProps, targetRouteParamsId)
		case 'push':
			// "action": {
			// "type": "push",
			// "audiences": {
			// 	"sendTo": "field",
			// 	"field": "Screen.CreatedBy",
			// 	"specific": null,
			// 	"filter": null
			// },
			// "mapping": {
			// 	"title": "",
			// 	"message": "",
			// 	}
			// }
			return await sendPush(actions, actionObj, blockProps, targetRouteParamsId)
		case 'email':
			// "action": {
			// "type": "email",
			// "audiences": {
			// 	"sendTo": "field",
			// 	"field": "Screen.CreatedBy",
			// 	"specific": null,
			// 	"filter": null
			// },
			// "mapping": {
			// 	"subject": "",
			// 	"body": "",
			// 	}
			// }
			return await sendEmail(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		case 'back':
			// "action": {
			// 	"type": "back"
			// }
			return await goBack(actions, actionObj, blockProps, targetRouteParamsId)
		case 'navigate':
			// "action": {
			// 	"type": "navigate",
			// 	"screenKey": "a778ac9c-f5ad-4708-ab77-b3b370946499"
			// }
			return await navigateWithParam(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)

		case 'navigateTab':
			// "action": {
			// 	"type": "navigateTab",
			// 	"screenKey": "a778ac9c-f5ad-4708-ab77-b3b370946499"
			// }
			return await navigate(actions, actionObj, blockProps, targetRouteParamsId)
		case 'openLink':
			// "action": {
			// 	"type": "openLink",
			// 	"mapping": {
			// 		"url": {
			// 			"default": "https://kozmik.io/",
			// 			"field": null
			// 			}
			// 		}
			// }
			return await openLink(actions, actionObj, blockProps, targetRouteParamsId)
		case 'shareLink':
			// "action": {
			// 	"type": "shareLink"
			// }
			return await shareLink(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		case 'drawer':
			// "action": {
			// 	"type": "drawer"
			// }
			return await openMenu(actions, actionObj, blockProps, targetRouteParamsId)
		case 'create':
			// "action": {
			// 	"type": "create",
			// }
			return await createRow(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		case 'update':
			// "action": {
			// 	"type": "update",
			// }
			return await updateRow(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		case 'delete':
			// "action": {
			// 	"type": "delete",
			// }
			return await deleteRow(
				actions,
				actionObj,
				blockProps,
				targetRouteParamsId
			)
		default:
			break
	}
}

const triggerActionList = (blockProps, actions, targetRouteParamsId = null) => {
	if (actions?.length > 0) {
		return actions
			.reduce((prev, action, index) => {
				return prev.then((prevResult) => {
					if (index > 0) {
						actions[index - 1]['result'] = prevResult
					}

					return actionByActionObj(
						actions,
						action,
						blockProps,
						targetRouteParamsId
					)
				})
			}, Promise.resolve())
			.catch((err) => {
				return Promise.reject(err)
			})
	} else {
		return Promise.resolve()
	}
}

export default triggerActionList
