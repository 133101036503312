import getFormattedValByDatatype from './getFormattedValByDatatype'
import getLocalizeContent from './getLocalizeContent'

const getMappingValByKey = (
	elementProps,
	key,
	data = null,
	userInfo = null
) => {
	const currentData = data ? data : elementProps.block.data

	if (elementProps.block?.mapping?.[key]?.type === 'Variables') {
		return getFormattedValByDatatype(
			elementProps.block.mapping[key].type,
			elementProps.block.mapping[key].field,
			userInfo
		)
	} else if (currentData?.[elementProps.block.mapping?.[key]?.field]) {
		const extractedData = currentData[elementProps.block.mapping[key].field]

		if (Array.isArray(extractedData)) {
			if (extractedData.length > 0) {
				if (typeof extractedData[0] === 'object') {
					return extractedData
						.map((item) => item?.Name ?? item?.Id ?? '')
						?.join(', ')
				} else {
					return extractedData
				}
			}

			return ''
		} else if (typeof extractedData === 'object') {
			return getFormattedValByDatatype(
				elementProps.block.mapping[key]?.type,
				extractedData?.Name ?? extractedData?.Id ?? ''
			)
		}

		return getFormattedValByDatatype(
			elementProps.block.mapping[key]?.type,
			extractedData
		)
	} else {
		let template = getLocalizeContent(elementProps.block.mapping?.[key])

		if (typeof template === 'string') {
			template?.replace(/\[(.*?)\]/g, (tagText) => {
				const tagArr = JSON.parse(tagText)
				if (Array.isArray(tagArr) && tagArr.length > 0) {
					const tag = tagArr[0]
					if (tag) {
						const dataType = tag.dataType ?? 'Variables'
						const field = tag.name ?? tag.value

						let data

						if (dataType === 'Variables') {
							data = field
						} else {
							data = currentData?.[field]
						}

						template = template.replace(
							tagText,
							getFormattedValByDatatype(dataType, data, userInfo) ?? ''
						)
					}
				}
			})
		}

		return template
	}
}

export default getMappingValByKey
