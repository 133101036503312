import React from 'react'
import { Linking, Platform } from 'react-native'
import qs from 'qs'

import CummunicationButton from './CummunicationButton'
import IconButton from './IconButton'

import getMappingValByKey from '../common/methods/getMappingValByKey'

const EmailButton = (props) => {
	const parameterKeys = ['cc', 'bcc', 'subject', 'body']
	const clickHandler = () => {
		let parameterValues = {}
		parameterKeys.map((item) => {
			const parameterValue = getMappingValByKey(props, item)
			if (parameterValue) {
				parameterValues[item] = parameterValue
			}
		})
		if (Platform.OS == 'ios') {
			Linking.openURL(
				`mailto:${getMappingValByKey(props, 'address') ?? ''}?${qs
					.stringify(parameterValues)
					.replace(/&/g, '?')}`
			)
		} else {
			Linking.openURL(
				`mailto:${getMappingValByKey(props, 'address') ?? ''}?${qs.stringify(
					parameterValues
				)}`
			)
		}
	}

	return (
		<CummunicationButton
			title={getMappingValByKey(props, 'title') ?? ''}
			text={getMappingValByKey(props, 'address') ?? ''}
			spacing={props.block.spacing}
			fontSize={props.block.fontSize}
		>
			<IconButton clickHandler={clickHandler} icon={'email'} />
		</CummunicationButton>
	)
}

export default EmailButton
