import React from 'react'
import {
	TouchableOpacity,
	StyleSheet,
	View,
	ActivityIndicator
} from 'react-native'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

const LoginButton = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<TouchableOpacity activeOpacity={0.8} style={styles.button} {...props}>
			{!!props?.text && (
				<TextWithFont style={styles.text} numberOfLines={1}>
					{props.text}
				</TextWithFont>
			)}
			{!!props?.loader && (
				<ActivityIndicator
					size="small"
					color="#ffffff"
					style={styles.indicator}
				/>
			)}
		</TouchableOpacity>
	)
}

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		button: {
			borderRadius: theme.radiuses.sm,
			backgroundColor: props?.transparent
				? 'transparent'
				: props?.bgColor
				? props.bgColor
				: theme.colors.accent,
			borderWidth: 0,
			opacity: props?.loader ? 0.6 : 1,
			// height: 40,
			justifyContent: 'center',
			alignItems: 'center',
			flex: 1,
			flexDirection: 'row',
			padding: 5
		},
		text: {
			color: props?.textColor
				? props.textColor
				: props?.transparent
				? theme.colors.accent
				: '#ffffff',
			fontSize: theme.fontSizes.md,
			fontWeight: '600'
		},
		indicator: {
			paddingLeft: 10
		}
	})
}

export default LoginButton
