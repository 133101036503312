import getFormattedValByDatatype from './getFormattedValByDatatype'

const getColumnValByKey = (column, data = null) => {
	if (data?.[column?.field]) {
		const extractedData = data[column.field]

		if (Array.isArray(extractedData)) {
			if (extractedData.length > 0) {
				if (typeof extractedData[0] === 'object') {
					return extractedData.map((item) => item?.Name ?? '')
				} else {
					return extractedData
				}
			}
		} else if (typeof extractedData === 'object') {
			return getFormattedValByDatatype(column?.type, extractedData?.Name ?? '')
		}

		return getFormattedValByDatatype(column?.type, extractedData)
	}

	return null
}

export default getColumnValByKey
