import React, { forwardRef } from 'react'

import TextEntry from './TextEntry'

const InputText = forwardRef((props, ref) => {
	return (
		<TextEntry
			ref={ref}
			// mask={}
			// maxLength={}
			// minLength={}
			multiline={props.block?.mapping?.type === 'LongText'}
			isRegExString={true}
			{...props.block}
		/>
	)
})

export default InputText
