import i18n from 'i18n-js'

import getLanguageCode from '../common/methods/getLanguageCode'

import tr from './tr'
import en from './en'

i18n.locale = getLanguageCode()

i18n.locales.no = 'en'
i18n.translations = {
	'en-US': en,
	'tr-TR': tr
}

export default i18n
