import Toast from 'react-native-toast-message'

//types:'success','error'
const showToast = (type, title = null, text = null) => {
	Toast.show({
		type: type,
		text1: title,
		text2: text,
		visibilityTime: 3000
	})
}

export default showToast
