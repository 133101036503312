import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useRef
} from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { Ionicons } from '@expo/vector-icons'
import GoogleMapReact from 'google-map-react'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import MapDetail from './MapDetail'
import TextWithFont from './TextWithFont'
import SearchBox from './SearchBox'

import dataClient from '../services/dataClient'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import triggerActionList from '../common/methods/triggerActionList'
import calculateScreenHeight from '../common/methods/calculateScreenHeight'
import env from '../../env'

const zoom = {
	'1x': 12,
	'2x': 15,
	'3x': 18
}

const defaultPageSize = 25

const MapList = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(
		props,
		theme,
		calculateScreenHeight(props.block.screenStyle, props.block.showTabBar)
	)
	const isPreviewApp = env.isPreview

	const [itemData, setItemData] = useState(null)
	const [detailVisible, setDetailVisible] = useState(false)
	const [defaultCenter, setDefaultCenter] = useState(null)

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const pageSize = props.block?.pageSize
		? props.block.pageSize
		: defaultPageSize

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)
	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)

	const [allShown, setAllShown] = useState(
		componentDetail?.config?.allShown ?? true
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)
	const [fetching, setFetching] = useState(false)
	const [noRecords, setNoRecords] = useState(
		componentDetail?.config?.noRecords ?? false
	)

	const noRecordsMessage = getLocalizeContent(props.block?.emptyState)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data
				//null,
				//props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getMoreData = () => {
		if (!allShown) {
			const newPage = page + 1
			setPage(newPage)
		}
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						const filteredOldList = (page === 1 ? [] : listData ?? []).filter(
							(x) => !x?.isEmpty
						)
						const managedListData = [
							...filteredOldList,
							...response.data.records
						]

						if (managedListData.length === 0) {
							setNoRecords(true)
						} else {
							setNoRecords(false)

							if (managedListData.length === response.data.total) {
								setAllShown(true)
							} else {
								setAllShown(false)
							}
						}

						setListData(managedListData)
					} else {
						setAllShown(true)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const createMapOptions = (maps) => {
		return {
			controlSize:
				props.screenSize === 'lg' ? 32 : props.screenSize === 'md' ? 25 : 24,
			gestureHandling: 'cooperative',
			mapTypeId: props.block.mapType ?? 'roadmap',
			scrollwheel: props.block.scrollwheel ?? true,
			clickableIcons: false,
			zoomControl: props.block.zoomControl ?? true,
			zoomControlOptions: {},
			fullscreenControl: false,
			fullscreenControlOptions: {},
			mapTypeControl: false,
			mapTypeControlOptions: {},
			rotateControl: false,
			rotateControlOptions: {},
			scaleControl: false,
			scaleControlOptions: {},
			keyboardShortcuts: false,
			styles: [
				{
					featureType: 'administrative.land_parcel',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'poi',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'poi.business',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.local',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road.local',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'transit',
					stylers: [
						{
							visibility: 'off'
						}
					]
				}
			]
		}
	}

	const extractLat = (location) => {
		if (!location) return null

		const locationArray = location.split(',')
		if (locationArray.length === 2) {
			return Number(locationArray[0])
		}
	}

	const extractLng = (location) => {
		if (!location) return null

		const locationArray = location.split(',')
		if (locationArray.length === 2) {
			return Number(locationArray[1])
		}
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			listData,
			{
				page: page,
				allShown: allShown,
				noRecords: noRecords,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)

		if (listData?.length > 0) {
			const item = listData[0]
			const location = getMappingValByKey(props, 'geoLocation', item.fields)

			setDefaultCenter({
				lat: extractLat(location),
				lng: extractLng(location)
			})
		}
	}, [listData])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	return defaultCenter ? (
		<View key={props.block._uid} style={styles.container}>
			<View style={styles.innerContainer}>
				<SearchBox
					block={{
						title: props.block.title,
						route: props?.block?.route,
						spacing: 'xl',
						searcheable: props.block?.searcheable,
						search: search,
						searchTerm: searchTerm,
						filter: filter,
						filterData: filterData,
						filterModal: props.block?.filterModal,
						searchPlaceholder: getLocalizeContent(
							props.block?.searchPlaceholder
						)
					}}
				/>

				{/* {noRecords ? (
					noRecordsMessage && (
						<TextWithFont style={styles.noRecords}>
							{noRecordsMessage}
						</TextWithFont>
					)
				) : (
				)} */}

				<GoogleMapReact
					bootstrapURLKeys={{
						key: props.config.settings.google_api_key || ''
					}}
					defaultCenter={defaultCenter}
					defaultZoom={zoom[props.block?.zoom || '2x']}
					options={createMapOptions}
					onClick={() => {
						setDetailVisible(false)
						setItemData(null)
					}}
				>
					{listData.map((item, index) => {
						const location = getMappingValByKey(
							props,
							'geoLocation',
							item.fields
						)
						const itemDatalocation = getMappingValByKey(
							props,
							'geoLocation',
							itemData
						)
						const lat = extractLat(location)
						const lng = extractLng(location)
						const markerText = getMappingValByKey(
							props,
							'markerText',
							item.fields
						)

						return (
							<TouchableOpacity
								key={index}
								lat={lat}
								lng={lng}
								activeOpacity={0.8}
								style={
									location === itemDatalocation && markerText
										? {
												...styles.markerTextContainer,
												...styles.activeMarkerTextContainer
										  }
										: markerText
										? styles.markerTextContainer
										: styles.markerIconContainer
								}
								onPress={() => {
									setDetailVisible(true)
									setItemData(item.fields)
								}}
							>
								{markerText ? (
									<TextWithFont style={styles.markerText}>
										{markerText}
									</TextWithFont>
								) : (
									<Ionicons
										name={props.block?.markerIcon ?? 'location'}
										size={24}
										style={{
											color: theme.colors.accent
										}}
										lat={lat}
										lng={lng}
									/>
								)}
							</TouchableOpacity>
						)
					})}
				</GoogleMapReact>

				{detailVisible && (
					<MapDetail
						setDetailVisible={setDetailVisible}
						{...props}
						block={{
							...props.block,
							data: itemData,
							id: getMappingValByKey(props, 'id', itemData)
						}}
					/>
				)}
			</View>
		</View>
	) : null
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components,
	...state.config
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(MapList)

const themedStyles = (props, theme, height) => {
	return StyleSheet.create({
		container:
			props.block.appearance == 'square'
				? {
						position: 'relative',
						paddingTop: props.screenSize === 'lg' ? '71.42%' : '100%',
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: props.block.appearance == 'rectangle'
				? {
						position: 'relative',
						paddingTop: '71.42%', // 7:5
						width: 'auto',
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  }
				: {
						position: 'relative',
						width: 'auto',
						height: height,
						marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
				  },
		innerContainer: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'flex-start',
			alignItems: 'stretch',
			overflow: 'hidden'
		},
		markerText: {
			width: '100%',
			textTransform: 'none',
			whiteSpace: 'nowrap',
			color: theme.colors.text,
			fontSize: theme.fontSizes.sm,
			textAlign: 'center',
			fontWeight: '600',
			paddingVertical: theme.spacing.xs,
			paddingHorizontal: theme.spacing.sm
		},
		activeMarkerTextContainer: {
			backgroundColor: theme.colors.accent,
			borderWidth: 0
		},
		markerTextContainer: {
			position: 'absolute',
			width: 70,
			height: 28,
			left: -70 / 2,
			top: -28 / 2,

			cursor: 'pointer',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.xl,

			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		},
		markerIconContainer: {
			position: 'absolute',
			width: 32,
			height: 32,
			left: -32 / 2,
			top: -32 / 2,

			cursor: 'pointer',
			backgroundColor: theme.colors.inputBackground,
			borderRadius: theme.radiuses.sm,

			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
}
