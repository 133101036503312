import linkService from '../../../services/linkService'
import routeService from '../../../services/routeService'

import store from '../../../redux/store/store'

const navigateTo = (target, appendToOldPath = true) => {
	let editedTarget

	if (appendToOldPath) {
		let path = routeService.route.path

		if (!path) {
			const generatorData = store.getState()?.generatorData?.generatorData
			const parentPath =
				generatorData.linking.config?.screens?.[routeService.route?.name]

			path = `/${parentPath}`
		}

		const createdNavigatelink = `${path}${target}`

		editedTarget = createdNavigatelink
	} else {
		editedTarget = target
	}

	linkService.linkTo(editedTarget)
}

export default navigateTo
