import React, { forwardRef } from 'react'

import BasePicker from './BasePicker'

const FilePicker = forwardRef((props, ref) => {
	return (
		<BasePicker
			ref={ref}
			icon={'attachment'}
			mimeTypes={[
				'image/jpg',
				'image/jpeg',
				'image/png',
				'image/gif',
				'application/pdf',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx
				'application/vnd.ms-excel', //.xls
				'text/csv',
				'text/plain' //.txt
			]}
			{...props.block}
		/>
	)
})

export default FilePicker
