import { SETSCREENWIDTH } from '../action-types/screenWidthActionTypes'

const initialState = {
	screenWidth: 340
}

const screenWidthReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETSCREENWIDTH:
			return { ...state, screenWidth: action.payload }

		default:
			return state
	}
}

export default screenWidthReducer
