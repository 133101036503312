import React, { useState, useEffect } from 'react'
import { TouchableOpacity, Image, View, StyleSheet } from 'react-native'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'
import SmartTextView from './SmartTextView'

import triggerActionList from '../common/methods/triggerActionList'

const Tile = (props) => {
	const theme = useTheme()

	const styles = themedStyles(props, theme)

	const conditionalImage = props?.imgUrl ? (
		<Image
			resizeMode={props.imageResizeMode}
			style={styles.conditionalImage}
			source={{
				uri: props.imgUrl
			}}
		/>
	) : (
		<View style={styles.conditionalMark}>
			{!props.square && (
				<TextWithFont style={styles.conditionalMarkText}>
					{props?.title?.[0]}
				</TextWithFont>
			)}
		</View>
	)

	return (
		<View
			style={
				props.horizontal
					? {
							width:
								props.size === 'xs'
									? 75
									: props.size === 'sm'
									? 100
									: props.size === 'md'
									? 125
									: props.size === 'lg'
									? 150
									: 75
					  }
					: props.index % props.numColumns == 0
					? { flex: 1 }
					: { flex: 1, paddingLeft: props.spacing }
			}
		>
			{!props.isEmpty && (
				<TouchableOpacity
					activeOpacity={0.8}
					disabled={props.block.actions?.[0]?.type == null ? true : false}
					onPress={() => {
						triggerActionList(props.block, props.block.actions, props.block.id)
					}}
				>
					<View style={styles.aspectRatioContainer}>
						<View style={styles.aspectRatioContent}>{conditionalImage}</View>
					</View>

					<View
						style={
							props.square
								? styles.textContainer
								: { marginVertical: 5, fontWeight: '600' }
						}
					>
						<SmartTextView
							alignCenter={true}
							style={styles.titleText}
							chipStyle={[styles.titleText, styles.chipsText]}
							mapping={'title'}
							numberOfLines={1}
							{...props}
						/>
						<SmartTextView
							alignCenter={true}
							style={styles.contentText}
							chipStyle={[styles.contentText, styles.chipsText]}
							mapping={'text'}
							numberOfLines={1}
							{...props}
						/>
					</View>
				</TouchableOpacity>
			)}
		</View>
	)
}

export default Tile

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		titleText: {
			fontSize: theme.fontSizes[props.fontSize],
			textAlign: props.textAlign,
			color: props.square ? theme.colors.coverText : theme.colors.text
		},
		contentText: {
			fontSize: theme.fontSizes[props.fontSize] - 2,
			textAlign: props.textAlign,
			color: props.square ? theme.colors.coverText : theme.colors.textLight
		},
		chipsText: {
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 3,
			paddingHorizontal: theme.spacing.sm,
			borderWidth: 1,
			borderColor: 'transparent',
			borderRadius: theme.radiuses.sm,
			overflow: 'hidden'
		},
		textContainer: {
			backgroundColor: 'rgba(0, 0, 0, .2)',
			flex: 1,
			justifyContent: 'center',
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			borderRadius: props.imageRadius,
			fontWeight: '600',
			paddingHorizontal: theme.spacing.xs
		},
		aspectRatioContainer: {
			position: 'relative',
			paddingTop: props.corner == 'circle' ? '100%' : '75%',
			width: '100%'
		},
		aspectRatioContent: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0
		},
		conditionalImage: {
			width: '100%',
			height: '100%',
			borderRadius: props.imageRadius
		},
		conditionalMark: {
			width: '100%',
			height: '100%',
			backgroundColor: opacity(theme.colors.accent, 0.2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			borderRadius: props.imageRadius,
			overflow: 'hidden'
		},
		conditionalMarkText: {
			fontSize:
				props.size === 'xs'
					? 22
					: props.size === 'sm'
					? 42
					: props.size === 'md'
					? 62
					: props.size === 'lg'
					? 72
					: 22,
			fontWeight: '600',
			color: theme.colors.accent,
			textTransform: 'uppercase'
		}
	})
}
