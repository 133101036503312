import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'

import { useTheme } from '../theme/Theme'

import CustomModal from './CustomModal'
import Button from './Button'
import TextWithFont from './TextWithFont'
import CustomPopup from './CustomPopup'

const ConfirmationModal = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [currentRoute, setCurrentRoute] = useState(null)

	const confirm = () => {
		props?.confirm && props.confirm()
		cancel()
	}

	const cancel = () => {
		props.cancel()
	}

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	useEffect(() => {
		setCurrentRoute(props.route)
	}, [props.route])

	return (
		currentRoute && (
			<CustomPopup
				coverScreen={true}
				isVisible={props.visible}
				preventParentEvent={preventParentEvent}
				cancel={() => cancel()}
			>
				<View style={{ padding: theme.spacing.xl }}>
					<View style={styles.textContainer}>
						<TextWithFont style={styles.primaryText}>
							{props?.primaryText}
						</TextWithFont>
						<TextWithFont style={styles.secondaryText}>
							{props?.secondaryText}
						</TextWithFont>
					</View>

					<View style={{ flexDirection: 'row' }}>
						<Button
							text={props.cancelText}
							type="transparent"
							marginRight={7}
							onPress={() => cancel()}
						/>
						<Button
							text={props.confirmText}
							marginLeft={7}
							onPress={() => confirm()}
						/>
					</View>
				</View>
			</CustomPopup>
		)
	)
}

export default ConfirmationModal

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		textContainer: {
			paddingBottom: theme.spacing.xl
		},
		primaryText: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text,
			textAlign: 'center',
			marginBottom: theme.spacing.sm
		},
		secondaryText: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight,
			textAlign: 'center'
		}
	})
}
