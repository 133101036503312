import {
	ADDROUTEDATA,
	REMOVEROUTEDATA
} from '../action-types/routeDataActionTypes'

const initialState = {
	routes: []
}

const routeDataReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case ADDROUTEDATA: {
			let routes = [...state?.routes] || []
			const route = routes.find((x) => x.name == action.name)
			if (route) {
				route.name = action.name
				route.data = action.data
			} else {
				routes.push({ name: action.name, data: action.data })
			}

			return {
				routes: routes
			}
		}

		case REMOVEROUTEDATA: {
			let routes = [...state?.routes] || []

			const route = routes.find((x) => x.name === action.name)
			if (route) {
				const routeIndex = routes.indexOf(route)
				if (routeIndex > -1) routes.splice(routeIndex, 1)
			}

			return {
				routes: routes
			}
		}
		default:
			return state
	}
}

export default routeDataReducer
