import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import { View, ScrollView, TouchableOpacity, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '../theme/Theme'

import Button from './Button'
import TextWithFont from './TextWithFont'
import RadioMarker from './RadioMarker'

import CustomPopup from './CustomPopup'
import CheckboxMarker from './CheckboxMarker'

import dataClient from '../services/dataClient'
import timeoutDelayFunc from '../common/methods/timeoutDelayFunc'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import showToast from '../common/methods/showToast'
import i18n from '../i18n/i18n'
import env from '../../env'

const InputChoice = forwardRef((props, ref) => {
	const theme = useTheme()
	const isPreviewApp = env.isPreview
	const [currentRoute, setCurrentRoute] = useState(null)

	const [comparisonData, setComparisonData] = useState(null)
	const [options, setOptions] = useState(null)
	const [value, setValue] = useState(null)
	const [modalValue, setModalValue] = useState(null)
	const [visible, setVisible] = useState(false)
	const [isValid, setIsValid] = useState(true)

	const styles = themedStyles(props, theme, isValid)

	const relationType = props.block.relation.type
	const placeholder = getLocalizeContent(props.block.placeholder)

	useFocusEffect(
		useCallback(() => {
			refresh()
		}, [])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		validate: validate,
		clearInput: () => {
			clearInput()
		}
		// getFieldVal: getFieldVal
	}))

	const refresh = () => {
		getChoiceData()
	}

	const clearInput = () => {
		setValue(null)
		setModalValue(null)
		setIsValid(true)
	}

	const currentd = props.block.operator
		? props.block.submitData?.[
				`${props.block.mapping?.field}_${props.block.operator}`
		  ] ?? props.block.submitData?.[props.block.mapping?.field]
		: props.block.submitData?.[props.block.mapping?.field]

	const current =
		relationType === 'One'
			? currentd?.Id
				? { Id: currentd?.Id }
				: null
			: currentd?.length
			? currentd?.map((x) => ({
					Id: x
			  }))
			: null

	const updateFormFieldByRequired = (val, valid = true) => {
		props.block.updateFormField &&
			props.block.updateFormField({
				isValid: valid,
				key: props.block.mapping.field,
				value: val,
				repeat: props.block.enableRepeat && {
					field: props.block.mapping.field
				},
				operator: props.block.operator
			})
	}
	const updateFormFieldDelayedByRequired = (val, valid = true) => {
		props.block.updateFormField &&
			timeoutDelayFunc(
				props.block.updateFormField,
				{
					isValid: valid,
					key: props.block.mapping.field,
					value: val,
					repeat: props.block.enableRepeat && {
						field: props.block.mapping.field
					},
					operator: props.block.operator
				},
				100
			)
	}

	const validate = () => {
		let vl = null
		let valid = true

		if (relationType === 'One') {
			vl = value
				? {
						Id: value,
						Name: options?.find((x) => x.id === value)?.label
				  }
				: null

			if (props.block.required?.status) {
				valid = !!vl
			}
		} else {
			vl = value?.length
				? value?.map((x) => ({
						Id: x,
						Name: options?.find((o) => o.id === x)?.label
				  }))
				: []

			if (props.block.required?.status) {
				valid = vl?.length === 0 ? false : true
			}
		}

		setIsValid(valid)

		return {
			key: props.block.mapping.field,
			dataType: props.block.mapping.type,
			isValid: valid,
			value: vl,
			repeat: props.block.enableRepeat && {
				field: props.block.mapping.field
			},
			operator: props.block.operator
		}
	}

	const initRelation = (data) => {
		const d = props.block.operator
			? data?.[`${props.block.mapping?.field}_${props.block.operator}`] ??
			  data?.[props.block.mapping?.field]
			: data?.[props.block.mapping?.field]

		let val = null
		if (relationType === 'One') {
			val = d?.Id ? d.Id : null
		} else if (relationType === 'Many') {
			const idArray = d ? d?.map((x) => x.Id) : []

			val = idArray
		} else {
			console.log('UNRECOGNIZED RELATION TYPE')
		}

		setValue(val)
		setModalValue(val)
	}

	const saveRelation = (val) => {
		let vl = null
		let changed = false

		if (relationType === 'One') {
			vl = {
				Id: val,
				Name: options?.find((x) => x.id === val)?.label
			}

			if (vl?.Id != current?.Id) {
				changed = true
			}
		} else {
			vl =
				val?.map((x) => ({
					Id: x,
					Name: options?.find((o) => o.id === x)?.label
				})) ?? []

			const currentIds = current?.map((x) => x.Id).sort()
			const vlIds = vl?.map((x) => x.Id).sort()

			if (currentIds?.toString() !== vlIds?.toString()) {
				changed = true
			}
		}

		changed && updateFormFieldDelayedByRequired(vl)
	}

	const getLabel = (data) => {
		if (!data) return ''

		const text = Array.isArray(data)
			? data.map((item) => (typeof item === 'object' ? item['Name'] : item))
			: typeof data === 'object'
			? [data['Name']]
			: [data]

		return text?.join(', ') ?? ''
	}

	const getChoiceData = () => {
		let jsonData = {
			rows: props.block?.limit ? props.block.limit : 25,
			page: 1,
			sortField: props.block?.sorting?.field,
			sortDirection: props.block?.sorting?.sort,
			fields: `Id,${props.block.relation.field}`,
			filters: {
				groups: props.block?.filters?.rules?.length
					? [
							formatFilterDataByScreenData(
								props.block.filters,
								props.block.data,
								props.block.submitData
							)
					  ]
					: []
			}
		}

		let waitForCascade = false
		jsonData.filters.groups.forEach((group) => {
			var cascade = group.rules.find(
				(y) => (y.type == 'Screen' || y.type == 'Form') && y.data == null
			)
			if (cascade) {
				waitForCascade = true
			}
		})

		if (waitForCascade) return

		const canMakeRequest =
			JSON.stringify(comparisonData) !== JSON.stringify(jsonData)
		if (!canMakeRequest) return

		setComparisonData(jsonData)

		dataClient
			.postData(`/${props.block.relation.source}/search`, {}, jsonData)
			.then((response) => {
				if (response?.status === 200 && response?.data) {
					// if (response.data.records?.length) {
					const createdOptions =
						response.data.records?.map((x) => ({
							id: x.id,
							label: getLabel(x.fields[props.block.relation.field])
						})) ?? []

					// if (createdOptions && createdOptions.length > 0) {
					setOptions(createdOptions)
					// }
					// }
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
			})
	}

	const preventParentEvent = (e) => {
		e.stopPropagation()
	}

	const selectedOptionsClick = (option) => {
		let val

		if (relationType === 'One') {
			val = option.id

			setValue(option.id)
			setModalValue(option.id)
		} else if (relationType === 'Many') {
			if (
				props?.block?.allowMultiple === null ||
				props?.block?.allowMultiple === undefined ||
				props?.block?.allowMultiple
			) {
				const idArray = [...(modalValue ?? [])]

				const idIndex = idArray.indexOf(option.id)
				if (idIndex >= 0) {
					idArray.splice(idIndex, 1)
				} else {
					idArray.push(option.id)
				}

				val = idArray

				setValue(idArray)
				setModalValue(idArray)
			} else {
				val = [option.id]

				setValue(option.id)
				setModalValue(option.id)
			}
		} else {
			console.log('UNRECOGNIZED RELATION TYPE')
		}

		saveRelation(val)
	}

	const getInputText = (val) => {
		if (val) {
			if (relationType === 'One') {
				const inputText = options?.find((x) => x.id === val)?.label
				return (
					<TextWithFont style={styles.text}>
						{inputText ? inputText : placeholder}
					</TextWithFont>
				)
			} else if (relationType === 'Many') {
				return val?.length ? (
					<View style={styles.tagContainer}>
						{options?.map((item, index) => {
							if (val.includes(item.id)) {
								return (
									<TextWithFont key={index} style={styles.inputTag}>
										{item.label || getLocalizeContent(props.block.emptyText)}
									</TextWithFont>
								)
							}
						})}
					</View>
				) : (
					<TextWithFont style={styles.text}>{placeholder}</TextWithFont>
				)
			} else {
				console.log('UNRECOGNIZED RELATION TYPE')
			}
		} else {
			return <TextWithFont style={styles.text}>{placeholder}</TextWithFont>
		}
	}

	const getModalOptions = (opts, modalVal) => {
		return opts?.map((item, index) => (
			<TouchableOpacity
				activeOpacity={0.8}
				key={index}
				style={styles.listItem}
				onPress={() => selectedOptionsClick(item)}
			>
				<TextWithFont style={styles.itemText} numberOfLines={1}>
					{item.label || getLocalizeContent(props.block.emptyText)}
				</TextWithFont>
				{((relationType === 'One' && modalVal === item.id) ||
					(relationType === 'Many' && modalVal?.includes(item.id))) && (
					<MaterialCommunityIcons
						name="check"
						size={20}
						style={styles.commonColor}
					/>
				)}
			</TouchableOpacity>
		))
	}

	const getOptions = (opts, modalVal) => {
		const itemIsActive = (item) =>
			(relationType === 'One' && modalVal === item.id) ||
			(relationType === 'Many' && modalVal?.includes(item.id))

		if (props.block.type === 'chips') {
			return (
				<View style={styles.tagContainer}>
					{opts?.map((item, index) => (
						<TouchableOpacity
							activeOpacity={0.8}
							key={index}
							style={
								itemIsActive(item) ? styles.activeItem : styles.passiveItem
							}
							onPress={() => selectedOptionsClick(item)}
						>
							<TextWithFont
								style={
									itemIsActive(item)
										? styles.activeItemText
										: styles.passiveItemText
								}
								numberOfLines={1}
							>
								{item.label || getLocalizeContent(props.block.emptyText)}
							</TextWithFont>
						</TouchableOpacity>
					))}
				</View>
			)
		} else if (props.block.type === 'radio') {
			return (
				<View>
					{opts?.map((item, index) => (
						<View key={index} style={styles.radioItemBox}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={styles.radioItem}
								onPress={() => selectedOptionsClick(item)}
							>
								<View style={styles.radioMarker}>
									<RadioMarker active={itemIsActive(item)} />
								</View>
								<TextWithFont style={styles.passiveItemText} numberOfLines={1}>
									{item.label || getLocalizeContent(props.block.emptyText)}
								</TextWithFont>
							</TouchableOpacity>
						</View>
					))}
				</View>
			)
		} else if (props.block.type === 'checkbox') {
			return (
				<View>
					{opts?.map((item, index) => (
						<View key={index} style={styles.radioItemBox}>
							<TouchableOpacity
								activeOpacity={0.8}
								style={styles.radioItem}
								onPress={() => selectedOptionsClick(item)}
							>
								<View style={styles.radioMarker}>
									<CheckboxMarker active={itemIsActive(item)} />
								</View>
								<TextWithFont style={styles.passiveItemText} numberOfLines={1}>
									{item.label || getLocalizeContent(props.block.emptyText)}
								</TextWithFont>
							</TouchableOpacity>
						</View>
					))}
				</View>
			)
		} else {
			return null
		}
	}

	useEffect(() => {
		if (props.block?.relation?.source) {
			getChoiceData()
		}
	}, [
		props.block.relation.source,
		props.block.sorting,
		props.block.data,
		props.block.submitData
	])

	useEffect(() => {
		setModalValue(value)
	}, [visible])

	useEffect(() => {
		setCurrentRoute(props?.block?.route)
	}, [props?.block?.route])

	useEffect(() => {
		props.block?.submitData && initRelation(props.block.submitData)
	}, [props.block.data, props.block.submitData, props.block.mapping.field])

	return (
		<>
			{props.block.type === 'dropdown' && (
				<>
					{currentRoute && (
						<CustomPopup
							coverScreen={true}
							isVisible={visible}
							preventParentEvent={preventParentEvent}
							cancel={() => setVisible(false)}
						>
							<View style={{ padding: theme.spacing.xl, height: '100%' }}>
								<ScrollView
									showsVerticalScrollIndicator={false}
									scrollEnabled
									style={styles.listBox}
								>
									{getModalOptions(options, modalValue)}
								</ScrollView>
								<View style={{ flexDirection: 'row' }}>
									{/* <Button
										text={getLocalizeContent(props.block?.cancel?.text)}
										type="transparent"
										marginRight={7}
										onPress={() => setVisible(false)}
									/> */}
									<Button
										text={getLocalizeContent(props.block?.submitText)}
										marginLeft={7}
										onPress={() => {
											setVisible(false)
										}}
									/>
								</View>
							</View>
						</CustomPopup>
					)}

					<View style={styles.mainContainer}>
						<View style={styles.titleContainer}>
							<TextWithFont style={styles.title}>
								{props.block?.title}
							</TextWithFont>

							{props.block.required.status ? (
								<TextWithFont style={styles.requiredText}>
									{getLocalizeContent(props.block.required.text)}
								</TextWithFont>
							) : null}
						</View>
						<TouchableOpacity
							activeOpacity={0.8}
							onPress={() => setVisible(true)}
							style={styles.pickerContainer}
						>
							{getInputText(value)}
							<MaterialCommunityIcons
								name={isValid ? 'menu-down' : 'alert'}
								size={20}
								style={isValid ? styles.iconColor : styles.errorColor}
							/>
						</TouchableOpacity>
						{!isValid && (
							<TextWithFont style={styles.errorText}>
								{getLocalizeContent(props.block.errorText)}
							</TextWithFont>
						)}
					</View>
				</>
			)}
			{(props.block.type === 'chips' ||
				props.block.type === 'radio' ||
				props.block.type === 'checkbox') && (
				<View style={styles.mainContainer}>
					<View style={styles.titleContainer}>
						<TextWithFont style={styles.title}>
							{props.block?.title}
						</TextWithFont>

						{props.block.required.status ? (
							<TextWithFont style={styles.requiredText}>
								{getLocalizeContent(props.block.required.text)}
							</TextWithFont>
						) : null}
					</View>
					{getOptions(options, modalValue)}
					{!isValid && (
						<TextWithFont style={styles.errorText}>
							{getLocalizeContent(props.block.errorText)}
						</TextWithFont>
					)}
				</View>
			)}
		</>
	)
})

export default InputChoice

const themedStyles = (props, theme, isValid) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: 10,
			justifyContent: 'space-between'
		},
		titleContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 10
		},
		title: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		text: {
			flex: 1,
			display: 'flex',
			fontSize: theme.fontSizes.md,
			marginLeft: 2,
			color: theme.colors.inputText
		},
		pickerContainer: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: theme.colors.inputBackground,
			minHeight: 40,
			borderRadius: theme.radiuses.sm,
			borderColor: isValid ? 'transparent' : theme.colors.inputTextDanger,
			borderWidth: 1,
			paddingHorizontal: theme.spacing.sm
		},
		activeItem: {
			backgroundColor: theme.colors.accent,
			borderColor: 'transparent',
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 6,
			paddingHorizontal: 6,
			borderRadius: theme.radiuses.sm
		},
		activeItemText: {
			flex: 1,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.layoutBackground
		},
		radioItemBox: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingVertical: 8,
			borderBottomColor: theme.colors.line,
			borderBottomWidth: 1
		},
		radioItem: {
			flexDirection: 'row',
			alignItems: 'center'
		},
		radioMarker: {
			marginRight: 8
		},
		passiveItem: {
			borderColor: theme.colors.inputText,
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 4,
			paddingVertical: 6,
			paddingHorizontal: 6,
			borderRadius: theme.radiuses.sm
		},
		passiveItemText: {
			flex: 1,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.text
		},
		listBox: {
			width: '100%',
			flex: 1,
			marginBottom: theme.spacing.xl
		},
		listItem: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: 40,
			marginBottom: 2,
			borderColor: theme.colors.line,
			borderBottomWidth: 1
		},
		itemText: {
			flex: 1,
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		commonColor: {
			color: theme.colors.text
		},
		iconColor: {
			color: theme.colors.inputText
		},
		tagContainer: {
			flex: 1,
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		inputTag: {
			color: theme.colors.inputText,
			borderColor: theme.colors.inputText,
			borderWidth: 1,
			marginRight: 5,
			marginVertical: 2,
			fontSize: 13,
			paddingVertical: 3,
			paddingHorizontal: 5,
			borderRadius: theme.radiuses.sm
		},
		errorColor: {
			color: theme.colors.inputTextDanger
		},
		requiredText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputText
		},
		errorText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputTextDanger,
			marginTop: 5
		}
	})
}
