import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import {
	View,
	Linking,
	Platform,
	TouchableOpacity,
	StyleSheet
} from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as FileSystem from 'expo-file-system'
import { useFocusEffect } from '@react-navigation/native'

import { useTheme } from '../theme/Theme'
import TextWithFont from './TextWithFont'

import getLocalizeContent from '../common/methods/getLocalizeContent'

const BasePicker = forwardRef((props, ref) => {
	const theme = useTheme()
	const [file, setFile] = useState(null)
	const [fileName, setFileName] = useState(null)
	const [isValid, setIsValid] = useState(true)
	const styles = themedStyles(props, theme, isValid)

	useEffect(() => {
		getFile()
	}, [props?.submitData])

	useFocusEffect(useCallback(() => {}, []))

	useImperativeHandle(ref, () => ({
		refresh: () => {},
		validate: validate,
		clearInput: clearInput
	}))

	const updateFormFieldByRequired = (val, valid = true) => {
		props.updateFormField &&
			props.updateFormField({
				isValid: valid,
				key: props.mapping.field,
				value: val,
				operator: props.operator
			})
	}

	const getFile = () => {
		if (props?.submitData != null) {
			const uri = props.submitData?.[props.mapping.field]
			setFile(uri)
			setFileName(uri?.split('/').pop())
		} else {
			if (props.initialValue) {
				setFile(props.initialValue)
				setFileName(props.initialValue?.split('/').pop())
			}
		}
	}

	const clearInput = () => {
		setFileName(null)
		if (props?.submitData != null) {
			setFile(props.submitData?.[props.mapping.field])
		} else {
			props.initialValue ? setFile(props.initialValue) : setFile(null)
		}

		setFileName(null)
		setIsValid(true)
	}

	const clearFile = () => {
		setFile(null)
		setFileName(null)
	}

	const validate = () => {
		let valid = true

		if (props.required?.status) {
			valid = !!file
		}

		setIsValid(valid)

		return {
			key: props.mapping.field,
			dataType: props.mapping.type,
			isValid: valid,
			value: file,
			operator: props.operator
		}
	}

	const pickFile = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: props.mimeTypes
		})

		setFileName(result?.name || null)

		if (Platform.OS !== 'web') {
			FileSystem.readAsStringAsync(result.uri, {
				encoding: FileSystem.EncodingType.Base64
			})
				.then((base64) => {
					const mimeType = `data:${result.mimeType};base64,`
					setFile(mimeType + base64)
				})
				.catch((err) => {
					clearFile()
				})
		} else {
			if (result?.uri) {
				setFile(result.uri)
			} else {
				clearFile()
			}
		}
		setIsValid(true)
	}

	const clickHandler = () => {
		if (file) {
			if (Platform.OS == 'web') {
				window.open(file, '_blank')
			} else {
				Linking.canOpenURL(file).then((supported) => {
					if (supported) {
						Linking.openURL(file)
					} else {
						console.log('Cannot open link', file)
					}
				})
			}
		} else {
			pickFile()
		}
	}

	const clearButtonAction = () => {
		clearFile()
	}

	return (
		<>
			<View style={styles.mainContainer}>
				<View style={styles.titleContainer}>
					<TextWithFont style={styles.title}>{props?.title}</TextWithFont>

					{props.required.status ? (
						<TextWithFont style={styles.requiredText}>
							{getLocalizeContent(props.required.text)}
						</TextWithFont>
					) : null}
				</View>
				<View style={styles.pickerContainer}>
					<TouchableOpacity
						activeOpacity={0.8}
						onPress={clickHandler}
						style={{
							width: '90%',
							height: '100%',
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<MaterialCommunityIcons
							name={props.icon}
							size={20}
							style={styles.commonColor}
						/>
						<TextWithFont
							style={{ ...styles.text, ...styles.commonColor }}
							numberOfLines={1}
						>
							{file === null ? getLocalizeContent(props.placeholder) : fileName}
						</TextWithFont>
					</TouchableOpacity>
					<TouchableOpacity
						activeOpacity={0.8}
						onPress={() => clearButtonAction()}
						style={{
							height: '100%',
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<MaterialCommunityIcons
							name={'close-circle'}
							size={20}
							style={styles.commonColor}
						/>
					</TouchableOpacity>
				</View>
				{!isValid && (
					<TextWithFont style={styles.errorText}>
						{getLocalizeContent(props.errorText)}
					</TextWithFont>
				)}
			</View>
		</>
	)
})

export default BasePicker

const themedStyles = (props, theme, isValid) => {
	return StyleSheet.create({
		mainContainer: {
			// height: 77,
			marginVertical: 10,
			justifyContent: 'space-between'
		},
		titleContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 10
		},
		title: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.inputText,
			fontWeight: '600'
		},
		text: {
			fontSize: theme.fontSizes.md,
			marginLeft: theme.spacing.xs,
			textAlign: 'left',
			textTransform: 'none',
			whiteSpace: 'nowrap',
			overflow: 'hidden'
		},
		pickerContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			backgroundColor: theme.colors.inputBackground,
			height: 40,
			borderRadius: theme.radiuses.sm,
			borderColor: isValid ? 'transparent' : theme.colors.inputTextDanger,
			borderWidth: 1,
			paddingHorizontal: theme.spacing.sm
		},
		commonColor: {
			color: theme.colors.inputText
		},
		errorColor: {
			color: theme.colors.inputTextDanger
		},
		requiredText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputText
		},
		errorText: {
			fontSize: theme.fontSizes.xs,
			color: theme.colors.inputTextDanger,
			marginTop: 5
		}
	})
}
