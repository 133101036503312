import { Dimensions } from 'react-native'

import env from '../../../env'
import store from '../../redux/store/store'

const calculateScreenHeight = (screenStyle, showTabBar, spacing = 0) => {
	const screenSize = store.getState().screenSize?.screenSize
	const { height } = Dimensions.get('window')

	const notch =
		env.isPreview && screenStyle === 'screen' && screenSize !== 'lg' ? 28 : 0
	const header = screenSize === 'lg' ? 127 : 49
	const tab = showTabBar ? (screenSize === 'lg' ? 0 : 66.5) : 0
	const extra =
		screenSize !== 'lg'
			? screenStyle === 'bottom'
				? 10
				: screenStyle === 'modal'
				? 5
				: 0
			: 0
	const calc =
		screenStyle === 'popup' ||
		(screenSize === 'lg' &&
			(screenStyle === 'bottom' || screenStyle === 'modal'))
			? height * 0.7
			: height - notch - header - spacing - tab - extra

	return calc
}

export default calculateScreenHeight
