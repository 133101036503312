import showToast from './showToast'
import i18n from '../../i18n/i18n'

const showToastCatchError = (error) => {
	if (error?.response?.data?.validations) {
		var messagesArr = error?.response?.data?.validations.map(function (el) {
			return el.message
		})
		var messages = messagesArr.toString()

		showToast('error', messages || i18n.t('api.error'))
	} else {
		showToast(
			'error',
			error?.response?.data?.message ||
				error?.response?.data?.errorDetails ||
				i18n.t('api.error')
		)
	}
}

export default showToastCatchError
