import React, { useEffect } from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'

import nav from '../common/methods/nav'

const TabBarItem = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { item, isActive, ...restProps } = props

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.mainContainer}
			onPress={() => {
				nav.navigateTo(`/${item.path}`, item.style !== 'screen')
			}}
			{...restProps}
		>
			<Ionicons
				name={item.icon}
				color={isActive ? theme.colors.accent : 'gray'}
				size={20}
			/>
			<TextWithFont style={styles.text} numberOfLines={1}>
				{item.name}
			</TextWithFont>
		</TouchableOpacity>
	)
}

export default TabBarItem

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		text: {
			fontWeight: 'bold',
			color: props.isActive ? theme.colors.accent : 'gray',
			fontSize: 10
		}
	})
}
