import React from 'react'
import { Platform } from 'react-native'

import { Provider } from 'react-redux'
// import * as Sentry from 'sentry-expo'

import store from './src/redux/store/store'
import AppProvider from './src/AppProvider'

// Sentry.init({
// 	dsn: 'https://f4bdaad7a9e845fabcde82c91da2f05a@o980428.ingest.sentry.io/5934913',
// 	enableInExpoDevelopment: true,
// 	debug: true
// })

export default () => (
	<Provider store={store}>
		<AppProvider />
	</Provider>
)
