import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { connect } from 'react-redux'
import { opacity } from 'simpler-color'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { useTheme } from '../theme/Theme'

import dataClient from '../services/dataClient'
import TextWithFont from './TextWithFont'
import triggerActionList from '../common/methods/triggerActionList'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import showToast from '../common/methods/showToast'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import i18n from '../i18n/i18n'
import localeParseFloat from '../common/methods/localeParseFloat'

const Rating = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [fetching, setFetching] = useState(false)

	const [text, setText] = useState(props.block.mapping?.text?.default || '')
	const [rating, setRating] = useState(null)
	const [count, setCount] = useState(0)

	useFocusEffect(
		useCallback(() => {
			refresh()
		}, [])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh
	}))

	const refresh = () => {
		getData()
	}

	const getData = () => {
		setFetching(true)

		let jsonData = {
			rows: props.block?.limit ?? 10,
			sortField: props.block?.sorting?.field,
			sortDirection: props.block?.sorting?.sort,
			filters: {
				groups: props.block?.filters?.rules?.length
					? [
							formatFilterDataByScreenData(
								props.block.filters,
								props.block?.data,
								null,
								props.block?.route?.params?.id
							)
					  ]
					: []
			}
		}

		let query = `avg ${
			props.block?.mapping?.rating?.field
				? props.block?.mapping?.rating?.field
				: '1'
		}, ${'count 1'}`

		dataClient
			.postData(`/${props.block?.source}/aggregate`, {}, jsonData, {
				params: {
					aggregation: query
				}
			})
			.then((response) => {
				if (response?.status === 200) {
					let countValue = response.data?.data?.[0]?.['count']
					let ratingValue =
						response.data?.data?.[0]?.[
							`avg${props.block?.mapping?.rating?.field ?? ''}`
						] ?? 0

					ratingValue = localeParseFloat(ratingValue)
					ratingValue = ratingValue.toFixed(1)

					setRating(ratingValue)
					setCount(countValue)
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	return (
		<TouchableOpacity
			key={props.block._uid}
			activeOpacity={0.8}
			disabled={props.block.actions?.[0]?.type == null ? true : false}
			onPress={() =>
				triggerActionList(
					props.block,
					props.block.actions,
					props.block?.route?.params?.id
				)
			}
		>
			<View key={props.block._uid} style={styles.container}>
				<View style={{ alignItems: 'center' }}>
					<TextWithFont style={styles.ratingContainer}>
						{rating &&
							Array.from({ length: props.maxRating ?? 5 }).map((e, idx) => (
								<MaterialCommunityIcons
									key={`review-${props.index}-${props.id}-rating-${idx}`}
									name={'star-box'}
									size={22}
									style={
										rating >= idx + 1
											? styles.ratingIcon
											: styles.ratingIconGray
									}
								/>
							))}
					</TextWithFont>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: 'flex-start',
						justifyContent: 'center'
					}}
				>
					<TextWithFont
						style={{
							fontSize: theme.fontSizes.md,
							fontWeight: '600',
							marginLeft: theme.spacing.md,
							marginRight: theme.spacing.xs
						}}
					>
						{rating}
					</TextWithFont>
				</View>
				<View style={{ alignItems: 'center' }}>
					<TextWithFont
						style={{
							fontSize: theme.fontSizes.md
						}}
					>{`(${count} ${text})`}</TextWithFont>
				</View>
			</View>
		</TouchableOpacity>
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null, null, {
	forwardRef: true
})(Rating)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		container: {
			flex: 1,
			alignItems: 'center',
			justifyContent: 'flex-start',
			flexDirection: 'row',
			marginVertical: theme.spacing.sm
		},
		ratingContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		ratingIcon: {
			color: theme.colors.accent
		},
		ratingIconGray: {
			color: opacity(theme.colors.inputText, 0.3)
		}
	})
}
