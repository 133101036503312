import React, { useState, useEffect } from 'react'
import {
	Platform,
	Image as NativeImage,
	View,
	StyleSheet,
	TouchableOpacity
} from 'react-native'
import { connect } from 'react-redux'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import triggerActionList from '../common/methods/triggerActionList'

const Image = (props) => {
	const theme = useTheme()

	const [imageHeight, setImageHeight] = useState(null)

	const imgUrl = getMappingValByKey(props, 'imgUrl', null, props.userInfo)

	const getImageHeight = (windowWidth) => {
		imgUrl &&
			NativeImage.getSize(imgUrl, (width, height) => {
				setImageHeight(height * (windowWidth / width))
			})
	}

	useEffect(() => {
		getImageHeight(props.screenWidth)
	}, [props.block.mapping, props.block.data, props.screenWidth])

	const styles = themedStyles(props, theme, imageHeight)

	return (
		<TouchableOpacity
			style={styles.mainContainer}
			activeOpacity={0.8}
			disabled={props.block.actions?.[0]?.type == null ? true : false}
			onPress={() =>
				triggerActionList(
					props.block,
					props.block.actions,
					props.block?.route?.params?.id
				)
			}
		>
			<View style={styles.aspectRatioContainer}>
				<View style={styles.aspectRatioContent}>
					<NativeImage
						resizeMode={props.block?.imageResizeMode || 'cover'}
						style={
							imgUrl
								? {
										width: '100%',
										height: '100%',
										backgroundColor: 'transparent'
								  }
								: {
										width: '100%',
										height: '100%',
										backgroundColor: 'transparent'
								  }
						}
						source={{
							uri: imgUrl
						}}
					/>
				</View>
			</View>
		</TouchableOpacity>
	)
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.userInfo
})

export default connect(mapStateToProps, null)(Image)

const themedStyles = (props, theme, imageHeight) => {
	return StyleSheet.create({
		mainContainer: {
			width: 'auto',
			flexDirection: 'row',
			justifyContent: 'center',
			paddingBottom: theme.spacing[props.block.spacing],
			marginHorizontal: props.screenSize === 'lg' ? 0 : -theme.spacing.xl
		},
		aspectRatioContainer:
			props.block.appearance == 'square'
				? {
						position: 'relative',
						paddingTop: '100%',
						width: '100%'
				  }
				: {
						position: 'relative',
						height: props.block.appearance == 'rectangle' ? 200 : imageHeight,
						width: '100%'
				  },
		aspectRatioContent: {
			flex: 1,
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.colors.inputBackground,
			overflow: 'hidden'
		}
	})
}
