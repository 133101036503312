import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useCallback,
	useEffect
} from 'react'
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { useTheme } from '../theme/Theme'

import dataClient from '../services/dataClient'
import TextWithFont from './TextWithFont'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import triggerActionList from '../common/methods/triggerActionList'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import showToast from '../common/methods/showToast'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import i18n from '../i18n/i18n'
import localeParseFloat from '../common/methods/localeParseFloat'
import SearchBox from './SearchBox'

const Numbers = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)

	const [fetching, setFetching] = useState(false)
	const [value, setValue] = useState(
		componentDetail?.config?.forceLoad ? ' ' : componentDetail?.data ?? ' '
	)

	const title = getMappingValByKey(props, 'title')
	const description = getMappingValByKey(props, 'description')

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ?? 10,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			refresh()
		}, [])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getData = () => {
		setFetching(true)

		let query = `${props.block?.mapping?.aggregation} ${
			props.block?.mapping?.value?.field
				? props.block?.mapping?.value?.field
				: '1'
		}`

		dataClient
			.postData(`/${props.block?.source}/aggregate`, {}, requestData, {
				params: {
					aggregation: query
				}
			})
			.then((response) => {
				if (response?.status === 200) {
					let vl =
						response.data?.data?.[0]?.[
							`${props.block?.mapping?.aggregation ?? ''}${
								props.block?.mapping?.value?.field ?? ''
							}`
						] ?? 0

					vl = localeParseFloat(vl)

					setValue(vl % 1 === 0 ? vl : vl.toFixed(2))
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	useEffect(() => {
		props.addComponentData(
			props.block._uid,
			value,
			{
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [value])

	useEffect(() => {
		if (reload) {
			getData()
		}
	}, [reload])

	return (
		<View key={props.block._uid} style={styles.mainContainer}>
			<SearchBox
				block={{
					compact: true,
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>

			<TouchableOpacity
				key={props.block._uid}
				activeOpacity={0.8}
				disabled={props.block.actions?.[0]?.type == null ? true : false}
				onPress={() =>
					triggerActionList(
						props.block,
						props.block.actions,
						props.block?.route?.params?.id
					)
				}
			>
				<View key={props.block._uid} style={styles.container}>
					<TextWithFont style={styles.title}>{title}</TextWithFont>
					<TextWithFont style={styles.value}>{value}</TextWithFont>
					<TextWithFont style={styles.description}>{description}</TextWithFont>
				</View>
			</TouchableOpacity>
		</View>
	)
})

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(Numbers)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			marginVertical: theme.spacing.sm,
			borderRadius:
				props.block?.background && props.block?.background == 'oval'
					? theme.radiuses.sm
					: !props.block?.background || props.block?.background === 'none'
					? theme.radiuses.sm
					: 0,
			borderColor: theme.colors.line,
			borderWidth: props.block?.background === 'none' ? 1 : 0,
			backgroundColor:
				props.block?.background && props.block?.background !== 'none'
					? theme.colors.inputBackground
					: 'transparent'
		},
		container: {
			alignItems: 'flex-start',
			flexDirection: 'column',
			paddingTop:
				props.block?.searcheable || props.block?.filterModal?.body?.length > 0
					? 0
					: theme.spacing.sm,
			paddingBottom: theme.spacing.sm,
			paddingHorizontal: theme.spacing.xl
			// marginTop:
			// 	props.block?.searcheable || props.block?.filterModal
			// 		? 0
			// 		: theme.spacing.sm,
			// marginBottom: theme.spacing.sm,
			// paddingVertical: theme.spacing.sm,
			// paddingHorizontal: theme.spacing.xl,
			// borderRadius:
			// 	props.block?.background && props.block?.background == 'oval'
			// 		? theme.radiuses.sm
			// 		: !props.block?.background || props.block?.background === 'none'
			// 		? theme.radiuses.sm
			// 		: 0,
			// borderColor: theme.colors.line,
			// borderWidth: props.block?.background === 'none' ? 1 : 0,
			// backgroundColor:
			// 	props.block?.background && props.block?.background !== 'none'
			// 		? theme.colors.inputBackground
			// 		: 'transparent'
		},
		title: {
			fontSize: theme.fontSizes.md,
			color: theme.colors.text
		},
		value: {
			fontSize: theme.fontSizes.xxl,
			fontWeight: 'bold',
			color: theme.colors.text
		},
		description: {
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		}
	})
}
