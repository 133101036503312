import React, { useEffect } from 'react'
import { View, TouchableOpacity, StyleSheet, Image } from 'react-native'
import { connect } from 'react-redux'

import i18n from '../../i18n/i18n'
import { useTheme } from '../../theme/Theme'
import { Ionicons } from '@expo/vector-icons'
import TextWithFont from '../TextWithFont'

const AddToIosSafari = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	const { doNotShowAgain } = props

	return (
		<View style={styles.mainContainer}>
			<View style={styles.closeContainer}>
				<TouchableOpacity
					style={styles.close}
					activeOpacity={0.8}
					onPress={doNotShowAgain}
				>
					<Ionicons name={'close-outline'} style={styles.closeIcon} />
				</TouchableOpacity>
			</View>
			<View style={styles.innerContainer}>
				{props.config.manifest?.icons &&
					props.config.manifest?.icons.length > 0 && (
						<View style={styles.logoContainer}>
							<Image
								resizeMode="contain"
								resizeMethod="auto"
								style={{
									width: 60,
									height: 60,
									borderRadius: theme.radiuses.sm
								}}
								source={{
									uri: props.config.manifest?.icons[0]?.src
								}}
							/>
						</View>
					)}
				<View style={styles.descriptionContainer}>
					<TextWithFont style={styles.text}>
						{i18n
							.t('addToHomeScreen.safari.description')
							.replace('{0}', props?.config?.manifest?.name ?? '')}
					</TextWithFont>
				</View>
				<View style={styles.step1Container}>
					<TextWithFont style={styles.text}>
						{i18n.t('addToHomeScreen.safari.click')}
					</TextWithFont>
					<Ionicons name={'share-outline'} style={styles.icon} />
					<TextWithFont style={styles.text}>
						{i18n.t('addToHomeScreen.safari.icon')}
					</TextWithFont>
				</View>
				<View style={styles.step2Container}>
					<View>
						<TextWithFont style={styles.text}>
							{i18n.t('addToHomeScreen.safari.scroll')}
						</TextWithFont>
					</View>
					<View style={styles.addToHomeScreenContainer}>
						<View style={styles.addToHomeScreenButtonContainer}>
							<TextWithFont style={styles.text}>
								{i18n.t('addToHomeScreen.safari.add')}
							</TextWithFont>
							<Ionicons name={'add-outline'} style={styles.plusIcon} />
						</View>
					</View>
				</View>
			</View>
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.config
})

export default connect(mapStateToProps)(AddToIosSafari)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		mainContainer: {
			flex: 1,
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		},
		closeContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'flex-end',
			justifyContent: 'flex-end',
			width: '100%'
		},
		close: {
			backgroundColor: 'transparent',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row'
		},
		closeIcon: {
			fontSize: 24,
			color: theme.colors.text,
			marginHorizontal: theme.spacing.md
		},
		innerContainer: {
			maxWidth: '90%',
			paddingBottom: theme.spacing.xl
		},
		logoContainer: {
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: theme.spacing.xxl
		},
		text: {
			color: theme.colors.text,
			textAlign: 'center'
		},
		icon: {
			fontSize: 22,
			color: theme.colors.text,
			marginHorizontal: theme.spacing.md
		},
		plusIcon: {
			borderWidth: 1,
			borderRadius: theme.radiuses.xs,
			borderColor: theme.colors.text,
			fontSize: 16,
			color: theme.colors.text,
			marginLeft: theme.spacing.xl,
			paddingHorizontal: 1
		},
		button: {
			backgroundColor: 'transparent',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			padding: theme.spacing.xs,
			marginTop: theme.spacing.sm
		},
		descriptionContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center'
		},
		step1Container: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: theme.spacing.sm
		},
		step2Container: {
			marginTop: theme.spacing.sm
		},
		addToHomeScreenContainer: {
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: theme.spacing.sm
		},
		addToHomeScreenButtonContainer: {
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: theme.radiuses.sm,
			backgroundColor: theme.colors.inputBackground,
			paddingHorizontal: theme.spacing.md,
			paddingVertical: theme.spacing.sm
		}
	})
}
