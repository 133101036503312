import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback
} from 'react'
import {
	View,
	StyleSheet,
	TouchableOpacity,
	ActivityIndicator
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { Calendar, modeToNum } from 'react-native-big-calendar'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/tr'

import { connect } from 'react-redux'
import {
	addComponentData,
	removeComponentData
} from '../redux/actions/componentDataActions'

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'

import TextWithFont from './TextWithFont'
import CalendarListItem from './CalendarListItem'
import SearchBox from './SearchBox'

import dataClient from '../services/dataClient'
import getLanguageCode from '../common/methods/getLanguageCode'
import getLocalizeContent from '../common/methods/getLocalizeContent'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import formatFilterDataByScreenData from '../common/methods/formatFilterDataByScreenData'
import formatFilterDataByFilter from '../common/methods/formatFilterDataByFilter'
import localeParseDate from '../common/methods/localeParseDate'
import showToast from '../common/methods/showToast'
import nav from '../common/methods/nav'
import triggerActionList from '../common/methods/triggerActionList'
import i18n from '../i18n/i18n'

const today = new Date()

const defaultPageSize = 120

const CalendarList = forwardRef((props, ref) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const [reload, setReload] = useState(null)

	const componentDetail = props.components?.find(
		(x) => x.uuid === props.block._uid
	)

	const localeString = getLanguageCode() === 'tr-TR' ? 'tr' : 'en'
	const pageSize = props.block?.pageSize
		? props.block.pageSize
		: defaultPageSize

	const [filterData, setFilterData] = useState(
		componentDetail?.config?.filterData ?? null
	)
	const [listData, setListData] = useState(
		componentDetail?.config?.forceLoad ? [] : componentDetail?.data ?? []
	)

	const [date, setDate] = useState(today)

	const [page, setPage] = useState(componentDetail?.config?.page ?? 1)

	const [allShown, setAllShown] = useState(
		componentDetail?.config?.allShown ?? true
	)
	const [events, setEvents] = useState(componentDetail?.config?.events ?? null)
	const [groupedEvents, setGroupedEvents] = useState(
		componentDetail?.config?.groupedEvents ?? []
	)
	const [searchTerm, setSearchTerm] = useState(
		componentDetail?.config?.searchTerm || null
	)
	const [componentType, setComponentType] = useState('list')
	const [fetching, setFetching] = useState(false)
	const [noRecords, setNoRecords] = useState(
		componentDetail?.config?.noRecords ?? false
	)
	const [selectedDay, setSelectedDay] = useState(null)
	const [selectedDayEvents, setSelectedDayEvents] = useState([])

	const noRecordsMessage = getLocalizeContent(props.block?.emptyState)

	const elementFilter = props.block?.filters?.rules?.length
		? formatFilterDataByScreenData(
				props.block.filters,
				props.block?.data,
				null,
				props?.block?.route?.params?.id
		  )
		: null

	const screenFilter = filterData ? formatFilterDataByFilter(filterData) : null

	const requestData = {
		rows: props.block?.limit ? props.block.limit : pageSize,
		page: page ?? 1,
		sortField: props.block?.sorting?.field,
		sortDirection: props.block?.sorting?.sort,
		term: searchTerm,
		filters: {
			groupOp: 'AND',
			groups: [elementFilter, screenFilter].filter((x) => x != null)
		}
	}

	useFocusEffect(
		useCallback(() => {
			const waitForScreenData = props.block?.filters?.rules?.some(
				(x) => x.type === 'Screen'
			)

			if (!waitForScreenData || (waitForScreenData && props.block.data)) {
				setReload(Math.random())
			}
		}, [props.block.data])
	)

	useImperativeHandle(ref, () => ({
		refresh: refresh,
		search: search
	}))

	const refresh = () => {
		setReload(Math.random())
	}

	const search = (term = null) => {
		if (props.block?.searcheable) {
			setReload(Math.random())
			setSearchTerm(term)
		}
	}

	const filter = (filterData = null) => {
		setReload(Math.random())
		setFilterData(filterData)
	}

	const getMoreData = () => {
		if (!allShown) {
			const newPage = page + 1
			setPage(newPage)
		}
	}

	const getData = () => {
		setFetching(true)

		dataClient
			.postData(`/${props.block?.source}/search`, {}, requestData)
			.then((response) => {
				if (response?.status === 200) {
					if (response?.data?.records) {
						const managedListData = [
							...(page === 1 ? [] : listData ?? []),
							...response.data.records
						]

						setListData(managedListData)

						if (managedListData.length === 0) {
							setNoRecords(true)
						} else {
							setNoRecords(false)

							if (managedListData.length === response.data.total) {
								setAllShown(true)
							} else {
								setAllShown(false)
							}
						}
					} else {
						setAllShown(true)
					}
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}

				setFetching(false)
			})
	}

	const calendarTheme = {
		palette: {
			primary: {
				main: theme.colors.accent,
				contrastText: opacity(theme.colors.accent, 0.2)
			},
			nowIndicator: 'red',
			gray: {
				100: theme.colors.line,
				200: theme.colors.line,
				300: theme.colors.line,
				500: theme.colors.inputText,
				800: theme.colors.inputText
			},
			moreLabel: theme.colors.textLight
		},
		typography: {
			xs: {
				fontSize:
					props.screenSize === 'lg' ? theme.fontSizes.md : theme.fontSizes.sm
			},
			sm: {
				fontSize:
					props.screenSize === 'lg' ? theme.fontSizes.lg : theme.fontSizes.md
			},
			md: {
				fontSize:
					props.screenSize === 'lg' ? theme.fontSizes.xl : theme.fontSizes.lg
			},
			xl: {
				fontSize:
					props.screenSize === 'lg' ? theme.fontSizes.xxxl : theme.fontSizes.xl
			},
			moreLabel: {
				fontSize: theme.fontSizes.xs,
				fontWeight: 'bold',
				textAlign: 'center'
			}
		}
	}

	const getDateText = (dateData, withDay = false) => {
		if (dateData) {
			const datetimeData = new Date(dateData)

			if (datetimeData != 'Invalid Date') {
				return withDay
					? dayjs(datetimeData).locale(localeString).format('MMMM DD, YYYY')
					: dayjs(datetimeData).locale(localeString).format('MMMM YYYY')
			} else {
				return null
			}
		} else {
			return null
		}
	}

	const getDateISOString = (date) => {
		if (date) {
			const datetimeData = new Date(date)
			if (datetimeData != 'Invalid Date') {
				const day = datetimeData.getDate()
				const month = datetimeData.getMonth() + 1
				const year = datetimeData.getFullYear()
				return `${year}-${month < 10 ? '0' : ''}${month}-${
					day < 10 ? '0' : ''
				}${day}`
			} else {
				return null
			}
		} else {
			return null
		}
	}

	const eventClick = (e) => {
		const blockWithData = {
			...props.block,
			screenData: props.block.data,
			data: e.data,
			id: e.data?.Id
		}
		triggerActionList(blockWithData, blockWithData.actions, blockWithData.id)
	}

	const cellClick = (selectedDate) => {
		const date = getDateText(getDateISOString(selectedDate), true)
		const selectedEvents = groupedEvents.filter((x) => x.date === date)

		if (selectedEvents) {
			setSelectedDayEvents(selectedEvents)
		} else {
			setSelectedDayEvents([])
		}
	}

	const getFormattedEvents = (eventArray) => {
		const formattedEvents =
			eventArray?.length && props.block.mapping.startDate.field
				? eventArray.map((event) => ({
						data: event.fields,
						id: event.id,
						title: getMappingValByKey(props, 'title', event.fields),
						description: getMappingValByKey(props, 'text', event.fields),
						start: localeParseDate(
							event.fields[props.block.mapping.startDate.field]
						),
						end: event.fields[props.block.mapping.endDate.field]
							? localeParseDate(event.fields[props.block.mapping.endDate.field])
							: componentType === 'month'
							? localeParseDate(
									event.fields[props.block.mapping.startDate.field]
							  )
							: null
				  }))
				: []

		return formattedEvents
	}

	const getGroupedEvents = (eventArray) => {
		if (eventArray && eventArray?.length) {
			const groups = eventArray.reduce((groups, object) => {
				const date = getDateISOString(object?.start)
				if (!groups[date]) {
					groups[date] = []
				}
				groups[date].push(object)
				return groups
			}, {})

			const groupedFormattedEvents = Object.keys(groups).map((date) => {
				return {
					date: getDateText(date, true),
					events: groups[date]
				}
			})

			return groupedFormattedEvents
		} else {
			return []
		}
	}

	const onPrevDate = () => {
		if (componentType === 'month') {
			setDate(
				dayjs(date)
					.add(dayjs(date).date() * -1, 'day')
					.toDate()
			)
		} else {
			setDate(
				dayjs(date)
					.add(modeToNum(componentType, date) * -1, 'day')
					.toDate()
			)
		}
	}

	const onNextDate = () => {
		setDate(dayjs(date).add(modeToNum(componentType, date), 'day').toDate())
	}

	const onToday = () => {
		setDate(today)
	}

	const footerIndicator = () => {
		return !allShown ? (
			<View style={styles.moreContainer}>
				<TouchableOpacity activeOpacity={0.8} onPress={getMoreData}>
					<TextWithFont style={styles.moreText}>
						{props.block?.moreText
							? getLocalizeContent(props.block.moreText)
							: i18n.t('tileList.moreText')}
					</TextWithFont>
				</TouchableOpacity>
			</View>
		) : null
	}

	useEffect(() => {
		setComponentType(props.block.type)
	}, [props.block.type])

	useEffect(() => {
		const events = getFormattedEvents(listData)
		setEvents(events)

		const groupedEvents = getGroupedEvents(events)
		setGroupedEvents(groupedEvents)

		props.addComponentData(
			props.block._uid,
			listData,
			{
				events: events,
				groupedEvents: groupedEvents,
				page: page,
				allShown: allShown,
				noRecords: noRecords,
				filterData: filterData,
				searchTerm: searchTerm,
				forceLoad: props.block?.filters?.rules?.some((x) => x.type === 'Screen')
			},
			new Date()
		)
	}, [listData])

	useEffect(() => {
		page > 1 && getData()
	}, [page, searchTerm, filterData])

	useEffect(() => {
		if (reload) {
			if (page === 1) {
				getData()
			} else {
				setPage(1)
			}
		}
	}, [reload])

	return events ? (
		<View key={props.block._uid}>
			{/* <TouchableOpacity
				activeOpacity={0.8}
				style={styles.typeButton}
				onPress={() =>
					setComponentType(componentType === 'list' ? 'calendar' : 'list')
				}
			>
				<MaterialCommunityIcons
					name={componentType === 'list' ? 'calendar' : 'format-list-bulleted'}
					size={24}
					color={theme.colors.layoutBackground}
				/>
			</TouchableOpacity> */}

			<SearchBox
				block={{
					title: props.block.title,
					route: props?.block?.route,
					spacing: 'xl',
					searcheable: props.block?.searcheable,
					search: search,
					searchTerm: searchTerm,
					filter: filter,
					filterData: filterData,
					filterModal: props.block?.filterModal,
					searchPlaceholder: getLocalizeContent(props.block?.searchPlaceholder)
				}}
			/>

			{componentType === 'list' && noRecords && noRecordsMessage && (
				<TextWithFont style={styles.noRecords}>{noRecordsMessage}</TextWithFont>
			)}

			{componentType === 'list' ? (
				groupedEvents?.length > 0 ? (
					<View style={styles.mainContainer}>
						{groupedEvents?.map((eventGroup, index) => (
							<View key={index} style={styles.paddingContainer}>
								<TextWithFont
									style={{
										marginBottom: 5,
										fontSize: theme.fontSizes.sm,
										color: theme.colors.text
									}}
								>
									{eventGroup.date}
								</TextWithFont>
								{eventGroup.events?.map((x, j) => {
									return x.start ? (
										<CalendarListItem
											key={j}
											eventData={x}
											localeString={localeString}
											onPressEvent={eventClick}
										/>
									) : null
								})}
							</View>
						))}
						{footerIndicator()}
					</View>
				) : null
			) : (
				<View style={styles.mainContainer}>
					<View style={styles.calendarActions}>
						<TouchableOpacity
							activeOpacity={0.8}
							onPress={() => onToday()}
							style={styles.todayButton}
						>
							<TextWithFont style={styles.todayButtonText}>
								{i18n.t('calendarList.today')}
							</TextWithFont>
						</TouchableOpacity>
						<TouchableOpacity
							activeOpacity={0.8}
							onPress={() => onPrevDate()}
							style={styles.prevNextButton}
						>
							<MaterialCommunityIcons
								name="chevron-left"
								size={30}
								color={theme.colors.inputText}
							/>
						</TouchableOpacity>
						<TouchableOpacity
							activeOpacity={0.8}
							onPress={() => onNextDate()}
							style={styles.prevNextButton}
						>
							<MaterialCommunityIcons
								name="chevron-right"
								size={30}
								color={theme.colors.inputText}
							/>
						</TouchableOpacity>
						<TextWithFont style={styles.actionTitle}>
							{getDateText(date, componentType === 'day')}
						</TextWithFont>
					</View>
					<View style={styles.calendarPaddingContainer}>
						<Calendar
							scrollOffsetMinutes={new Date().getHours() * 60}
							theme={calendarTheme}
							headerContentStyle={{
								justifyContent: 'center',
								alignItems: 'center',
								borderBottomColor: theme.colors.line,
								borderBottomWidth: 1,
								borderLeftColor: theme.colors.line,
								borderLeftWidth: 1,
								paddingHorizontal: theme.spacing.xs,
								minHeight: 60
							}}
							dayHeaderStyle={{
								width: '100%'
							}}
							hourStyle={{
								fontSize: theme.fontSizes.sm,
								color: theme.colors.inputText
							}}
							dayHeaderHighlightColor={theme.colors.accent}
							weekDayHeaderHighlightColor={theme.colors.accent}
							showAllDayEventCell={false}
							maxVisibleEventCount={1}
							events={events}
							height={
								(componentType === 'month' || componentType === 'week') &&
								props.screenSize != 'lg'
									? 500
									: 600
							}
							eventMinHeightForMonthView={18}
							eventCellStyle={{
								paddingVertical: 0,
								paddingHorizontal: 4
							}}
							date={date}
							ampm={true}
							mode={componentType}
							locale={localeString}
							onPressEvent={eventClick}
							onPressCell={componentType === 'month' && cellClick}
							renderEvent={(event, touchableOpacityProps) => (
								<TouchableOpacity
									activeOpacity={0.8}
									{...touchableOpacityProps}
								>
									<TextWithFont style={styles.eventTitle}>
										{event.title}
									</TextWithFont>
									{!!event?.description && (
										<TextWithFont style={styles.eventDesc}>
											{event.description}
										</TextWithFont>
									)}
								</TouchableOpacity>
							)}
						/>
					</View>
					{selectedDayEvents?.length > 0 ? (
						<View
							style={styles.mainContainer}
							onLayout={(event) => {
								const layout = event.nativeEvent.layout

								props.scrollTo(layout.top ?? layout.y)
							}}
						>
							{selectedDayEvents.map((eventGroup, index) => (
								<View key={index} style={styles.paddingContainer}>
									<TextWithFont
										style={{
											marginBottom: 5,
											fontSize: theme.fontSizes.sm,
											color: theme.colors.text
										}}
									>
										{eventGroup.date}
									</TextWithFont>
									{eventGroup.events?.map((x, j) => {
										return x.start ? (
											<CalendarListItem
												key={j}
												eventData={x}
												localeString={localeString}
												onPressEvent={eventClick}
											/>
										) : null
									})}
								</View>
							))}
						</View>
					) : null}
				</View>
			)}
		</View>
	) : null

	// <View style={[styles.indicatorContainer, styles.indicatorHorizontal]}>
	// 		<ActivityIndicator
	// 			size="small"
	// 			color={theme.colors.accent}
	// 			style={styles.indicator}
	// 		/>
	// </View>
})

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		indicatorContainer: {
			flex: 1,
			justifyContent: 'center'
		},
		indicatorHorizontal: {
			flexDirection: 'row',
			justifyContent: 'space-around',
			padding: 10
		},
		indicator: {
			paddingTop: 10,
			paddingBottom: 10
		},
		mainContainer: {
			position: 'relative',
			paddingBottom: theme.spacing.sm
		},
		paddingContainer: {
			paddingVertical: theme.spacing.sm
		},
		calendarPaddingContainer: {
			paddingVertical: theme.spacing.sm
		},
		typeButton: {
			position: 'absolute',
			top: 10,
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'flex-end',
			backgroundColor: theme.colors.text,
			width: 40,
			height: 40,
			borderRadius: 20,
			zIndex: 100,
			elevation: 100
		},
		calendarActions: {
			paddingBottom: theme.spacing[props.block.spacing],
			paddingTop:
				props.screenSize !== 'lg' && theme.spacing[props.block.spacing],
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: props.screenSize === 'lg' ? 'flex-start' : 'center'
		},
		actionTitle: {
			color: theme.colors.text,
			fontSize:
				props.screenSize === 'lg' ? theme.fontSizes.lg : theme.fontSizes.md,
			marginLeft: 20
		},
		todayButton: {
			borderRadius: 17,
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 20
		},
		todayButtonText: {
			color: theme.colors.text,
			fontSize:
				props.screenSize === 'lg' ? theme.fontSizes.lg : theme.fontSizes.md
		},
		prevNextButton: {
			borderRadius: 17,
			justifyContent: 'center',
			alignItems: 'center'
		},
		buttonPassive: {
			backgroundColor: 'transparent'
		},
		buttonActive: {
			backgroundColor: theme.colors.text
		},
		buttonText: {
			fontWeight: '600',
			color: theme.colors.text
		},
		buttonTextActive: {
			color: theme.colors.layoutBackground
		},
		eventTitle: {
			alignSelf: 'baseline',
			fontSize:
				props.screenSize === 'lg'
					? theme.fontSizes.md
					: props.screenSize === 'md'
					? theme.fontSizes.sm
					: theme.fontSizes.xs,
			color: '#FFFFFF'
		},
		eventDesc: {
			alignSelf: 'baseline',
			fontSize:
				props.screenSize === 'lg' ? theme.fontSizes.sm : theme.fontSizes.xs,
			color: '#FFFFFF'
		},
		moreContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: 40,
			minWidth: 130
		},
		moreText: {
			fontSize: theme.fontSizes.sm,
			fontWeight: 'bold',
			color: theme.colors.textLight
		},
		noRecords: {
			flex: 1,
			paddingTop: theme.spacing.xs,
			paddingBottom: theme.spacing.sm,
			fontSize: theme.fontSizes.sm,
			color: theme.colors.textLight
		}
	})
}

const mapStateToProps = (state) => ({
	...state.screenSize,
	...state.screenWidth,
	...state.components
})

const mapDispatchToProps = (dispatch) => {
	return {
		addComponentData: (uuid, data, config, time) =>
			dispatch(addComponentData(uuid, data, config, time)),
		removeComponentData: (uuid) => dispatch(removeComponentData(uuid))
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(CalendarList)
