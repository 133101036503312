import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'

// import SearchBox from './SearchBox'
import TextWithFont from './TextWithFont'
import ActionButton from './ActionButton'

const SimpleHeader = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	return (
		<View style={styles.headerMainContainer}>
			<View style={styles.header}>
				<View style={styles.sectionLeft}>
					<View>
						<ActionButton onPress={() => props.cancel()} icon={'close'} />
					</View>
				</View>

				<View style={styles.sectionMid}>
					<TextWithFont style={styles.pageTitle} numberOfLines={1}>
						{props.title}
					</TextWithFont>
				</View>

				<View style={styles.sectionRight}></View>
			</View>
		</View>
	)
}

export default SimpleHeader

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		headerMainContainer: {
			backgroundColor: opacity(theme.colors.headerBackground, 0.97),
			flexDirection: 'column',
			minWidth: 30,
			borderBottomColor: theme.colors.line,
			borderBottomWidth: 1
		},
		header: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingVertical: 8
		},
		pageTitle: {
			color: theme.colors.headerText,
			fontSize: theme.fontSizes.md,
			fontWeight: 'bold',
			minWidth: 30
		},
		sectionLeft: {
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'row',
			width: 68
		},
		sectionRight: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'row',
			width: 68
		},
		sectionMid: {
			flex: 1,
			justifyContent: 'center',
			flexDirection: 'row'
		}
	})
}
