import { formatDistance } from 'date-fns'
import { enUS, tr } from 'date-fns/locale'

import getLanguageCode from './getLanguageCode'
import localeParseDate from './localeParseDate'

const locales = {
	tr: tr,
	en: enUS
}

const languageCode = getLanguageCode()
const localeString = languageCode === 'tr-TR' ? 'tr' : 'en'

const localeDateDistance = (date) => {
	const d = localeParseDate(date)

	return formatDistance(d, new Date(), {
		addSuffix: false,
		locale: locales[localeString]
	})
}

export default localeDateDistance
