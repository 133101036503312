import React, { useEffect } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { connect } from 'react-redux'
import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'
import getMappingValByKey from '../common/methods/getMappingValByKey'
import nav from '../common/methods/nav'
import triggerActionList from '../common/methods/triggerActionList'

// import SearchBox from './SearchBox'
import TextWithFont from './TextWithFont'
import ActionButton from './ActionButton'

const Header = (props) => {
	const theme = useTheme()
	const hasBackgroundImage =
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.default ||
		!!props.block?.screenConfig?.mapping?.backgroundImageUrl?.field

	const styles = themedStyles(props, theme, hasBackgroundImage)

	const showMenu = () => {
		props.block?.navigation?.openDrawer()
	}

	return (
		<View style={styles.mainContainer}>
			<View style={styles.header}>
				<View style={styles.sectionLeft}>
					{props.block?.canGoBack ? (
						!props.block.screenConfig?.style ||
						props.block.screenConfig?.style === 'screen' ? (
							<View>
								<ActionButton
									onPress={() => nav.navigateBack()}
									icon={'arrow-back'}
								/>
							</View>
						) : (
							<View>
								<ActionButton
									onPress={() => nav.navigateBack()}
									icon={'close'}
								/>
							</View>
						)
					) : (
						!!props?.generatorData?.screens?.drawers?.length &&
						props.block.screenConfig?.style === 'screen' && (
							<View>
								<ActionButton onPress={() => showMenu()} icon={'menu'} />
							</View>
						)
					)}
					{!!props.block?.buttons?.left?.length &&
						props.block.buttons.left.map((item, index) => (
							<View key={`left_${index}`}>
								<ActionButton
									onPress={() =>
										triggerActionList(
											props.block,
											item.actions,
											props.block?.route?.params?.id
										)
									}
									icon={item.icon}
								/>
							</View>
						))}
				</View>

				<View style={styles.sectionMid}>
					{props.block?.showLogo && props.config.appearence?.Logo ? (
						<View style={styles.logoContainer}>
							<Image
								resizeMode="contain"
								resizeMethod="auto"
								style={{
									width: '100%',
									height: '100%'
								}}
								source={{
									uri: props.config.appearence.Logo
								}}
							/>
						</View>
					) : (
						<TextWithFont style={styles.pageTitle} numberOfLines={1}>
							{getMappingValByKey(props, 'title')}
						</TextWithFont>
					)}
				</View>

				<View style={styles.sectionRight}>
					{!!props.block?.buttons?.right?.length &&
						props.block.buttons.right.map((item, index) => {
							const text = getMappingValByKey(
								{
									block: {
										...item
									}
								},
								'text'
							)

							return (
								<TouchableOpacity
									key={`right_${index}`}
									activeOpacity={0.8}
									onPress={() =>
										triggerActionList(
											props.block,
											item.actions,
											props.block?.route?.params?.id
										)
									}
									style={
										text && {
											flex: 1,
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'flex-end'
										}
									}
								>
									<Ionicons
										name={item.icon}
										size={24}
										style={styles.buttonIcon}
									/>
									{!item.icon && text && (
										<TextWithFont
											style={{
												color: theme.colors.headerText,
												fontSize: theme.fontSizes.md,
												marginLeft: 5
											}}
											numberOfLines={1}
										>
											{text}
										</TextWithFont>
									)}
								</TouchableOpacity>
							)
						})}
				</View>
			</View>
			{/* {props.block?.searcheable && (
						<SearchBox
							block={{
								spacing: 'xl',
								search: props.block?.search,
								searchPlaceholder: getLocalizeContent(
									props.block?.searchPlaceholder
								)
							}}
						/>
					)} */}
		</View>
	)
}

const mapStateToProps = (state) => ({
	...state.config,
	...state.generatorData
})

export default connect(mapStateToProps, null)(Header)

const themedStyles = (props, theme, hasBackgroundImage) => {
	return StyleSheet.create({
		mainContainer: {
			backgroundColor: hasBackgroundImage
				? opacity(theme.colors.headerBackground, 0.2)
				: opacity(theme.colors.headerBackground, 0.97),
			flexDirection: 'column',
			minWidth: 30,
			borderBottomColor: hasBackgroundImage
				? opacity(theme.colors.line, 0.2)
				: theme.colors.line,
			borderBottomWidth: 1,
			paddingHorizontal: theme.spacing.xl,
			paddingTop:
				props.block.screenConfig?.style &&
				props.block.screenConfig?.style === 'modal'
					? theme.spacing.xs
					: 0
		},
		header: {
			height:
				28 +
				(!props.block.screenConfig?.style ||
				props.block.screenConfig?.style === 'screen'
					? theme.spacing.sm
					: theme.spacing.xs) *
					2,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingTop:
				!props.block.screenConfig?.style ||
				props.block.screenConfig?.style === 'screen'
					? theme.spacing.sm
					: 0,
			paddingBottom:
				!props.block.screenConfig?.style ||
				props.block.screenConfig?.style === 'screen'
					? theme.spacing.sm
					: 0
		},
		buttonIcon: {
			color: theme.colors.accent
		},
		pageTitle: {
			color: theme.colors.headerText,
			fontSize: theme.fontSizes.md,
			fontWeight: 'bold',
			minWidth: 30
		},
		actionColor: {
			color: theme.colors.accent
		},
		logoContainer: {
			minWidth: 150,
			height: 34
		},
		sectionLeft: {
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'row',
			width: 68
		},
		sectionRight: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'row',
			width: 68,
			gap: 5
		},
		sectionMid: {
			flex: 1,
			justifyContent: 'center',
			flexDirection: 'row'
		}
	})
}
