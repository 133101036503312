import linkService from '../../../services/linkService'
import routeService from '../../../services/routeService'

import store from '../../../redux/store/store'

const navigateByKey = (key, appendToOldPath = true) => {
	const generatorData = store.getState()?.generatorData?.generatorData
	const screenPath = generatorData.linking.config?.screens?.[key]

	if (screenPath) {
		const target = `/${screenPath}`
		if (appendToOldPath) {
			let path = routeService.route.path

			if (!path) {
				const parentPath =
					generatorData.linking.config?.screens?.[routeService.route?.name]

				path = `/${parentPath}`
			}

			const createdNavigatelink = `${path}${target}`

			linkService.linkTo(createdNavigatelink)
		} else {
			linkService.linkTo(target)
		}
	} else {
		console.log('SCREEN_KEY_NOT_FOUND')
	}
}

export default navigateByKey
