import React, { forwardRef } from 'react'

import TextEntry from './TextEntry'

const InputMail = forwardRef((props, ref) => {
	return (
		<TextEntry
			ref={ref}
			// mask={}
			// maxLength={}
			// minLength={}
			regExp={
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			}
			isRegExString={false}
			{...props.block}
		/>
	)
})

export default InputMail
