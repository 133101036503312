import linkService from '../../../services/linkService'
import routeService from '../../../services/routeService'

import navigateHome from './navigateHome'
import removeLastScreenFromPath from './removeLastScreenFromPath'

import store from '../../../redux/store/store'

import {
	ADDROUTEDATA,
	REMOVEROUTEDATA
} from '../../../redux/action-types/routeDataActionTypes'

const navigateBack = (xTimes = 1) => {
	const path = routeService.route?.path

	if (routeService.route?.name) {
		store.dispatch({
			type: REMOVEROUTEDATA,
			name: routeService.route?.name
		})
	}

	if (path) {
		let target = '/'

		target = removeLastScreenFromPath(path)

		if (xTimes > 1) {
			for (let index = 0; index < xTimes - 1; index++) {
				target = removeLastScreenFromPath(target)
			}
		}

		if (target && target != '/') {
			linkService.linkTo(target)
		} else {
			linkService.linkTo('/')
		}
	} else {
		navigateHome()
	}
}

export default navigateBack
