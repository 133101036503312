import React, { useEffect, useRef } from 'react'
import { Platform, TouchableOpacity, StatusBar, StyleSheet } from 'react-native'
import { useTheme } from '../theme/Theme'
import { opacity } from 'simpler-color'

import ActionSheet from 'react-native-actions-sheet'

// import ActionSheet, { SheetManager } from 'react-native-actions-sheet'
// import { registerSheet } from 'react-native-actions-sheet'

// registerSheet('filter-bottom-sheet', CustomBottomSheet)
// registerSheet('screen-bottom-sheet', CustomBottomSheet)

const CustomBottomSheet = (props) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)
	const actionSheetRef = useRef(null)

	const content = () => {
		return (
			<TouchableOpacity style={styles.modalContainer} activeOpacity={1}>
				{Platform.OS !== 'web' && (
					<StatusBar
						barStyle={theme.statusBar.style}
						backgroundColor={theme.colors.modalOverlay}
					/>
				)}

				{props.children}
			</TouchableOpacity>
		)
	}

	useEffect(() => {
		if (props.isVisible) {
			actionSheetRef.current?.show()
			// SheetManager.show(props.name)
		} else {
			actionSheetRef.current?.hide()
			// SheetManager.hide(props.name)
		}
	}, [props.isVisible, props.name])

	return (
		<ActionSheet
			// id={props.name}
			isModal={props.coverScreen ?? false}
			focusTrap={false}
			ref={actionSheetRef}
			animated={true}
			onClose={() => {
				props.cancel && props.cancel()
			}}
			indicatorStyle={{
				backgroundColor: theme.colors.inputBackground
			}}
			containerStyle={{
				backgroundColor: theme.colors.layoutBackground,
				borderTopLeftRadius: theme.radiuses.md,
				borderTopRightRadius: theme.radiuses.md
			}}
			// snapPoints={[50, 100]}
			// initialSnapIndex={1}
			statusBarTranslucent
			drawUnderStatusBar={true}
			gestureEnabled={true}
			overlayColor={theme.colors.modalOverlay}
			defaultOverlayOpacity={1}
		>
			{content()}
		</ActionSheet>
	)
}

export default CustomBottomSheet

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		modalContainer: {
			width: '100%',
			maxHeight: '100%'
			// paddingBottom: theme.spacing.xl
		}
	})
}
