import {
	SETUSERINFO,
	SETGHOSTUSER,
	SETORGID
} from '../action-types/userInfoActionTypes'

export const setUserInfo = (value) => ({
	type: SETUSERINFO,
	payload: value
})

export const setGhostUser = (value) => ({
	type: SETGHOSTUSER,
	payload: value
})

export const setOrgId = (value) => ({
	type: SETORGID,
	payload: value
})
