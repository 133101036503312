import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { useLinkTo } from '@react-navigation/native'

import ScrollableContainer from './ScrollableContainer'
import ScreenContainer from './ScreenContainer'
import TabBar from './TabBar'
import HeaderWeb from './HeaderWeb'

import '../global/variables'
import linkService from '../services/linkService'
import routeService from '../services/routeService'
import { useTheme } from '../theme/Theme'

const AuthScreenComponent = ({ navigation, route, ...props }) => {
	const theme = useTheme()
	const styles = themedStyles(props, theme)

	/*********NAV_SERVICES_INIT*********/
	const linkTo = useLinkTo()

	// const route = useRoute()
	useEffect(() => {
		linkService.linkTo = linkTo
	}, [linkTo])

	useEffect(() => {
		routeService.route = route
	}, [route])
	/********************/

	return (
		<ScreenContainer>
			{props.screenSize === 'lg' && (
				<HeaderWeb
					block={{
						screenConfig: props.screenConfig,
						route: route,
						screens: props.screens,
						navigation: navigation,
						userInfo: props.userInfo
					}}
				/>
			)}

			<ScrollableContainer
				style={styles.defaultPadding}
				showsVerticalScrollIndicator={false}
			>
				{props.children}
			</ScrollableContainer>

			{props.screenSize !== 'lg' && (
				<TabBar
					screens={props.screens}
					route={route}
					navigation={navigation}
					userInfo={props.userInfo}
				/>
			)}
		</ScreenContainer>
	)
}

// export default AuthScreenComponent

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.screenSize,
	...state.screenWidth
})

export default connect(mapStateToProps, null)(AuthScreenComponent)

const themedStyles = (props, theme) => {
	return StyleSheet.create({
		defaultPadding: {
			flex: 1,
			paddingBottom: props.screenConfig?.style === 'modal' ? 20 : 45
		}
	})
}
