import { SETSCREENSIZE } from '../action-types/screenSizeActionTypes'

const initialState = {
	screenSize: 'sm' // sm, md, lg
}

const screenSizeReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETSCREENSIZE:
			return { ...state, screenSize: action.payload }

		default:
			return state
	}
}

export default screenSizeReducer
