import getLimitedNumber from './getLimitedNumber'

const getLimitedWidthByPlatform = (
	platform,
	width,
	maxWidth,
	isUpperLimit = true
) => {
	if (platform == 'web') {
		return getLimitedNumber(width, maxWidth, isUpperLimit)
	} else {
		return width
	}
}

export default getLimitedWidthByPlatform
