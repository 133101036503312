import resolveDataByPath from './resolveDataByPath'

const extractActionResultFromActions = (actions, field) => {
	const fieldText = field?.replace('Action.', '')
	if (!fieldText) return null

	const indexOfPoint = fieldText.indexOf('.')
	const index = fieldText.substring(0, indexOfPoint)
	const path = fieldText.substring(indexOfPoint + 1, fieldText.length)
	const result = actions?.[index]?.result
	const resolvedData = Array.isArray(result)
		? result.map((item) => {
				return resolveDataByPath(path, item)
		  })
		: resolveDataByPath(path, result)

	return resolvedData
}

export default extractActionResultFromActions
