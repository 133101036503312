import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { opacity } from 'simpler-color'

import { useTheme } from '../theme/Theme'

const IconButton = (props) => {
	const theme = useTheme()
	const styles = themedStyles(theme)

	return (
		<TouchableOpacity
			activeOpacity={0.8}
			style={styles.button}
			onPress={props.clickHandler}
		>
			<MaterialCommunityIcons
				name={props.icon}
				size={18}
				style={styles.buttonIcon}
			/>
		</TouchableOpacity>
	)
}

export default IconButton

const themedStyles = (theme) => {
	return StyleSheet.create({
		button: {
			backgroundColor: opacity(theme.colors.accent, 0.2),
			width: 40,
			height: 40,
			borderRadius: 20,
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: 10
		},
		buttonIcon: {
			color: theme.colors.accent
		}
	})
}
