import React, { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Platform, Dimensions } from 'react-native'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import Cookies from 'universal-cookie'
import Toast from 'react-native-toast-message'

import { connect } from 'react-redux'
import { setTheme } from '../src/redux/actions/themeActions'
import { setScreenSize } from './redux/actions/screenSizeActions'
import { setScreenWidth } from './redux/actions/screenWidthActions'
import { setWindowWidth } from './redux/actions/windowWidthActions'
import { setGeneratorData } from '../src/redux/actions/generatorDataActions'
import { setBuilderOnMobile } from '../src/redux/actions/setBuilderOnMobileActions'
import {
	setUserInfo,
	setOrgId,
	setGhostUser
} from './redux/actions/userInfoActions'
import { setAuth } from './redux/actions/authActions'
import { setConfig } from './redux/actions/configActions'

import Navigation from './navigation/Navigation'
import { ThemeProvider } from './theme/Theme'
// import Loader from './components/Loader'
import authorizationClient from './services/authorizationClient'
import pushNotificationClient from './services/pushNotificationClient'
import Themes from './config/themes/themes'
import platformClient from './services/platformClient'
import tokenServices from './services/tokenServices'
import playClient from './services/playClient'
import cryptoServices from './services/cryptoServices'
import '../src/global/variables'
import shortNameExtractor from '../src/services/shortNameExtractor'
import AppContainer from './AppContainer'

import toastConfig from './config/toastConfig'
import i18n from './i18n/i18n'

import showToast from './common/methods/showToast'
import { setClientId, removeClientId } from './common/methods/ClientHelper'
import { setStoreData } from './common/methods/StoreData'
import nav from './common/methods/nav'
import { getStoreData } from './common/methods/StoreData'
import getLimitedWidthByPlatform from './common/methods/getLimitedWidthByPlatform'

import useFonts from './hooks/useFonts'

import env from '../env'

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false
	})
})

const AppProvider = (props) => {
	const [updateData, setUpdateData] = useState(null)
	const [updateConfig, setUpdateConfig] = useState(null)
	const [shortName, setShortName] = useState(null)
	const [previewAppId, setPreviewAppId] = useState(null)
	const [clientIdReady, setClientIdReady] = useState(false)

	const [ghostUserInfo, setGhostUserInfo] = useState(true)

	const [pushToken, setPushToken] = useState('')
	const [pushTokenRegistered, setPushTokenRegistered] = useState(false)
	const [notification, setNotification] = useState(false)
	const notificationListener = useRef()
	const responseListener = useRef()

	const isPreviewApp = env.isPreview
	const isLocalConfig = env.useLocalConfig
	const cookies = new Cookies()

	const [containerWidth, setContainerWidth] = useState(
		getLimitedWidthByPlatform(Platform.OS, props.windowWidth, 1186)
	)

	const LoadFonts = async () => {
		await useFonts()
	}

	const resizeListener = (e) => {
		const width = Dimensions.get('window').width

		props.setWindowWidth(width)

		setContainerWidth(getLimitedWidthByPlatform(Platform.OS, width, 1186))
	}

	const messageListener = (event) => {
		if (
			event.origin != 'http://localhost:3000' &&
			event.origin != 'https://localhost:3000' &&
			event.origin != 'https://builder.kozmik.io'
		) {
			// TODO designer domaini verilecek
			return
		} else if (event.data.source === 'kozmik-builder') {
			// event.data?.theme && props.changeTheme(event.data.theme)
			// event.data?.generatorData &&
			// 	props.changeGeneratorData(event.data.generatorData)
			if (event.data?.updatedProp) {
				setUpdateData(event.data.updatedProp)
			} else {
				setGhostUserInfo(event.data?.ghostUser)
			}

			if (event.data?.updatedConfig) {
				setUpdateConfig(event.data.updatedConfig)
			}

			if (event.data?.shortName && shortName != event.data.shortName) {
				setShortName(event.data.shortName)
			}

			if (event.data?.appId && previewAppId != event.data.appId) {
				setPreviewAppId(event.data.appId)
			}

			if (event.data?.orgId) {
				props.setOrgId(event.data.orgId)
			}

			if (event.data?.user) {
				props.setUserInfo(event.data.user)
			}

			if (
				event.data?.builderOnMobile !== undefined &&
				event.data?.builderOnMobile !== null
			) {
				props.setBuilderOnMobile(event.data.builderOnMobile)
			}
		} else if (event.data.source === 'kozmik-screens') {
			event.data?.configData && props.setGeneratorData(event.data.configData)
			event.data?.navigate && nav.navigateByKey(event.data.navigate.key, false)

			if (event.data?.scrollToId) {
				const element = document.getElementById(event.data.scrollToId)
				if (element) {
					element.scrollIntoView({
						block: 'end',
						behavior: 'smooth'
					})
				}
			}

			// event.data?.navigate && event.data.navigate.key
			// 	navigationRef.current.navigate(
			// 		event.data.navigate.key,
			// 		event.data.navigate.detailKey
			// 	)
			// event.data?.navigationReset &&
			// 	navigationRef.current.reset(event.data.navigationReset)
		}
	}

	const pushRegistrationDone = (response) => {
		if (response?.data?.success) {
			setPushTokenRegistered(true)
		}
	}

	const pushNotificationRegister = async () => {
		let data = {
			token: pushToken,
			osVersion: Device?.osVersion,
			brand: Device?.brand,
			modelName: Device?.modelName,
			osName:
				Platform.OS === 'web'
					? 'Web'
					: Platform.OS === 'android'
					? 'Android'
					: Platform.OS === 'ios'
					? 'iOS'
					: 'Web'
		}

		await pushNotificationClient.register(data, pushRegistrationDone)
	}

	const getGhostToken = async (userId) => {
		return await authorizationClient
			.ghost({ userId: userId })
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
				} else {
					showToast(
						'error',
						response?.data?.message ||
							response?.data?.errorDetails ||
							i18n.t('api.error')
					)
				}
			})
	}

	const getUserInfo = async () => {
		return await authorizationClient.getUserInfo().then((response) => {
			if (response?.status === 200) {
				props.setUserInfo(response.data)
			} else {
				showToast(
					'error',
					response?.data?.message ||
						response?.data?.errorDetails ||
						i18n.t('api.error')
				)
			}
		})
	}

	const updateReduxThemeByConfig = (config) => {
		const themeName = config?.appearence?.Theme
		const theme = JSON.parse(JSON.stringify(Themes?.[themeName]))

		const appearence = config?.appearence

		if (appearence != null && appearence != undefined && appearence != {}) {
			if (appearence?.AccentColor) {
				theme.colors['accent'] = appearence.AccentColor
			} else {
				theme.colors['accent'] =
					Themes[config.appearence.Theme].colors['accent']
			}

			if (appearence?.FontFamily) {
				theme.fontFamily = appearence.FontFamily
			}
		}

		props.changeTheme(theme)
	}

	const updateReduxConfigProp = (objectName, propName, value) => {
		let tempData = { ...props.config }
		tempData[objectName][propName] = value
		props.setConfig(tempData)
	}

	const updateReduxConfig = (objectName, value) => {
		let tempData = { ...props.config }
		tempData[objectName] = value
		props.setConfig(tempData)
	}

	const shadowCookies = () => {
		const platformToken = cookies.get(global.PLATFORM_TOKEN_KEY)
		const platformRefreshToken = cookies.get(global.PLATFORM_REFRESH_TOKEN_KEY)

		cookies.set(global.TOKEN_KEY, platformToken, {
			path: '/',
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
		})
		cookies.set(global.REFRESH_TOKEN_KEY, platformRefreshToken, {
			path: '/',
			sameSite: env.env === 'DEV' ? 'lax' : 'none',
			secure: env.env === 'DEV' ? false : true
		})
	}

	useEffect(() => {
		LoadFonts()

		if (isPreviewApp) {
			shadowCookies()

			window.parent.postMessage(
				{ source: 'kozmik-preview', previewLoad: true },
				'*'
			) //NOTE:init detection for platform
			window.addEventListener('message', messageListener)
		} else if (!props?.ssr) {
			const short_name = shortNameExtractor(window?.location?.host)
			setShortName(short_name)
		}

		if (Platform.OS === 'web') {
			window.addEventListener('resize', resizeListener)
		} else {
			registerForPushNotificationsAsync().then((token) => setPushToken(token))

			// This listener is fired whenever a notification is received while the app is foregrounded
			notificationListener.current =
				Notifications.addNotificationReceivedListener((notification) => {
					setNotification(notification)
				})

			// This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
			responseListener.current =
				Notifications.addNotificationResponseReceivedListener((response) => {
					console.log(response)
				})
		}

		return () => {
			if (isPreviewApp) {
				window.removeEventListener('message', messageListener)
				window.removeEventListener('resize', resizeListener)
			}

			if (Platform.OS !== 'web') {
				Notifications.removeNotificationSubscription(
					notificationListener.current
				)
				Notifications.removeNotificationSubscription(responseListener.current)
			}
		}
	}, [])

	useEffect(() => {
		if (
			clientIdReady &&
			pushToken &&
			(!pushTokenRegistered || (pushTokenRegistered && props.auth))
		) {
			pushNotificationRegister()
		}
	}, [pushToken, clientIdReady, props.auth])

	useEffect(() => {
		if (!props.auth) {
			if (Platform.OS !== 'web') {
				getStoreData(global.TOKEN_KEY).then(async (token) => {
					if (token === undefined || token === null) {
						console.log('NAVIGATE_LOGIN')
					} else {
						props.setAuth(true)
					}
				})
			} else {
				const token = cookies.get(global.TOKEN_KEY)
				if (token === undefined || token === null) {
					if (props?.config?.settings?.sign_in_required) {
						console.log('NAVIGATE_LOGIN')
					}
				} else {
					props.setAuth(true)
				}
			}
		}
	}, [props.auth])

	useEffect(() => {
		if (!isPreviewApp && clientIdReady && props.auth) {
			getUserInfo()
		}
	}, [clientIdReady, props.auth])

	useEffect(() => {
		if (isPreviewApp && clientIdReady) {
			if (ghostUserInfo) {
				getGhostToken(ghostUserInfo.Id).then(() => {
					getUserInfo().then(() => {
						props.setGhostUser(ghostUserInfo)
					})
				})
			} else {
				props.setGhostUser(null)

				shadowCookies()

				getUserInfo()
			}
		}
	}, [clientIdReady, ghostUserInfo])

	useEffect(() => {
		if (!isPreviewApp) {
			removeClientId()

			if (props?.ssr) {
				if (Platform.OS === 'web') {
					if (props.ssr?.config?.appearence?.Logo) {
						//FAVICON
						let faviconLink = document.querySelector(
							'link[rel~="shortcut icon"]'
						)
						if (!faviconLink) {
							faviconLink = document.createElement('link')
							faviconLink.rel = 'shortcut icon'
							document.getElementsByTagName('head')[0].appendChild(faviconLink)
						}
						faviconLink.href = props.ssr.config.appearence.Logo
					}

					props.ssr?.config?.settings?.api_key &&
						cookies.set(global.API_KEY, props.ssr?.config?.settings?.api_key, {
							path: '/',
							sameSite: env.env === 'DEV' ? 'lax' : 'none',
							secure: env.env === 'DEV' ? false : true
						})
				} else {
					props.ssr?.config?.settings?.api_key &&
						setStoreData(global.API_KEY, props.ssr?.config?.settings?.api_key)
				}

				props.setConfig(props.ssr?.config)
				setClientId(props.ssr?.config?.id).then(() => {
					setClientIdReady(true)
				})
				props.setGeneratorData(props.ssr?.screens)
			} else if (isLocalConfig) {
				//NOTE:local configs are only available for mobile

				if (Platform.OS === 'web') {
					props.config?.settings?.api_key &&
						cookies.set(global.API_KEY, props.config?.settings?.api_key, {
							path: '/',
							sameSite: env.env === 'DEV' ? 'lax' : 'none',
							secure: env.env === 'DEV' ? false : true
						})
				} else {
					props.config?.settings?.api_key &&
						setStoreData(global.API_KEY, props.config?.settings?.api_key)
				}

				setClientId(props.config.id).then(() => {
					setClientIdReady(true)
				})
			} else {
				playClient.getFeatures().then(async (response) => {
					if (response?.status === 200 && response?.data) {
						const decryptedData = cryptoServices.decrypt(response.data)
						const parsedData = JSON.parse(decryptedData)

						if (Platform.OS === 'web') {
							if (parsedData?.appearence?.Logo) {
								//FAVICON
								let faviconLink = document.querySelector(
									'link[rel~="shortcut icon"]'
								)
								if (!faviconLink) {
									faviconLink = document.createElement('link')
									faviconLink.rel = 'shortcut icon'
									document
										.getElementsByTagName('head')[0]
										.appendChild(faviconLink)
								}
								faviconLink.href = parsedData.appearence.Logo
							}

							parsedData?.settings?.api_key &&
								cookies.set(global.API_KEY, parsedData?.settings?.api_key, {
									path: '/',
									sameSite: env.env === 'DEV' ? 'lax' : 'none',
									secure: env.env === 'DEV' ? false : true
								})
						} else {
							parsedData?.settings?.api_key &&
								setStoreData(global.API_KEY, parsedData?.settings?.api_key)
						}

						props.setConfig(parsedData)
						await setClientId(parsedData.id)
						setClientIdReady(true)
					} else {
						showToast(
							'error',
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
				})
			}
		}
	}, [shortName])

	useEffect(() => {
		if (isPreviewApp) {
			removeClientId()

			previewAppId &&
				platformClient.getFeatures(previewAppId).then(async (response) => {
					if (response?.status === 200 && response?.data) {
						const decryptedData = cryptoServices.decrypt(response.data)
						const parsedData = JSON.parse(decryptedData)

						props.setConfig(parsedData)
						await setClientId(parsedData.id)
						setClientIdReady(true)
					} else {
						showToast(
							'error',
							response?.data?.message ||
								response?.data?.errorDetails ||
								i18n.t('api.error')
						)
					}
				})
		}
	}, [previewAppId])

	useEffect(() => {
		const actualScreenSize =
			containerWidth <= 415 ? 'sm' : containerWidth <= 821 ? 'md' : 'lg'

		props.setScreenWidth(containerWidth)

		if (props.screenSize !== actualScreenSize)
			props.setScreenSize(actualScreenSize)
	}, [props.windowWidth, containerWidth])

	useEffect(() => {
		if (props.config) {
			updateReduxThemeByConfig(props.config)
		}
	}, [props.config])

	useEffect(() => {
		updateData &&
			updateReduxConfigProp(
				updateData.objectName,
				updateData.propName,
				updateData.value
			)
	}, [updateData])

	useEffect(() => {
		updateConfig &&
			updateReduxConfig(updateConfig.objectName, updateConfig.value)
	}, [updateConfig])

	const navigationRef = useRef()

	return (
		<ThemeProvider theme={props.theme}>
			{clientIdReady && (
				<AppContainer
					auth={props.auth}
					pushToken={pushToken}
					setPushToken={setPushToken}
				>
					<Navigation
						ref={navigationRef}
						shortName={shortName}
						previewAppId={previewAppId}
						ssrData={props?.ssr}
					/>

					{/* {props.fetching > 0 && <Loader />} */}

					<Toast config={toastConfig} />
				</AppContainer>
			)}
		</ThemeProvider>
	)
}

async function registerForPushNotificationsAsync() {
	let token
	if (Device.isDevice) {
		const { status: existingStatus } = await Notifications.getPermissionsAsync()
		let finalStatus = existingStatus
		if (existingStatus !== 'granted') {
			const { status } = await Notifications.requestPermissionsAsync()
			finalStatus = status
		}
		if (finalStatus !== 'granted') {
			alert('Failed to get push token for push notification!')
			return
		}
		token = (await Notifications.getDevicePushTokenAsync()).data
	} else {
		alert('Must use physical device for Push Notifications')
	}

	if (Platform.OS === 'android') {
		Notifications.setNotificationChannelAsync('default', {
			name: 'default',
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: '#FF231F7C'
		})
	}

	return token
}

const mapStateToProps = (state) => ({
	...state.fetching,
	...state.theme,
	...state.generatorData,
	...state.userInfo,
	...state.auth,
	...state.config,
	...state.ghostUser,
	...state.screenSize,
	...state.screenWidth,
	...state.windowWidth
})

const mapDispatchToProps = (dispatch) => {
	return {
		changeTheme: (data) => dispatch(setTheme(data)),
		setGeneratorData: (x) => dispatch(setGeneratorData(x)),
		setUserInfo: (x) => dispatch(setUserInfo(x)),
		setAuth: (x) => dispatch(setAuth(x)),
		setConfig: (x) => dispatch(setConfig(x)),
		setOrgId: (x) => dispatch(setOrgId(x)),
		setGhostUser: (x) => dispatch(setGhostUser(x)),
		setScreenSize: (x) => dispatch(setScreenSize(x)),
		setScreenWidth: (x) => dispatch(setScreenWidth(x)),
		setWindowWidth: (x) => dispatch(setWindowWidth(x)),
		setBuilderOnMobile: (x) => dispatch(setBuilderOnMobile(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppProvider)
